import React, { useContext, useEffect, useState } from "react";
import {
  getUserNameInfo,
  getUsersCLients,
  getUsersClientsPlatforms,
  getUsersClientsProjects,
  getFilterUser,
  postFilterUser,
  putFilterUpdate,
} from "../services/api/usersAPI";
import { useQuery, useMutation } from "react-query";
import { DashboardContext } from "../context/Dashboard/DashboardContentx";
import { AuthContext } from "../auth/context/AuthContext";
import { message } from "antd";

export const useFilters = () => {
  const [operators, setOperators] = useState([]);
  const [operatorsId, setOperatorsId] = useState(null);
  const [leases, setLeases] = useState([]);
  const [regions, setRegions] = useState([]);
  const { User } = useContext(AuthContext);
  const { getUserDataInfo, userDataInfo } = useContext(DashboardContext);

  const {
    data: getDataUser,
    isLoading: loadGetDataUser,
    isError: ErrorGetDataUser,
    refetch: refetchGetDataUser,
  } = useQuery({
    queryKey: ["get_data_info_user"],
    queryFn: () => getUserNameInfo(User.token, User.table, User.user_email),
    retry: 2,
    enabled: false,
    onSuccess: (data) => {
      // setLeases(data.proyects)
      // setRegions(data.platforms)
      if(User.role === "Simple User"){
        const dataOperator = {
          idOperator: data.user_clientId,
          Operator: data.clients
        }
        setOperators([dataOperator]);
      }
      else{
        const arrayOperator = data.user_clientId?.map((numero, index) => {
          return {
            idOperator: numero,
            Operator: data.clients[index],
          };
        });
        setOperators(arrayOperator);
      }

    },
    onError: (error) => {},
  });

  const {
    data: dataLeaase,
    isLoading: loadGetDataLeaase,
    isError: ErrorGetDataLeaase,
    refetch: refetchGetDataLeaase,
  } = useQuery({
    queryKey: ["get_data_lease"],
    queryFn: () => getUsersClientsProjects(User.token, operatorsId),
    retry: 2,
    enabled: false,
    onSuccess: (data) => {
      const lease = getDataUser?.proyects
      setTimeout(() => {
        const resultadosFiltrados = data.filter(objeto => lease?.includes(objeto.proyect));
        // const leaseArray = resultadosFiltrados.map((item)=> {return item.proyects})
        setLeases(resultadosFiltrados);
      }, 1000);
    },
    onError: (error) => {},
  });

  const {
    data: dataRegion,
    isLoading: loadGetDataRegion,
    isError: ErrorGetDataRegion,
    refetch: refetchGetDataRegion,
  } = useQuery({
    queryKey: ["get_data_region"],
    queryFn: () => getUsersClientsPlatforms(User.token, operatorsId),
    retry: 2,
    enabled: false,
    onSuccess: (data) => {

      const region = getDataUser?.platforms
      setTimeout(() => {
        const resultadosFiltrados = data.filter(objeto => region?.includes(objeto.platform));
        // const regionArray = resultadosFiltrados.map((item)=> {return item.platform})
        setRegions(resultadosFiltrados);
      }, 1000);
    },
    onError: (error) => {},
  });

  const {
    data: dataFilter,
    isLoading: loadGetDataFilter,
    isError: ErrorGetDataFilter,
    refetch: refetchGetDataFilter,
  } = useQuery({
    queryKey: ["get_data_filter"],
    queryFn: () => getFilterUser(User.user_name),
    retry: 2,
    enabled: false,
    onSuccess: (data) => {
    },
    onError: (error) => {},
  });

  const mutationCreateFilter = useMutation({
    mutationFn: postFilterUser,
    onSuccess: () => {
      message.success(`Data Filter Created Successfull`);
    },
    onError: (error) => {
      message.error(
        `An error has occurred when loading the filte`
      );
    },
  });

  const mutationUpdateFilter = useMutation({
    mutationFn: putFilterUpdate,
    onSuccess: () => {
      message.success(`Data Filter Updated Successfull`);
    },
    onError: (error) => {
      message.error(
        `An error has occurred when loading the filter`
      );
    },
  });

  return {
    User,
    userDataInfo,
    getUserDataInfo,
    leases,
    regions,
    operators,
    getDataUser,
    refetchGetDataUser,
    refetchGetDataLeaase,
    refetchGetDataRegion,
    loadGetDataUser,
    ErrorGetDataUser,
    operatorsId,
    setOperatorsId,
    getFilterUser,
    refetchGetDataFilter,
    mutationCreateFilter,
    dataFilter,
    mutationUpdateFilter,
    setRegions,
    setLeases
  };
};
