import React, { useContext, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { message } from "antd";
import { getScreeningRank, getScreeningDonut, getScreeningDonutDepth, getScreening3DRank,  getScreening3DRankDepth, getMultipleScatter, uploadScreening, getFileScreening, getListReservoir, deleteScreening} from "../services/api/screeningToolAPI";
import { DashboardContext } from "../context/Dashboard/DashboardContentx";
import { DashboardSCTContext } from '../context/DashboardSCT/DasboardSCTContext';
import { AuthContext } from '../auth/context/AuthContext';

export const useTabScreening = () => {
    const {userDataInfo} = useContext(DashboardContext)
    const { dataFilterUserSCT} = useContext(DashboardSCTContext);
    const {User} = useContext(AuthContext)
    
    const { data: queryRankStats, isLoading: loadQueryRankStats, isError: errorQueryRankStats, refetch: refetchQueryRankStats} = useQuery({
        queryKey: ["RankStats"],
        retry: 1,
        enabled:false,
        queryFn: () =>
        getScreeningRank( userDataInfo.operator, userDataInfo.lease, userDataInfo.region, dataFilterUserSCT.county, dataFilterUserSCT.field, dataFilterUserSCT.reservoir_id, User.user_name ),
        onSuccess: (data) => {
            // message.success(`Data query Successfull`);
        },
        onError: (error) => {},
    });

    const { data: queryScreeningDonut, isLoading: loadDataScreeningDonut, isError: errorDataScreeningDonut, refetch: refetchScreeningDonut} = useQuery({
        queryKey: ["DonutRank"],
        retry: 1,
        enabled:false,
        queryFn: () =>
            getScreeningDonut( userDataInfo.operator, userDataInfo.lease, userDataInfo.region, dataFilterUserSCT.county, dataFilterUserSCT.field, dataFilterUserSCT.reservoir_id, User.user_name ),
        onSuccess: (data) => {
            // message.success(`Data query Successfull`);
        },
        onError: (error) => {},
    });

    const { data: queryScreeningDonutDepth, isLoading: loadDataScreeningDonutDepth, isError: errorDataScreeningDonutDepth, refetch: refetchScreeningDonutDepth} = useQuery({
        queryKey: ["DonutRankDepth"],
        retry: 1,
        enabled:false,
        queryFn: () =>
            getScreeningDonutDepth( userDataInfo.operator, userDataInfo.lease, userDataInfo.region, dataFilterUserSCT.county, dataFilterUserSCT.field, dataFilterUserSCT.reservoir_id, User.user_name ),
        onSuccess: (data) => {
            // message.success(`Data query Successfull`);
        },
        onError: (error) => {},
    });

    const { data: queryScreening3DRank, isLoading: loadDataScreening3DRank, isError: errorDataScreening3DRank, refetch: refetchScreening3DRank} = useQuery({
        queryKey: ["3DRank"],
        retry: 1,
        enabled:false,
        queryFn: () =>
            getScreening3DRank( userDataInfo.operator, userDataInfo.lease, userDataInfo.region, dataFilterUserSCT.county, dataFilterUserSCT.field, dataFilterUserSCT.reservoir_id, User.user_name ),
        onSuccess: (data) => {
            //   message.success(`Data query Successfull`);
        },
        onError: (error) => {
        },
    });

    const { data: queryScreening3DRankDepth, isLoading: loadDataScreening3DRankDepth, isError: errorDataScreening3DRankDepth, refetch: refetchScreening3DRankDepth} = useQuery({
        queryKey: ["3DRankDepth"],
        retry: 1,
        enabled:false,
        queryFn: () =>
            getScreening3DRankDepth( userDataInfo.operator, userDataInfo.lease, userDataInfo.region, dataFilterUserSCT.county, dataFilterUserSCT.field, dataFilterUserSCT.reservoir_id, User.user_name ),
        onSuccess: (data) => {
            // message.success(`Data query Successfull`);
        },
        onError: (error) => {},
    });
    
    const { data: queryMultipleScatter, isLoading: loadQueryMultipleScatter, isError: errorQueryMultipleScatter, refetch: refetchQueryMultipleScatter } = useQuery({
    queryKey: ["multiple_scatter_query"],
    retry: 1,
    enabled:false,
    queryFn: () =>
        getMultipleScatter( userDataInfo.operator, userDataInfo.lease, userDataInfo.region, dataFilterUserSCT.county, dataFilterUserSCT.field, dataFilterUserSCT.reservoir_id, User.user_name ),
        onSuccess: (data) => {
            // message.success(`Data query Successfull`);
        },
        onError: (error) => {
        },
    });

    const { data: queryListReservoir, isLoading: loadQueryListReservoir, isError: errorQueryListReservoir, refetch: refetchQueryListReservoir } = useQuery({
        queryKey: ["list_reservoir_query"],
        retry: 1,
        enabled:false,
        queryFn: () =>
            getListReservoir( userDataInfo.operator, userDataInfo.lease, userDataInfo.region, User.user_name ),
            onSuccess: (data) => {
            // message.success(`Data query Successfull`);
        },
        onError: (error) => {
        },
    });

    const mutationCreateScreening= useMutation({
    mutationFn: uploadScreening,
    onSuccess: () => {
        message.success(`Data Done Successfull`);
    },
    onError: (error) => {
        message.error(
        `An error has occurred at the time of create`
        );
    },
    });

    const mutationDeleteWellScreening= useMutation({
        mutationFn: deleteScreening,
        onSuccess: () => {
            message.success(`Data delete Successfull`);
        },
        onError: (error) => {
            message.error(
            `An error has occurred at the time of create`
            );
        },
        });

    const { data: queryFileScreening, isLoading: loadQueryFileScreening, isError: errorQueryFileScreening, refetch: refetchQueryFileScreening } = useQuery({
    queryKey: ["FileScreening"],
    retry: 1,
    enabled:false,
    queryFn: () =>
        getFileScreening( userDataInfo.operator, userDataInfo.lease, userDataInfo.region, dataFilterUserSCT.county, dataFilterUserSCT.field, dataFilterUserSCT.reservoir_id, User.user_name ),
        onSuccess: (data) => {
            // message.success(`Data query Successfull`);
        },
        onError: (error) => {
        },
    });

    return {
        queryRankStats,
        queryScreeningDonut,
        queryScreeningDonutDepth,
        queryScreening3DRank,
        queryScreening3DRankDepth,
        queryMultipleScatter,
        queryListReservoir,
        queryFileScreening,
        refetchQueryRankStats,
        refetchScreeningDonut,
        refetchScreeningDonutDepth,
        refetchScreening3DRank,
        refetchScreening3DRankDepth,
        refetchQueryMultipleScatter,
        refetchQueryListReservoir,
        refetchQueryFileScreening,
        loadDataScreening3DRank,
        loadDataScreening3DRankDepth,
        loadQueryListReservoir,
        mutationCreateScreening,
        userDataInfo,
        mutationDeleteWellScreening,
    }
}