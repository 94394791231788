import React, { useContext, useEffect, useState } from "react";
import { message, Switch, Tooltip, Select } from "antd";
import { useNavigate } from "react-router-dom";
import { Navbar } from "../../components/layout/Navbar";
import { Sidebar } from "../../components/layout/Sidebar";
import { useUsers } from "../../hooks/useUsers";
import { ReactComponent as IconAdd } from "../../assets/img/icons/add.svg";
import { ReactComponent as IconDelete } from "../../assets/img/icons/delete.svg";
import { ImportFile } from "../../components/GasLift/Complements/ImportFile";
import { DATA_PLUNGER_LIFT,  } from "../../components/elements/types/type_plunger_Lift";
import { ChartPlungerLift } from "../../components/PlungerLift/GraphicsPL/ChartPlungerLift";
import plunger_lift from '../../assets/img/plunger_lift.svg';
import { useTabPlungerLift } from "../../hooks/useTabPlungerLift";

export const StepMenuEditPlungerLift = () => {
  const navigate = useNavigate();
  const { User } = useUsers();
  const { Option } = Select;
  const { 
    mutationCalcCasing,
    mutationCalcPressure,
    mutationUpdateWellPlungerLift,
    dataCalcCasing, 
    dataCalcPressure,
    dataCalcInterpolation,
    refetchCalcDataCasing,
    refetchCalcDataPressure,
    refetchDataWellPL,
    refetchCalcDataInterpolation,
    userDataInfo, 
    dataFilterUserPL,
    dataWellPL,
    refetchListqIPR,
    listqIPR,
  } = useTabPlungerLift();

  const [ wellboreOptionState, setWellboreOptionState ] = useState(false);
  const [ dataPlungerLift, setDataPlungerLift ] = useState(DATA_PLUNGER_LIFT.initialInputPlungerLift)
  const [ dataTableTubing, setDataTableTubing ] = useState(DATA_PLUNGER_LIFT.initialDataTubing);
  const [ dataInformationEnd, setDataInformationEnd ] = useState();
  const [ inputEmpty, setInputEmpty ] = useState(false);
  const [ selectCalc, setSelectCalc ] = useState("const_by_glr");
  const [enableInputGOR , setEnableInputGOR] = useState(false);
  const [enableInputGasRate , setEnableInputGasRate] = useState(false);
  const [ flagDataInterpolation, setFlagDataInterpolation] = useState([]);
   
  useEffect(()=>{
    refetchDataWellPL();
    refetchListqIPR();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (dataWellPL?.statusCode === 200075 && dataWellPL?.data) {
        const filterDataCreate = dataWellPL?.data;
        const filterDataEnd = DATA_PLUNGER_LIFT.initialInputPlungerLift;
        const filteredJson = Object.fromEntries(
          Object.entries(filterDataCreate).filter(([key]) => !(key in filterDataEnd))
        );
        setDataInformationEnd(filteredJson);

        setDataPlungerLift((prevState) => ({
          ...prevState,
          reservoir_mid_perf_tvd_depth:  filterDataCreate.reservoir_mid_perf_tvd_depth,
          reservoir_temperature: filterDataCreate.reservoir_temperature,
          reservoir_pressure: filterDataCreate.reservoir_pressure,
          production_test_date_pp: filterDataCreate.production_test_date_pp,
          production_liquid_rate: filterDataCreate.production_liquid_rate,
          production_gas_rate: filterDataCreate.production_gas_rate,
          production_gor: filterDataCreate.production_gor,
          production_water_cut: filterDataCreate.production_water_cut,
          production_supplemental_gas_rate:  filterDataCreate.production_supplemental_gas_rate,
          twp_test_date: filterDataCreate.twp_test_date,
          twp_wellhead_temperature: filterDataCreate.twp_wellhead_temperature,
          twp_wellhead_pressure: filterDataCreate.twp_wellhead_pressure,
          twp_real_time: filterDataCreate.twp_real_time,
          cwp_test_date: filterDataCreate.cwp_test_date,
          cwp_wellhead_pressure: filterDataCreate.cwp_wellhead_pressure,
          cwp_wellhead_temperature: filterDataCreate.cwp_wellhead_temperature,
          cwp_real_time: filterDataCreate.cwp_real_time,
          plunger_depth:  filterDataCreate.plunger_depth,
          plunger_weight:  filterDataCreate.plunger_weight,
          plunger_fvg:  filterDataCreate.plunger_fvg,
          plunger_fvl:  filterDataCreate.plunger_fvl,
          plunger_rising_velocity:  filterDataCreate.plunger_rising_velocity,
          tubing_data: filterDataCreate.tubing_data,
          required_casing_pressure:  filterDataCreate.required_casing_pressure,
          supplemental_gas_rate:  filterDataCreate.supplemental_gas_rate,
          required_gas_liquid_ratio:  filterDataCreate.required_gas_liquid_ratio,
          required_gas_volume_per_cycle:  filterDataCreate.required_gas_volume_per_cycle,
          liquid_rate:  filterDataCreate.liquid_rate,
          gas_rate:  filterDataCreate.gas_rate,
          cycles_per_day:  filterDataCreate.cycles_per_day,
        }));

        setDataTableTubing(filterDataCreate.tubing_data)
      }
    }, 500);
  }, [dataWellPL]);  

  useEffect(() =>{
    if(!!dataCalcInterpolation?.data && dataCalcInterpolation?.statusType === "SUCCESS"){
      const dataValues = dataCalcInterpolation?.data[0];
      setFlagDataInterpolation(false);
      switch (selectCalc) {
        case "const_by_glr":
          setDataPlungerLift((prevState) => ({
            ...prevState,
            required_casing_pressure: dataValues.pc_inter_glr.toFixed(4), // Required Casing pressure (psia)
            supplemental_gas_rate: dataValues.qg_supplem_glr.toFixed(4), // Supplement gas rate (MSCF/D)
            required_gas_liquid_ratio: dataValues.glr_req.toFixed(4), // Required gas liquid ratio (MSCF/BBL)
            required_gas_volume_per_cycle: dataValues.vg_inter_glr.toFixed(4), // Required gas volume per cycle (MSCF)
            liquid_rate: dataValues.ql_inter_glr.toFixed(4), // Liquid rate (BBL/D)
            gas_rate: dataValues.qg_glr.toFixed(4), // Gas rate (MSCF/D)
            cycles_per_day: dataValues.cycle_day_glr.toFixed(4), //Cycles per day (Cycles/Day)
          }));
        break;
        case "const_by_casing_pressure":
          setDataPlungerLift((prevState) => ({
            ...prevState,
            required_casing_pressure: dataValues.pc_req.toFixed(4), // Required Casing pressure (psia)
            supplemental_gas_rate: dataValues.qg_supplem_pc.toFixed(4), // Supplement gas rate (MSCF/D)
            required_gas_liquid_ratio: dataValues.glr_inter_pc.toFixed(4), //Required gas liquid ratio (MSCF/BBL)
            required_gas_volume_per_cycle: dataValues.vg_inter_pc.toFixed(4), // Required gas volume per cycle (MSCF)
            liquid_rate: dataValues.ql_inter_pc.toFixed(4), // Liquid rate (BBL/D)
            gas_rate: dataValues.qg_pc.toFixed(4),// Gas rate (MSCF/D)
            cycles_per_day: dataValues.cycle_day_pc.toFixed(4) // Cycles per day (Cycles/Day)
          }));
          break;
        default:
          setDataPlungerLift((prevState) => ({
            ...prevState,
            required_casing_pressure: "", // Required Casing pressure (psia)
            supplemental_gas_rate: "", // Supplement gas rate (MSCF/D)
            required_gas_liquid_ratio: "", //Required gas liquid ratio (MSCF/BBL)
            required_gas_volume_per_cycle: "", // Required gas volume per cycle (MSCF)
            liquid_rate: "", // Liquid rate (BBL/D)
            gas_rate: "",// Gas rate (MSCF/D)
            cycles_per_day: "" // Cycles per day (Cycles/Day)
          }));
          break;
      }
    }
  }, [dataCalcInterpolation, selectCalc,flagDataInterpolation]);

  const wellboreOption = () => {
    setWellboreOptionState(!wellboreOptionState);
  };

  const handleChangeInputData = (event) => {
    const { name, value } = event.target;

    if(name === "production_gas_rate" && value === "" || value === null){
      setEnableInputGOR(false)
    }
    if(name === "production_gas_rate" && value !== "" || value === null){
      setEnableInputGOR(true)
    }
    if(name === "production_gor" && value === "" || value === null){
      setEnableInputGasRate(false)
    }
    if(name === "production_gor" && value !== "" || value === null){
      setEnableInputGasRate(true)
    }

    if(name === "cwp_wellhead_pressure" && parseFloat(value) < 45){
      message.info("This value must be greater than or equal to 45");
      setDataPlungerLift({
        ...dataPlungerLift,
        [name]: null
      })
    }
    else{
      setDataPlungerLift({
        ...dataPlungerLift,
        [name]: value
      })
    }
  }

  const handleChangeSwitch = (name, checked) => {
    setDataPlungerLift({
      ...dataPlungerLift,
      [name]: checked
    })
  }

  const handleChangeSelect = (name, value) => {
    setSelectCalc(value);
  }

  const handleClickRun = () => {

    const validateData = dataValidatePlungerLift(dataPlungerLift);
    const validateTable = VerifyingNulls(dataTableTubing);
    let qoMax = [];
    switch (dataWellPL?.data?.ipr_model) {
      case "vogel":
        qoMax = listqIPR?.data?.qo?.vogel   
        break;
      case "straight":
        qoMax = listqIPR?.data?.qo?.straight   
        break;
      case "fetko":
        qoMax = listqIPR?.data?.qo?.fetko   
        break;
      case "composite":
        qoMax = listqIPR?.data?.qo?.qliq_pet   
        break;
      default:
        qoMax = [];
        break;
    }
    if(!validateData && !validateTable){
      const dataCalculateCasing = {
        id_tubing: dataTableTubing[2].internal_diameter,
        od_tubing_in:  dataTableTubing[2].external_diameter,
        wh_temp_casing: Number(dataPlungerLift.reservoir_temperature),
        wh_press_casing: Number(dataPlungerLift.cwp_wellhead_pressure),
        wh_press_tubing: Number(dataPlungerLift.twp_wellhead_pressure),
        res_temp: Number(dataPlungerLift.reservoir_temperature),
        plunger_weight: Number(dataPlungerLift.plunger_weight),
        plunger_rising_vel: Number(dataPlungerLift.plunger_rising_velocity),
        plunger_fall_vel_liq: Number(dataPlungerLift.plunger_fvl),
        plunger_fall_vel_gas: Number(dataPlungerLift.plunger_fvg),
        plunger_depth_md: Number(dataPlungerLift.plunger_depth),
        id1_casing: dataTableTubing[0].internal_diameter,
        id2_casing: dataTableTubing[1].internal_diameter,
        id3_casing: dataTableTubing[1].internal_diameter,
        length1: dataTableTubing[0].measured_depth,
        length2: dataTableTubing[1].measured_depth,
        length3: 100,
        sgg_mix: Number(dataWellPL?.data?.res_gas_gravity),
        yn2: dataWellPL?.data?.res_N2,
        yco2: dataWellPL?.data?.res_CO2,
        yh2s: dataWellPL?.data?.res_H2S,
        qo_max: qoMax,
        qg_sc: Number(dataPlungerLift.production_gas_rate),
        qg_supp_sc: Number(dataPlungerLift.production_supplemental_gas_rate),
        ql_sc: Number(dataPlungerLift.production_liquid_rate),
      }

      const measuredDepths = dataWellPL?.data?.dataDeviation?.map(item => item.measured_depth);
      const trueVertical = dataWellPL?.data?.dataDeviation?.map(item => item.tvd);

      const dataCalculatePress = {
        num_intervals: 10,
        mid_perf_md: Number(dataPlungerLift.reservoir_mid_perf_tvd_depth),
        res_temp: Number(dataPlungerLift.reservoir_temperature),
        wh_temp: Number(dataPlungerLift.twp_wellhead_temperature),
        gas_sgg_mix: Number(dataWellPL?.data?.res_gas_gravity),
        yN2: dataWellPL?.data?.res_N2,
        yCO2: dataWellPL?.data?.res_CO2,
        yH2S: dataWellPL?.data?.res_H2S,
        measured_depth_table: measuredDepths,
        true_vertical_depth_table: trueVertical,
        tolerance: 0.01
      }
      async function executeFunction() {
        await mutationCalcCasing.mutateAsync({
          operator: userDataInfo.operator, 
          lease: userDataInfo.lease, 
          region: userDataInfo.region, 
          county: dataFilterUserPL.county, 
          field: dataFilterUserPL.field, 
          well: dataFilterUserPL.well_id, 
          username: User.user_name, 
          data: dataCalculateCasing 
        });
        await new Promise(resolve => setTimeout(resolve, 100));

        await mutationCalcPressure.mutateAsync({
          operator: userDataInfo.operator, 
          lease: userDataInfo.lease, 
          region: userDataInfo.region, 
          county: dataFilterUserPL.county, 
          field: dataFilterUserPL.field, 
          well: dataFilterUserPL.well_id, 
          username: User.user_name, 
          data: dataCalculatePress 
        });
        await new Promise(resolve => setTimeout(resolve, 100));
        refetchCalcDataCasing();
        await new Promise(resolve => setTimeout(resolve, 100));
        refetchCalcDataPressure();
        await new Promise(resolve => setTimeout(resolve, 100));
        refetchCalcDataInterpolation();
        await new Promise(resolve => setTimeout(resolve, 100));
        setFlagDataInterpolation(true);
      }
      executeFunction();
    }else {
      setInputEmpty(true);
      message.error("empty data");
    }
  }

  const handleChangeTubing = (e, id) => {
    const { name, value } = e.target;
    let dataValue = value !== "" ? Number(value) : null;
    
    const selectedIndex = dataTableTubing.findIndex(
      (data) => data.id === id
    );

    if(name === "measured_depth" && dataValue < 0){
      message.error("Length (ft) value can't be less than 0 ");
      setDataTableTubing(prevState =>
        prevState.map(item =>
          item.id === id ? { ...item, [name]: null } : item
        )
      );
    }else if(name === "internal_diameter" && dataValue > 36){
      message.error("Diamaters value can't be less than or equal to 0 and greater than 36");
      setDataTableTubing(prevState =>
        prevState.map(item =>
          item.id === id ? { ...item, [name]: null } : item
        )
      );
    }
    else if(name === "external_diameter" && dataValue > 36){
      message.error("Diamaters value can't be less than or equal to 0 and greater than 36");
      setDataTableTubing(prevState =>
        prevState.map(item =>
          item.id === id ? { ...item, [name]: null, internal_diameter: null } : item
        )
      );
    }
    else if(name === "internal_diameter" && dataValue >= dataTableTubing[selectedIndex].external_diameter){
      message.error("Internal diamater value can't be greater than or equal External diamater value");
      setDataTableTubing(prevState =>
        prevState.map(item =>
          item.id === id ? { ...item, [name]: null } : item
        )
      );
    }
    else if(name === "external_diameter" && dataValue <= dataTableTubing[selectedIndex].internal_diameter){
      message.error("Internal diamater value can't be greater than or equal External diamater value");
      setDataTableTubing(prevState =>
        prevState.map(item =>
          item.id === id ? { ...item, [name]: null } : item
        )
      );
    }else{
      setDataTableTubing(prevState =>
        prevState.map(item =>
          item.id === id ? { ...item, [name]: value } : item
        )
      );
    }
  };

  const handleChangeBlur = (e, id) => {
    const { name, value } = e.target;
    let dataValue = value !== "" ? Number(value) : value;
    
    if((name === "internal_diameter" || name === "external_diameter") && dataValue <= 0){
      message.error("Diamaters value can't be less than or equal to 0 and greater than 36");
      setDataTableTubing(prevState =>
        prevState.map(item =>
          item.id === id ? { ...item, [name]: null } : item
        )
      );
    }else{
      setDataTableTubing(prevState =>
        prevState.map(item =>
          item.id === id ? { ...item, [name]: dataValue } : item
        )
      );
    }
  };

  const handleClickDone = () => {

    const validateData = dataValidatePlungerLift(dataPlungerLift);
    
    if(!validateData){

      const dataCreateFluid = Object.assign({}, dataPlungerLift, {tubing_data : [...dataTableTubing]}, dataInformationEnd);
      async function executeFunction() {
        await mutationUpdateWellPlungerLift.mutateAsync({
          operator: userDataInfo.operator, 
          lease: userDataInfo.lease, 
          region: userDataInfo.region, 
          county: dataFilterUserPL.county, 
          field: dataFilterUserPL.field, 
          well: dataFilterUserPL.well_id, 
          username: User.user_name, 
          data: dataCreateFluid,
          type: "parametersplungerlift"
        });
  

        await new Promise(resolve => setTimeout(resolve, 100));

        setInputEmpty(false);
        navigate("/AddPlungerLift");
        message.success("Processing complete!");
      }
      executeFunction();
    }else {
      setInputEmpty(true);
      message.error("empty data");
    }
  }

  function dataValidatePlungerLift(data) {
    return Object.entries(data)
      .some(([key, value]) => 
        key != "twp_real_time" 
        && key != "cwp_real_time" 
        && key != "tubing_data"
        && key != "required_casing_pressure"
        && key != "supplemental_gas_rate"
        && key != "required_gas_liquid_ratio"
        && key != "required_gas_volume_per_cycle"
        && key != "liquid_rate"
        && key != "gas_rate"
        && key != "cycles_per_day"
        && key != "production_gor"
        && (!value)
      );
  };

  function VerifyingNulls(array) {
    
    let validateValuesNull = false;

    array.forEach(obj => {
      Object.values(obj).forEach(value => {
        if (value === null || value === "") {
          validateValuesNull = true;
        }
      });
    });
    return validateValuesNull;
  }

  return (
    <div className="flex flex-col w-full h-full bg-white">
      <div>
        <Navbar wellboreOption={wellboreOption}></Navbar>
        <Sidebar className="flex absolute h-full"></Sidebar>
      </div>
      <div className="flex flex-col w-full h-full pl-[150px] text-center justify-center">
        <div className="flex w-full h-[50px] justify-start text-center items-center mt-[50px] pl-[110px]">
          <div className="flex flex-row w-[1230px] h-[24px] gap-5 justify-start text-center items-center">
            <h1 className="flex flex-row font-bold text-[25px]">Plunger Lift Analysis</h1>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full h-full pl-[260px] pr-[170px] text-center justify-center mb-[10px]">
        <div className="flex w-full h-full justify-center text-center items-center mb-[7px]">
          <div className="flex flex-row w-full h-full gap-5 justify-start text-center items-center ">
            <h1 className="flex flex-row font-bold text-[20px]" >Reservoir Parameters</h1>
          </div>
        </div>
        <div className="flex flex-row w-full text-center justify-center gap-5">
          <div className="flex flex-col w-1/3">
            <label 
              htmlFor="" 
              className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Mid-perf Measured Depth (feet)
            </label>
            <input 
              type="number"
              name="reservoir_mid_perf_tvd_depth" 
              value={dataPlungerLift.reservoir_mid_perf_tvd_depth}
              onChange={handleChangeInputData}
              className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px] mb-[17px] border 
                ${
                  (inputEmpty && dataPlungerLift.reservoir_mid_perf_tvd_depth === "") || dataPlungerLift.reservoir_mid_perf_tvd_depth === null
                  ? 'border-red-700 bg-yellow-100' 
                  : 'border-solid border-[#BCBCCC]'
                }
              `} 
              min="0"
              max="60000"
            />
          </div>
          <div className="flex flex-col w-1/3">
            <label 
              htmlFor="" 
              className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Reservoir Pressure (psia)
            </label>
            <input 
              type="number" 
              name="reservoir_pressure" 
              value={dataPlungerLift.reservoir_pressure} 
              onChange={handleChangeInputData} 
              className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px] mb-[17px] border 
                ${
                  (inputEmpty && dataPlungerLift.reservoir_pressure === "" ) || dataPlungerLift.reservoir_pressure === null
                  ? 'border-red-700 bg-yellow-100' 
                  : 'border-solid border-[#BCBCCC]'
                }
              `} 
              min="0" 
              max="25000"
            />
          </div>
          <div className="flex flex-col w-1/3">
            <label 
              htmlFor="" 
              className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Reservoir Temperature (deg F)
            </label>
            <input 
              type="number"
              name="reservoir_temperature" 
              value={dataPlungerLift.reservoir_temperature} 
              onChange={handleChangeInputData} 
              className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border 
                ${
                  (inputEmpty && dataPlungerLift.reservoir_temperature === "") || dataPlungerLift.reservoir_temperature === null
                  ? 'border-red-700 bg-yellow-100' 
                  : 'border-solid border-[#BCBCCC]'
                }
              `} 
              min="0" 
              max="450"
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col w-full h-full pl-[260px] pr-[170px] text-center justify-center mb-[10px]">
        <div className="flex w-full h-full justify-center text-center items-center mb-[7px]">
          <div className="flex flex-row w-full h-full gap-5 justify-start text-center items-center ">
            <h1 className="flex flex-row font-bold text-[20px]">Production parameters</h1>
          </div>
        </div>
        <div className="flex flex-row w-full text-center justify-center gap-5">
          <div className="flex flex-col w-1/3">
            <label 
              htmlFor="" 
              className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Test date
            </label>
            <input 
              type="date"
              name="production_test_date_pp" 
              value={dataPlungerLift.production_test_date_pp}
              onChange={handleChangeInputData}
              className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px] mb-[17px] border 
                ${
                  (inputEmpty && dataPlungerLift.production_test_date_pp === "") || dataPlungerLift.production_test_date_pp === null
                  ? 'border-red-700 bg-yellow-100' 
                  : 'border-solid border-[#BCBCCC]'
                }
              `}
            />
          </div>
          <div className="flex flex-col w-1/3">
            <label 
              htmlFor="" 
              className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Liquid Rate (BBL/D)
            </label>
            <input 
              type="number" 
              name="production_liquid_rate" 
              value={dataPlungerLift.production_liquid_rate} 
              onChange={handleChangeInputData} 
              className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border 
                ${
                  (inputEmpty && dataPlungerLift.production_liquid_rate === "" ) || dataPlungerLift.production_liquid_rate === null
                  ? 'border-red-700 bg-yellow-100' 
                  : 'border-solid border-[#BCBCCC]'
                }
              `}
            />
          </div>
          <div className="flex flex-col w-1/3">
            <label 
              htmlFor="" 
              className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Gas rate (MSCF/D)
            </label>
            <input 
              type="number"
              name="production_gas_rate" 
              value={dataPlungerLift.production_gas_rate} 
              onChange={handleChangeInputData} 
              disabled={enableInputGasRate}
              className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border 
                ${
                  (inputEmpty && dataPlungerLift.production_gas_rate === "") || dataPlungerLift.production_gas_rate === null
                  ? 'border-red-700 bg-yellow-100' 
                  : 'border-solid border-[#BCBCCC]'
                }
              `}
            />
          </div>
        </div>
        <div className="flex flex-row w-full text-center justify-center gap-5">
          <div className="flex flex-col w-1/3">
            <label 
              htmlFor="" 
              className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              GOR (SCF/STB)
            </label>
            <input 
              type="number"
              name="production_gor" 
              value={dataPlungerLift.production_gor}
              onChange={handleChangeInputData}
              disabled={true} 
              className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px] mb-[17px] border 
                ${
                  (inputEmpty && dataPlungerLift.production_gor === "") || dataPlungerLift.production_gor === null
                  ? 'border-red-700 bg-yellow-100' 
                  : 'border-solid border-[#BCBCCC]'
                }
              `}
            />
          </div>
          <div className="flex flex-col w-1/3">
            <label 
              htmlFor="" 
              className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Water cut (%)
            </label>
            <input 
              type="number" 
              name="production_water_cut" 
              value={dataPlungerLift.production_water_cut} 
              onChange={handleChangeInputData} 
              className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border 
                ${
                  (inputEmpty && dataPlungerLift.production_water_cut === "" ) || dataPlungerLift.production_water_cut === null
                  ? 'border-red-700 bg-yellow-100' 
                  : 'border-solid border-[#BCBCCC]'
                }
              `}
            />
          </div>
          <div className="flex flex-col w-1/3">
            <label 
              htmlFor="" 
              className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Supplement gas rate (MSCF/D)
            </label>
            <input 
              type="number"
              name="production_supplemental_gas_rate" 
              value={dataPlungerLift.production_supplemental_gas_rate} 
              onChange={handleChangeInputData} 
              className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border 
                ${
                  (inputEmpty && dataPlungerLift.production_supplemental_gas_rate === "") || dataPlungerLift.production_supplemental_gas_rate === null
                  ? 'border-red-700 bg-yellow-100' 
                  : 'border-solid border-[#BCBCCC]'
                }
              `}
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col w-full h-full pl-[260px] pr-[170px] text-center justify-center mb-[10px]">
        <div className="flex w-full h-full justify-center text-center items-center mb-[7px]">
          <div className="flex flex-row w-full h-full gap-5 justify-start text-center items-center ">
            <h1 className="flex flex-row font-bold text-[20px]" >Tubing Wellhead Parameter</h1>
          </div>
        </div>
        <div className="flex w-full max-w-[592px] text-start items-center font bold text-[15px] gap-2">
          <label htmlFor="">User</label>
          <Switch
            className="items-center"
            size="small"
            style={{
              backgroundColor: dataPlungerLift?.twp_real_time ? "#662D91" : "#707070",
              borderColor: dataPlungerLift?.twp_real_time ? "#662D91" : "#707070",
            }}
            checked={dataPlungerLift?.twp_real_time}
            onChange={(checked) => handleChangeSwitch("twp_real_time", checked)}
          ></Switch> 
          <label htmlFor="">Real Time</label>
        </div>
        <div className="flex flex-row w-full text-center justify-center gap-5 mt-[8px]">
          <div className="flex flex-col w-1/3">
            <label 
              htmlFor="" 
              className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Test date
            </label>
            <input 
              type="date"
              name="twp_test_date" 
              value={dataPlungerLift.twp_test_date}
              onChange={handleChangeInputData}
              className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px] mb-[17px] border 
                ${
                  (inputEmpty && dataPlungerLift.twp_test_date === "") || dataPlungerLift.twp_test_date === null
                  ? 'border-red-700 bg-yellow-100' 
                  : 'border-solid border-[#BCBCCC]'
                }
              `} 
              min="0"
              max="60000"
            />
          </div>
          <div className="flex flex-col w-1/3">
            <label 
              htmlFor="" 
              className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Tubing wellhead pressure (psia)
            </label>
            <input 
              type="number" 
              name="twp_wellhead_pressure" 
              value={dataPlungerLift.twp_wellhead_pressure} 
              onChange={handleChangeInputData} 
              className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border 
                ${
                  (inputEmpty && dataPlungerLift.twp_wellhead_pressure === "" ) || dataPlungerLift.twp_wellhead_pressure === null
                  ? 'border-red-700 bg-yellow-100' 
                  : 'border-solid border-[#BCBCCC]'
                }
              `} 
              min="0" 
              max="25000"
            />
          </div>
          <div className="flex flex-col w-1/3">
            <label 
              htmlFor="" 
              className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Wellhead temperature (degF)
            </label>
            <input 
              type="number"
              name="twp_wellhead_temperature" 
              value={dataPlungerLift.twp_wellhead_temperature} 
              onChange={handleChangeInputData} 
              className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border 
                ${
                  (inputEmpty && dataPlungerLift.twp_wellhead_temperature === "") || dataPlungerLift.twp_wellhead_temperature === null
                  ? 'border-red-700 bg-yellow-100' 
                  : 'border-solid border-[#BCBCCC]'
                }
              `} 
              min="0" 
              max="450"
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col w-full h-full pl-[260px] pr-[170px] text-center justify-center mb-[10px]">
        <div className="flex w-full h-full justify-center text-center items-center mb-[7px]">
          <div className="flex flex-row w-full h-full gap-5 justify-start text-center items-center ">
            <h1 className="flex flex-row font-bold text-[20px]">Casing Wellhead Parameter</h1>
          </div>
        </div>
        <div className="flex w-full max-w-[592px] text-start items-center font bold text-[15px] gap-2">
          <label htmlFor="">User</label>
          <Switch
            className="items-center"
            size="small"
            style={{
              backgroundColor: dataPlungerLift?.cwp_real_time ? "#662D91" : "#707070",
              borderColor: dataPlungerLift?.cwp_real_time ? "#662D91" : "#707070",
            }}
            checked={dataPlungerLift?.cwp_real_time}
            onChange={(checked) => handleChangeSwitch("cwp_real_time", checked)}
          ></Switch> 
          <label htmlFor="">Real Time</label>
        </div>
        <div className="flex flex-row w-full text-center justify-center gap-5 mt-[8px]">
          <div className="flex flex-col w-1/3">
            <label 
              htmlFor="" 
              className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Test date
            </label>
            <input 
              type="date"
              name="cwp_test_date" 
              value={dataPlungerLift.cwp_test_date}
              onChange={handleChangeInputData}
              className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px] mb-[17px] border 
                ${
                  (inputEmpty && dataPlungerLift.cwp_test_date === "") || dataPlungerLift.cwp_test_date === null
                  ? 'border-red-700 bg-yellow-100' 
                  : 'border-solid border-[#BCBCCC]'
                }
              `} 
              min="0"
              max="60000"
            />
          </div>
          <div className="flex flex-col w-1/3">
            <label 
              htmlFor="" 
              className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Casing wellhead pressure (psia)
            </label>
            <input 
              type="number" 
              name="cwp_wellhead_pressure" 
              value={dataPlungerLift.cwp_wellhead_pressure} 
              onChange={handleChangeInputData} 
              className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border 
                ${
                  (inputEmpty && dataPlungerLift.cwp_wellhead_pressure === "" ) || dataPlungerLift.cwp_wellhead_pressure === null
                  ? 'border-red-700 bg-yellow-100' 
                  : 'border-solid border-[#BCBCCC]'
                }
              `} 
              min="0" 
              max="25000"
            />
          </div>
          <div className="flex flex-col w-1/3">
            <label 
              htmlFor="" 
              className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Wellhead temperature (degF)
            </label>
            <input 
              type="number"
              name="cwp_wellhead_temperature" 
              value={dataPlungerLift.cwp_wellhead_temperature} 
              onChange={handleChangeInputData} 
              className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border 
                ${
                  (inputEmpty && dataPlungerLift.cwp_wellhead_temperature === "") || dataPlungerLift.cwp_wellhead_temperature === null
                  ? 'border-red-700 bg-yellow-100' 
                  : 'border-solid border-[#BCBCCC]'
                }
              `} 
              min="0" 
              max="450"
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col w-full h-full pl-[260px] pr-[170px] text-center justify-center mb-[10px]">
        <div className="flex w-full h-full justify-center text-center items-center mb-[7px]">
          <div className="flex flex-row w-full h-full gap-5 justify-start text-center items-center ">
            <h1 className="flex flex-row font-bold text-[20px]">Plunger Lift parameters</h1>
          </div>
        </div>
        <div className="flex flex-row w-full text-center justify-center gap-5 mt-[8px]">
          <div className="flex flex-col w-1/3">
            <label 
              htmlFor="" 
              className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Plunger Depth (feet)
            </label>
            <input 
              type="number"
              name="plunger_depth" 
              value={dataPlungerLift.plunger_depth}
              onChange={handleChangeInputData}
              className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px] mb-[17px] border 
                ${
                  (inputEmpty && dataPlungerLift.plunger_depth === "") || dataPlungerLift.plunger_depth === null
                  ? 'border-red-700 bg-yellow-100' 
                  : 'border-solid border-[#BCBCCC]'
                }
              `} 
              min="0"
              max="60000"
            />
          </div>
          <div className="flex flex-col w-1/3">
            <label 
              htmlFor="" 
              className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Plunger Weight (lbf)
            </label>
            <input 
              type="number" 
              name="plunger_weight" 
              value={dataPlungerLift.plunger_weight} 
              onChange={handleChangeInputData} 
              className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border 
                ${
                  (inputEmpty && dataPlungerLift.plunger_weight === "" ) || dataPlungerLift.plunger_weight === null
                  ? 'border-red-700 bg-yellow-100' 
                  : 'border-solid border-[#BCBCCC]'
                }
              `} 
              min="0" 
              max="25000"
            />
          </div>
          <div className="flex flex-col w-1/3">
            <label 
              htmlFor="" 
              className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Plunger falling velocity in gas (ft/min)
            </label>
            <input 
              type="number"
              name="plunger_fvg" 
              value={dataPlungerLift.plunger_fvg} 
              onChange={handleChangeInputData} 
              className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border 
                ${
                  (inputEmpty && dataPlungerLift.plunger_fvg === "") || dataPlungerLift.plunger_fvg === null
                  ? 'border-red-700 bg-yellow-100' 
                  : 'border-solid border-[#BCBCCC]'
                }
              `}
            />
          </div>
        </div>
        <div className="flex flex-row w-full text-center justify-center gap-5">
          <div className="flex flex-col w-1/3">
            <label 
              htmlFor="" 
              className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Plunger falling valocity in liquid (ft/min)
            </label>
            <input 
              type="number"
              name="plunger_fvl" 
              value={dataPlungerLift.plunger_fvl}
              onChange={handleChangeInputData}
              className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px] mb-[17px] border 
                ${
                  (inputEmpty && dataPlungerLift.plunger_fvl === "") || dataPlungerLift.plunger_fvl === null
                  ? 'border-red-700 bg-yellow-100' 
                  : 'border-solid border-[#BCBCCC]'
                }
              `}
            />
          </div>
          <div className="flex flex-col w-1/3">
            <label 
              htmlFor="" 
              className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Plunger rising velocity (ft/min)
            </label>
            <input 
              type="number" 
              name="plunger_rising_velocity" 
              value={dataPlungerLift.plunger_rising_velocity} 
              onChange={handleChangeInputData} 
              className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border 
                ${
                  (inputEmpty && dataPlungerLift.plunger_rising_velocity === "" ) || dataPlungerLift.plunger_rising_velocity === null
                  ? 'border-red-700 bg-yellow-100' 
                  : 'border-solid border-[#BCBCCC]'
                }
              `}
              disabled
            />
          </div>
          <div className="flex flex-col w-1/3" hidden={true}></div>
        </div>
      </div>

      <div className="flex flex-col w-full h-full pl-[260px] pr-[170px] text-center justify-center">
        <div className="flex flex-col w-full h-full justify-center text-center items-center mb-[27px]">
          <div className="flex w-full h-full justify-center text-center items-center mb-[7px]">
            <div className="flex flex-row w-full h-full gap-5 justify-start text-center items-center ">
              <h1 className="flex flex-row font-bold text-[16px]" >Tubing</h1>
              <ImportFile 
                // uploadfile={handleUploadFileTubing}
              ></ImportFile>
            </div>
          </div>
          <ul className="flex flex-col w-full h-full">
            {/* seccion de titulos */}
            <div className="flex flex-col w-full text-start justify-end items-end mb-[7px]">
              <div className="flex flex-row w-full h-[20px] gap-2">
                <label className="flex max-w-[592px] w-1/4 text-left items-start justify-start text-[15px] mb-[7px]">
                  Name
                </label>
                <label className="flex max-w-[592px] w-1/4 text-left items-start justify-start text-[15px] mb-[7px] pl-[5px]">
                  MD Length (feet)
                </label>
                <label className="flex max-w-[592px] w-1/4 text-left items-start justify-start text-[15px] mb-[7px] pl-[8px]">
                  External Diameter (inches)
                </label>
                <label className="flex max-w-[592px] w-1/4 text-left items-start justify-start text-[15px] mb-[7px] pl-[6px]">
                  Internal Diameter (inches)
                </label>
              </div>
            </div>
            {dataTableTubing.map((item, index) => (
              <li key={item.id+"tubing"+index} className="flex flex-row w-full overflow-y-auto">
                {/* <IconArrowsVertical className="flex w-6 h-[47px] fill-neutral-500" /> */}

                <div className="flex flex-col w-full gap-x-[18px] mb-[10px]">
                  <div className="flex flex-row w-full gap-5">
                    <div className="flex flex-col w-1/4 text-right items-end">
                      <input
                        type="text"
                        defaultValue={item.nameTubing}
                        name={`nameTubing`}
                        value={dataTableTubing[index].nameTubing}
                        onChange={(e) => handleChangeTubing(e, item.id)}
                        className={`w-full max-w-[592px] h-[47px] px-2 rounded-[4px] border border-solid border-[#BCBCCC]`}
                      />
                    </div>
                    <div className="flex flex-col w-1/4 text-right items-end">
                      <input
                        type="number"
                        defaultValue={item.measured_depth}
                        name={`measured_depth`}
                        value={dataTableTubing[index].measured_depth}
                        onChange={(e) => handleChangeTubing(e, item.id)}
                        onWheel={(e) => e.target.blur()}
                        onBlur={(e) => handleChangeBlur(e, item.id)}
                        className={`w-full max-w-[592px] h-[47px] px-2 rounded-[4px] border border-solid 
                          ${item.measured_depth === null
                            ? "border-red-700 bg-yellow-100"
                            : "border-[#BCBCCC]"
                          }`}
                      />
                    </div>
                    <div className="flex flex-col w-1/4 text-right items-end">
                      <input
                        type="number"
                        defaultValue={item.external_diameter}
                        name={`external_diameter`}
                        value={dataTableTubing[index].external_diameter}
                        onChange={(e) => handleChangeTubing(e, item.id)}
                        onWheel={(e) => e.target.blur()}
                        className="w-full  max-w-[592px]  h-[47px] px-2 rounded-[4px] border border-solid border-[#BCBCCC]"
                      />
                    </div>
                    <div className="flex flex-col w-1/4 text-right items-end">
                      <input
                        type="number"
                        defaultValue={item.internal_diameter}
                        name={`internal_diameter`}
                        value={dataTableTubing[index].internal_diameter}
                        onChange={(e) => handleChangeTubing(e, item.id)}
                        onWheel={(e) => e.target.blur()}
                        className="w-full max-w-[592px] h-[47px] px-2 rounded-[4px] border border-solid border-[#BCBCCC]"
                      />
                    </div>
                  </div>
                  {/* <div className={`flex flex-row text-right items-start gap-2 ${dataTableTubing.length - 1 === index ? "": "pr-8"}`}>
                    {dataTableTubing.length - 1 === index &&(
                      <IconAdd
                        className="w-6 h-[47px] fill-neutral-500"
                        onClick={() => handleAddTubing()}
                      />
                    )}
                    {dataTableTubing.length >= 2 && (
                      <IconDelete
                        className="w-6 h-[47px] fill-neutral-500 "
                        onClick={() => handleDeleteTubing(item.id)}
                      />
                    )}
                  </div> */}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="flex flex-col w-full h-full pl-[260px] pr-[170px] text-center justify-center mb-[10px]">
        <div className="flex w-full h-full justify-center text-center items-center mb-[7px]">
          <div className="flex flex-row w-full h-full gap-5 justify-start text-center items-center ">
            <button
              className="w-[180px] h-[40px] bg-pa-purple text-white rounded-md"
              onClick={() => handleClickRun()}
            >
              Run
            </button>
            <Select
              className="flex w-full h-[40px] max-w-[320px] text-start"
              name="select_calc"
              value={selectCalc || "Selected option"}
              onChange={(e) => handleChangeSelect('select_calc', e)}
            >
              <Option value="const_by_glr">Constrained by GLR</Option>
              <Option value="const_by_casing_pressure">Constrained by Casing Pressure</Option>
            </Select>
            {/* <p className="font-bold">(Message)</p> */}
          </div>
        </div>
      </div>
      <div className="relative w-[1030px] h-[600px] mx-auto mt-[2rem]">
        <img
          src={plunger_lift}
          className="w-full h-full lg:absolute mt-[1.3rem]"
        />
        <label 
        htmlFor="" 
        className="w-full max-w-[592px] text-start font bold text-[15px] top-[2.5rem] ml-[120px]"
        >
          Required Casing pressure (psia)
        </label>
        <input
          type="number"
          name="required_casing_pressure"
          value={dataPlungerLift.required_casing_pressure}
          onChange={handleChangeInputData}
          className="absolute top-[2rem] left-[120px] w-[260px] h-[37px] text-[15px] px-2 border rounded-md shadow-sm bg-white"
        />

        <label 
        htmlFor="" 
        className="absolute w-[592px] left-[28px] text-start items-start font bold text-[15px] mt-[8.4rem]"
        >
          Supplement gas rate (MSCF/D)
        </label>
        <input
          type="text"
          name="supplemental_gas_rate"
          value={dataPlungerLift.supplemental_gas_rate}
          onChange={handleChangeInputData}
          className="absolute top-[10.1rem] left-[28px] w-[260px] h-[37px] text-[15px] px-2 border rounded-md shadow-sm bg-white"
        />
        
        <label 
          htmlFor="" 
          className="absolute w-[592px] left-[10px] text-start items-start font bold text-[15px] mt-[27.3rem]"
          >
          Required gas liquid ratio (MSCF/BBL)
        </label>
        <input
          type="text"
          name="required_gas_liquid_ratio"
          value={dataPlungerLift.required_gas_liquid_ratio}
          onChange={handleChangeInputData}
          className="absolute top-[29rem] left-[10px] w-[260px] h-[37px] text-[15px] px-2 border rounded-md shadow-sm bg-white"
        />

        <label 
          htmlFor="" 
          className="absolute w-[592px] left-[10px] text-start items-start font bold text-[15px] mt-[33.6rem]"
          >
          Required gas volume per cycle (MSCF)
        </label>
        <input
          type="text"
          name="required_gas_volume_per_cycle"
          value={dataPlungerLift.required_gas_volume_per_cycle}
          onChange={handleChangeInputData}
          className="absolute top-[35.4rem] left-[10px] w-[260px] h-[37px] text-[15px] px-2 border rounded-md"
        />

        <label 
          htmlFor="" 
          className="absolute w-[592px] left-[755px] text-start items-start font bold text-[15px] mt-[5.2rem]"
          >
          Liquid rate (BBL/D)
        </label>
        <input
          type="text"
          name="liquid_rate"
          value={dataPlungerLift.liquid_rate}
          onChange={handleChangeInputData}
          className="absolute top-[7rem] right-[15px] w-[260px] h-[37px] px-2 border rounded-md"
        />

        <label 
          htmlFor="" 
          className="absolute w-[592px] left-[755px] text-start items-start font bold text-[15px] mt-[11.5rem]"
          >
          Gas rate (MSCF/D)
        </label>
        <input
          type="text"
          name="gas_rate"
          value={dataPlungerLift.gas_rate}
          onChange={handleChangeInputData}
          className="absolute top-[13.2rem] right-[15px] w-[260px] h-[37px] px-2 border rounded-md"
        />

        <label 
          htmlFor="" 
          className="absolute w-[592px] left-[475px] text-start items-start font bold text-[15px] mt-[24.1rem]"
          >
          Cycles per day (Cycles/Day)
        </label>
        <input
          type="text"
          name="cycles_per_day"
          value={dataPlungerLift.cycles_per_day}
          onChange={handleChangeInputData}
          className="absolute top-[26rem] left-[475px] w-[260px] h-[37px] px-2 border rounded-md"
        />
      </div>
      <div className="relative w-[1200px] h-[600px] mx-auto mt-[6rem]">
      <ChartPlungerLift dataCasingChart={dataCalcCasing ? dataCalcCasing?.data : []} dataPressChart={dataCalcPressure ? dataCalcPressure?.data : []}></ChartPlungerLift>
      </div>
        
      <div className="flex flex-col w-full h-full pl-[260px] pr-[170px] items-end text-center justify-center mb-[10px]">
        <button
          className="w-[180px] h-[40px] bg-pa-purple text-white rounded-md"
          onClick={() => handleClickDone()}
        >
          Save
        </button>
      </div>
    </div>
  )
}