import React, { useContext, useEffect, useState } from "react";
import { useCrudUsers } from "../../hooks/useCrudUsers";
import { Navbar } from "../../components/layout/Navbar";
import { Sidebar } from "../../components/layout/Sidebar";
import { ReactComponent as IconEdit } from "../../assets/icon/edit.svg";
import { ReactComponent as IconDelete } from "../../assets/icon/x-circle.svg";
import { Pagination } from "antd";
import { DashboardContext } from "../../context/Dashboard/DashboardContentx";

export const UsersInternal = () => {
  const { getDataPage } = useContext(DashboardContext);
  const [wellboreOptionState, setWellboreOptionState] = useState(false);
  const wellboreOption = () => {
    setWellboreOptionState(!wellboreOptionState);
  };
  const {
    dataUsersInternal,
    setEnabledUserInt,
    loadUserInternal,
    ErrorUsersInternal,
    tasks,
    users,
    search,
    setUsers,

    currentPage,
    setCurrentPage,

    updateTasks,

    searchUserInternal,
    setTasks,
  } = useCrudUsers();
  const [usersPerPage, setUsersPerPage] = useState(6);

  const lastIndex = currentPage * usersPerPage;
  const firstIndex = lastIndex - usersPerPage;
  const totalUsers = users.length;

  useEffect(() => {
    getDataPage("Internal");
  }, []);

  useEffect(() => {
    setEnabledUserInt(true);
  }, []);
  useEffect(() => {
    setTasks(tasks);
    setUsers(tasks);
  }, [tasks])
  useEffect(() => {
    if (dataUsersInternal) {
      setTasks(dataUsersInternal);
      setUsers(dataUsersInternal);
      setUsersPerPage(6)
    }
  }, [dataUsersInternal]);
  const totalItems = dataUsersInternal ? dataUsersInternal.length : 0;
  const pageSize = 6;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const startIdx = (currentPage - 1) * pageSize;
  const endIdx = currentPage * pageSize;

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  };
  return (
    <div className="flex flex-col w-full h-full bg-white ">
      <div className="flex">
        <Navbar wellboreOption={wellboreOption}></Navbar>
        <Sidebar className="flex absolute h-full "></Sidebar>
      </div>
      <div className="flex flex-col pl-[88px] w-full h-full">
        <div className="flex flex-row max-w[1500px] w-full h-full">
          <div className="flex flex-row w-full pl-[10px] m-[20px]">
            <div className="flex flex-col w-full text-start">
              <input
                placeholder="Search"
                type="text"
                className="w-[250px] h-[47px] rounded-[4px] border border-solid border-[#BCBCCC]"
                value={search}
                onChange={searchUserInternal}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-row w-[1500px] h-full">
          <div className="flex flex-row h-full w-[1500px] ml-[20px] mr-[20px] ">
            <div className="flex flex-col w-full border border-solid text-left">
              <table className="table-fixed">
                <thead className="h-[30px] text-left bg-zinc-800 text-white">
                  <tr>
                    <th className="pl-[20px]">#</th>
                    <th>User</th>
                    <th>Operator</th>
                    <th>Project</th>
                    <th>Region</th>
                    <th>Rol</th>
                    <th>Edit</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody className="text-left">
                  {users?.slice(startIdx, endIdx).map((user) => (
                    <tr key={user.iduser}>
                      <td className="pl-[20px]">{user.iduser}</td>
                      <td>{user.user_name}</td>
                      <td>{user.clients}</td>
                      <td>
                        {user.proyects &&
                          user.proyects?.map((project, index) => (
                            <React.Fragment key={project}>
                              {project}
                              {index !== user.project?.length - 1 ? "," : "."}
                            </React.Fragment>
                          ))}
                      </td>
                      <td>
                        {user.platforms &&
                          user.platforms?.map((platform, index) => (
                            <React.Fragment key={platform}>
                              {platform}
                              {index !== user.platform?.length - 1 ? "," : "."}
                            </React.Fragment>
                          ))}
                      </td>
                      <td>{user.roles_name}</td>
                      <td className="text-center">
                        <IconEdit className="w-6 h-[47px] fill-yellow-400" />
                      </td>
                      <td className="text-center">
                        <IconDelete className="w-6 h-[47px] fill-red-600 " />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="flex flex-row max-w-[1500px] w-full h-full">
          <div className="flex flex-row w-full ml-[20px] mr-[20px] mt-[10px]">
            <div className="flex flex-col w-full ml-[20px] text-start">
              <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={totalItems}
                onChange={handlePageChange}
                itemRender={itemRender}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
