import { logDOM } from '@testing-library/react';
import React, {useState} from 'react'

export const TableInflowOutFlow = ({dataTable}) => {
  
  
  const fixedDecimal = function(value, decimal) {
    const valueData = typeof value === 'undefined' ? 0 : value;
    const fixedNumber = Number(valueData.toFixed(1));
    return fixedNumber;
  };

  const sortedTableData = [...dataTable].sort((a, b) => a.qg_inj_sc - b.qg_inj_sc);

  const dataFixedTable = sortedTableData.map((item, index) => ({
    qo_step : fixedDecimal(item.qo_step),
    gor : fixedDecimal(item.gor),
    water_cut : fixedDecimal(item.water_cut),
    w_rate : fixedDecimal(item.w_rate),
    gas_rate : fixedDecimal(item.gas_rate),
    pressure : fixedDecimal(item.pressure),
    qg_inj_sc : fixedDecimal(item.qg_inj_sc),
    w_cut: fixedDecimal(item.w_cut)
  }))
  
  return (

    <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
            <tr>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">Oil Rate (STB/D)</th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">GOR (SCF/STB)</th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">Water Cut (%)</th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">Water Rate (STB/D)</th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">Injection Gas Rate (MSCF/D)</th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">Operating Pressure (psia)</th>
            </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
            {dataFixedTable.map((item,index) => (
            <tr key={index} className="hover:bg-gray-100">
                <td className="px-6 py-4 whitespace-nowrap">{item.qo_step}</td>
                <td className="px-6 py-4 whitespace-nowrap">{item.gor}</td>
                <td className="px-6 py-4 whitespace-nowrap">{item.w_cut}</td>
                <td className="px-6 py-4 whitespace-nowrap">{item.w_rate}</td>
                <td className="px-6 py-4 whitespace-nowrap">{item.qg_inj_sc}</td>
                <td className="px-6 py-4 whitespace-nowrap">{item.pressure}</td>
            </tr>
            ))}
        </tbody>
    </table>
  )
}
