import React, { useState, useContext, useEffect } from "react";
import { useTabDCA } from '../../../hooks/useTabDCA';

export const TableForecast = ({forecastSelected}) => {
const lastTableData = forecastSelected;
const {dataForecast, refetchDataForecast, extractTitle} = useTabDCA(lastTableData);
const { sensibility, period } = extractTitle(lastTableData);
const dataTable = !!dataForecast ? dataForecast.data : [];
useEffect(() => {
    refetchDataForecast();
}, [refetchDataForecast]);
return (
    <table className="min-w-full text-left text-sm font-light ">
        <thead className="font-medium">
            <tr>
                <th scope="col" className="px-6 py-4 border border-gray-400 text-center">
                    Month
                </th>
                <th scope="col" className="px-6 py-4 border border-gray-400 text-center">
                    Date
                </th>
                <th scope="col" className="px-6 py-4 border border-gray-400 text-center">
                    Oil Rate (STB/D)
                </th>
                <th scope="col" className="px-6 py-4 border border-gray-400 text-center">
                    Cumulative Oil (STB)
                </th>
                <th scope="col" className="px-6 py-4 border border-gray-400 text-center">
                    Gas Rate (MSCF/D)
                </th>
                {/* <th scope="col" className="px-6 py-4 border border-gray-400 text-center ">
                    Water Rate
                </th> */}
            </tr>
        </thead>
        <tbody>
            {dataTable.map((item, index) => {
            const key = `${item.id}_${item.cp_range}_${item.x_plot}_${item.b_parameter}_${item.di_parameter}`;
            return (
                <tr key={index}>
                <td className="border border-gray-400 px-6 py-4 text-center">{item.x_plot_true}</td>
                <td className="border border-gray-400 px-6 py-4 text-center">{item.datetime}</td>
                <td className="border border-gray-400 px-6 py-4 text-center">{item.y_plot_calc.toFixed(1)}</td>
                <td className="border border-gray-400 px-6 py-4 text-center">{item.cumulative_oil.toFixed(1)}</td>
                <td className="border border-gray-400 px-6 py-4 text-center">{item.gas_rate.toFixed(2)}</td>
                {/* <td className="border border-gray-400 px-6 py-4 text-center">{item.water_rate.toFixed(1)}</td> */}
                </tr>
            );
            })}
        </tbody>
    </table>
  );
}