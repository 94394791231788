import React, { useEffect, useState } from 'react'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
} from 'recharts';

function CustomTooltip({ active, payload, stateContent }) {
  if (active && payload && payload.length) {

    const data = payload[0].payload;
    const titleColor = "#000000";
    return (
      <div className="custom-tooltip" style={{ backgroundColor: 'white', padding: '5px' }}>
        <p style={{ color: titleColor }}>{`Pressure: ` + data.press + ` (psia)`}</p>
        <p style={{ color: '#5D7EC6' }}>{stateContent.solution_gor && data.solution_gor? `Solution GOR: ` + data.solution_gor + ` (SCF/STB)`: ``}</p>
        <p style={{ color: '#dc2626' }}>{stateContent.oil_fvf && data.oil_fvf? `Oil FVF: ` + data.oil_fvf + ` (RB/STB)` : ``}</p>
        <p style={{ color: '#FFC737' }}>{stateContent.oil_viscosity && data.oil_viscosity ? `Oil viscosity: ` + data.oil_viscosity + ` (cp)` : ``}</p>
        <p style={{ color: '#008A15' }}>{stateContent.oil_compressibility && data.oil_compressibility? `Oil compressibility: ` + data.oil_compressibility + ` (1/psi)` : ``}</p>
      </div>
    )
  }

}

export const ChartCalculation = ({ dataTable, stateCheck }) => {

  const linesAxis = Object.keys(stateCheck).filter(key => stateCheck[key]);
  const [dataGraph, setDataGraph] = useState([])

  useEffect(() => {

    const data = dataTable[0]?.bo?.map((value, index) => ({
      id: index + 1,
      press: Math.round(dataTable[0]?.press[index]),
      solution_gor: decimalRound(dataTable[0]?.rs[index], 2),
      oil_fvf: decimalRound(value, 3),
      oil_viscosity: decimalRound(dataTable[0]?.viso[index], 3),
      oil_compressibility: dataTable[0]?.co[index] === 0 ? null : decimalRound(dataTable[0]?.co[index], 10)
    }));

    setDataGraph(data);
  },[dataTable, stateCheck]);

  const colorsByType = {
    solution_gor: "#5D7EC6",
    oil_fvf: "#dc2626",
    oil_viscosity: "#FFC737",
    oil_compressibility: "#008A15"
  };
  
  const DataAxisY = Object.keys(stateCheck).filter(key => stateCheck[key]);

  const namData = {
    solution_gor: {
      state: stateCheck.solution_gor,
      name: "Solution GOR (SCF/STB)"
    },
    oil_fvf: {
      state: stateCheck.oil_fvf,
      name: "Oil FVF (RB/STB)"
    },
    oil_viscosity: {
      state: stateCheck.oil_viscosity,
      name: "Oil viscosity (cp)"
    },
    oil_compressibility: {
      state: stateCheck.oil_compressibility,
      name: "Oil compressibility (1/psi)"
    }
  };
  
  const nameAxisY = DataAxisY.map((key) => namData[key]?.name).join(",  ");
  const dy = -10 - (DataAxisY.length - 1) * -105;
  
  const getColorByType = (type) => colorsByType[type] || "#4c0519";

  const decimalRound = (number, round) => {
    let valRound = number != undefined && number != null ? Number(number.toFixed(round)) : null;
    return valRound;
  };
  
  const getDomainX = (data, key) => {
    const values = data?.map(item => item[key]).filter(value => value !== null && value !== undefined);
    return [Math.min(...values), Math.max(...values)];
  };
  const xDomain = getDomainX(dataGraph, 'press');

  const DomainY = () => {
    if (DataAxisY.length === 1) {
      const key = DataAxisY[0];
      const values = dataGraph.map(item => parseFloat(item[key])).filter(value => !isNaN(value)); 
      const min = Math.min(...values);
      const max = Math.max(...values);
      return [min, max];
    }
    return ['auto', 'auto'];
  };

  const calculateTicks = (min, max, tickCount) => {
    const step = (max - min) / (tickCount - 1);
    return Array.from({ length: tickCount }, (_, i) => min + i * step);
  };
  
  return (
    <div className='flex w-[1500px] h-[700px]'>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={300}
          data={dataGraph}
          margin={{
            top: 20,
            right: 50,
            left: 38,
            bottom: 20
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            type='number'
            dataKey="press"
            tickCount={14}
            domain={xDomain}
            allowDataOverflow={true}
            label={{ 
              value: "Pressure (psia)", 
              position: "insideBottom", 
              offset: -15,
              fontSize: 14 
            }}
            tick={{ fontSize: 12}}
            ticks={calculateTicks(xDomain[0], xDomain[1], 11)}
          />
          {linesAxis.map((lineKey, index) => (
            <YAxis
              key={`yAxis-${namData[lineKey].name}`}
              type="number" 
              label={{
                value: namData[lineKey]?.name, // Etiqueta del eje Y
                angle: index % 2 === 0 ? -90 : 90,
                position: index % 2 === 0 ? 'outsideLeft' : 'outsideRight', // Coloca las etiquetas fuera
                fill: colorsByType[lineKey],
                style: { textAnchor: "middle" }, // Mover el label hacia arriba
                fontSize: 14,
                dx: index % 2 === 0 && lineKey != "oil_compressibility" ? -20 : lineKey === "oil_compressibility" && index % 2 != 0 ? 48 : index % 2 === 0 && lineKey === "oil_compressibility" ? -50 : 20,
              }}
              tick={{ fill: colorsByType[lineKey], 
              fontSize: 12}} // Color de las marcas
              stroke={colorsByType[lineKey]} // Color del eje, igual al de la línea
              yAxisId={index} // Cada eje tiene un ID único
              orientation={index % 2 === 0 ? 'left' : 'right'} // Alterna la orientación
              interval={0} // Muestra todos los ticks
              width={80} 
              tickCount={13} 
              domain={DomainY()}
              allowDataOverflow={true}
            />
            ))}
          <Tooltip content={<CustomTooltip stateContent={stateCheck}/>}/>
          {DataAxisY.map((key, index) => (
            <Line
              key={key}
              type="monotone"
              dataKey={key}
              yAxisId={index}
              stroke={getColorByType(key)}
              dot={{ r: 4 }}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}
