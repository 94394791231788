import React from 'react'

export const TablePerformanceAnalysis = ({dataTable}) => {
  return (
    <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
            <tr>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Sensitivity</th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Oil Rate(STB/D)</th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Lift Gas Injection Rate(MSCF/D)</th>
            </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
            {dataTable.map((item,index) => (
            <tr key={index} className="hover:bg-gray-100">
                <td className="px-6 py-4 whitespace-nowrap">{item.sensitivity}</td>
                <td className="px-6 py-4 whitespace-nowrap">{item.oil_rate}</td>
                <td className="px-6 py-4 whitespace-nowrap">{item.lift_gas_injection_rate}</td>
            </tr>
            ))}
        </tbody>
    </table>
  )
}
