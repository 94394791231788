import { serviceEndPoints } from "../../config/config";

const BASE_URL_IPR = serviceEndPoints.IPR.BASE_URL_IPR+"/IPR";

export const uploadJsonIPR = async (dataJson) => {
  const operator = dataJson.operator;
    const lease = dataJson.lease;
    const region = dataJson.region;
    const county = dataJson.county;
    const field = dataJson.field;
    const well = dataJson.well;
    const username = dataJson.username;
  try {
    const response = await fetch(`${BASE_URL_IPR}/upload_json/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`, {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm" },
      body: JSON.stringify(dataJson.data)
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem getting users");
  }
};

export const uploadCsvIPR = async (operator,lease,region,country,field,well,dataFile) => {
  try {
    const response = await fetch(`${BASE_URL_IPR}/upload_csv/${operator}/${lease}/${region}/${country}/${field}/${well}`, {
      method: "POST",
      headers: { 
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm" },
      body: dataFile
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem getting users");
  }
};

export const calculateQIPR = async (operator,lease,region,country,field,well,username) => {
  try {
    const response = await fetch(`${BASE_URL_IPR}/calculate_q/${operator}/${lease}/${region}/${country}/${field}/${well}/${username}`, {
      method: "GET",
      headers: { 
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm" }
    }); 
    return response.json();
  } catch (error) {
    throw new Error("Problem getting users");
  }
};

export const calculatePwfIPR = async (operator,lease,region,country,field,well,dataFile) => {
  try {
    const response = await fetch(`${BASE_URL_IPR}/calculate_pwf/${operator}/${lease}/${region}/${country}/${field}/${well}`, {
      method: "GET",
      headers: { 
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm" }
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem getting users");
  }
};

