import { serviceEndPoints } from "../../config/config";

const BASE_URL_PVT = serviceEndPoints.PVT.BASE_URL_PVT+"/PVT";

export const uploadJsonPVT = async (dataJson) => {
  try {
    const operator = dataJson.operator;
    const lease = dataJson.lease;
    const region = dataJson.region;
    const county = dataJson.county;
    const field = dataJson.field;
    const well = dataJson.well;
    const username = dataJson.username;
    const response = await fetch(`${BASE_URL_PVT}/upload_json_PVT/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`, {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm" },
      body: JSON.stringify(dataJson.data)
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem getting users");
  }
};

export const uploadJsonDataPVT = async (dataJson) => {
  try {
    const operator = dataJson.operator;
    const lease = dataJson.lease;
    const region = dataJson.region;
    const county = dataJson.county;
    const field = dataJson.field;
    const well = dataJson.well;
    const username = dataJson.username;
    const response = await fetch(`${BASE_URL_PVT}/upload_json_data/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`, {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm" },
      body: JSON.stringify(dataJson.data)
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem getting users");
  }
};

export const udpateJsonPVT = async (dataJson) => {
  try {
    const operator = dataJson.operator;
    const lease = dataJson.lease;
    const region = dataJson.region;
    const county = dataJson.county;
    const field = dataJson.field;
    const well = dataJson.well;
    const username = dataJson.username;
    const response = await fetch(`${BASE_URL_PVT}/update_json_PVT/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`, {
      method: "PUT",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm" },
      body: JSON.stringify(dataJson.data)
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem getting users");
  }
};

export const updateJsonDataPVT = async (dataJson) => {
  try {
    const operator = dataJson.operator;
    const lease = dataJson.lease;
    const region = dataJson.region;
    const county = dataJson.county;
    const field = dataJson.field;
    const well = dataJson.well;
    const username = dataJson.username;
    const response = await fetch(`${BASE_URL_PVT}/update_json_data/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`, {
      method: "PUT",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm" },
      body: JSON.stringify(dataJson.data)
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem getting users");
  }
};

//ESTA APIS ESTAN DESCONTINUADAS
export const uploadJsonFoPVT = async (dataJson) => {
  try {
    const operator = dataJson.operator;
    const lease = dataJson.lease;
    const region = dataJson.region;
    const county = dataJson.county;
    const field = dataJson.field;
    const well = dataJson.well;
    const username = dataJson.username;
    const response = await fetch(`${BASE_URL_PVT}/upload_json_FO/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`, {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm" },
      body: JSON.stringify(dataJson.data)
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem getting users");
  }
};

export const updateJsonFoPVT = async (dataJson) => {
  try {
    const operator = dataJson.operator;
    const lease = dataJson.lease;
    const region = dataJson.region;
    const county = dataJson.county;
    const field = dataJson.field;
    const well = dataJson.well;
    const username = dataJson.username;
    const response = await fetch(`${BASE_URL_PVT}/update_json_FO/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`, {
      method: "PUT",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm" },
      body: JSON.stringify(dataJson.data)
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem getting users");
  }
};

export const calculatePbPVT = async (operator,lease,region,county,field,well,username) => {
  try {
    const response = await fetch(`${BASE_URL_PVT}/calculate_pb/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`, {
      method: "GET",
      headers: { 
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm" }
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem getting users");
  }
};

export const calculateRsPVT = async (operator,lease,region,county,field,well, username) => {
  try {
    const response = await fetch(`${BASE_URL_PVT}/calculate_rs/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`, {
      method: "GET",
      headers: { 
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm" }
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem getting users");
  }
};

export const calculateCoPVT = async (operator,lease,region,county,field,well, username) => {
  try {
    const response = await fetch(`${BASE_URL_PVT}/calculate_co/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`, {
      method: "GET",
      headers: { 
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm" }
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem getting users");
  }
};

export const calculateBoPVT = async (operator,lease,region,county,field,well, username) => {
  try {
    const response = await fetch(`${BASE_URL_PVT}/calculate_bo/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`, {
      method: "GET",
      headers: { 
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm" }
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem getting users");
  }
};

export const calculateDenoPVT = async (operator,lease,region,county,field,well, username) => {
  try {
    const response = await fetch(`${BASE_URL_PVT}/calculate_deno/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`, {
      method: "GET",
      headers: { 
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm" }
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem getting users");
  }
};

export const calculateVisoPVT = async (operator,lease,region,county,field,well, username) => {
  try {
    const response = await fetch(`${BASE_URL_PVT}/calculate_viso/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`, {
      method: "GET",
      headers: { 
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm" }
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem getting users");
  }
};

export const calculateStogPVT = async (operator,lease,region,county,field,well, username) => {
  try {
    const response = await fetch(`${BASE_URL_PVT}/calculate_stog/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`, {
      method: "GET",
      headers: { 
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm" }
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem getting users");
  }
};

export const calculatePpcPVT = async (operator,lease,region,county,field,well, username) => {
  try {
    const response = await fetch(`${BASE_URL_PVT}/calculate_ppc/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`, {
      method: "GET",
      headers: { 
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm" }
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem getting users");
  }
};

export const calculateTcpPVT = async (operator,lease,region,county,field,well, username) => {
  try {
    const response = await fetch(`${BASE_URL_PVT}/calculate_tpc/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`, {
      method: "GET",
      headers: { 
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm" }
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem getting users");
  }
};

export const calculatePprPVT = async (operator,lease,region,county,field,well, username) => {
  try {
    const response = await fetch(`${BASE_URL_PVT}/calculate_ppr/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`, {
      method: "GET",
      headers: { 
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm" }
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem getting users");
  }
};

export const calculateTprPVT = async (operator,lease,region,county,field,well, username) => {
  try {
    const response = await fetch(`${BASE_URL_PVT}/calculate_ppr/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`, {
      method: "GET",
      headers: { 
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm" }
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem getting users");
  }
};

export const calculateZfactorPVT = async (operator,lease,region,county,field,well, username) => {
  try {
    const response = await fetch(`${BASE_URL_PVT}/calculate_z_factor/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`, {
      method: "GET",
      headers: { 
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm" }
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem getting users");
  }
};

export const calculateBgPVT = async (operator,lease,region,county,field,well, username) => {
  try {
    const response = await fetch(`${BASE_URL_PVT}/calculate_bg/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`, {
      method: "GET",
      headers: { 
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm" }
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem getting users");
  }
};

export const calculateDengPVT = async (operator,lease,region,county,field,well, username) => {
  try {
    const response = await fetch(`${BASE_URL_PVT}/calculate_deng/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`, {
      method: "GET",
      headers: { 
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm" }
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem getting users");
  }
};

export const calculateVisgPVT = async (operator,lease,region,county,field,well, username) => {
  try {
    const response = await fetch(`${BASE_URL_PVT}/calculate_visg/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`, {
      method: "GET",
      headers: { 
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm" }
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem getting users");
  }
};

export const calculateStwgPVT = async (operator,lease,region,county,field,well, username) => {
  try {
    const response = await fetch(`${BASE_URL_PVT}/calculate_stwg/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`, {
      method: "GET",
      headers: { 
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm" }
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem getting users");
  }
};

export const calculateBwPVT = async (operator,lease,region,county,field,well, username) => {
  try {
    const response = await fetch(`${BASE_URL_PVT}/calculate_bw/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`, {
      method: "GET",
      headers: { 
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm" }
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem getting users");
  }
};

export const calculateDenwPVT = async (operator,lease,region,county,field,well, username) => {
  try {
    const response = await fetch(`${BASE_URL_PVT}/calculate_denw/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`, {
      method: "GET",
      headers: { 
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm" }
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem getting users");
  }
};

export const calculateViswPVT = async (operator,lease,region,county,field,well, username) => {
  try {
    const response = await fetch(`${BASE_URL_PVT}/calculate_visw/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`, {
      method: "GET",
      headers: { 
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm" }
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem getting users");
  }
};

export const calculateVislPVT = async (operator,lease,region,county,field,well, username) => {
  try {
    const response = await fetch(`${BASE_URL_PVT}/calculate_visl/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`, {
      method: "GET",
      headers: { 
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm" }
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem getting users");
  }
};


export const calculateSigmalPVT = async (operator,lease,region,county,field,well, username) => {
  try {
    const response = await fetch(`${BASE_URL_PVT}/calculate_sigmal/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`, {
      method: "GET",
      headers: { 
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm" }
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem getting users");
  }
};

//APIS QUE ACTUALMENTE SE ENCUENTRAN FUNCIONANDO EN EL PROCESOS DE PVT
export const uploadPVT = async (dataJson) => {
  try {
    const operator = dataJson.operator;
    const lease = dataJson.lease;
    const region = dataJson.region;
    const county = dataJson.county;
    const field = dataJson.field;
    const well = dataJson.well;
    const username = dataJson.username;
    const response = await fetch(`${BASE_URL_PVT}/upload_json_PVT/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`, {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm" },
      body: JSON.stringify(dataJson.data)
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem getting users");
  }
};

export const uploadJsonTablePVT = async (dataJson) => {
  try {
    const operator = dataJson.operator;
    const lease = dataJson.lease;
    const region = dataJson.region;
    const county = dataJson.county;
    const field = dataJson.field;
    const well = dataJson.well;
    const username = dataJson.username;
    const response = await fetch(`${BASE_URL_PVT}/upload_json_data/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`, {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm" },
      body: JSON.stringify(dataJson.data)
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem getting users");
  }
};

export const getRS = async (operator,lease,region,county,field,well, username, lab_calc) => {
  try {
    const response = await fetch(`${BASE_URL_PVT}/calculate_rs${lab_calc}/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`, {
      method: "GET",
      headers: { 
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm" }
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem getting users");
  }
};

export const getBo = async (operator,lease,region,county,field,well, username, lab_calc) => {
  try {
    const response = await fetch(`${BASE_URL_PVT}/calculate_bo${lab_calc}/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`, {
      method: "GET",
      headers: { 
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm" }
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem getting users");
  }
};

export const getViso = async (operator,lease,region,county,field,well, username, lab_calc) => {
  try {
    const response = await fetch(`${BASE_URL_PVT}/calculate_viso${lab_calc}/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`, {
      method: "GET",
      headers: { 
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm" }
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem getting users");
  }
};

export const getCo = async (operator,lease,region,county,field,well, username, lab_calc) => {
  try {
    const response = await fetch(`${BASE_URL_PVT}/calculate_co${lab_calc}/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`, {
      method: "GET",
      headers: { 
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm" }
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem getting users");
  }
};

export const getCalculationPb = async (operator,lease,region,county,field,well, username, lab_calc) => {
  try {
    const response = await fetch(`${BASE_URL_PVT}/calculate_pb${lab_calc}/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`, {
      method: "GET",
      headers: { 
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm" }
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem getting users");
  }
};

export const getCalculationsParameters = async (operator,lease,region,country,field,well, username) => {
  try {
    const response = await fetch(`${BASE_URL_PVT}/calculate_p1_p2/${operator}/${lease}/${region}/${country}/${field}/${well}/${username}`, {
      method: "GET",
      headers: { 
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm" }
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem getting users");
  }
};

export const getCalculationsDeviatonPB = async (operator,lease,region,country,field,well, username) => {
  try {
    const response = await fetch(`${BASE_URL_PVT}/calculate_standard_deviation_pb/${operator}/${lease}/${region}/${country}/${field}/${well}/${username}`, {
      method: "GET",
      headers: { 
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm" }
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem getting users");
  }
};

export const getCalculationsDeviaton = async (operator,lease,region,country,field,well, username) => {
  try {
    const response = await fetch(`${BASE_URL_PVT}/calculate_standard_deviation/${operator}/${lease}/${region}/${country}/${field}/${well}/${username}`, {
      method: "GET",
      headers: { 
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm" }
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem getting users");
  }
};

export const getRSTuned = async (operator,lease,region,country,field,well, username) => {
  try {
    const response = await fetch(`${BASE_URL_PVT}/calculate_rs_tuned/${operator}/${lease}/${region}/${country}/${field}/${well}/${username}`, {
      method: "GET",
      headers: { 
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm" }
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem getting users");
  }
};

export const getBOTuned = async (operator,lease,region,country,field,well, username) => {
  try {
    const response = await fetch(`${BASE_URL_PVT}/calculate_bo_tuned/${operator}/${lease}/${region}/${country}/${field}/${well}/${username}`, {
      method: "GET",
      headers: { 
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm" }
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem getting users");
  }
};

export const getVisoTuned = async (operator,lease,region,country,field,well, username) => {
  try {
    const response = await fetch(`${BASE_URL_PVT}/calculate_viso_tuned/${operator}/${lease}/${region}/${country}/${field}/${well}/${username}`, {
      method: "GET",
      headers: { 
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm" }
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem getting users");
  }
};

export const getPBTuned = async (operator,lease,region,country,field,well, username) => {
  try {
    const response = await fetch(`${BASE_URL_PVT}/calculate_pb_tuned/${operator}/${lease}/${region}/${country}/${field}/${well}/${username}`, {
      method: "GET",
      headers: { 
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm" }
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem getting users");
  }
};

export const getSepPress = async (operator,lease,region,country,field,well, username) => {
  try {
    const response = await fetch(`${BASE_URL_PVT}/calculate_press/${operator}/${lease}/${region}/${country}/${field}/${well}/${username}`, {
      method: "GET",
      headers: { 
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm" }
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem getting users");
  }
};

export const getSepCalculate = async (operator,lease,region,country,field,well, username, lab_calc) => {
  try {
    const response = await fetch(`${BASE_URL_PVT}/calculate_sep${lab_calc}/${operator}/${lease}/${region}/${country}/${field}/${well}/${username}`, {
      method: "GET",
      headers: { 
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm" }
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem getting users");
  }
};
