import React from 'react'
import { AuthForm } from '../../components/Login//AuthForm'
export const Login = () => {
  return (
    <div className="flex w-full font-sans bg-no-repeat bg-center bg-cover bg-fd-login justify-start  ">
      <div className="w-full h-screen flex items-center justify-center lg:items-end lg:justify-start lg:w-[380px] min-h-[51rem] lg:ml-20">
        <AuthForm></AuthForm>
      </div>
    </div>
  )
}
