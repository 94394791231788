import { serviceEndPoints } from "../../config/config";

const BASE_URL_FLOW = serviceEndPoints.FLOW.BASE_URL_FLOW+"/outflow_inflow";

export const getDataFlow= async (operator, lease, region, county, field, wellid, username) => {
    try {
        const reponse = await fetch(`${BASE_URL_FLOW}/calculate_outflow_inflow/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${username}`, {
            method: "GET",
            headers: {
                "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm"
            },
        });
        return reponse.json();
    }catch (error){
        throw new Error("Problem in data tuning");
    }
}

export const getDataFlowGraph = async (operator, lease, region, county, field, wellid, username) => {
    try {
        const reponse = await fetch(`${BASE_URL_FLOW}/grouped_inflow_and_outflow_information/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${username}`, {
            method: "GET",
            headers: {
                "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm"
            },
        });
        return reponse.json();
    }catch (error){
        throw new Error("Problem in data tuning");
    }
}

export const postDataFlow = async (dataJson) => {
    try {
        const operator = dataJson.operator;
        const lease = dataJson.lease;
        const region = dataJson.region;
        const county = dataJson.county;
        const field = dataJson.field;
        const well = dataJson.well;
        const username = dataJson.username; 
        const data = dataJson.data;
        const response = await fetch(`${BASE_URL_FLOW}/upload_json_outflow_inflow/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`, {
            method: "POST",
            headers: { 
            "Content-Type": "application/json",
            "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm" },
            body: JSON.stringify(data)
        });
    return response.json();
    } catch (error) {
    throw new Error("Problem getting data");
    }
};

export const getDataPerformanceFlow= async (operator, lease, region, county, field, wellid, username) => {
    try {
        const reponse = await fetch(`${BASE_URL_FLOW}/calculate_performance_flow/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${username}`, {
            method: "GET",
            headers: {
                "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm"
            },
        });
        return reponse.json();
    }catch (error){
        throw new Error("Problem in data tuning");
    }
}

export const postDataPerformanceFlow = async (dataJson) => {
    try {
        const operator = dataJson.operator;
        const lease = dataJson.lease;
        const region = dataJson.region;
        const county = dataJson.county;
        const field = dataJson.field;
        const well = dataJson.well;
        const username = dataJson.username; 
        const data = dataJson.data;
        const response = await fetch(`${BASE_URL_FLOW}/upload_json_performance_flow/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`, {
            method: "POST",
            headers: { 
            "Content-Type": "application/json",
            "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm" },
            body: JSON.stringify(data)
        });
    return response.json();
    } catch (error) {
    throw new Error("Problem getting data");
    }
};