import { logDOM } from "@testing-library/react";
import React, { PureComponent, useContext, useState } from "react";
import { useEffect } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import moment from "moment";
import { DataGasLiftContext } from "../../../context/DataGasLift/DataGasLiftContext";
import { DashboardContext } from "../../../context/Dashboard/DashboardContentx";

const ChartHole = (props) => {
  const {
    dataGrapIOT,
    dataHistory,
    dataDomainGraph,
    statusGraph
  } = props;
  const { dataFilterUserGasLift } = useContext(DataGasLiftContext);
  const { deviceRealTime } = useContext(DashboardContext);
  const validateDataProp = !!dataGrapIOT || !!dataHistory;

  const pressureMax = !!dataDomainGraph.press_max_wpc && validateDataProp ? dataDomainGraph.press_max_wpc : 0
  const pressureMin = !!dataDomainGraph.press_min_wpc && validateDataProp ? dataDomainGraph.press_min_wpc : 0
  const temperatureMax = !!dataDomainGraph.temp_max_wpc && validateDataProp ? dataDomainGraph.temp_max_wpc : 0
  const temperatureMin = !!dataDomainGraph.temp_min_wpc && validateDataProp ? dataDomainGraph.temp_min_wpc : 0
  
  const [yAxisDomain1, setYAxisDomain1] = useState([pressureMin, pressureMax]);
  const [yAxisDomain2, setYAxisDomain2] = useState([temperatureMin, temperatureMax,]);
  
  useEffect(() => {
    setYAxisDomain1([pressureMin, pressureMax]);
    setYAxisDomain2([temperatureMin, temperatureMax]);
  }, [pressureMax, temperatureMax, pressureMin, temperatureMin]);

  const formattedData = dataGrapIOT?.map((item, index) => ({
    date: String(item["Date (MM/DD/YYYY)"]).split(" ")[0],
    //date_hour: String(item["Date (MM/DD/YYYY)"]),
    'Static Pressure': parseFloat(item[deviceRealTime?.device_gaslift?.discretized ? dataFilterUserGasLift.well_name + ' Well Head Pressure': 'Static Pressure' ]),
    "Flowing Temperature": parseFloat(item["Flowing Temperature"]),
  }));

  let dataFinalGraph = [];

  if(dataHistory != null && statusGraph === false){
    const dataHistoryMod = dataHistory?.map((item, index) => ({
      "date": String(item["date"])?.split(" ")[0],
      "Static Pressure": item["wellhead_pressure"],
      "Flowing Temperature": item["wellhead_temp"],
    }));
  
    dataFinalGraph = dataHistoryMod;
    
  }else{
    dataFinalGraph = formattedData;
  }

  const CustomDot = ({ cx, cy, payload, index, color }) => {
    // Solo muestra el punto si el índice es un múltiplo de 5000
    if ((index + 1) % 1 === 0) {
      return (
        <circle cx={cx} cy={cy} r={2} stroke={color} fill={color} />
      );
    }
    return null; // No muestra el punto
  };

  function obtenerFechasUnicas(data) {
    const fechas = data?.map(item => item?.date); // Obtener todas las fechas
    const fechasUnicas = fechas?.filter((fecha, index) => fechas.indexOf(fecha) === index);
    return fechasUnicas;
  }
  
  // Obtener fechas únicas
  const fechasUnicas = obtenerFechasUnicas(dataFinalGraph);

  function customTooltip({ active, payload, option }) {
    if (active && payload && payload.length) {
      const valuesDate = payload[0]?.payload;
      const valuesData1 = payload[0]?.value;
      const valuesData2 = payload[1]?.value;
      return (
        <div
          className="custom-tooltip"
          style={{ backgroundColor: "white", padding: "5px", textAlign: "left" }}
        >
          <p>
            {`Date: `}
            <span>{valuesDate?.date} </span>
          </p>
          <p style={{ color: "#16a34a"}}>
            {`Static Pressure: `}
            <span>{valuesData1} (psig)</span>
          </p>
          <p style={{ color: "#dc2626"}}>
            {`Flowing Temperature: `}
            <span>{valuesData2} (deg F)</span>
          </p>
          
        </div>
      );
    }
  
    return null;
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={500}
        height={200}
        data={dataFinalGraph}
        margin={{
          top: 0,
          right: 30,
          left: 23,
          bottom: 20,
        }}
      >
        <CartesianGrid />
        <XAxis
          dataKey="date"
          label={{
            value: `Date`,
            style: { textAnchor: "middle" },
            //key: "xAxisLabel",
            position: "bottom",
          }}
          tickCount={9}
          // ticks={fechasUnicas}
          tickFormatter={(unixTime) => moment(unixTime,"MM/DD/YYYY").format("MM/DD/YY")}
          type="category"
        />
        <YAxis
          yAxisId="left"
          domain={yAxisDomain1}
          allowDataOverflow={true}
          label={{
            value: `Wellhead Pressure (psig)`,
            style: { textAnchor: "middle" },
            angle: -90,
            position: "left",
            offset: 0,
          }}
          // tickCount={8}
          padding={{ bottom: 10 }}
        />
        <YAxis
          yAxisId="right"
          domain={yAxisDomain2}
          orientation="right"
          allowDataOverflow={true}
          label={{
            value: `Wellhead Temperature (deg F)`,
            style: { textAnchor: "middle" },
            angle: -90,
            position: "left",
            offset: -60,
          }}
          // tickCount={7}
          padding={{ bottom: 10 }}
        />
        <Tooltip content={customTooltip}/>
        {/* <Legend align="center" verticalAlign="top" /> */}
        <Line
          yAxisId="left"
          type="monotone"
          dataKey="Static Pressure"
          stroke="#16a34a"
          dot={<CustomDot color="#16a34a" 
          />}
          activeDot={{ r: 8 }}
          connectNulls={true} // mostrar que se conecten los puntos sin valor
        />
        <Line
          yAxisId="right"
          type="monotone"
          dataKey="Flowing Temperature"
          stroke="#dc2626"
          dot={<CustomDot color="#dc2626" />}
          activeDot={{ r: 8 }}
          connectNulls={true} // mostrar que se conecten los puntos sin valor
        />
      </LineChart>
    </ResponsiveContainer>
  );
};
export default ChartHole;
