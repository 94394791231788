import React, { useContext, useEffect, useState } from 'react'
import { DashboardContext } from '../../context/Dashboard/DashboardContentx';
import { Navbar } from '../../components/layout/Navbar';
import { Sidebar } from '../../components/layout/Sidebar';
import { Menu, Select, Modal, Button, Alert, message } from 'antd';
import { ChartCrossOilGor } from '../../components/ScreeningTool/GraphicsSCT/StatustucakAnalytics/ChartCrossOilGor';
import { ChartPieStatus } from '../../components/ScreeningTool/GraphicsSCT/StatustucakAnalytics/ChartPieStatus';
import { ChartMultipleScatter } from '../../components/ScreeningTool/GraphicsSCT/StatustucakAnalytics/ChartMultipleScatter';
import { ChartPieStage } from '../../components/ScreeningTool/GraphicsSCT/StatustucakAnalytics/ChartPieStage';
import { ChartTableAnalytics } from '../../components/ScreeningTool/GraphicsSCT/StatustucakAnalytics/ChartTableAnalytics';
import { ChartPoint_1 } from '../../components/ScreeningTool/GraphicsSCT/MachingLearningReport/ChartPoint_1';
import { ChartPoint_2 } from '../../components/ScreeningTool/GraphicsSCT/MachingLearningReport/ChartPoint_2';
import { ChartPoint_3 } from '../../components/ScreeningTool/GraphicsSCT/MachingLearningReport/ChartPoint_3';
import { ChartPoint_4 } from '../../components/ScreeningTool/GraphicsSCT/MachingLearningReport/ChartPoint_4';
import { ChartPie_1 } from '../../components/ScreeningTool/GraphicsSCT/MachingLearningReport/ChartPie_1';
import { ChartPie_2 } from '../../components/ScreeningTool/GraphicsSCT/MachingLearningReport/ChartPie_2';
import { useTabScreening } from '../../hooks/useTabScreening';
import { Loading } from "../../components/Loading";
import { ReactComponent as ExpandIcon } from "../../assets/icon/expand1.svg";
import { DashboardSCTContext } from '../../context/DashboardSCT/DasboardSCTContext';

const items = [
  {
    label: 'Statistical Analytics',
    key: 'tab1',
  },
  // {
  //   label: 'Machine Learning',
  //   key: 'tab2',
  // },
  {
    label: 'Analysis report',
    key: 'tab3',
  }
];

const Typegraphics = [
  {Donut: {

  }}
]

export const DashboardScreeningTool = () => { 

  const { Option } = Select;
  const { getDataPage, selectedReservoirSCT } = useContext(DashboardContext);
  const { dataFilterUserSCT} = useContext(DashboardSCTContext);
  const { 
    queryRankStats, 
    queryScreeningDonut,
    queryScreeningDonutDepth,
    queryScreening3DRank,
    queryScreening3DRankDepth,
    queryMultipleScatter,
    queryFileScreening,
    refetchQueryRankStats,
    refetchScreeningDonut,
    refetchScreeningDonutDepth,
    refetchScreening3DRank, 
    refetchQueryMultipleScatter,
    refetchScreening3DRankDepth,
    refetchQueryFileScreening,
    loadDataScreening3DRank,
    loadDataScreening3DRankDepth
  } = useTabScreening();
  const [ current, setCurrent ] = useState('tab1');
  const [ openModalCroosPlot, setOpenModalCroosPlot ] = useState(false);
  const [ openModal3D, setOpenModal3D ] = useState(false);
  const [ typeGraphMultipleScatter, setTypeGraphMultipleScatter] = useState();
  const [ dataGraphMultipleScatter, setDataGraphMultipleScatter] = useState([]);
  const [ selectDonut, setSelectDonut ] = useState("Donut");
  const [ select3D, setSelect3d ] = useState("3drank");;
  const [ selectRank, setSelectRank ] = useState("");
  const [ selectTypeRank, setSelectTyperank ] = useState("Oil Rate (STB/D)")  
  const [ dataFile, setDataFile ] = useState();
  const [ queryRankPie, setQueryRankPie] = useState("");
  const [ typeRankpie, setTypeRankpie] = useState("");
  const rankKeys = queryRankStats?.statusCode === 200044 && !!queryRankStats?.data[0] ? Object.keys(queryRankStats?.data[0]) : [];
  const rankIndex = rankKeys.indexOf(selectRank);
  
  useEffect(() => {
      getDataPage("DashboardScreeningTool");
      if(!!selectedReservoirSCT){
        refetchQueryRankStats();
        refetchQueryMultipleScatter();
        refetchScreeningDonut();
        refetchScreeningDonutDepth();
        refetchScreening3DRank();
        refetchScreening3DRankDepth();
        refetchQueryFileScreening();
      }
  }, []);
  
  useEffect(() => {
    if (queryRankStats?.statusCode === 200044 && !!queryRankStats?.data[0]) {
      const dataRanks = queryRankStats?.data[0];
      const rankValue = Object.keys(dataRanks)[0];
      
      const type = dataRanks[rankValue];
      
      setSelectRank(rankValue)
      setQueryRankPie(dataRanks);
      setTypeRankpie(type);
    }else{
      setQueryRankPie([]);
      setTypeRankpie("");
      setSelectRank("")
    }
  }, [queryRankStats])

  useEffect(() => {
    if (!!queryFileScreening) {
      const csvBlob = new Blob([queryFileScreening], { type: "text/csv" });
    
    const csvFile = new File([csvBlob], "Gas_lift_screening_data.csv", {
      type: "text/csv",
      lastModified: new Date(),
    });
    
    const formData = new FormData();
    formData.append('file', csvFile);

    setDataFile(csvFile)
    }
  }, [queryFileScreening]);
  
  const onClick = (e) => {
    setCurrent(e.key);
  };

  const handleModal = (state, type) => {
    const dataGraphQuery = queryMultipleScatter?.data[0];
    let combinedArray = [];
    
    setOpenModalCroosPlot((type === "oil_vs_water_cut" || type === "gor_vs_water_cut" || type === "oil_rate_vs_gor") ? state : !type ? state : false);
    setOpenModal3D(type === "3DChart" ? state : !type ? state : false);
    setTypeGraphMultipleScatter(type);

    switch (type) {
      case "oil_vs_water_cut":
        combinedArray = verifyTypeGraph(dataGraphQuery?.oil_rate_vs_water_cut, "oil_rate", "water_cut");
        setDataGraphMultipleScatter(combinedArray);
        break;
      case "gor_vs_water_cut":
        combinedArray = verifyTypeGraph(dataGraphQuery?.gor_vs_water_cut, "gor", "water_cut");
        setDataGraphMultipleScatter(combinedArray);
      break;
      case "oil_rate_vs_gor":
        combinedArray = verifyTypeGraph(dataGraphQuery?.oil_rate_vs_gor, "oil_rate", "gor");
        setDataGraphMultipleScatter(combinedArray);
        break;
      case "3DChart":
        
      break
      default:
        break;
    }
  }

  const handleChangeSelect = (name, valueData) => {
    switch (name) {
      case "typeDonut":
        setSelectDonut(valueData);
        break;
      case "select_rank":
        setSelectRank(valueData);
        break;
      case "typeRank3D":
        setSelect3d(valueData);
        break;
      case "type_rank":
      setSelectTyperank(valueData);
      break;
      default:
        break;
    }
  };
  
  function verifyTypeGraph(data, value1, value2) {
    
    if(data && Object.keys(data).length){
      const dataGraph = data;
      const combinedArray = dataGraph[value1]?.map((value, index) => ({
        [value1]: value,
        [value2]: data[value2][index]
      }));

      return combinedArray;
    }{
      return [];
    }
  }
  
  const ButtonDowload = () => {
    if (dataFile !== undefined ) {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(dataFile);
      link.download = 'Gas lift screening data.csv';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      message.info("The file could not be downloaded")
    }
  }

  return (
    <div className='flex flex-col w-full h-screen bg-white'>
      <Modal
        title={typeGraphMultipleScatter === "oil_vs_water_cut" ? "Crossplot Oil Rate vs Water Cut" : typeGraphMultipleScatter === "gor_vs_water_cut" ? "Crossplot GOR vs Water Cut" : typeGraphMultipleScatter === "oil_rate_vs_gor" ? "Crossplot Oil Rate vs GOR" : ""}
        centered
        open={openModalCroosPlot}
        onOk={() => {handleModal(false)}}
        onCancel={() => {handleModal(false)}}
        width="70%"
        className="rounded-md"
        okButtonProps={{
          className: "bg-[#662D91]"
        }}
        footer={[
          <Button type="primary" className="bg-[#662D91] hover:bg-[#662D91]" onClick={() => handleModal(false)}>
            OK
          </Button>
        ]}
      >
        <div className="flex flex-col w-full h-full text-center justify-center mt-8">
          <div 
            className={`flex w-full justify-center text-center mb-[0] h-full`}
            style={{ height: '600px' }}
          >
            {}
            <ChartMultipleScatter 
              dataGraph={dataGraphMultipleScatter} 
              type={typeGraphMultipleScatter}
            >
            </ChartMultipleScatter>
          </div>
        </div>
      </Modal>
      <Modal
        title={"3D Rank"}
        centered
        open={openModal3D}
        onOk={() => {handleModal(false)}}
        onCancel={() => {handleModal(false)}}
        width="70%"
        className="rounded-md"
        okButtonProps={{
          className: "bg-[#662D91]"
        }}
        footer={[
          <Button type="primary" className="bg-[#662D91] hover:bg-[#662D91]" onClick={() => handleModal(false)}>
            OK
          </Button>
        ]}
      >
        <div className="flex flex-col w-full h-full text-center justify-center mt-8">
          <div 
            className={`flex w-full justify-center text-center mb-[0] h-full`}
            style={{ height: '600px' }}
          >

            {((!loadDataScreening3DRank || !loadDataScreening3DRankDepth) && (!!queryScreening3DRank || !!queryScreening3DRankDepth)) 
              ?
                <>
                  <div>
                    <ChartCrossOilGor 
                      graphicRank={queryScreening3DRank} 
                      graphicDepth={queryScreening3DRankDepth} 
                      enable={select3D}
                      className="flex flex-col w-[1200px] h-[600px] text-center justify-center mt-8"
                    />
                  </div>
                </>
              : (loadDataScreening3DRank || loadDataScreening3DRankDepth) ? 
                <div 
                  className="flex flex-col w-full h-[28rem] text-center justify-center mt-8"
                >
                  <Loading/>
                </div>
              :
                <div>
                  <ChartCrossOilGor 
                    graphicRank={[]} 
                    graphicDepth={[]} 
                    enable={select3D}
                    className="flex flex-col w-full h-[28rem] text-center justify-center mt-8"
                  />
                <label htmlFor="">entra 3</label>
                </div>
            }
          </div>
        </div>
      </Modal>
      <div className='flex'>
          <Navbar/>
          <Sidebar className="flex abosolute h-full"/>
      </div>
      {!!selectedReservoirSCT ?
      <>
        <div className='flex flex-col w-full pt-[40px] pr-[60px] pl-[140px]'>
          <h1>Reservoir</h1>
          <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" items={items} className=''/>
          {current === "tab1" &&
          <>
            <div className="flex flex-row w-[66.5%] gap-4 pt-5 justify-center items-center">
              <div className="flex w-[190px] h-[30px]">
                <button
                  className="flex w-[190px] h-[35px] justify-center text-center items-center text-[12px] text-pa-purple border border-solid border-pa-purple rounded-md hover:bg-pa-purple hover:text-pa-white1"
                  onClick={() => handleModal(true, "oil_vs_water_cut")}
                >
                  Crossplot Oil Rate vs Water Cut
                </button>
              </div>
              <div className="flex w-[190px] h-[30px]">
                <button
                  className="flex w-[190px] h-[35px] justify-center text-center items-center text-[12px] text-pa-purple border border-solid border-pa-purple rounded-md hover:bg-pa-purple hover:text-pa-white1"
                  onClick={() => handleModal(true, "gor_vs_water_cut")}
                >
                  Crossplot GOR vs Water Cut
                </button>
              </div>
              <div className="flex w-[190px] h-[30px]">
                <button
                  className="flex w-[190px] h-[35px] justify-center text-center items-center text-[12px] text-pa-purple border border-solid border-pa-purple rounded-md hover:bg-pa-purple hover:text-pa-white1"
                  onClick={() => handleModal(true, "oil_rate_vs_gor")}
                >
                  Crossplot Oil Rate vs GOR
                </button>
              </div>            
            </div>
            <div className='flex flex-row w-full h-full gap-6 pt-5 pb-10'>
              <div className='flex flex-col w-[70%] h-full gap-4'>
                <div className='w-full h-3/4 border border-solid border-gray-600'>
                  <div className='mt-[10px] ml-[10px] w-[350px] h-[30px] gap-2 flex items-start'>
                    <Select
                      name="typeRank3D"
                      value={select3D || "Selected option"}
                      className="w-[150px] h-[30px] text-start mb-[17px]"
                      onChange={(value) => handleChangeSelect("typeRank3D", value)}
                    >
                      <Option value="3drank">3D Graph</Option>
                      <Option value="3drankdepth">3D Graph Depth</Option>
                    </Select>
                    {/* <ExpandIcon 
                      className="w-5 h-5 mt-[5px] hover:bg-pc-grey3"
                      onClick={() => handleModal(true, "3DChart")}
                    /> */}
                  </div>
                  {((!loadDataScreening3DRank || !loadDataScreening3DRankDepth) && (!!queryScreening3DRank || !!queryScreening3DRankDepth)) 
                    ?
                      <>
                        <div>
                          <ChartCrossOilGor 
                            graphicRank={queryScreening3DRank} 
                            graphicDepth={queryScreening3DRankDepth} 
                            enable={select3D}
                            className="flex flex-col w-full h-[28rem] text-center justify-center mt-8"
                          />
                        </div>
                      </>
                    : (loadDataScreening3DRank || loadDataScreening3DRankDepth) ? 
                      <div 
                        className="flex flex-col w-full h-[28rem] text-center justify-center mt-8"
                      >
                        <Loading/>
                      </div>
                    :
                      <div>
                        <ChartCrossOilGor 
                          graphicRank={[]} 
                          graphicDepth={[]} 
                          enable={select3D}
                          className="flex flex-col w-full h-[28rem] text-center justify-center mt-8"
                        />
                      </div>
                  }
                </div>
                <div className='mt-[5px] '>
                  <label>{rankIndex !== -1 ? `Cluster ${rankIndex + 1}` : "Cluster"}</label>
                </div>
                <div className=' flex w-full h-1/3 border border-solid border-gray-600'>
                  <ChartTableAnalytics dataTable={queryRankPie} rank={selectRank} ></ChartTableAnalytics>
                </div>
              </div>
              <div className='flex flex-col w-1/3 h-[710px] gap-4'>
                <div className='w-full h-1/2 border border-solid border-gray-600'>
                  <div className='mt-[1px] items-center text-center '>
                    <label >Well distribution by cluster</label>
                  </div>
                  <div className='mt-[2px] ml-[10px] w-[300px]  h-[30px]'>
                    <Select
                      name="typeDonut"
                      value={selectDonut || "Selected option"}
                      className="w-full max-w-[200px] h-[25px] text-start mb-[17px]"
                      onChange={(value) => handleChangeSelect("typeDonut", value)}
                    >
                      <Option value="Donut">Clustering without depth</Option>
                      <Option value="DonutDepth">Clustering with depth</Option>
                    </Select>
                  </div>
                  <div className='h-[50px] '>
                    <ChartPieStatus dataDonut={queryScreeningDonut?.data} dataDonutDepth={queryScreeningDonutDepth?.data} status={selectDonut} ></ChartPieStatus>
                  </div>
                </div>
                <div className='w-full h-1/2 border border-solid border-gray-600'>
                  <div className='mt-[1px] items-center text-center '>
                    <label >Well distribution by intervals in Cluster {rankIndex !== -1 ? `cluster ${rankIndex + 1}` : ""}</label>
                  </div>
                  <div className='flex mt-[1px] ml-[10px] w-[500px] h-[30px]'>
                    <Select
                      name="select_rank"
                      value={selectRank || "Selected option"}
                      className="w-full max-w-[200px] h-[30px] text-start mb-[17px]"
                      onChange={(value) => handleChangeSelect("select_rank", value)}
                    >
                      {Object.keys(queryRankPie)?.map((item, index)=> (
                        <Option 
                          key={index} 
                          value={item}
                        >
                          {`Cluster ${index + 1}`}
                        </Option>
                      ))}
                    </Select>
                    <Select
                      name="type_rank"
                      value={selectTypeRank || "Selected option"}
                      className="w-full max-w-[200px] h-[30px] text-start mb-[17px] ml-[15px] "
                      onChange={(value) => handleChangeSelect("type_rank", value)}
                    >
                      {Object.keys(typeRankpie)?.filter(item => item !== "total_wells").map((item, index)=> (
                        <Option
                          key={index}
                          value={item}
                        >
                          {item}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <div className='h-[310px]'>
                    <ChartPieStage dataRank={queryRankPie} rank={selectRank} typeRank={selectTypeRank} ></ChartPieStage>
                  </div>
                </div>
              </div>
            </div>
          </>
          }
          {current === "tab2" &&
            <div className='flex flex-col w-full h-full gap-6 pt-10 pb-10'>
              <div className='flex flex-row w-full h-[470px] gap-4 text-center justify-center'>
                <div className='flex w-1/2  h-full border border-solid border-gray-600'>
                  <ChartPoint_1></ChartPoint_1>
                </div>
                <div className=' flex w-1/2 h-full border border-solid border-gray-600'>
                  <ChartPoint_2></ChartPoint_2>
                </div>
              </div>
              <div className='flex flex-row w-full h-[470px]  gap-4 text-center justify-center'>
                <div className='flex w-1/2 h-full border border-solid border-gray-600'>
                  <ChartPoint_3></ChartPoint_3>
                </div>
                <div className=' flex w-1/2 h-full border border-solid border-gray-600'>
                  <ChartPoint_4></ChartPoint_4>
                </div>
              </div>
              <div className='flex flex-row w-full h-[470px]  gap-4 text-center justify-center'>
                <div className='flex w-1/2 h-full border border-solid border-gray-600'>
                  <ChartPie_1></ChartPie_1>
                </div>
                <div className=' flex w-1/2 h-full border border-solid border-gray-600'>
                  <ChartPie_2></ChartPie_2>
                </div>
              </div>
            </div>
          }
          {current === "tab3" &&
            <div className='flex flex-row w-full h-full gap-6 pt-10 pb-10'>
              <div className='flex flex-col w-full h-[410px]  gap-4'>
                <div className='flex w-full h-full border border-solid border-gray-600'>
                <button 
                  className='ml-[15px] mt-[15px] flex w-[257px] h-[42px] justify-center text-center items-center text-[15px] text-pa-purple border border-solid border-pa-purple rounded-md hover:bg-pa-purple hover:text-pa-white1'
                  onClick={ButtonDowload}
                > Dowload file</button>
                </div>
              </div>
            </div>
          }
        </div>
      </>
      : 
        <div className="flex w-full h-full text-center items-center justify-center">
          <Alert
            description="Click on the plus sign button at the top left to select a reservoir"
            type="error"
            style={{fontSize: 20}}
          />
        </div>
      }
    </div>
  )
}
