import { logDOM } from "@testing-library/react";
import React, { useState, useEffect, useContext } from "react";
import { Checkbox } from "antd";
import { Check } from "react-bootstrap-icons";
import { DataGasLiftContext } from "../../../context/DataGasLift/DataGasLiftContext";

export const TableRegression = ({
  selectedCheck,
  dataPVT,
  selected,
}) => {
  const [dataTablePVT, setDataTablePVT] = useState([]);
  const [CheckPVT, setCheckPVT] = useState([]);
  
  useEffect(()=>{
    setCheckPVT(selected)
  }, [selected])

  useEffect(() => {
    if (dataPVT) {
      setDataTablePVT(dataPVT);
    }else{
      setDataTablePVT({...dataTablePVT})
    }
  }, [dataPVT]);

  const handleChangeCheck = (item, index) => {
    const stateCheck = CheckPVT.map((item, idCheck) => ({
      ...item,
      check: idCheck === index,
    }));
    selectedCheck(stateCheck);
    setCheckPVT(stateCheck)
  };
  
  const decimalRound = (number, round) => {
    let valRound = number != undefined && number != null ? Number(number.toFixed(round)) : null;
    return valRound;
  };

  return (
    <div className="flex w-[1220px] h-full overflow-hidden rounded-lg border border-gray-200">
      <table className="min-w-full text-left text-sm font-light">
        {CheckPVT?.map((item, index) => {
          return (
            <>
              <thead className="font-medium">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-4 border border-gray-200 text-left"
                  >
                    {item.name}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-4 border border-gray-200 text-left"
                  >
                    {item.name === "Oil viscosity"
                      ? "Beggs et al., Beal"
                      : "Glaso"}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-4 border border-gray-200 text-left"
                  >
                    {item.name === "Oil viscosity"
                      ? "Glaso, Beggs et al., Vazquez et al"
                      : "Standing"}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-4 border border-gray-200 text-left"
                  >
                    {item.name === "Oil viscosity"
                      ? null
                      : "Petrosky and Farshad"}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-4 border border-gray-200 text-left "
                  >
                    Plot
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border border-gray-200 px-6 py-4 text-left">
                    Parameter 1
                  </td>
                  <td className="border border-gray-200 px-6 py-4 text-left">
                    {item.name === "Bubble point pressure" && !!dataTablePVT?.dataPVT
                      ? decimalRound(
                          dataTablePVT?.dataPVT?.parameters_pb?.glaso
                            ?.parameter_1_pb,
                          4
                        )
                      : item.name === "Solution Gas-Oil ratio" && !!dataTablePVT?.dataPVT
                      ? decimalRound(
                          dataTablePVT?.dataPVT?.parameters_rs?.glaso
                            ?.parameter_1,
                          4
                        )
                      : item.name === "Oil formation volume factor" && !!dataTablePVT?.dataPVT
                      ? decimalRound(
                          dataTablePVT?.dataPVT?.parameters_bo?.glaso
                            ?.parameter1_below_pb,
                          4
                        )
                      : item.name === "Oil viscosity" && !!dataTablePVT?.dataPVT
                      ? decimalRound(
                          dataTablePVT?.dataPVT?.parameters_viso?.beggs_beal
                            ?.parameter1_below_pb,
                          4
                        )
                      : ""}
                  </td>
                  <td className="border border-gray-200 px-6 py-4 text-left">
                    {item.name === "Bubble point pressure" && !!dataTablePVT?.dataPVT
                      ? decimalRound(
                          dataTablePVT?.dataPVT?.parameters_pb?.standing
                            ?.parameter_1_pb,
                          4
                        )
                      : item.name === "Solution Gas-Oil ratio" && !!dataTablePVT?.dataPVT
                      ? decimalRound(
                          dataTablePVT?.dataPVT?.parameters_rs?.standing
                            ?.parameter_1,
                          4
                        )
                      : item.name === "Oil formation volume factor" && !!dataTablePVT?.dataPVT
                      ? decimalRound(
                          dataTablePVT?.dataPVT?.parameters_bo?.standing
                            ?.parameter1_below_pb,
                          4
                        )
                      : item.name === "Oil viscosity" && !!dataTablePVT?.dataPVT
                      ? decimalRound(
                          dataTablePVT?.dataPVT?.parameters_viso?.glaso_beggs
                            ?.parameter1_below_pb,
                          4
                        )
                      : ""}
                  </td>
                  <td className="border border-gray-200 px-6 py-4 text-left">
                    {item.name === "Bubble point pressure" && !!dataTablePVT?.dataPVT
                      ? decimalRound(
                          dataTablePVT?.dataPVT?.parameters_pb?.petrosky
                            ?.parameter_1_pb,
                          4
                        )
                      : item.name === "Solution Gas-Oil ratio" && !!dataTablePVT?.dataPVT
                      ? decimalRound(
                          dataTablePVT?.dataPVT?.parameters_rs?.petrosky
                            ?.parameter_1,
                          4
                        )
                      : item.name === "Oil formation volume factor" && !!dataTablePVT?.dataPVT
                      ? decimalRound(
                          dataTablePVT?.dataPVT?.parameters_bo?.petrosky
                            ?.parameter1_below_pb,
                          4
                        )
                      : ""}
                  </td>
                  <td
                    className="border border-gray-200 px-6 py-4 text-center"
                    rowSpan={3}
                  >
                    <Checkbox
                      className={"custom-checkbox"}
                      checked={item.check}
                      onChange={() => handleChangeCheck(item, index)}
                      disabled={!Object.keys(dataTablePVT).length}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-200 px-6 py-4 text-left">
                    Parameter 2
                  </td>
                  <td className="border border-gray-200 px-6 py-4 text-left">
                    {item.name === "Bubble point pressure" && !!dataTablePVT?.dataPVT
                      ? decimalRound(
                          dataTablePVT?.dataPVT?.parameters_pb?.glaso
                            ?.parameter_2_pb,
                          4
                        )
                      : item.name === "Solution Gas-Oil ratio" && !!dataTablePVT?.dataPVT
                      ? decimalRound(
                          dataTablePVT?.dataPVT?.parameters_rs?.glaso
                            ?.parameter_2,
                          4
                        )
                      : item.name === "Oil formation volume factor" && !!dataTablePVT?.dataPVT
                      ? decimalRound(
                          dataTablePVT?.dataPVT?.parameters_bo?.glaso
                            ?.parameter2_below_pb,
                          4
                        )
                      : item.name === "Oil viscosity" && !!dataTablePVT?.dataPVT
                      ? decimalRound(
                          dataTablePVT?.dataPVT?.parameters_viso?.beggs_beal
                            ?.parameter2_below_pb,
                          4
                        )
                      : ""}
                  </td>
                  <td className="border border-gray-200 px-6 py-4 text-left">
                    {item.name === "Bubble point pressure" && !!dataTablePVT?.dataPVT
                      ? decimalRound(
                          dataTablePVT?.dataPVT?.parameters_pb?.standing
                            ?.parameter_2_pb,
                          4
                        )
                      : item.name === "Solution Gas-Oil ratio" && !!dataTablePVT?.dataPVT
                      ? decimalRound(
                          dataTablePVT?.dataPVT?.parameters_rs?.standing
                            ?.parameter_2,
                          4
                        )
                      : item.name === "Oil formation volume factor" && !!dataTablePVT?.dataPVT
                      ? decimalRound(
                          dataTablePVT?.dataPVT?.parameters_bo?.standing
                            ?.parameter2_below_pb,
                          4
                        )
                      : item.name === "Oil viscosity" && !!dataTablePVT?.dataPVT
                      ? decimalRound(
                          dataTablePVT?.dataPVT?.parameters_viso?.glaso_beggs
                            ?.parameter2_below_pb,
                          4
                        )
                      : ""}
                  </td>
                  <td className="border border-gray-200 px-6 py-4 text-left">
                    {item.name === "Bubble point pressure" && !!dataTablePVT?.dataPVT
                      ? decimalRound(
                          dataTablePVT?.dataPVT?.parameters_pb?.petrosky
                            ?.parameter_2_pb,
                          4
                        )
                      : item.name === "Solution Gas-Oil ratio" && !!dataTablePVT?.dataPVT
                      ? decimalRound(
                          dataTablePVT?.dataPVT?.parameters_rs?.petrosky
                            ?.parameter_2,
                          4
                        )
                      : item.name === "Oil formation volume factor" && !!dataTablePVT?.dataPVT
                      ? decimalRound(
                          dataTablePVT?.dataPVT?.parameters_bo?.petrosky
                            ?.parameter2_below_pb,
                          4
                        )
                      : ""}
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-200 px-6 py-4 text-left">
                    Standard Deviation
                  </td>
                  <td className="border border-gray-200 px-6 py-4 text-left">
                    {item.name === "Bubble point pressure" && !!dataTablePVT?.dataPBDeviation 
                      ? decimalRound(dataTablePVT?.dataPBDeviation?.standar_deviation_pb?.glaso, 10)
                      : item.name === "Solution Gas-Oil ratio" && !!dataTablePVT?.dataPBDeviation 
                      ? decimalRound(
                          dataTablePVT?.dataPVTDeviation?.standar_deviation_rs
                            ?.glaso,
                          4
                        )
                      : item.name === "Oil formation volume factor" && !!dataTablePVT?.dataPBDeviation 
                      ? decimalRound(
                          dataTablePVT?.dataPVTDeviation?.standar_deviation_bo
                            ?.glaso,
                          4
                        )
                      : item.name === "Oil viscosity" && !!dataTablePVT?.dataPBDeviation 
                      ? decimalRound(
                          dataTablePVT?.dataPVTDeviation?.standar_deviation_viso
                            ?.beggs_beal,
                          4
                        )
                      : ""}
                  </td>
                  <td className="border border-gray-200 px-6 py-4 text-left">
                    {item.name === "Bubble point pressure" && !!dataTablePVT?.dataPBDeviation 
                      ? decimalRound(
                          dataTablePVT?.dataPBDeviation?.standar_deviation_pb
                            ?.standing,
                          10
                        )
                      : item.name === "Solution Gas-Oil ratio" && !!dataTablePVT?.dataPBDeviation 
                      ? decimalRound(
                          dataTablePVT?.dataPVTDeviation?.standar_deviation_rs
                            ?.standing,
                          4
                        )
                      : item.name === "Oil formation volume factor" && !!dataTablePVT?.dataPBDeviation 
                      ? decimalRound(
                          dataTablePVT?.dataPVTDeviation?.standar_deviation_bo
                            ?.standing,
                          4
                        )
                      : item.name === "Oil viscosity" && !!dataTablePVT?.dataPBDeviation 
                      ? decimalRound(
                          dataTablePVT?.dataPVTDeviation?.standar_deviation_viso
                            ?.glaso_beggs,
                          4
                        )
                      : ""}
                  </td>
                  <td className="border border-gray-200 px-6 py-4 text-left">
                    {item.name === "Bubble point pressure" && !!dataTablePVT?.dataPBDeviation 
                      ? decimalRound(
                          dataTablePVT?.dataPBDeviation?.standar_deviation_pb?.petrosky,
                          10
                        )
                      : item.name === "Solution Gas-Oil ratio" && !!dataTablePVT?.dataPBDeviation 
                      ? decimalRound(
                          dataTablePVT?.dataPVTDeviation?.standar_deviation_rs?.petrosky,
                          4
                        )
                      : item.name === "Oil formation volume factor" && !!dataTablePVT?.dataPBDeviation 
                      ? decimalRound(
                        dataTablePVT?.dataPVTDeviation?.standar_deviation_bo?.petrosky,
                        3)
                      : ""}
                  </td>
                </tr>
              </tbody>
            </>
          );
        })}
      </table>
    </div>
  );
};
