import React, { useContext, useEffect, useState } from "react";
import { Select } from "antd";
import { useFilters } from "../hooks/useFilters";
import { Loading } from "./Loading";
import { AuthContext } from "../auth/context/AuthContext";

const initialFilter = {
  operator: "",
  lease: "",
  region: ""
};

export const FilterUser = ({ active }) => {
  const {
    User,
    userDataInfo,
    getUserDataInfo,
    leases,
    regions,
    operators,
    getDataUser,
    refetchGetDataUser,
    refetchGetDataLeaase,
    refetchGetDataRegion,
    loadGetDataUser,
    ErrorGetDataUser,
    operatorsId,
    setOperatorsId,
    refetchGetDataFilter,
    dataFilter,
    mutationCreateFilter,
    setRegions,
    setLeases

  } = useFilters();
  const { userData } = useContext(AuthContext);
  const { Option } = Select;

  const dataFilterUser = dataFilter != null ? dataFilter?.data : null;

  useEffect(() => {
    refetchGetDataFilter();
  }, []);

  useEffect(() => {
    if (dataFilterUser?.operator === "" && dataFilterUser?.lease === "" && dataFilterUser?.region === "") {
      getUserDataInfo(initialFilter);
    }
    else if (dataFilterUser?.operator != userDataInfo.operator && dataFilterUser?.lease != userDataInfo.lease && dataFilterUser?.region != userDataInfo.region) {
      getUserDataInfo({
        ...getUserDataInfo,
        operator: dataFilterUser.operator,
        lease: dataFilterUser.lease,
        region: dataFilterUser.region
      });
    }
    else if (dataFilterUser?.operator != "" && dataFilterUser?.lease != "" && dataFilterUser?.region != "") {
      getUserDataInfo({
        ...getUserDataInfo,
        operator: dataFilterUser?.operator,
        lease: dataFilterUser?.lease,
        region: dataFilterUser?.region
      });
    }
  }, [dataFilterUser])

  useEffect(() => {
    setTimeout(() => {
      refetchGetDataUser();
    }, 1000);
  }, [active]);

  const handleChangeSelect = (nameData, valueData) => {
    if (
      nameData === "operator" &&
      valueData !== "" &&
      valueData !== undefined
    ) {
      const OperatorSelected = operators.find((item) => item.idOperator === valueData);
      if (OperatorSelected.Operator !== userDataInfo.operator) {
        setRegions([])
        setLeases([])
        setOperatorsId(valueData);
        setTimeout(() => {
          refetchGetDataUser();
          refetchGetDataLeaase();
          refetchGetDataRegion();
        }, 1000);
        getUserDataInfo({
          [nameData]: OperatorSelected.Operator,
          lease: "",
          region: "",
        });
      }
    } else if (
      nameData === "lease" &&
      valueData !== "" &&
      valueData !== undefined
    ) {
      const LeaseSelected = leases.find((item) => item.idproyect === valueData);
      getUserDataInfo({
        ...userDataInfo,
        [nameData]: LeaseSelected.proyect,
      });
      // getUserDataInfo({
      //   ...userDataInfo,
      //   [nameData]: valueData
      // });
    } else if (
      nameData === "region" &&
      valueData !== "" &&
      valueData !== undefined
    ) {
      const RegionSelected = regions.find((item) => item.idplatform === valueData);
      getUserDataInfo({
        ...userDataInfo,
        [nameData]: RegionSelected.platform,
      });
      // getUserDataInfo({
      //   ...userDataInfo,
      //   [nameData]: valueData,
      // });
    }
  };

  if (loadGetDataUser) {
    return (
      <div className="absolute w-full h-full">
        <Loading></Loading>
      </div>
    );
  }
  if (ErrorGetDataUser) {
    return <div>Error</div>;
  }

  return (
    <div className="flex w-full h-full ">
      <div className="flex flex-col w-full h-full text-center justify-center items-center gap-4">
        <div className="w-full max-w-[592px]">
          <label
            htmlFor=""
            className="flex text-left justify-start items-start text-pa-purple text-lg mb-2 font-sans"
          >
            Operator
          </label>
          <Select
            className="w-full h-[47px] text-left"
            name="operator"
            value={
              (userDataInfo ? userDataInfo.operator : "") || "Selected option"
            }
            onChange={(value) => handleChangeSelect("operator", value)}
            defaultValue={""}
            defaultOpen={""}
          >
            {operators?.map((item, index) => (
              <Option key={index} value={item.idOperator}>
                {item.Operator}
              </Option>
            ))}
          </Select>
        </div>
        <div className="w-full max-w-[592px]">
          <label
            htmlFor=""
            className="flex text-left justify-start items-start text-pa-purple text-lg mb-2 font-sans"
          >
            Lease
          </label>
          <Select
            disabled={operatorsId === null ? true : false}
            className="w-full max-w-[592px] h-[47px] text-left"
            name="lease"
            value={
              (userDataInfo ? userDataInfo.lease : "") || "Selected option"
            }
            onChange={(value) => handleChangeSelect("lease", value)}
          >
            {leases?.map((item, index) => (
              <Option key={index} value={item.idproyect}>
                {item.proyect}
              </Option>
            ))}
          </Select>
        </div>
        <div className="w-full max-w-[592px]">
          <label
            htmlFor=""
            className="flex text-left justify-start items-start text-pa-purple text-lg mb-2 font-sans"
          >
            Region
          </label>
          <Select
            disabled={operatorsId === null ? true : false}
            className="w-full max-w-[592px] h-[47px] text-left"
            name="region"
            value={
              (userDataInfo ? userDataInfo.region : "") || "Selected option"
            }
            onChange={(value) => handleChangeSelect("region", value)}
          >
            {regions?.map((item, index) => (
              <Option key={index} value={item.idplatform}>
                {item.platform}
              </Option>
            ))}
          </Select>
        </div>
      </div>
    </div>
  );
};
