import React, { useEffect, useState, useContext } from "react";
import { useCrudUsers } from "../../hooks/useCrudUsers";
import { Navbar } from "../../components/layout/Navbar";
import { Sidebar } from "../../components/layout/Sidebar";
import { ReactComponent as IconEdit } from "../../assets/icon/edit.svg";
import { ReactComponent as IconDelete } from "../../assets/icon/x-circle.svg";
import { Modal } from "antd";
import { Pagination } from "antd";
import { UserContext } from "../../context/User/UserContext";
import ModalDelete from "../../components/User/ModalDelete";
import ModalActu from "../../components/User/ModalActu";
import { DashboardContext } from "../../context/Dashboard/DashboardContentx";

export const UsersExternal = () => {
  const { getDataPage } = useContext(DashboardContext);
  const [wellboreOptionState, setWellboreOptionState] = useState(false);
  const wellboreOption = () => {
    setWellboreOptionState(!wellboreOptionState);
  };
  // const { selectedUserCrud } = useContext(UserContext);
  const {
    dataUsersExternal,
    setEnabledUserExt,
    loadUserExternal,
    ErrorUsersExternal,
    usuarioSeleccionado,
    setUserSelect,
    tasks,
    users,
    search,
    setUsers,
    modalEditar,
    modalEliminar,
    error,
    currentPage,
    setCurrentPage,

    updateTasks,

    searchUserInternal,
    setTasks,
    setModalEditar,
    setModalEliminar,
    cerrarModalEditar,
    cerrarModalEliminar,
  } = useCrudUsers();

  useEffect(() => {
    setEnabledUserExt(true);
  }, []);
  
  useEffect(() => {
    getDataPage("External");
  }, []);

  const [usersPerPage, setUsersPerPage] = useState(6);

  const lastIndex = currentPage * usersPerPage;
  const firstIndex = lastIndex - usersPerPage;
  const totalUsers = users.length;
  useEffect(() => {
    setEnabledUserExt(true);
  }, []);

  useEffect(() => {
    setTasks(tasks);
    setUsers(tasks);
  }, [tasks])
  useEffect(() => {
    if (dataUsersExternal) {
      setTasks(dataUsersExternal);
      setUsers(dataUsersExternal);
      setUsersPerPage(6)
    }
  }, [dataUsersExternal]);
  // if (loadUserExternal) {
  //   return <div>loading</div>;
  // }

  // if (ErrorUsersExternal) {
  //   return <div>Error</div>;
  // }
  const totalItems = dataUsersExternal ? dataUsersExternal.length : 0;
  const pageSize = 6;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  //Paginador
  const pageNumbers = [];

  for (let index = 1; index <= Math.ceil(totalUsers / usersPerPage); index++) {
    pageNumbers.push(index);
  }

  const startIdx = (currentPage - 1) * pageSize;
  const endIdx = currentPage * pageSize;

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  };

  const seleccionarUsuario = (elemento, caso) => {
    // selectedUserCrud(elemento);
    setUserSelect(elemento);
    caso === "Editar" ? setModalEditar(true) : setModalEliminar(true);
  };

  return (
    <div className="flex flex-col w-full h-full bg-white ">
      <div className="flex">
        <Navbar wellboreOption={wellboreOption}></Navbar>
        <Sidebar className="flex absolute h-full "></Sidebar>
      </div>
      <div className="flex flex-col pl-[88px] w-full h-full">
        <div className="flex flex-row max-w[1500px] w-full h-full">
          <div className="flex flex-row w-full pl-[10px] m-[20px]">
            <div className="flex flex-col w-full text-start">
              <input
                placeholder="Search"
                type="text"
                className="w-[250px] h-[47px] rounded-[4px] border border-solid border-[#BCBCCC]"
                value={search}
                onChange={searchUserInternal}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-row max-w-[1500px] w-full h-full">
          <div className="flex flex-row h-full w-full ml-[20px] mr-[20px] ">
            <div className="flex flex-col w-full border border-solid text-left">
              <table>
                <thead className="h-[30px] w-full text-left bg-zinc-800 text-white">
                  <tr>
                    <th className="pl-[20px]">#</th>
                    <th>User</th>
                    <th>Operator</th>
                    <th>Project</th>
                    <th>Region</th>
                    <th>Edit</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody className="text-left">
                  {users?.slice(startIdx, endIdx).map((user) => (
                    <tr key={user.iduser}>
                      <td className="pl-[20px]">{user.iduser}</td>
                      <td>{user.user_name}</td>
                      <td>{user.clients}</td>
                      <td>
                        {user.proyects &&
                          user.proyects?.map((project, index) => (
                            <React.Fragment key={project}>
                              {project}
                              {index !== user.project?.length - 1 ? "," : "."}
                            </React.Fragment>
                          ))}
                      </td>
                      <td>
                        {user.platforms &&
                          user.platforms?.map((platform, index) => (
                            <React.Fragment key={platform}>
                              {platform}
                              {index !== user.platform?.length - 1 ? "," : "."}
                            </React.Fragment>
                          ))}
                      </td>
                      <td className="text-center">
                        <IconEdit className="w-6 h-[47px] fill-yellow-400 cursor-pointer" onClick={() =>
                          seleccionarUsuario(user, "Editar")
                        } />
                      </td>
                      <td className="text-center">
                        <IconDelete className="w-6 h-[47px] fill-red-600 cursor-pointer" onClick={() =>
                          seleccionarUsuario(user, "Eliminar")
                        } />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="flex flex-row max-w[1500px] w-full h-full">
          <div className="flex flex-row w-full ml-[20px] mr-[20px] mt-[10px]">
            <div className="flex flex-col w-full ml-[20px] text-start">
              <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={totalItems}
                onChange={handlePageChange}
                itemRender={itemRender}
              />
            </div>
          </div>
        </div>

        {error && <h1>Has been impossible to consult users</h1>}
        {modalEditar && (

          <ModalActu
            table={'users_externals'}
            visible={modalEditar}
            typeUser={"Simple User"}
            user={usuarioSeleccionado}
            onClose={cerrarModalEditar}
          />
        )}

        {modalEliminar && (
          <ModalDelete
            table={'users_externals'}
            visible={modalEliminar}
            tasks={tasks}
            user={usuarioSeleccionado}
            onClose={cerrarModalEliminar}
            updateTasks={updateTasks}
          />
        )}
      </div>
    </div>
  );
};
