import { message } from 'antd'
import {getListDCA, getListGasLift} from '../services/api/mapApi';
import { useMutation, useQuery } from 'react-query'
import { useContext, useState } from 'react'
import { AuthContext } from '../auth/context/AuthContext'
import { DashboardContext } from '../context/Dashboard/DashboardContentx'
import { DashboardDCAContext } from '../context/DasboardDCA/DashboardDCAContext';
import { DataGasLiftContext } from '../context/DataGasLift/DataGasLiftContext'

export const useTapMap = () => {
 const {User} = useContext(AuthContext)
 const {getUserDataInfo, userDataInfo} = useContext(DashboardContext);
 const {getDataFilterUserDCA, dataFilterUserDCA} = useContext(DashboardDCAContext)
 const {getDataFilterUserGaslift, dataFilterUserGasLift, } = useContext(DataGasLiftContext);
 const [viewLoading, setViewLoading] = useState(true)

 const { data: dataListDCA, isLoading: loadDataADDWellDCA, isError: ErrorDataADDWellDCA, refetch: refetchListDCA } = useQuery({
        queryKey: ['dataListDCA'],
        queryFn: () => getListDCA( userDataInfo.operator, userDataInfo.lease, userDataInfo.region, User.user_name ),
        retry: 2,
        enabled: false,
        onSuccess: (data) => {
          setViewLoading(false)
        },
        onError: (error) => {
          setViewLoading(false)
        }
 });

 const { data: listWellsGasLift, isLoading: loadListWellsGasLift, isError: ErrorListWellsGasLift, refetch: refetchListWellsGasLift } = useQuery({
    queryKey: ['get_list_wells_gas_lift'],
    queryFn: () => getListGasLift(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, User.user_name),
    retry: 2,
    enabled: false,
    onSuccess: (data) => {
    },
    onError: (error) => {
    }
  })

    return {
        userDataInfo,
        getUserDataInfo,
        dataListDCA,
        refetchListDCA,
        listWellsGasLift,
        refetchListWellsGasLift,
        getDataFilterUserDCA,
        dataFilterUserDCA,
        getDataFilterUserGaslift,
        dataFilterUserGasLift,
        viewLoading,
        setViewLoading,
        loadDataADDWellDCA,
        loadListWellsGasLift
    }
}