import React, { useEffect, useRef, useState } from 'react'
import { read, utils } from 'xlsx';
import { message } from 'antd'
import { ReactComponent as IconUpload } from '../../../assets/img/icons/upload.svg';
export const ImportFile = ({uploadfile}) => {
    const fileInputRef = useRef(null);
    const [file, setFile] = useState([]);
    const handleImport = (event) => {
        const files = event.target.files;
        if(files){
            const file = files[0];
            const reader = new FileReader();
            
            reader.onload = (e) => {
                const wb = read(e.target.result);
                const sheetName = wb.SheetNames[0];
                const sheets = wb.Sheets[sheetName];
                if(sheets){
                    const jsonData = utils.sheet_to_json(sheets);

                    const noID = jsonData.map(item => {
                      const { id, ...excludeID } = item;
                      return excludeID;
                  });
                    setFile(noID);
                    uploadfile(noID);
                    message.success("The file data has been loaded successfully");
                }
              };
            reader.readAsArrayBuffer(file);
            event.target.value = '';
        }
    };
    const handleImageClick = () => {
        fileInputRef.current.click();
      };

  return (
    <div>
        <input
        ref={fileInputRef}
        type="file"
        name='file'
        id="inputGroupFile"
        onChange={handleImport}
        style={{ display: 'none' }}
      />
      {/* Este es el icono de carga */}
      <label htmlFor="fileInput">
        <IconUpload size={32} className="flex w-4 h-4 fill-neutral-500 hover:fill-pa-purple bg-opacity-50" onClick={handleImageClick}/>
      </label>
    </div>
  )
}

