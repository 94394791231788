import { serviceEndPoints } from "../../config/config";

const DB_URL_PLOTTING = serviceEndPoints.PLOTTING.URL_PLOTTING;

export const getJsonPlottingIOT = async (operator, lease, region, platform, device ) => {
    try{
        const response = await fetch(`${DB_URL_PLOTTING}/iotwelldataplotting/${operator}/${lease}/${region}/${platform}/${device}/200`, {
            method: "GET",
            headers: {
                "Authorization": "Token SMFGAHDJVqUr2xWifzjpLWC66qdNCPjFGonBROOKs",
            }
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
    } catch (error) {
        throw new Error("Problem getting the information")
    }
};

export const getListPlatformsPlotting = async (operator, lease, region) => {
    try{
        const response = await fetch(`${DB_URL_PLOTTING}/platformslistplotting/${operator}/${lease}/${region}`, {
            method: "GET",
            headers: {
                "Authorization": "Token SMFGAHDJVqUr2xWifzjpLWC66qdNCPjFGonBROOKs"
            }
        });
        return response.json();
    } catch (error) {
        throw new Error("Problem getting the information")
    }
};

export const getListDevicesPlotting = async (operator, lease, region, platform) => {
    try{
        const response = await fetch(`${DB_URL_PLOTTING}/platformsensorsplotting/${operator}/${lease}/${region}/${platform}`, {
            method: "GET",
            headers: {
                "Authorization": "Token SMFGAHDJVqUr2xWifzjpLWC66qdNCPjFGonBROOKs"
            }
        });
        return response.json();
    } catch (error) {
        throw new Error("Problem getting the information")
    }
};