import React, { useState, useEffect, useRef, useContext } from 'react';
import {
  BarChart,
  Bar,
  Brush,
  ReferenceLine,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
  ComposedChart,
  Line,
} from 'recharts';
import { useTabIOT } from '../../../hooks/useTabIOT';
import { DashboardContext } from '../../../context/Dashboard/DashboardContentx';

const renderCustomizedLabelGas = ({ x, y, width, value }) => {
  const rectWidth = 30;
  return (
    <g transform={`translate(${x + (width - rectWidth) / 2},${y - 25})`} textAnchor="middle">
      <rect width={rectWidth} height={15} fill="#fff" stroke="#ce0f41" strokeWidth={1} />
      <text x={rectWidth / 2} y={9} fill="#000000" dominantBaseline="middle" fontSize={10}>
        {value}
      </text>
    </g>
  );
};

const renderCustomizedLabelOil = ({ x, y, width, value }) => {
  const rectWidth = 30;
  return (
    <g transform={`translate(${x + (width - rectWidth) / 2},${y - 25})`} textAnchor="middle">
      <rect width={rectWidth} height={15} fill="#fff" stroke="#2bca41" strokeWidth={1} />
      <text x={rectWidth / 2} y={9} fill="#000000" dominantBaseline="middle" fontSize={10}>
        {value}
      </text>
    </g>
  );
};

function renderTooltip ({ active, payload, label}) {

  if (active && payload && payload.length) {
    const oilData = payload[0].payload;
    const gasData = payload[0].payload;
    const waterData = payload[0].payload;
    const rateOil = oilData['Corrected Flow RateO'];
    const rateGas = gasData['Corrected Flow RateG'];
    const ratewater = waterData['Corrected Flow RateW'];

    const roundToOneDecimals = (value, range) => {
      return parseFloat(value).toFixed(range);
    };

    return (
      <div style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '10px' }}>
        <p style={{ marginBottom: '5px', textAlign: 'letf' }}>{`Date: ${label}`}</p>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ flex: 1 }}>
            {gasData && (
              <p style={{ color: '#ce0f41' }}>
                {`GAS RATE: ${roundToOneDecimals(rateGas, 1)} MSCF/D`}
              </p>
            )}
            {oilData && (
              <p style={{ color: '#2bca41' }}>
                {`OIL RATE: ${roundToOneDecimals(rateOil, 1)} STB/D`}
              </p>
            )}
            {oilData && (
              <p style={{ color: '#194bff'}}>
                {`Water RATE: ${roundToOneDecimals(ratewater, 1)} STB/D`}
              </p>
            )}
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export const ChartHistory = (props) => {

  const {dataIOTDCA, typeGraph, dataHistory, enableModal, graphType} = props;
  const { deviceRealTime } = useContext(DashboardContext);
  const chartRef = useRef();
  let [valueOffset, setValueOffset] = useState();

  const [dataHistoryRT, setDataHistoryRT] = useState([]);
  const dataOil = dataIOTDCA.filter(item => 
    item['LSD'] === deviceRealTime.device_dca.name &&
    item['Corrected Flow Rate'] !== -999.25 
  );
  const dataGas = dataIOTDCA.filter(item => 
    item['LSD'] === deviceRealTime.device_dca.name &&
    item['Corrected Flow Rate'] !== -999.25 
  );
  const dataWater = dataIOTDCA.filter(item => 
    item['LSD'] === deviceRealTime.device_dca.name &&
    item['Corrected Flow Rate'] !== -999.25 
  );

  //ESTA PROCESO SE DEBE OPTIMIZAR PARA QUE NO SE SETEE TODO EL TIEMPO

  useEffect(() => {
    // Combinar la información de dataOil y dataGas en dataHistory
    const combinedData = [];
    const oilLength = dataOil?.length;
    const gasLength = dataGas?.length;
    const waterLength = dataWater?.length;
    const maxLength = Math.max(oilLength, gasLength, waterLength);

    for (let i = 0; i < maxLength; i++) {
      const newData = {};

      if (i < oilLength) {
        for (const key in dataOil[i]) {
          newData[key] = dataOil[i][key];
        }
      }

      if (i < gasLength) {
        for (const key in dataGas[i]) {
          newData[key] = dataGas[i][key];
        }
      }

      if (i < gasLength) {
        for (const key in dataWater[i]) {
          newData[key] = dataWater[i][key];
        }
      }

      combinedData.push(newData);
    }

    if(combinedData?.length > 0){
      setTimeout(() => {
        setDataHistoryRT(combinedData);
      }, 1000);
    }
    //Data de renderizado anterior dataGas, dataOil
  }, [dataIOTDCA,deviceRealTime]);

  useEffect(() => {
    const handleResize = () => {
      if (chartRef.current) {
        const chartNode = chartRef.current;
        const offsetTop = chartNode.offsetTop;
        // Obtener el tamaño de la pantalla
        const screenHeight = window.innerHeight;

        setValueOffset(enableModal ? offsetTop + 450  : screenHeight - (offsetTop*0.97));

      }
    };

    // Agregar el listener de cambio de tamaño de ventana
    window.addEventListener('resize', handleResize);

    // Llamar a handleResize al montar el componente para obtener el valor inicial
    handleResize();

    // Limpiar el listener al desmontar el componente
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Cálculo del máximo valor de "Date (MM/DD/YYYY)" y conversión a meses
  const maxTimeMonths = dataHistoryRT.reduce((max, item) => {
    return Math.max(max, item['Date (MM/DD/YYYY)'] / 30 || 0);
  }, -Infinity);

  // Convertir "Date (MM/DD/YYYY)" a meses y redondear a 2 decimales
  const dataWithMonths = dataHistoryRT.map((item) => {
    return {
      ...item,
      "date": String(item['Date (MM/DD/YYYY)'])?.split(" ")[0], // Redondear a 2 decimales
      'Corrected Flow RateO': parseFloat(item['Liquid Flow Rate']),
      'Corrected Flow RateG': parseFloat(item['Corrected Flow Rate']),
      'Corrected Flow RateW': parseFloat(item['Liquid Flow Rate']),
    };
  });
  
  let dataFinalHistory = [];

  if(dataHistory != [] && dataHistory != undefined && dataHistory != "undefined" && graphType === false){
  const dataHistoryMod = dataHistory?.map((item) => ({
      ...item,
      "Time (months)": String(item["date"])?.split(" ")[0],
      "Corrected Flow RateO": item["oil_rate"],
      "Corrected Flow RateG": item["gas_rate"],
      "Corrected Flow RateW": item["water_rate"],
  })).filter(
    (item) =>
      item["Corrected Flow RateO"] !== -999.25 &&
      item["Corrected Flow RateG"] !== -999.25 &&
      item["Corrected Flow RateW"] !== -999.25);
  
  dataFinalHistory = dataHistoryMod;
  
  }else if(dataWithMonths && graphType === true){
  dataFinalHistory = dataWithMonths;
  }
  
  // Redondear los máximos a valores enteros más cercanosobj
  const maxY1Domain = Math.max(...dataFinalHistory?.map(item => item['Corrected Flow RateO']));
   const maxY2Domain = Math.max(...dataFinalHistory?.map(item => item['Corrected Flow RateG']));

  // Establecer el dominio de los ejes Y
  const y1Domain = Math.ceil(Math.round(maxY1Domain * 10) / 1000) *10;
   const y2Domain = Math.ceil(Math.round(maxY2Domain * 10) / 1000) *10;

  const CustomDot = ({ cx, cy, payload, index, color }) => {
    // Solo muestra el punto si el índice es un múltiplo de 1
    if ((index + 1) % 1 === 0) {
      return (
        <circle cx={cx} cy={cy} r={2} stroke={color} fill={color} />
      );
    }
    return null; // No muestra el punto
  };

  return (
    <div className="w-full h-full" ref={chartRef}>
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          width={500}
          height={200}
          data={dataFinalHistory} // dataFinalHistory
          margin={{
            top: 25,
            right: 30,
            left: 20,
            bottom: 45,
          }}
        >
          <Brush dataKey="Date (MM/DD/YYYY)" height={10} stroke="#8884d8" y={valueOffset}/>
          <XAxis
            dataKey="date"
            label={{
              value: 'Date', position: 'bottom',
              fill: 'black'
            }}
            tickCount={10} // Número de ticks en el eje X
            tick={{ fill: 'black' }}
            fill='black'
          />
          <YAxis
            yAxisId={1}
            domain={y1Domain}
            label={{
              value: 'Liquid Rate (STB/D)', 
              angle: -90, 
              position: 'insideLeft', 
              style: { textAnchor: "middle" },
              fill: 'black'
            }}
            width={110}
            tickFormatter={(value) => Math.round(value)}
            padding={{bottom:10}}
            tick={{ fill: 'black' }}
            fill='black'
          />
          <YAxis
            yAxisId={2}
            orientation="right"
            domain={y2Domain}
            label={{
              value: 'Gas rate (MSCF/D)',
              angle: -90, 
              position: 'insideRight', 
              style: { textAnchor: "middle" },
              fill: 'black'
            }}
            tickFormatter={(value) => Math.round(value)}
            padding={{bottom:10}}
            tick={{ fill: 'black' }}
            fill='black'
            width={110}
          />
          <Tooltip content={renderTooltip} />
          <ReferenceLine y={0} stroke="#000" yAxisId={1} />
          {typeGraph === 'Bar' ? (
            <>
              <Bar dataKey="Corrected Flow RateG" name="GAS" fill="#ce0f41" yAxisId={2} />
              <Bar dataKey="Corrected Flow RateW" name="WATER" fill="#194bff" yAxisId={1} />
              <Bar dataKey="Corrected Flow RateO" name="OIL" fill="#2bca41" yAxisId={1} />
            </>
          ) : (
            <>
              
              <Line dataKey="Corrected Flow RateW" name="WATER" fill="#194bff" stroke='#194bff' yAxisId={1} />
              <Line dataKey="Corrected Flow RateO" name="OIL" fill="#2bca41" stroke='#2bca41' yAxisId={1} />
              <Line dataKey="Corrected Flow RateG" name="GAS" fill="#ce0f41" stroke='#ce0f41' yAxisId={2} />

            </>)}

        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};