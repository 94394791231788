import React, { useEffect, useState } from 'react'
  
const initialDataPlotting = {
  selected_platform:"",
  selected_device:[],
  selected_parameter:[],
  optionsParameters:[],
  dataConfigGraph: []
}
export const useDashboardPlotting = () => {
    const [dataPlotting, setDataPlotting] = useState(initialDataPlotting);
    const [optionsParmsPlotting, setOptionsParmsPlotting] = useState([]);
    useEffect(()=>{
        const getDomainPlotting = JSON.parse(sessionStorage.getItem('dataPlotting'));
        const getOptionsParmsPlotting = JSON.parse(sessionStorage.getItem('optionsParmsPlotting'));
  
        if (getDomainPlotting) {
          setDataPlotting(getDomainPlotting);
        } 
  
        if (getOptionsParmsPlotting) {
          setOptionsParmsPlotting(getOptionsParmsPlotting);
        } 
    },[])
  
    const getDataPlotting = (options) => {
      setDataPlotting(options);
        sessionStorage.setItem('dataPlotting', JSON.stringify(options));
    }
    const getOptionsParmsPlotting = (options) => {
      setOptionsParmsPlotting(options);
      sessionStorage.setItem('optionsParmsPlotting', JSON.stringify(options));
  }
  return {
    dataPlotting,
    getDataPlotting,
    optionsParmsPlotting,
    getOptionsParmsPlotting
  }
}
