import React from 'react'
import { ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Cell } from 'recharts';

const specificValue= {
  oil_rate:{
    value: "oil_rate", 
    name: "Oil rate", 
    unit: "(STB/D)"
  },
  gor:{
    value: "gor", 
    name: "GOR", 
    unit: "(SCF/STB)"
  },
  water_cut:{
    value: "water_cut", 
    name: "Water cut", 
    unit: "(%)"
  }
}

export const ChartMultipleScatter = (props) => {
  const { dataGraph, type } = props
  
  const axisX = type === "oil_vs_water_cut" || type === "oil_rate_vs_gor" ? specificValue.oil_rate : specificValue.gor;
  const axisY = type === "oil_vs_water_cut" || type === "gor_vs_water_cut" ? specificValue.water_cut : specificValue.gor;

//   const COLORS = ['#0088FE', '#00C49F', '#FFBB28'];

  function customTooltip({ active, payload, option }) {
    if (active && payload && payload.length) {
      const valuesData = payload[0]?.payload;

      return (
        <div
          className="custom-tooltip"
          style={{ backgroundColor: "white", padding: "6px", textAlign: "left" }}
        >
          <p>
            {axisX.name+`: `}
            <span>{(valuesData[axisX.value])?.toFixed(2)} {axisX.unit}</span>
          </p>
          <p>
            {axisY.name+`: `}
            <span>{(valuesData[axisY.value])?.toFixed(2)} {axisY.unit}</span>
          </p>
        </div>
      );
    }
    return null;
  }

  return (
    <div className='flex w-full h-full'>
        <ResponsiveContainer width="100%" height="100%">
        <ScatterChart
          margin={{
            top: 20,
            right: 25,
            bottom: 20,
          }}
        >
          <CartesianGrid />
          <XAxis 
            type="number" 
            dataKey={axisX.value}
            name="Oil rate"
            label={{
              value: type === "oil_vs_water_cut" || type === "oil_rate_vs_gor" ? "Oil Rate (STB/D)" : "GOR (SCF/STB)",
              position: "insideBottom",
              offset: -15,
            }}
        />
          <YAxis 
            type="number" 
            dataKey={axisY.value} 
            name="Water cut"
            label={{
              value: type === "oil_vs_water_cut" || type === "gor_vs_water_cut" ? "Water Cut (%)" : "GOR (SCF/STB)",
              style: { textAnchor: "middle" },
              angle: -90,
              position: "left",
              offset: 0,
              dx: 12
            }}
          />
          <Tooltip content={customTooltip}/>
          <Scatter data={dataGraph} fill="#8884d8">
            {/* {dataGraph.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))} */}
          </Scatter>
        </ScatterChart>
      </ResponsiveContainer>
    </div>
  )
}

