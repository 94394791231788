import { Checkbox } from "antd";
import React, { useState, useEffect, useContext } from "react";
import { VALUE_SENSIBILTY } from "../../elements/types/type_value_sensitives";
import { DashboardDCAContext } from "../../../context/DasboardDCA/DashboardDCAContext";
import { Key } from "react-bootstrap-icons";

export const dataTable = [
  {
    sensitivity: "1",
    period: "1",
    initialRate: "0.3578",
    bParameter: "0.3578",
    diParameter: "0.3578",
  },
  {
    sensitivity: "1",
    period: "2",
    initialRate: "0.3578",
    bParameter: "0.3578",
    diParameter: "0.3578",
  },
  {
    sensitivity: "1",
    period: "3",
    initialRate: "0.3578",
    bParameter: "0.3578",
    diParameter: "0.3578",
  },
  {
    sensitivity: "1",
    period: "4",
    initialRate: "0.3578",
    bParameter: "0.3578",
    diParameter: "0.3578",
  },
  {
    sensitivity: "2",
    period: "1",
    initialRate: "0.3578",
    bParameter: "0.3578",
    diParameter: "0.3578",
  },
  {
    sensitivity: "2",
    period: "2",
    initialRate: "0.3578",
    bParameter: "0.3578",
    diParameter: "0.3578",
  },
  {
    sensitivity: "2",
    period: "3",
    initialRate: "0.3578",
    bParameter: "0.3578",
    diParameter: "0.3578",
  },
  {
    sensitivity: "2",
    period: "4",
    initialRate: "0.3578",
    bParameter: "0.3578",
    diParameter: "0.3578",
  }
];
const formatNumber = (value, decimalPlaces) => {
  if (typeof value !== "number") {
    return value;
  }
  if (Math.floor(value) !== value) {
    return value.toFixed(decimalPlaces);
  }

  return value;
}

const formatValueSensitivity = (sensibilityValue) => {
  const stringValue = String(sensibilityValue);
  const formattedValue = VALUE_SENSIBILTY[stringValue] || "N/A";
  return isNaN(parseInt(formattedValue)) ? "N/A" : parseInt(formattedValue);
};

export const ModalTable = ({ dataTable, onCheckChange,onCheckChangeLast }) => {
  
  const { checkedValuesFirstCol, getCheckedValuesFirstCol, checkedValuesLastCol, getCheckedValuesLastCol } = useContext(DashboardDCAContext);
  const data = dataTable ? dataTable : [];
  
  useEffect(() => {
    handleChangeFirstCol(checkedValuesFirstCol);
  }, []);

  const handleChangeFirstCol = (sensitivity, period, value) => {
    const key = `${sensitivity}_${period}`;
    const updatedValues = { ...checkedValuesFirstCol, [key]: value };
    getCheckedValuesFirstCol(updatedValues);

    onCheckChange(Object.entries(updatedValues)
      .filter(([_, value]) => value)
      .map(([key]) => key)
      .map(checkedKey => {
        const [sensitivity, period] = checkedKey.split('_');
        return { sensitivity, period };
      })
    );
  };

  const handleChangeLastCol = (sensitivity, period, value) => {
    const key = `${sensitivity}_${period}`;
    const updatedValues = {[key]: value };
    getCheckedValuesLastCol(updatedValues);
    onCheckChangeLast(key)
  };

  const selectedCountFirstCol = Object.values(checkedValuesFirstCol).filter(v => v).length;
  const selectedCountLastCol = Object.values(checkedValuesLastCol).filter(v => v).length;

  return (
    <table className="min-w-full text-left text-sm font-light ">
      <thead className="font-medium">
        <tr>
          <th scope="col" className="px-6 py-4 border border-gray-400 text-center"> Select data to graph (Max 4 graphs) </th>
          <th scope="col" className="px-6 py-4 border border-gray-400 text-center">
            Sensitivity
          </th>
          <th scope="col" className="px-6 py-4 border border-gray-400 text-center">
            Period
          </th>
          <th scope="col" className="px-6 py-4 border border-gray-400 text-center">
            Initial Rate (STB/D)
          </th>
          <th scope="col" className="px-6 py-4 border border-gray-400 text-center">
            b parameter
          </th>
          <th scope="col" className="px-6 py-4 border border-gray-400 text-center ">
            Di parameter
          </th>
          <th scope="col" className="px-6 py-4 border border-gray-400 text-center">Data select to Forecasting</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => {
          const key = `${item.sensitivity}_${item.period}`;
          return (
            <tr key={index}>
              <td className="border border-gray-400 px-6 py-4 text-center">
                <Checkbox
                  className={"custom-checkbox"}
                  onChange={(e) => handleChangeFirstCol(item.sensitivity, item.period, e.target.checked)}
                  checked={checkedValuesFirstCol[key] || false}
                  disabled={!checkedValuesFirstCol[key] && selectedCountFirstCol >= 4}
                />
              </td>
              <td className="border border-gray-400 px-6 py-4 text-center">{formatValueSensitivity(item.sensitivity)}</td>
              <td className="border border-gray-400 px-6 py-4 text-center">{item.period}</td>
              <td className="border border-gray-400 px-6 py-4 text-center">{formatNumber(item.initial_rate, 2)}</td>
              <td className="border border-gray-400 px-6 py-4 text-center">{formatNumber(item.b_parameter, 5)}</td>
              <td className="border border-gray-400 px-6 py-4 text-center">{formatNumber(item.di_parameter, 5)}</td>
              <td className="border border-gray-400 px-6 py-4 text-center">
                <Checkbox
                  className={"custom-checkbox"} 
                  onChange={(e) => handleChangeLastCol(item.sensitivity, item.period, e.target.checked)}
                  checked={checkedValuesLastCol[key] || false}
                  disabled={!checkedValuesLastCol[key] && selectedCountLastCol >= 1}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default ModalTable;