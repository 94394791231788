import { serviceEndPoints } from "../../config/config";

const BASE_URL_TUNING = serviceEndPoints.TUNING.BASE_URL_TUNING;
const BASE_URL_TUNING_VLP = serviceEndPoints.TUNING.BASE_URL_TUNING_VLP+"/VLP";


export const getEspecificTuning = async (operator, lease, region, county, field, wellid, username) => {
    try {
        const reponse = await fetch(`${BASE_URL_TUNING}/parameters/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${username}/parameterstuning`, {
            method: "GET",
            headers: {
                "Authorization": "Token TroT6HpzeXw78LnkgPrPO772lSBAYELfpFWzpC1S3"
            },
        });
        return reponse.json();
    }catch (error){
        throw new Error("Problem in data tuning");
    }
}

export const getEspecificWell = async (operator, lease, region, county, field, wellid, username) => {
    try {
        const reponse = await fetch(`${BASE_URL_TUNING}/parameters/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${username}/parametersgaslift`, {
            method: "GET",
            headers: {
                "Authorization": "Token TroT6HpzeXw78LnkgPrPO772lSBAYELfpFWzpC1S3"
            },
        });
        return reponse.json();
    }catch (error){
        throw new Error("Problem in data tuning");
    }
}

export const postTuningAdd = async (dataJson) => {
    try {
        
        const operator = dataJson.operator;
        const lease = dataJson.lease;
        const region = dataJson.region;
        const county = dataJson.county;
        const field = dataJson.field;
        const wellid = dataJson.wellid;
        const username = dataJson.username;
        const data = dataJson.data;
        const reponse = await fetch(`${BASE_URL_TUNING}/parameters/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${username}/parameterstuning`, {
        // const reponse = await fetch(`${URL_test}/upload_json_VLP/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${username}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Token TroT6HpzeXw78LnkgPrPO772lSBAYELfpFWzpC1S3"
            },
            body: JSON.stringify(data)
        });
        return reponse.json();
    }catch (error){
        throw new Error("Problem save data tuning");
    }
}
export const putTuningUpdate = async (dataJson) => {
    try {
        
        const operator = dataJson.operator;
        const lease = dataJson.lease;
        const region = dataJson.region;
        const county = dataJson.county;
        const field = dataJson.field;
        const wellid = dataJson.wellid;
        const username = dataJson.username;
        const data = dataJson.data;

        const reponse = await fetch(`${BASE_URL_TUNING}/parameters/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${username}/parameterstuning`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Token TroT6HpzeXw78LnkgPrPO772lSBAYELfpFWzpC1S3"
            },
            body: JSON.stringify(data)
        });
        return reponse.json();
    }catch (error){
        throw new Error("Problem save data tuning");
    }
}

export const deleteTuning = async (dataJson) => {
    try {
        
        const operator = dataJson.operator;
        const lease = dataJson.lease;
        const region = dataJson.region;
        const county = dataJson.county;
        const field = dataJson.field;
        const wellid = dataJson.wellid;
        const username = dataJson.username;

        const reponse = await fetch(`${BASE_URL_TUNING}/parameters/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${username}/parameterstuning`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Token TroT6HpzeXw78LnkgPrPO772lSBAYELfpFWzpC1S3"
            }
        });
        return reponse.json();
    }catch (error){
        throw new Error("Problem save data tuning");
    }
}

export const emptyTuningUpdate = async (dataJson) => {
    try {
        
        const operator = dataJson.operator;
        const lease = dataJson.lease;
        const region = dataJson.region;
        const county = dataJson.county;
        const field = dataJson.field;
        const wellid = dataJson.wellid;
        const username = dataJson.username;
        const data = dataJson.data;

        const reponse = await fetch(`${BASE_URL_TUNING}/parameters/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${username}/parameterstuning`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Token TroT6HpzeXw78LnkgPrPO772lSBAYELfpFWzpC1S3"
            },
            body: JSON.stringify(data)
        });
        return reponse.json();
    }catch (error){
        throw new Error("Problem save data tuning");
    }
}

export const getListTuning = async (operator, lease, region, username) => {
    try {
        const reponse = await fetch(`${BASE_URL_TUNING}/parameterslist/${operator}/${lease}/${region}/${username}/parameterstuning`, {
            method: "GET",
            headers: {
                "Authorization": "Token TroT6HpzeXw78LnkgPrPO772lSBAYELfpFWzpC1S3"
            },
        });
        return reponse.json();
    }catch (error){
        throw new Error("Problem in data tuning");
    }
}

export const postTuningCalculateVLP = async (dataJson) => {
    try {
        const operator = dataJson.operator;
        const lease = dataJson.lease;
        const region = dataJson.region;
        const county = dataJson.county;
        const field = dataJson.field;
        const wellid = dataJson.wellid;
        const username = dataJson.username;
        const data = dataJson.data;
        const reponse = await fetch(`${BASE_URL_TUNING_VLP}/upload_json_VLP/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${username}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm"
            },
            body: JSON.stringify(data)
        });
        return reponse.json();
    }catch (error){
        throw new Error("Problem save data tuning");
    }
}

export const putTuningCalculateVLP = async (dataJson) => {
    try {
        const operator = dataJson.operator;
        const lease = dataJson.lease;
        const region = dataJson.region;
        const county = dataJson.county;
        const field = dataJson.field;
        const wellid = dataJson.wellid;
        const username = dataJson.username;
        const data = dataJson.data;
        const reponse = await fetch(`${BASE_URL_TUNING_VLP}/update_json_VLP/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${username}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm"
            },
            body: JSON.stringify(data)
        });
        return reponse.json();
    }catch (error){
        throw new Error("Problem save data tuning");
    }
}

export const getTuningCalculateVLP = async (operator, lease, region,county,field,wellid,username) => {
    try {
        const reponse = await fetch(`${BASE_URL_TUNING_VLP}/calculate_vlp/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${username}`, {
            method: "GET",
            headers: {
                "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm"
            },
        });
        return reponse.json();
    }catch (error){
        throw new Error("Problem in data tuning");
    }
}

export const getUnTunedCalculateVLP = async (operator, lease, region,county,field,wellid,username) => {
    try {
        const reponse = await fetch(`${BASE_URL_TUNING_VLP}/calculate_vlp_untuned/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${username}`, {
            method: "GET",
            headers: {
                "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm"
            },
        });
        return reponse.json();
    }catch (error){
        throw new Error("Problem in data tuning");
    }
}

export const getPressDrowDown = async (operator, lease, region,county,field,wellid,username) => {
    try {
        const reponse = await fetch(`${BASE_URL_TUNING_VLP}/press_drow_down/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${username}`, {
            method: "GET",
            headers: {
                "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm"
            },
        });
        return reponse.json();
    }catch (error){
        throw new Error("Problem in data tuning");
    }
}


