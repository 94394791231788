import React, { useEffect, useState } from 'react';

export const TablePVTCalculate = ({dataTable}) => {

  const decimalRound = (number, round) => {
    let valRound = number != undefined && number != null ? Number(number.toFixed(round)) : null;
    return valRound;
  };

 return (
    <div className='flex w-[1220px] h-full overflow-hidden rounded-lg border border-gray-200'>
      <table className="min-w-full text-left text-sm font-light">
        <thead className="font-medium">
          <tr>
              <th scope="col" className="px-6 py-4 border border-gray-200 text-left">
                Pressure (psia)
              </th>
              <th scope="col" className="px-6 py-4 border border-gray-200 text-left">
                Solution GOR (SCF/STB)
              </th>
              <th scope="col" className="px-6 py-4 border border-gray-200 text-left">
                Oil FVF (RB/STB)
              </th>
              <th scope="col" className="px-6 py-4 border border-gray-200 text-left">
                Oil viscosity (cp)
              </th>
              <th scope="col" className="px-6 py-4 border border-gray-200 text-left ">
                Oil Compressibility (1/psi)
              </th>
          </tr>
        </thead>
        <tbody>
          {dataTable && dataTable[0]?.press?.map((item, index) => {
              return (
                <tr key={index}>
                  <td className="border border-gray-200 px-6 py-4 text-left">{decimalRound(dataTable[0]?.press[index], 0)}</td>
                  <td className="border border-gray-200 px-6 py-4 text-left">{decimalRound(dataTable[0]?.rs[index], 1)}</td>
                  <td className="border border-gray-200 px-6 py-4 text-left">{decimalRound(dataTable[0]?.bo[index], 3)}</td>
                  <td className="border border-gray-200 px-6 py-4 text-left">{decimalRound(dataTable[0]?.viso[index], 3)}</td>
                  <td className="border border-gray-200 px-6 py-4 text-left">{decimalRound(dataTable[0]?.co[index], 7)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  )
}

export const TablePVTSep = ({ sepPress, sepCalculate }) => {
  const [dataAjustment, setDataAjustment] = useState();
  useEffect(() => {
    if (sepCalculate?.rs && sepCalculate?.bo && sepPress) {
      setDataAjustment(sepCalculate);
    }
  }, [sepCalculate]);

  const decimalRound = (number, round) => {
    let valRound = number != undefined && number != null ? Number(number.toFixed(round)) : null;
    return valRound;
  };

  return (
    <div className='flex w-[1220px] h-full overflow-hidden rounded-lg border border-gray-200 mb-[1rem]'>
      <table className="min-w-full text-left text-sm font-light">
        <thead className="font-medium">
          <tr>
            <th scope="col" className="px-6 py-4 border border-gray-200 text-left">
              Pressure (psia)
            </th>
            <th scope="col" className="px-6 py-4 border border-gray-200 text-left">
              Solution GOR (SCF/STB)
            </th>
            <th scope="col" className="px-6 py-4 border border-gray-200 text-left">
              Oil FVF (RB/STB)
            </th>
          </tr>
        </thead>
        <tbody>
          {sepPress && sepPress?.map((pressure, index) => {
            return (
              <tr key={index}>
                <td className="border border-gray-200 px-6 py-4 text-left">{decimalRound(pressure, 0)}</td>
                <td className="border border-gray-200 px-6 py-4 text-left">{decimalRound(dataAjustment?.rs[index], 1)}</td>
                <td className="border border-gray-200 px-6 py-4 text-left">{decimalRound(dataAjustment?.bo[index], 3)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

