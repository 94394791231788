import React, { useEffect, useState } from 'react'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
} from 'recharts';

function CustomTooltip({ active, payload, stateContent }) {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    const titleColor = "#000000";

    const decimalRound = (value, decimalPlaces) => {
      return value !== null && value !== undefined ? value.toFixed(decimalPlaces) : 'N/A';
    };

    return (
      <div className="custom-tooltip" style={{ backgroundColor: 'white', padding: '5px' }}>
        <p style={{ color: titleColor }}>{`Pressure: ` + data.pressure + ` (psia)`}</p>
        <p style={{ color: '#008A15' }}>{stateContent.laboratory.state ? `Laboratory: ` + data.laboratory : ``}</p>
        <p style={{ color: '#5D7EC6' }}>{stateContent.glaso.state ?  `Glaso: ` + decimalRound(data.glaso, 2) : ``}</p>
        <p style={{ color: '#dc2626' }}>{stateContent.standing.state ? `Standing: ` + decimalRound(data.standing, 2) : ``}</p>
        <p style={{ color: '#FFC737' }}>{stateContent.petrosky.state ? `Petrosky and Farshad: ` + decimalRound(data.petrosky, 2) : ``}</p>
        <p style={{ color: '#5D7EC6' }}>{stateContent.beggs_beal.state ? `Beggs et al., Beal: ` + decimalRound(data.beggs_beal, 3) : ``}</p>
        <p style={{ color: '#dc2626' }}>{stateContent.beggs_beal.state ? `Glaso, Beggs et al., Vazquez et al: ` + decimalRound(data.glaso_beggs, 3) : ``}</p>
      </div>
    )
  }

}

export const ChartEndStep = ({stateCheck, nameCheck, dataPB, dataRS, dataBO, dataViso, dataPressure, bubblePoint, pb}) => {
  const [dataCheck, setDataCheck] = useState();
  const [dataGraphic, setDataGraphic] = useState([]);
  const [dataBp, setDataBp] = useState([])
  
  useEffect(() => {
    if (stateCheck[nameCheck] !== undefined) {
      setDataCheck(stateCheck[nameCheck]);

      const nameData = nameCheck === "Bubble point pressure" ? dataPB?.pb_tuned : nameCheck === "Solution Gas-Oil ratio" ? dataRS?.rs_tuned : nameCheck === "Oil formation volume factor" ? dataBO?.bo_tuned : dataViso?.viso;

      // Convert all values to arrays if they are not
      const normalizedData = {};
      for (const key in nameData) {
        if (Array.isArray(nameData[key])) {
          normalizedData[key] = nameData[key];
        } else {
          normalizedData[key] = [nameData[key]];
        }
      }

      // Pressure values are included in normalizedData
      if (dataPressure && Array.isArray(dataPressure)) {
        normalizedData.pressure = dataPressure.map((item, index) => item.pressureTable1);
        if (nameCheck === "Bubble point pressure"){
          normalizedData.laboratory = [bubblePoint.bubble_point_pressure_lab];
        } else if (nameCheck === "Solution Gas-Oil ratio") {
          normalizedData.laboratory = dataPressure.map((item, index) => item.gas_oil_ratio);
        } else if (nameCheck === "Oil formation volume factor") {
          normalizedData.laboratory = dataPressure.map((item, index) => item.oil_fvf);
        } else if (nameCheck === "Oil viscosity") {
          normalizedData.laboratory = dataPressure.map((item, index) => item.oil_viscosity);
        }
      }

      // Transform data into an array of objects suitable for recharts
      const maxLength = Math.max(...Object.values(normalizedData).map(arr => arr.length));
      const graphicData = Array.from({ length: maxLength }).map((_, i) => {
        const dataPoint = {};
        for (const key in normalizedData) {
          dataPoint[key] = normalizedData[key][i] !== undefined ? normalizedData[key][i] : null;
        }
        return dataPoint;
      });
      setDataGraphic(graphicData);
      setDataBp([
        {
        ...dataPB?.pb_tuned,
        pressure: pb,
        laboratory: pb,
        }
      ]
      );
    }
  }, [stateCheck, nameCheck, dataPB, dataRS, dataBO, dataViso, dataPressure]);

  const colorsByType = {
    glaso: "#5D7EC6",
    standing: "#dc2626",
    petrosky: "#FFC737",
    beggs_beal: "#5D7EC6",
    glaso_beggs: "#dc2626",
    laboratory: "#008A15"
  };

    const namData = {
    glaso: {
      state: dataCheck === undefined || dataCheck["Glaso"] === undefined ? false : dataCheck["Glaso"],
      name: "Glaso"
    },
    standing: {
      state: dataCheck === undefined || dataCheck["Standing"]  === undefined ? false : dataCheck["Standing"],
      name: "Standing"
    },
    petrosky: {
      state: dataCheck === undefined || dataCheck["Petrosky and Farshad"]  === undefined ? false : dataCheck["Petrosky and Farshad"],
      name: "Petrosky and Farshad"
    },
    beggs_beal: {
      state: dataCheck === undefined || dataCheck["Beggs et al., Beal"]  === undefined ? false : dataCheck["Beggs et al., Beal"],
      name: "Beggs et al., Beal"
    },
    glaso_beggs: {
      state: dataCheck === undefined || dataCheck["Glaso, Beggs et al., Vazquez et al"]  === undefined ? false : dataCheck["Glaso, Beggs et al., Vazquez et al"],
      name: "Glaso, Beggs et al., Vazquez et al"
    },
    laboratory: {
      state: dataCheck === undefined ? false : dataCheck["Laboratory"],
      name: "Laboratory"
    }

  };

  const DataAxisY = Object.keys(namData).filter(key => namData[key].state);

  const nameAxisY = DataAxisY.map((key) => namData[key].name).join(",  ");

  const dy = -10 - (DataAxisY.length - 1) * -65;

  const getColorByType = (type) => colorsByType[type] || "#4c0519";

  const DomainY = () => {
    if (DataAxisY.length === 1) {
      const key = DataAxisY[0];
      const values = dataGraphic.map(item => parseFloat(item[key])).filter(value => !isNaN(value)); 
      const min = Math.min(...values);
      const max = Math.max(...values);
      return [min, max];
    }
    return ['auto', 'auto'];
  };

  return (
    <div className='flex w-[1420px] h-[700px] mb-[20px]'>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={300}
          data={nameCheck === "Bubble point pressure" ? dataBp : dataGraphic}
          margin={{
            top: 20,
            right: 100,
            left: 45,
            bottom: 20,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey={"pressure"}
            label={{ value: "Pressure (psia)", position: "insideBottom", offset: -15 }}
            type='number'
            tickCount={11}
          />
          <YAxis
            label={{ value: nameAxisY, angle: -90, position: "insideLeft", dy, dx: -40}}
            domain={DomainY()}
            tickFormatter={(value) => value.toFixed(2)}
            type='number'
            tickCount={11}
          />
          <Tooltip content={<CustomTooltip stateContent={namData}/>} />
          {DataAxisY.map((key, index) => (
            <Line
              key={key}
              type="monotone"
              dataKey={key}
              stroke={getColorByType(key)}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}
