import React from "react";
import {
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";

const CustomTooltip = ({ active, payload}) => {
  if (active && payload && payload.length) {

    return (
      <div className="custom-tooltip" style={{ backgroundColor: "#fff", padding: '10px', border: '1px solid #ccc' }}>
        <p>{`Pwf = ${Math.round(payload[0].payload?.steps)} psia`}</p>
        <p className="label text-[#873d01]">{`Liquid Rate = ${Math.round(payload[0].payload.qliq_pet)} STB/D`}</p>
        <p className="label text-[#008000]">{`Oil Rate = ${Math.round(payload[0].payload.qo_pet)} STB/D`}</p>
        <p className="label text-[#0000ff]">{`Water Rate = ${Math.round(payload[0].payload.qw_pet)} STB/D`}</p>
      </div>
    );
  }

  return null;
};
const ChartComposite = ({ data }) => {
  const isValidData = Array.isArray(data) && data.length > 0;

  // Inicializa maxValueY de forma segura
  const maxValueY = isValidData ? Math.max(...data.map(d => d.steps)) : 0;

  // Lógica para determinar tickStepY basada en maxValueY
  let tickStepY = 100; // Valor predeterminado
  if (maxValueY > 100000) {
    tickStepY = 10000;
  } else if (maxValueY > 10000) {
    tickStepY = 1000;
  } else if (maxValueY > 1000) {
    tickStepY = 500;
  } else if (maxValueY > 100) {
    tickStepY = 100;
  } else if (maxValueY > 10) {
    tickStepY = 10;
  } else if (maxValueY > 0) {
    tickStepY = 1;
  }

  // Calcula roundedMaxY y genera ticksY de forma segura
  const roundedMaxY = isValidData ? Math.ceil(maxValueY / tickStepY) * tickStepY : 0;
  const ticksY = isValidData ? Array.from({ length: Math.ceil(roundedMaxY / tickStepY) + 1 }, (_, i) => i * tickStepY) : [];


  // Encuentra el valor máximo de qo
  const maxValue =  isValidData ?Math.max(...data?.map(d => d.qo)): 0;

  // Determina el paso de tick basado en el valor máximo
  let tickStep;
  if (maxValue <= 10) {
    tickStep = 1; // Ajusta para valores muy pequeños, cada 1 unidad
  } else if (maxValue <= 100) {
    tickStep = 10; // Para valores hasta 100, cada 10 unidades
  } else if (maxValue <= 1000) {
    tickStep = 100; // Para valores hasta 1000, cada 100 unidades
  } else {
    tickStep = 1000; // Para valores mayores, cada 1000 unidades
  }

  // Ajusta para tu caso específico, donde los ticks deben ser cada 5 si el máximo es 23
  if (maxValue <= 25) {
    tickStep = 5; // Cada 5 unidades para valores hasta 25
  }

  // Calcula el valor máximo ajustado al paso de tick y genera los ticks
  const roundedMax =  isValidData ?Math.ceil(maxValue / tickStep) * tickStep: 0;
  const ticks =  isValidData ? Array.from({ length: Math.ceil(roundedMax / tickStep) + 1 }, (_, i) => i * tickStep): [];

  return (
    <div className="flex w-[700px] h-[510px]">
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={500}
          data={data}
          margin={{
            left: 5,
            bottom: 30,
            top: 30
          }}
          layout="vertical"
        >
          <XAxis
            tickCount={10}
            label={{
              value: `Liquid Rate (STB/D)`,
              position: 'insideBottom ',
              style: { textAnchor: "middle" },
              dy: 20,
            }}
            allowDataOverflow={true} // Permite que los datos sobrepasen los límites establecidos por domain
            type='number'
          />
          <YAxis
            dataKey="steps"
            domain={[0, roundedMaxY]}
            tickCount={10} // Especifica manualmente los ticks
            // tickFormatter={(value) => Math.round(value)} // Redondea los valores a enteros
            label={{
              value: `Pwf (psia)`,
              angle: -90,
              position: 'insideLeft',
              offset: 0,
              dy: 20,
            }}
            type="number"
            
            margin={{ left: 10 }}
            allowDataOverflow={true}
            reversed
          />
          <Tooltip content={<CustomTooltip />} />
          <Legend
            layout="horizontal"     // Opciones: "vertical", "horizontal"
            align="center"         // Opciones: "left", "center", "right"
            wrapperStyle={{ bottom: '5px', right: 0, lineHeight: 0 }} // Ajustar el estilo para mover la leyenda
            payload={[
              { value: 'Liquid Rate', type: 'line', color: '#873d01' },
              { value: 'Oil Rate', type: 'line', color: '#008000' },
              { value: 'Water Rate', type: 'line', color: '#0000ff' },
            ]}
          />
          <Line type="monotone" dataKey="qliq_pet" stroke="#873d01" activeDot={{ r: 8 }} />
          <Line type="monotone" dataKey="qo_pet" stroke="#008000" activeDot={{ r: 8 }} />
          <Line type="monotone" dataKey="qw_pet" stroke="#0000ff" activeDot={{ r: 8 }} />
          {/* {ActiveChecks.map((type, index) => (
            <Line
              key={index}
              type="monotone"
              dataKey={typeGraph[type]}
              stroke={getColorByType(type)}
            />
          ))} */}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ChartComposite;
