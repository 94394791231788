import { Button, Modal } from 'antd';
import React, { useState } from 'react'

export const AlertModal = ({showAlert,handleCloseModal,messageAlert,typeModal}) => {

  return (
    <>
      <Modal
        title={typeModal}
        open={showAlert}
        onCancel={handleCloseModal}
        onOk={handleCloseModal}
        okText="Ok"
        className="bg-[#662D91] rounded-md"
        okButtonProps={{
          className: "bg-[#662D91]",
        }}

      >
        <p>{messageAlert}</p>
      </Modal>
    </>
  )
}