import { serviceEndPoints } from "../../config/config";

const BASE_URL_SCREENING = serviceEndPoints.SCREENING.URL_SCREENING;
const BASE_URL_SCREENING_TUNEL = "https://n2f23443-5000.use2.devtunnels.ms/"

export const getScreeningRank = async (operator, lease, region, county, field, wellid, username) => {
    try {
        const reponse = await fetch(`${BASE_URL_SCREENING}/screening_tool/get_rank_stats/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${username}`, {
            method: "GET",
            headers: {
                "Authorization": "Token 9TyU77LmBfId79DKaIVeuJ8cpKA4JGprZI3yH85Hii8c8DkRFSh4YZ5P4mDBUUga"
            },
        });
        return reponse.json();
    }catch (error){
        throw new Error("Problem in data screening");
    }
}

export const getScreeningDonut = async (operator, lease, region, county, field, wellid, username) => {
    try {
        const reponse = await fetch(`${BASE_URL_SCREENING}/screening_tool/plot_donut_rank/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${username}`, {
            method: "GET",
            headers: {
                "Authorization": "Token 9TyU77LmBfId79DKaIVeuJ8cpKA4JGprZI3yH85Hii8c8DkRFSh4YZ5P4mDBUUga"
            },
        });
        return reponse.json();
    }catch (error){
        throw new Error("Problem in data screening");
    }
}

export const getScreeningDonutDepth = async (operator, lease, region, county, field, wellid, username) => {
    try {
        const reponse = await fetch(`${BASE_URL_SCREENING}/screening_tool/plot_donut_without_depth/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${username}`, {
            method: "GET",
            headers: {
                "Authorization": "Token 9TyU77LmBfId79DKaIVeuJ8cpKA4JGprZI3yH85Hii8c8DkRFSh4YZ5P4mDBUUga"
            },
        });
        return reponse.json();
    }catch (error){
        throw new Error("Problem in data screening");
    }
}

export const getScreening3DRank = async (operator, lease, region, county, field, wellid, username) => {
    try {
        // const response = await fetch(`${BASE_URL_SCREENING}/screening_tool/plot_3d_rank/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${username}`, {
        const response = await fetch(`${BASE_URL_SCREENING}/screening_tool/plot_3d_rank/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${username}`, {
            method: "GET",
            headers: {
                "Authorization": "Token 9TyU77LmBfId79DKaIVeuJ8cpKA4JGprZI3yH85Hii8c8DkRFSh4YZ5P4mDBUUga"
            },
        });
        return response.text();
    }catch (error){
        throw new Error("Problem in data screening");
    }
}

export const getScreening3DRankDepth = async (operator, lease, region, county, field, wellid, username) => {
    try {
        const reponse = await fetch(`${BASE_URL_SCREENING}/screening_tool/plot_3d_rank_depth/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${username}`, {
            method: "GET",
            headers: {
                "Authorization": "Token 9TyU77LmBfId79DKaIVeuJ8cpKA4JGprZI3yH85Hii8c8DkRFSh4YZ5P4mDBUUga"
            },
        });
        return reponse.text();
    }catch (error){
        throw new Error("Problem in data screening");
    }
}

export const getMultipleScatter = async (operator, lease, region, county, field, wellid, username) => {
    try {
        const response = await fetch(`${BASE_URL_SCREENING}/screening_tool/plot_multiple_scatter/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${username}`, {
            method: "GET",
            headers: {
                "Authorization": "Token 9TyU77LmBfId79DKaIVeuJ8cpKA4JGprZI3yH85Hii8c8DkRFSh4YZ5P4mDBUUga"
            }
        })
        return response.json();
    }catch (error){
        throw new Error("Problem in data multiple scatter")
    }
}

export const getListReservoir = async (operator, lease, region, username) => {
    try {
        // const reponse = await fetch(`${BASE_URL_SCREENING}/screening_tool/metadata/${operator}/${lease}/${region}/${username}`, {
        const reponse = await fetch(`${BASE_URL_SCREENING}/screening_tool/metadata/${operator}/${lease}/${region}/${username}`, {
            method: "GET",
            headers: {
                "Authorization": "Token 9TyU77LmBfId79DKaIVeuJ8cpKA4JGprZI3yH85Hii8c8DkRFSh4YZ5P4mDBUUga"
            },
        });
        return reponse.json();
    }catch (error){
        throw new Error("Problem in data screening");
    }
}

export const uploadScreening = async (dataJson) => {
    
    try {
      const operator = dataJson.operator;
      const lease = dataJson.lease;
      const region = dataJson.region;
      const county = dataJson.county;
      const field = dataJson.field;
      const well = dataJson.reservoir;
      const username = dataJson.userName;
      const data = dataJson.data;

      const response = await fetch(`${BASE_URL_SCREENING}/screening_tool_input/upload_data/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token 9TyU77LmBfId79DKaIVeuJ8cpKA4JGprZI3yH85Hii8c8DkRFSh4YZ5P4mDBUUga" },
        body: JSON.stringify(data)
      });
      return response.json();
    } catch (error) {
      throw new Error("Problem getting users");
    }
};

export const deleteScreening = async (dataJson) => {
    
    try {
      const operator = dataJson.operator;
      const lease = dataJson.lease;
      const region = dataJson.region;
      const county = dataJson.county;
      const field = dataJson.field;
      const well = dataJson.reservoir;
      const username = dataJson.userName;
    //const response = await fetch(`${BASE_URL_SCREENING}/screening_tool_input/upload_csv/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`, {
        const response = await fetch(`${BASE_URL_SCREENING}/screening_tool_input/delete_data/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`, {
        method: "DELETE",
        headers: {
            "Authorization": "Token 9TyU77LmBfId79DKaIVeuJ8cpKA4JGprZI3yH85Hii8c8DkRFSh4YZ5P4mDBUUga" },
      });
      return response.json();
    } catch (error) {
      throw new Error("Problem getting users");
    }
};

export const getFileScreening = async (operator, lease, region, county, field, wellid, username) => {
    try {
        const response = await fetch(`${BASE_URL_SCREENING}/screening_tool/data_for_rank/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${username}`, {
            method: "GET",
            headers: {
                "Authorization": "Token 9TyU77LmBfId79DKaIVeuJ8cpKA4JGprZI3yH85Hii8c8DkRFSh4YZ5P4mDBUUga"
            }
        })
        return response.text();
    }catch (error){
        throw new Error("Problem in data multiple scatter")
    }
}

