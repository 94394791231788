import moment from 'moment';
import React, { useContext } from 'react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { DashboardPlottingContext } from '../../../context/DashboardPlotting/DashboardPlottingContext';
import { COLORS_LINES } from '../../elements/types/type_plotting';

export const ChartPlotting = ({data, linesData, colorMapping }) => {
  const { dataPlotting } = useContext(DashboardPlottingContext);

  const filteredDataConfigGraph = dataPlotting.dataConfigGraph.filter(item =>
    linesData.includes(item.device)
  );

  const formattedData = data?.map((item, index) => ({
  ...item,
      Date: String(item["Date (MM/DD/YYYY)"]).split(" ")[0], // Formato deseado de fecha
  }))

  const filterData = formattedData?.map(item => {
    const updatedItem = { ...item };
    Object.keys(updatedItem).forEach(key => {
      if (updatedItem[key] === -999.25) {
        updatedItem[key] = null;
      }
    });
    
    return updatedItem;
  });

  return (
    <div className='flex w-full'>
        <ResponsiveContainer width="100%" height="100%">
            <LineChart
            width={500}
            height={300}
            data={formattedData}
            margin={{
                top: 15,
                right: 100,
                left: 30,
                bottom: 30,
            }}
            >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
                dataKey="Date (MM/DD/YYYY)"
                type="category"
                allowDecimals={false}
                label={{
                    value: "Date",
                    position: "bottom",
                    fill: "black",
                    fontSize: 14,
                }}
                tickFormatter={(unixTime) => moment(unixTime,"MM/DD/YYYY HH:mm:ss").format("MM/DD/YYYY")}
                tick={{ fill: "black", fontSize: 12}}
            />
            {dataPlotting.optionsParameters.length && filteredDataConfigGraph.map((lineKey, index) => {
            const color = colorMapping[lineKey.device] || lineKey.color; 
            return (
            <YAxis
                key={`yAxis-${lineKey.device}`}
                label={{
                    value: lineKey.device, // Etiqueta del eje Y
                    angle: -90,
                    position: index % 2 === 0 ? 'outsideLeft' : 'outsideRight', // Coloca las etiquetas fuera
                    fill: color,
                    style: { textAnchor: "middle" }, // Mover el label hacia arriba
                    fontSize: 14,
                    dx: index % 2 === 0 ? -10 : 10,
                }}
                tick={{ fill: color, fontSize: 12}} // Color de las marcas
                yAxisId={index} // Cada eje tiene un ID único
                orientation={index % 2 === 0 ? 'left' : 'right'} // Alterna la orientación
                stroke={color} // Color del eje, igual al de la línea
                interval={0} // Muestra todos los ticks
                width={60} 
                tickCount={10} 
                domain={dataPlotting.dataConfigGraph[dataPlotting.dataConfigGraph.findIndex(item => item.device === lineKey.device)].domain}
                allowDataOverflow={true}
            />
            )})}
            <Tooltip />
            {/* <Legend /> */}
            {filteredDataConfigGraph?.map((lineKey, index) => {
              let colorIndex = dataPlotting.optionsParameters.findIndex(item => item.value === lineKey.device);
              // Asignar color cíclico
              if (colorIndex >= 0) {
                colorIndex = colorIndex % COLORS_LINES.length;
              } else {
                colorIndex = 0; // Color por defecto si no se encuentra
              }
              const color = colorMapping[lineKey.device] || lineKey.color; 
              return (
                <Line
                    key={lineKey.device}
                    type="monotone"
                    dataKey={lineKey.device}
                    stroke={color} // Puedes variar los colores
                    yAxisId={index}
                    activeDot={{ r: 8 }}
                />
            )})}
            </LineChart>
        </ResponsiveContainer>
    </div>
  )
}
