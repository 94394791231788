import { DeleteOutlined, EditOutlined, ExclamationCircleFilled, CopyOutlined } from "@ant-design/icons";
import { Checkbox, message, Modal, Tooltip, Select, Switch, Tag } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ImportFileHistoryDCA } from "../../components/DCA/complements/ImportFileHistoryDCA";
import { ErrorLoadingData } from "../../components/ErrorPage";
import { Navbar } from "../../components/layout/Navbar";
import { Sidebar } from "../../components/layout/Sidebar";
import { Loading } from "../../components/Loading";
import { DashboardContext } from "../../context/Dashboard/DashboardContentx";
import { useTabDCA } from "../../hooks/useTabDCA";
import { useUsers } from "../../hooks/useUsers";
import { useFilters } from "../../hooks/useFilters";
import { useTabIOT } from "../../hooks/useTabIOT";
import { DATA_CREATE_WELL_DCA } from "../../components/elements/types/type_dca";
import { useCrudUsers } from "../../hooks/useCrudUsers";

const platform = {
  data : [
    "V-191",
    "SMI-27"
  ]
}

const dataWellCopyUser = {
  well_id: "",
  well_name: "",
  user: []
}

export const AddWellDCA = () => {
  const { confirm } = Modal;
  const { Option } = Select;
  const navigate = useNavigate();
  const { dataFilter, mutationUpdateFilter } = useFilters();
  const { User } = useUsers();
  const { dataUsersExternal, dataUsersInternal, setEnabledUserExt, setEnabledUserInt} = useCrudUsers();

  const {
    userDataInfo,
    dataGetADDWellDCA,
    dataFilterUserDCA,
    getDataFilterUserDCA,
    dataListDataHistory,
    dataHistoryDCA,
    dataGetWellDCA,
    refetchDataWellDCA,
    refetchDataADDWellDCA,
    refetchListDataHistory,
    refetchDataHistoryDCA,
    mutationDeleteJsonWellDCA,
    mutationUploadDataHistory,
    mutationDeleteDataHistory,
    mutationUploadJsonWellDCA,
    viewLoading,
    setViewLoading,
    ErrorDataADDWellDCA,
    generateRandomID,
    setUserMultiselect,
    refetchWellsUserDCA,
    wellsUserDCA
  } = useTabDCA();
  const { refetchDataListPlatformsWells, setTypeProcessPlatform, refetchDataListPlatforms, dataListPlatforms, dataListPlatformsWells} = useTabIOT();
  const { selectedWellDCA, getSelectedWellDCA, getDataPage, getOpenFirstModal, getDataAlertDCA, getDeviceRealTime, deviceRealTime} = useContext(DashboardContext);
  const [wellboreOptionState, setWellboreOptionState] = useState(false);
  const [data, setData] = useState([]);
  const [openModalCopy, setOpenModalCopy] = useState(false);
  const [inputDataDCA, setInputDataDCA] = useState(DATA_CREATE_WELL_DCA);
  const [inputEmpty, setInputEmpty] = useState(false);
  const [enableMultiUser, setEnableMultiUser] = useState(false);
  const [validationWellEnd, setValidationWellEnd] = useState(false);
  const [loadCopy, setLoadCopy] = useState(false);
  const [multiUserCopy, setmultiUserCopy] = useState(dataWellCopyUser);
  const [validationWell, setValidationWell] = useState(false);
  const [enableValidationWells, setEnableValidationWells] = useState(false);
  const [validationWells, setValidationWells] = useState([]);
  const listWells = dataGetADDWellDCA ? dataGetADDWellDCA?.data : null;
  const listDataHistory = dataListDataHistory != null || dataListDataHistory != undefined ? dataListDataHistory?.data: null;
  const userOption = dataUsersInternal?.filter(user => user.user_name.split('@')[0] !== User.user_name).map(user => ({value: user.user_name.split('@')[0]}));

  useEffect(() => {
    getDataPage("AddWellDCA");
    refetchDataADDWellDCA();
    refetchListDataHistory();
    setViewLoading(true)
  }, []);

  useEffect(() => {
    if (selectedWellDCA !== null) {
      refetchDataWellDCA();
      refetchDataHistoryDCA();
    }
  }, [selectedWellDCA]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      refetchDataADDWellDCA();
      refetchListDataHistory();
    }, 10000); // 10 segundos
    // Limpieza del intervalo cuando el componente se desmonta
    return () => clearInterval(intervalId);
  }, [refetchDataADDWellDCA, refetchListDataHistory]); // Dependencia del efecto

  useEffect(() => {
    setTimeout(() => {
      if (dataGetADDWellDCA) {
        if (dataGetADDWellDCA.statusType != "ERROR") {
          setData(dataGetADDWellDCA.data);
        }
      }
    }, 500);
  }, [dataGetADDWellDCA]);

  useEffect(() => {
    if (openModalCopy) {
      refetchDataListPlatforms();
    }
  }, [openModalCopy]);

  useEffect(() => {
    if(wellsUserDCA?.statusCode === 200075 && wellsUserDCA?.data && validationWell.state){

      const validationResults = wellsUserDCA?.data?.some(well => 
        well.well_id === multiUserCopy.well_id || 
        well.well_name === multiUserCopy.well_name
      );

      setValidationWells(prevState => ({
        ...prevState,
        [validationWell.user]: validationResults
      }));

      if (validationResults) {
        message.error("The "+validationWell.user+" already has a well with the NAME or ID");
      }
      setValidationWell({...validationWell, state: false});
    }else if(wellsUserDCA?.statusCode === 400077 && !wellsUserDCA?.data && validationWell.state){
      setValidationWells(prevState => ({
        ...prevState,
        [validationWell.user]: false
      }));
      setValidationWell({...validationWell, state: false});
    }
  }, [wellsUserDCA, validationWell]);

  useEffect(() => {
    if (enableValidationWells) {
      const valUserDuplicate = Object.values(validationWells).some(value => !!value);
      setEnableValidationWells(false);
      setValidationWellEnd(!valUserDuplicate);
      setLoadCopy(false);
      if(!valUserDuplicate){
        message.success("No problem for validation duplicate ID or NAME in others wells");
      }
    }
  }, [enableValidationWells]);

  const handleEdit = (wellID) => {
    // Implementar la lógica de edición según tus necesidades
    if (selectedWellDCA !== null && selectedWellDCA !== undefined && selectedWellDCA !== "null"){
      if (
        wellID === dataFilterUserDCA.well_id && dataFilterUserDCA.well_id !== null && dataFilterUserDCA.well_id !== undefined
      ) {
        navigate("./StepMenu-EditWellDCA")
      } else {
        info();
      }
    } else {
      message.info("Please select first a well");
    }
  };

  const handleDelete = (wellID) => {
    if (!!selectedWellDCA){
      if (
        wellID === dataFilterUserDCA.well_id && dataFilterUserDCA.well_id !== null && dataFilterUserDCA.well_id !== undefined
      ) {
        confirm({
          title: "Do you want to delete well information?",
          icon: <ExclamationCircleFilled />,
          content: "Deleted well cannot be recovered",
          okText: "Yes",
          okType: "danger",
          cancelText: "No",
          onOk() {
            const validateExistDataHistory = listDataHistory?.some((dataHistoryItem) => dataHistoryItem.wellid === wellID);
            if (validateExistDataHistory){
              setTimeout(() => {
                mutationDeleteDataHistory.mutate({
                  operator: userDataInfo.operator,
                  lease: userDataInfo.lease,
                  region: userDataInfo.region,
                  county: dataFilterUserDCA.county,
                  field: dataFilterUserDCA.field,
                  wellid: dataFilterUserDCA.well_id,
                  userName: User.user_name,
                });
              }, 500);
            }
            setTimeout(() => {
              mutationDeleteJsonWellDCA.mutate({
                operator: userDataInfo.operator,
                lease: userDataInfo.lease,
                region: userDataInfo.region,
                county: dataFilterUserDCA.county,
                field: dataFilterUserDCA.field,
                wellid: dataFilterUserDCA.well_id,
                userName: User.user_name,
              });
            }, 500);
            const newData = data.filter((item) => item.id !== wellID);
            setData(newData);
            setTimeout(() => {
              refetchDataADDWellDCA();
            }, 1000);
            getDataFilterUserDCA(null);
            getSelectedWellDCA(null);
          },
          onCancel() {
            console.log("Cancel");
          },
        })
      } else {
        info();
      }
    } else {
      message.info("Please select first a well");
    }
  };

  const handleModalCopyWell = (idCheck, state) => {

    if (selectedWellDCA !== null && (selectedWellDCA === idCheck) && state && dataGetWellDCA?.data) {
      setOpenModalCopy(state);
      refetchDataListPlatforms();
      setInputDataDCA({
          ...dataGetWellDCA?.data,
          well_id:"", 
          well_platforms:"", 
          well_name:"",
          discretized_well: false
        })
    }else if(!state){
      setInputDataDCA(DATA_CREATE_WELL_DCA);
      setOpenModalCopy(state);
      setInputEmpty(false);
      setmultiUserCopy(dataWellCopyUser);
      setValidationWellEnd(false);
      setValidationWell(false);
      setEnableMultiUser(false);
      setValidationWells([]);
    }else{
      message.info("Please select check with correct well")
    }
  };
  const handleCopyWell = (type) => {
    if (!!inputDataDCA.well_id && !!inputDataDCA.well_name && !!inputDataDCA.well_platforms && dataGetWellDCA?.data) {
      if(type === "save"){
        if (enableMultiUser) {
          async function executeFunction() {
            await sequentialMutationCopyWell(multiUserCopy.user);
          }
          executeFunction();
        }else{
          getDataFilterUserDCA({
            ...dataFilterUserDCA,
            county: inputDataDCA.county,
            field: inputDataDCA.field,
            well_id: inputDataDCA.well_id,
            well_platforms: inputDataDCA.well_platforms,
            well_name: inputDataDCA.well_name,
          });
    
          getSelectedWellDCA(inputDataDCA.well_id);
          async function executeFunction() {
            await sequentialMutationCopyWell([User?.user_name]);
          }
          executeFunction();
        }
      }else if (type === "validate_multi_user"){
        setLoadCopy(true);
        setmultiUserCopy((prevState) => ({
          ...prevState,
          ["well_id"]: inputDataDCA.well_id,
          ["well_name"]: inputDataDCA.well_name
        }));
        
        async function executeFunction() {

          setValidationWells([]);
          for (const user of multiUserCopy.user) {
            setUserMultiselect(user);
            await new Promise(resolve => setTimeout(resolve, 500));

            await refetchWellsUserDCA();
            setValidationWells(prevState => ({
                ...prevState,
                [user]: null
            }));
            setValidationWell({state: true, user: user});
          }
          await new Promise(resolve => setTimeout(resolve, 500));
          setEnableValidationWells(true)
        }
        executeFunction();
      }
    }else{
      message.error("Please complete information")
      setInputEmpty(true)
    }
  }

  const handleChangeCheckWell = (valueCheck, idCheck) => {
    if (valueCheck === true) {
      // En esta seccion de debe llamar el api de listado de pozos y buscar el id que se toma de la variable de estado para extraer el resto de datos y setearlo de esta forma se valida que exista
      const dataDCAFind = data.find((item) => item.well_id === idCheck);
      const dataFilterDCA = {
        county: dataDCAFind.county,
        field: dataDCAFind.field,
        well_id: dataDCAFind.well_id,
        well_platforms: dataDCAFind.well_platforms,
        well_name: dataDCAFind.well_name,
      };
      getDataFilterUserDCA(dataFilterDCA);
      
      setTimeout(() => {
        mutationUpdateFilter.mutate({
          user: User.user_name,
          data: {...dataFilter?.data,checkedWellDCA:idCheck}
         
        });
      }, 1000);
      getSelectedWellDCA(idCheck);
    } else {
      getSelectedWellDCA(null);
      getDataFilterUserDCA(null);
      getDataAlertDCA([]);
      setTimeout(() => {
        mutationUpdateFilter.mutate({
          user: User.user_name,
          data: {...dataFilter?.data,checkedWellDCA:""}
         
        });
      }, 1000);
    }
  };

  const hendleClickButtonCreate = (idButton) => {
    if (
      idButton === 0 &&
      !!userDataInfo &&
      !!userDataInfo.operator &&
      !!userDataInfo.lease  &&
      !!userDataInfo.region 
    ) {
      navigate("./StepMenu-WellDCA")
    } else {
      info();
    }
  };
  const info = () => {
    Modal.info({
      title: "Warning",
      icon: <ExclamationCircleFilled />,
      okText: "OK",
      okType: "danger",
      content: (
        <div>
          {(userDataInfo.operator === undefined &&
            userDataInfo.lease === undefined &&
            userDataInfo.region === undefined) ? (
            <>
              <p>Please select or fill-out the information of operator, lease, and region</p>
            </>
          ) : selectedWellDCA === undefined || selectedWellDCA === null ?
            (<p>Please select a well first</p>) : (<p>please select the well to edit or delete it</p>)}
          
        </div>
      ),
      onOk() { !!userDataInfo.operator === undefined || userDataInfo.lease === undefined ? getOpenFirstModal(true) : getOpenFirstModal(false)},
    });
  };
  
  const handleUploadFile = (dataFile) => {
    const dataFilePre = dataFile.map((obj, index) => {
      return { id: index + 1, ...obj };
    });
    const dataFileEnd = dataFilePre.map((item)=>({
       ...item, 
       ['date']: convertDate(item.date) 
    }))
    if (dataFileEnd?.length > 0) {
      setTimeout(() => {
        mutationUploadDataHistory.mutate({
          operator: userDataInfo.operator,
          lease: userDataInfo.lease,
          region: userDataInfo.region,
          county: dataFilterUserDCA.county,
          field: dataFilterUserDCA.field,
          wellid: dataFilterUserDCA.well_id,
          userName: User.user_name,
          data: dataFileEnd,
        });
      }, 1000);
    }
  };

  const handleDeleteDataHistory = (wellID) => {
    if (!!selectedWellDCA){
      if (wellID === dataFilterUserDCA.well_id && !!dataFilterUserDCA.well_id) {
        confirm({
          title: "Do you want to delete production history data? ",
          icon: <ExclamationCircleFilled />,
          content: "Deleted information cannot be recovered",
          okText: "Yes",
          okType: "danger",
          cancelText: "No",
          onOk() {
            setTimeout(() => {
              mutationDeleteDataHistory.mutate({
                operator: userDataInfo.operator,
                lease: userDataInfo.lease,
                region: userDataInfo.region,
                county: dataFilterUserDCA.county,
                field: dataFilterUserDCA.field,
                wellid: dataFilterUserDCA.well_id,
                userName: User.user_name,
              });
            }, 1000);
            setTimeout(() => {
              refetchListDataHistory()
            }, 1500);
            
            const newData = data.filter((item) => item.id !== wellID);
            setData(newData);
          },
          onCancel() {
            console.log("Cancel");
          },
        })
      } else {
        message.info("Please select first the well for delete the data history");
      }
    } else {
      message.info("Please select first a well");
    }
  };

  const handleChangeInputData = (e) => {
    const { name, value } = e.target;
    let exists = false;
    switch (name) {
      case "well_platforms":
        getDeviceRealTime({
          ...deviceRealTime,
          device_dca: {
            ...deviceRealTime.device_dca,
            platform:value
          }
        })
        setTypeProcessPlatform(value);
        setTimeout(() => {
          refetchDataListPlatformsWells();
        }, 500);
        setInputDataDCA((prevState) => ({
          ...prevState,
          [name]: value,
        }));
        break;
      case "discretized_well":
        getDeviceRealTime({
          ...deviceRealTime,
          device_dca: {
            ...deviceRealTime.device_dca,
            discretized: value
          }
        });
        setInputDataDCA((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      case "well_id":
        exists = wellIdExists(name, value, data);
        if (exists) {
          setInputDataDCA((prevState) => ({
            ...prevState,
            [name]: null,
          }));
          message.error(
            "There is already a well with the same ID, please generate a new ID"
          );
        }else{
          setInputDataDCA((prevState) => ({
            ...prevState,
            [name]: value,
          }));
        }
        break;
      case "well_name":
        exists = wellIdExists(name, value, data);
        if (exists) {
          setInputDataDCA((prevState) => ({
            ...prevState,
            [name]: null,
          }));
          message.error(
            "There is already a well with the same Name, please select a new name"
          );
        }else{
          setInputDataDCA((prevState) => ({
            ...prevState,
            [name]: value,
          }));
        }
        break;
      case "option_select":
        setmultiUserCopy((prevState) => ({
          ...prevState,
          ["user"]: value,
        }));
        break;
      default:
        setInputDataDCA((prevState) => ({
          ...prevState,
          [name]: value,
        }));
        break;
    }
    setValidationWellEnd(false);
  }

  const handleChangeSelect = (nameData, valueData) => {
    const customEvent = {
      target: {
        name: nameData,
        value: valueData,
      },
    };
    handleChangeInputData(customEvent);
  };

  const handleSwitch = (enabled) => {
    const customEvent = {
      target: {
        name: "discretized_well",
        value: enabled,
      },
    };
    handleChangeInputData(customEvent);
  };

  const handleSwitchMultiUser = (enabled) => {
    setEnableMultiUser(enabled);
    setEnabledUserInt(enabled);
    setValidationWellEnd(false);
    if (!enabled) {
      
      const existsWellName = wellIdExists("well_name", inputDataDCA.well_name, data);
      const existsWellId = wellIdExists("well_id", inputDataDCA.well_id, data);
      
      setInputDataDCA((prevState) => ({
        ...prevState,
        ["well_name"]: existsWellName ? null : inputDataDCA.well_name,
        ["well_id"]: existsWellId ? null : inputDataDCA.well_id,
      }));

   }else if (enabled) {
    setInputDataDCA((prevState) => ({
      ...prevState,
      ["well_name"]: inputDataDCA.well_name === null ? "" : inputDataDCA.well_name,
      ["well_id"]: inputDataDCA.well_id === null ? "" : inputDataDCA.well_id,
    }));
   }
  }

  const handleClickGenerateID = () => {
    const idGenerated = generateRandomID();
    setInputDataDCA({ ...inputDataDCA, well_id: idGenerated });
  };

  const sequentialMutationCopyWell = async (users) => {
    setLoadCopy(true);
    
    for (const user of users){
      await mutationUploadJsonWellDCA.mutateAsync({
        operator: userDataInfo.operator,
        lease: userDataInfo.lease,
        region: userDataInfo.region,
        county: inputDataDCA.county,
        field: inputDataDCA.field,
        wellid: inputDataDCA.well_id,
        userName: user,
        data: inputDataDCA,
      });
      
      await new Promise(resolve => setTimeout(resolve, 500));
  
      if(dataHistoryDCA?.data?.data){
        await mutationUploadDataHistory.mutateAsync({
          operator: userDataInfo.operator,
          lease: userDataInfo.lease,
          region: userDataInfo.region,
          county: inputDataDCA.county,
          field: inputDataDCA.field,
          wellid: inputDataDCA.well_id,
          userName: user,
          data: dataHistoryDCA?.data?.data,
        });
      }
  
      await new Promise(resolve => setTimeout(resolve, 100));
      await Promise.all([
        refetchDataADDWellDCA(),
        refetchDataWellDCA(),
        refetchDataHistoryDCA()
      ]);
    };
    await new Promise(resolve => setTimeout(resolve, 100));
    setLoadCopy(false)
    setOpenModalCopy(false);
    setInputEmpty(false);
    setValidationWells([]);
    setmultiUserCopy(dataWellCopyUser);
    setInputDataDCA(DATA_CREATE_WELL_DCA);
  };

  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
      className={`text-white rounded-md ${validationWells[label] ? "bg-[#991b1b]" : "bg-[#662D91]"}`}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginRight: 3,
        }}
      >
        {label}
      </Tag>
    );
  };

  function wellIdExists(name, value, data) {
    const val = data?.some((obj) => obj[name] === value);
    return val
  }

  const wellboreOption = () => {
    setWellboreOptionState(!wellboreOptionState);
  };

  const convertDate = (fechaNumerica) => {
    const fecha = new Date((fechaNumerica - (25567 + 1)) * 86400 * 1000); // Convertir la fecha numérica en milisegundos
    const mes = fecha.getMonth() + 1;
    const dia = fecha.getDate();
    const anio = fecha.getFullYear();
    return `${mes}/${dia}/${anio}`;
  };

  if (viewLoading) {
    return (<Loading />)
  }

  return (
    <>
      <Modal
        title="Copy Well DCA"
        centered
        open={openModalCopy}
        onOk={() => {
          if (!loadCopy) {
            handleCopyWell(enableMultiUser && !validationWellEnd ? "validate_multi_user" : "save");
          }
        }}
        onCancel={() => {
          if (!loadCopy) {
            handleModalCopyWell(false);
          }
        }}
        width={700}
        className="rounded-md"
        okButtonProps={{
          className: enableMultiUser && !validationWellEnd ? "bg-[#2c7233]" : "bg-[#662D91]",
          disabled: loadCopy
        }}
        cancelButtonProps={{disabled: loadCopy}}
        okText={enableMultiUser && !validationWellEnd ? "validate" : "OK"}
      >
        {loadCopy ? 
          <div 
            className="flex flex-col w-full text-center justify-center mt-8"
            style={{ height: enableMultiUser ? '28em' : '23em' }}
          >
            <Loading/>
          </div>
         : 
          <div className="flex flex-col w-full h-full text-center justify-center mt-8">
            <div 
              className={`flex w-full justify-center text-center mb-[0]`}
              style={{ height: enableMultiUser ? '28em' : '23em' }}
              >
              <div className="grid grid-cols-2 text-right w-[90%] justify-start items-center h-[10em]">
                {User?.role !== "Simple User" &&
                  <>
                    <label
                        htmlFor=""
                        className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                      >
                      Copy Multi Users
                    </label>
                    <div className="flex w-full max-w-[592px] h-[47px] items-center justify-start mb-[17px]">
                      <Tooltip title={"Multi User"} color={"#662D91"}>
                        <Switch
                          style={{
                            backgroundColor: enableMultiUser ? "#662D91" : "#707070",
                            borderColor: enableMultiUser ? "#662D91" : "#707070",
                          }}
                          checked={enableMultiUser}
                          onChange={handleSwitchMultiUser}
                        ></Switch>
                      </Tooltip>
                    </div>
                  </>
                }
                <label
                  htmlFor=""
                  className="flex max-w-[50px] text-start font bold text-[15px] mb-[7px]"
                >
                  Well ID
                </label>
                <div className="flex flex-row w-full h-[47px] gap-x-2 text-right justify-start items-center mb-[17px]">
                  <input
                    type="text"
                    name="well_id"
                    value={inputDataDCA.well_id}
                    onChange={handleChangeInputData}
                    className={`flex w-2/3 h-[47px] rounded-[4px] px-3  border border-solid  ${
                      (inputEmpty && inputDataDCA.well_id === "") ||
                      inputDataDCA.well_id === null
                        ? " border-red-700 bg-yellow-100"
                        : "border-[#BCBCCC]"
                    } `}
                  />
                  <button
                    onClick={handleClickGenerateID}
                    className="flex w-1/3 h-[47px] justify-center text-center items-center text-[15px] text-pa-purple border border-solid border-pa-purple rounded-md hover:bg-pa-purple hover:text-pa-white1"
                  >
                    Generate ID
                  </button>
                </div>
                <label
                  htmlFor=""
                  className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                >
                  Platforms
                </label>
                <Select
                  style={{
                    backgroundColor: inputEmpty && inputDataDCA.well_platforms === "" ? "#B91C1C" : "",
                    border: inputEmpty && inputDataDCA.well_platforms === "" ? "1px solid #B91C1C" : "",
                    borderRadius: "8px",
                    textAlignalign: "left"
                  }}
                  name="well_platforms"
                  value={inputDataDCA.well_platforms || "Select option"}
                  className="w-full max-w-[592px] h-[47px] text-start mb-[17px]"
                  onChange={(value) => handleChangeSelect("well_platforms", value)}
                >
                  {dataListPlatforms?.data?.map((item, index) => (
                  <Option key={index} value={item}>
                    {item}
                  </Option>
                  ))}
                </Select>
                <label
                  htmlFor=""
                  className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                >
                  Discretized well
                </label>
                <div className="flex w-full max-w-[592px] h-[47px] items-center justify-start mb-[17px]">
                  <Switch
                    style={{
                      backgroundColor: inputDataDCA.discretized_well ? "#662D91" : "#707070",
                      borderColor: inputDataDCA.discretized_well ? "#662D91" : "#707070",
                    }}
                    checked={inputDataDCA.discretized_well}
                    onChange={handleSwitch}
                  ></Switch>
                </div>
                <label
                  htmlFor=""
                  className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                >
                  Well name
                </label>
                {inputDataDCA.discretized_well ?
                  <Select
                    style={{
                      backgroundColor: inputEmpty && inputDataDCA.well_name === "" ? "#B91C1C" : "",
                      border: inputEmpty && inputDataDCA.well_name === "" ? "1px solid #B91C1C" : "",
                      borderRadius: "8px",
                      textAlignalign: "left"
                    }}
                    name="fluid_type"
                    value={inputDataDCA.well_name || ""}
                    className="w-full max-w-[592px] h-[47px] text-start mb-[17px]"
                    onChange={(value) => handleChangeSelect("well_name", value)}
                  >
                  <Option value="">Selected option</Option>
                  {dataListPlatformsWells?.data?.map((item, index) => (
                  <Option key={index} value={item}>
                    {item}
                  </Option>
                  ))}
                  </Select>
                :
                  <input
                    type="text"
                    name="well_name"
                    value={inputDataDCA.well_name}
                    onChange={handleChangeInputData}
                    className={`w-full max-w-[592px] h-[47px] rounded-[4px]  mb-[17px] border border-solid px-3 ${
                      (inputEmpty && inputDataDCA.well_name === "") || inputDataDCA.well_name === null
                        ? " border-red-700 bg-yellow-100"
                        : "border border-solid border-[#BCBCCC]"
                    } `}
                  />
                }
                {(enableMultiUser && User?.role !== "Simple User") && (
                  <>
                    <div className="flex w-full max-w-[592px] h-[47px] items-center justify-start mb-[17px]">
                      <label
                          htmlFor=""
                          className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                        >
                        Users
                      </label>
                    </div>
                    <Select
                      style={{
                        backgroundColor: inputEmpty && inputDataDCA.well_platforms === "" ? "#B91C1C" : "",
                        border: inputEmpty && inputDataDCA.well_platforms === "" ? "1px solid #B91C1C" : "",
                        borderRadius: "8px",
                        textAlignalign: "left"
                      }}
                      name="users"
                      value={multiUserCopy.user}
                      className="w-full max-w-[592px] h-[47px] text-start mb-[17px]"
                      onChange={(value) => handleChangeSelect("option_select", value)}
                      mode="multiple"
                      tagRender={tagRender}
                      options={userOption}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        }
      </Modal>
      <div className="flex flex-col w-full h-full bg-white">
        <div className="flex">
          <Navbar wellboreOption={wellboreOption}></Navbar>
          <Sidebar className="flex abosolute h-full"></Sidebar>
        </div>
        <div className="flex flex-col w-full h-full  gap-4 pr-2 pl-[90px] p-5">
          <div className="flex flex-row gap-5 pl-[76px] pr-[76pc]">
            <div className="flex w-[157px] h-[42px] justify-center text-center items-center">
              <button
                onClick={() => hendleClickButtonCreate(0)}
                className="flex w-[157px] h-[42px] justify-center text-center items-center text-[15px] text-pa-purple border border-solid border-pa-purple rounded-md hover:bg-pa-purple hover:text-pa-white1"
              >
                Add Well DCA
              </button>
            </div>
            <div className="flex w-[157px] h-[42px] justify-center text-center items-center">
              <ImportFileHistoryDCA uploadfile={handleUploadFile}></ImportFileHistoryDCA>
            </div>
          </div>
        {(ErrorDataADDWellDCA && listWells === null) ? <ErrorLoadingData /> :
          <ul className="flex flex-col gap-y-[17px]">
            {data.map((item, index) => {
              const dataHistoryExists = listDataHistory?.some(
                (dataHistoryItem) => dataHistoryItem.wellid === item.well_id
              );
              return (
                <li
                  key={index}
                  className="flex flex-row w-full  border-solid p-5 rounded-md"
                >
                  <div className="flex flex-row w-full gap-5">
                    <table className="min-w-full text-left text-sm font-light">
                      <thead className="border-b font-medium text-[15px] border border-[#BCBCCC] bg-[#FAFAFA]">
                        <tr style={{ width: '100%' }}>
                          <th scope="col" className="px-6 py-4" style={{ width: '15%' }}>
                            Select well
                          </th>
                          <th scope="col" className="px-6 py-4" style={{ width: '20%' }}>
                            Name
                          </th>
                          <th scope="col" className="px-6 py-4" style={{ width: '20%' }}>
                            ID
                          </th> 
                          <th scope="col" className="px-6 py-4" style={{ width: '20%' }}>
                            Description
                          </th>
                          <th scope="col" className="px-6 py-4 justify-center text-center items-center" style={{ width: '20%' }}>
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody className="border-b font-medium text-[15px] border border-[#BCBCCC]">
                        <tr>
                          <td className="whitespace-nowrap px-6 py-4" rowSpan={dataHistoryExists ? 2 : 1}>
                            <Checkbox
                              className={"custom-checkbox"}
                              onChange={(e) => {
                                handleChangeCheckWell(e.target.checked, item.well_id)
                                getSelectedWellDCA(e.target.checked ? item.well_id : null);
                              }}
                              checked={selectedWellDCA ? item.well_id === dataFilterUserDCA?.well_id : false}
                            />
                          </td>
                          <td className="whitespace-nowrap px-6 py-4 text-purple-800" >
                            {item.well_name}
                          </td>
                          <td className="whitespace-nowrap px-6 py-4" rowSpan={dataHistoryExists ? 2 : 1}>
                            {item.well_id}
                          </td>
                          <td className="whitespace-nowrap px-6 py-4" >
                            well's data 
                          </td>
                          <td className="whitespace-nowrap px-6 py-4 justify-center text-center items-center" >
                            <Tooltip title={"Update"} color={"#662D91"}>
                              <EditOutlined
                                key="editWell"
                                onClick={() => handleEdit(item.well_id)} 
                                style={{ color: '#707070' }}/>
                            </Tooltip>
                            <span className="mx-3"></span>
                            <Tooltip title={"Copy well"} color={"#662D91"}>
                              <CopyOutlined
                                key="deleteWell"
                                onClick={() => handleModalCopyWell(item.well_id, true)}
                                style={{ color: '#707070' }}
                              />
                            </Tooltip>
                            <span className="mx-3"></span>
                            <Tooltip title={"Delete"} color={"#662D91"}>
                              <DeleteOutlined
                                key="deleteWell"
                                onClick={() => handleDelete(item.well_id)}
                                style={{ color: '#707070' }}
                              />
                            </Tooltip>
                          </td>
                        </tr>
                        {dataHistoryExists && (
                          <tr>
                            <td className="whitespace-nowrap px-6 py-4 text-purple-800" >
                              Data history
                            </td>
                            <td className="whitespace-nowrap px-6 py-4" >
                              well's production history data 
                            </td>
                            <td className="whitespace-nowrap px-6 py-4 justify-center text-center items-center" >
                              <Tooltip title={"Delete"} color={"#662D91"}>
                                <DeleteOutlined
                                  key="delete"
                                  onClick={() => handleDeleteDataHistory(item.well_id)} 
                                  style={{ color: '#707070' }} />
                              </Tooltip>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </li>)
            })}
          </ul>
        }
        </div>
      </div>
    </>
  );
};