import { config,keyIntegration } from "../../config/config";
import CryptoJS from "crypto-js";
const PORT_DB = config.PORT_DB;


export const loginUser = async (user_data) => {
  const hash = CryptoJS.SHA256(keyIntegration.keyEncryptPass);
  const hashHex = hash.toString(CryptoJS.enc.Hex);
  const extendedKey = hashHex.padEnd(64, '0').substring(0, 64);
  const key = CryptoJS.enc.Hex.parse(extendedKey);
  const iv = CryptoJS.lib.WordArray.random(128 / 8);
  const encryptedPassword = CryptoJS.AES.encrypt(
    CryptoJS.enc.Utf8.parse(user_data.user_password), key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  }).toString();
  const ivString = CryptoJS.enc.Hex.stringify(iv);
  const payloadPassword = `${ivString}:${encryptedPassword}`;
    let response = await fetch(`${PORT_DB}/signin`, {
        method: "POST",
        body: JSON.stringify({ ...user_data, user_password: payloadPassword }),
        headers: { "Content-Type": "application/json" },
      });
    return response.json();
};
