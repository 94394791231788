
export const DATA_SCREENING_TOOL = {
    initialDataScreeningTool : {
        reservoir_id:"",
        reservoir_name:"",
        reservoir_type:"",
        country:"",
        state:"",
        field:"",
        selection_dashboard: []
      },
      prevDataScreeningTool : {
        reservoir_id:"123",
        reservoir_name:"reservoir2",
        reservoir_type:"oil",
        country:"sa",
        state:"state",
        field:"field",
        selection_dashboard: []
      }
}