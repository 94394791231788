import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import { Checkbox, message, Modal, Tooltip } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ErrorLoadingData } from "../../components/ErrorPage";
import { ImportFileHistory } from "../../components/PlungerLift/Complements/ImportFileHistory";
import { Loading } from "../../components/Loading";
import { Navbar } from "../../components/layout/Navbar";
import { Sidebar } from "../../components/layout/Sidebar";
import { DashboardContext } from "../../context/Dashboard/DashboardContentx";
import { useTabPlungerLift } from "../../hooks/useTabPlungerLift";
import { REQUIRED_PROPIERTIES_PL } from "../../components/elements/types/type_plunger_Lift";

const emptyDataPVT= {
  res_pressure: "",
  res_temperature: "",
  res_initial_gor: "",
  res_oil_gravity: "",
  res_gas_gravity: "",
  res_H2S: "",
  res_CO2: "",
  res_N2: "",
  option_data_point: "",
  calculate_correlation: "",
  data_point_table:[],
};

const emptyDataReservoir = {
  layer_pressure: "",
  layer_temperature: "",
  md_perf_depth: "",
  water_cut_fraction: "",
  state_reservoir: "",
  ipr_model: "",
  test_pressure_1_s: "",
  test_flow_rate_1_s: "",
  test_pressure_2_s: "",
  test_flow_rate_2_s: "",
  productivity_index_j_s: "",
  test_pressure_1_us: "",
  test_flow_rate_1_us: "",
  test_pressure_2_us: "",
  test_flow_rate_2_us: "",
  productivity_index_j_us: "",
  check_phases: false,
  pwf_ipr: false,
  straight_line: false
};

const emptyDataPlungerLift = {
  reservoir_mid_perf_tvd_depth: "",
  reservoir_temperature:"",
  reservoir_pressure:"",
  production_test_date_pp:"",
  production_liquid_rate:"",
  production_gas_rate:"",
  production_gor:"",
  production_water_cut:"",
  production_supplemental_gas_rate: "",
  twp_test_date:"",
  twp_wellhead_temperature:"",
  twp_wellhead_pressure:"",
  twp_real_time: false,
  cwp_test_date: "",
  cwp_wellhead_pressure:"",
  cwp_wellhead_temperature:"",
  cwp_real_time: false,
  plunger_depth: "",
  plunger_weight: "",
  plunger_fvg: "",
  plunger_fvl: "",
  plunger_rising_velocity: "",
  tubing_data: [],
  required_casing_pressure: "",
  supplemental_gas_rate: "",
  required_gas_liquid_ratio: "",
  required_gas_volume_per_cycle: "",
  liquid_rate: "",
  gas_rate: "",
  cycles_per_day: "",
};

export const AddPlungerLift = () => {
  const { confirm } = Modal;
  const Navigate = useNavigate();
  const { selectedWellPL, getSelectedWellPL, getDataPage, getOpenFirstModal, getDataAlertGasLift, someAlert, getSomeAlert} = useContext(DashboardContext);
  const {
    User,
    userDataInfo,
    dataFilterUserPL,
    dataCreated,
    getDataCreated,
    getDataFilterPL,
    mutationDeleteWell,
    mutationUpdateWellPlungerLift,
    mutationCreateDataHistory,
    mutationDeleteDataHistory,
    mutationUpdateDataHistory,
    errorListDataHistory,
    refetchDataWellPL,
    refetchListWellPL,
    listWellPL,
    dataWellPL
  } = useTabPlungerLift();
  const [wellboreOptionState, setWellboreOptionState] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [data, setData] = useState([]);
  const [dataInformationEndPVT, setDataInformationEndPVT] = useState();
  const [dataInformationEndReservoir, setDataInformationEndReservoir] = useState();
  const [dataInformationEndPlungerLift, setDataInformationEndPlungerLift] = useState();
  const [listWells, setListWells] = useState(null);
  const [listDataHistory, setListDataHistory] = useState([]);
  const [valuesDataHistory, setValuesDataHistory] = useState();
  
  useEffect(() => {
    getDataPage("AddPlungerLift");
    refetchListWellPL();
    if (!!selectedWellPL) {
      setSelectedItemId(selectedWellPL);
      const dataWellFind = data.find((item) => item.well_id === selectedWellPL);
      const dataFilterNew = {
        county: dataWellFind?.county,
        field: dataWellFind?.field,
        well_id: dataWellFind?.well_id,
        well_platforms: dataWellFind?.well_platforms,
        well_name: dataWellFind?.well_name,
      };
      if (data.length > 0) {
        getDataFilterPL(dataFilterNew);
      }
    }
    if (listWellPL) {
      setListWells(listWellPL?.data);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (dataWellPL) {
        if (dataWellPL.statusCode === 200075) {
          const filterDataCreate = dataWellPL.data;
          const filterDataEndPVT = Object.assign({}, emptyDataPVT);
          const filterDataEndReservoir = Object.assign({},emptyDataReservoir);
          const filterDataEndPlungerLift = Object.assign({},emptyDataPlungerLift);
          const filteredJson = Object.fromEntries(
            Object.entries(filterDataCreate).filter(([key]) => !(key in filterDataEndPVT))
          );
          const filteredJson2 = Object.fromEntries(
            Object.entries(filterDataCreate).filter(([key]) => !(key in filterDataEndReservoir))
          );
          const filteredJson3 = Object.fromEntries(
            Object.entries(filterDataCreate).filter(([key]) => !(key in filterDataEndPlungerLift))
          );
          setDataInformationEndPVT(filteredJson);
          setDataInformationEndReservoir(filteredJson2);
          setDataInformationEndPlungerLift(filteredJson3);
        }

        // if(queryDataHistory?.statusType === "SUCCESS"){
        //   setArrayDataHistory(queryDataHistory?.data?.data)
        //   const dataHistory = arrayDataHistory?.map(obj => obj['id']);
        //   setValuesDataHistory(dataHistory)
        // }
      }
    }, 1000);
  }, [dataWellPL])

  useEffect(() => {
    if (!!selectedWellPL) {
      setTimeout(() => {
        refetchDataWellPL();
      }, 1000);
    }
  }, [selectedWellPL])

  useEffect(() => {
    const intervalId = setInterval(() => {
      refetchListWellPL();
    }, 10000); // 30 segundos
    // Limpieza del intervalo cuando el componente se desmonta
    return () => clearInterval(intervalId);
  }, [refetchListWellPL]); // Dependencia del efecto

  useEffect(() => {
    setTimeout(() => {
      if (listWellPL) {
        if (listWellPL.statusType != "ERROR") {
          setData(listWellPL.data)
        }
      }
    }, 500);
  }, [listWellPL]);

  const wellboreOption = () => {
    setWellboreOptionState(!wellboreOptionState);
  };

  const handleDelete = (id) => {
    if (selectedWellPL === id) {
      showDeleteConfirm(id);

    } else {
      message.warning('Please, select the proper well')
    }
  };

  const handleClickButtonCreate = (idButton) => {
    const selectedWellIndex = data.findIndex(
      (well) => well.well_id === selectedWellPL
    );
    if (
      idButton === 0 &&
      !!userDataInfo &&
      !!userDataInfo.operator &&
      !!userDataInfo.lease &&
      !!userDataInfo.region
    ) {
      Navigate("./StepMenu-WellPlungerLift");
    } else if (idButton === 1) {
        if (data[selectedWellIndex]?.wellConfig?.pvtData && selectedWellPL) {
          message.warning(`Sorry! A fluid is already exist to this well, please edit this`);
        }
        else if (!data[selectedWellIndex]?.wellConfig?.pvtData && !!selectedWellPL) {
          Navigate("./StepMenu-FluidPlungerLift");
        } else {
          info()
        }
    } else if (idButton === 2) {
        if (data[selectedWellIndex]?.wellConfig?.reservoir) {
          message.warning(`Sorry! A reservoir is already exist to this well, please edit this`);
        } else if (!data[selectedWellIndex]?.wellConfig?.pvtData && !!selectedWellPL) {
          Navigate("./StepMenu-ReservoirPlungerLift");
        } else if (!!selectedWellPL) {
            message.warning('Please select a well with a fluid')
        } else {
          info()
        }
    } else if (idButton === 3 && !!selectedWellPL) {
      Navigate("./StepMenu-PlungerLift");
    } else {
      info();
    }
  };

  const handleUploadFile = (dataFile) => {
    const historyExists = Array.isArray(listDataHistory) && listDataHistory.some(
      (itemHistory) => itemHistory.wellid === selectedWellPL
    );
    const maxValueHistory = valuesDataHistory != undefined ? Math.max(...valuesDataHistory) + 1 : 0;
    const dataFilePre = dataFile.map((obj, index) => {
      return { id: index + (historyExists ? maxValueHistory : 1), ...obj };
    });
    const dataFileEnd = dataFilePre.map((item)=>({
      ...item, 
      ['date']: convertDate(item.date) 
    }))

    if (dataFileEnd?.length > 0 && !historyExists) {
      setTimeout(() => {
        mutationCreateDataHistory.mutate({
          operator: userDataInfo.operator,
          lease: userDataInfo.lease,
          region: userDataInfo.region,
          county: dataFilterUserPL.county,
          field: dataFilterUserPL.field,
          wellid: dataFilterUserPL.well_id,
          username: User.user_name,
          data: dataFileEnd,
        });
      }, 1000);
    }else if(dataFileEnd?.length > 0 && historyExists){
      setTimeout(() => {
        mutationUpdateDataHistory.mutate({
          operator: userDataInfo.operator,
          lease: userDataInfo.lease,
          region: userDataInfo.region,
          county: dataFilterUserPL.county,
          field: dataFilterUserPL.field,
          wellid: dataFilterUserPL.well_id,
          username: User.user_name,
          data: dataFileEnd,
        });
      }, 1000);
    }
  };

  const handleClickButtonEdit = (typeConfig, id) => {
    if (typeConfig === "wellConfig") {
      if (selectedWellPL === id) {
        Navigate("./StepMenu-EditWellPlungerLift");
      } else {
        message.warning('Please, select the proper well')
      }
    }
    if (typeConfig === "pvtData") {
      if (selectedWellPL === id) {
        Navigate("./StepMenu-EditFluidPlungerLift");
      } else {
        message.warning('Please select the well corresponding to this fluid')
      }
    }
    if  (typeConfig === "plunger_lift"){
      if (selectedWellPL === id) {
        Navigate("./StepMenu-EditPlungerLift");
      } else {
        message.warning('Please select the well corresponding to this Plunger lift')
      }
    }
    if (typeConfig === "reservoir") {
      if (selectedWellPL === id) {
        Navigate("./StepMenu-EditReservoirPlungerLift");
      } else {
        message.warning('Please select the well corresponding to this reservoir')
      }
    }
  }

  const handleClickButtonDelete = (typeConfig, well_id) => {
    if (well_id === selectedWellPL) {
      if (selectedWellPL ? selectedWellPL != null && selectedWellPL != 'null' && selectedWellPL != undefined : well_id === selectedItemId) {
        showDeleteCardConfirm(typeConfig, well_id);
      } else {
        infoDelete(typeConfig, well_id)
      }
    } else {
      message.warning(`Please select the well corresponding to this ${typeConfig === 'fluidConfig' ? 'fluid' : typeConfig === 'reservoir' ? 'reservoir' : typeConfig === 'tuning' ? 'tuning' : typeConfig === 'history' ? 'data history' : 'well'}`)
    }
  }

  const convertDate = (fechaNumerica) => {
    const fecha = new Date((fechaNumerica - (25567 + 1)) * 86400 * 1000); // Convertir la fecha numérica en milisegundos
    const mes = fecha.getMonth() + 1;
    const dia = fecha.getDate();
    const anio = fecha.getFullYear();
    return `${mes}/${dia}/${anio}`;
  };

  const showDeleteCardConfirm = (type, id) => {
    confirm({
      title: `Do you want to delete this ${type === 'fluidConfig' ? 'fluid' : type === 'reservoir' ? 'reservoir' : type === 'tuning' ? 'tuning' : type === 'history' ? 'data history' : type === 'plunger_lift' ? 'plunger lift' : 'well'}?`,
      icon: <ExclamationCircleFilled />,
      content: 'Deleted information can´t be recovered',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {

        const selectedWellIndex = data.findIndex(well => well.well_id === id);
        if (type === 'fluidConfig') {
          if (data[selectedWellIndex]?.wellConfig?.reservoir) {
            infoDelete(type, id)
          } else {
            const dataCreateFuild = Object.assign({}, emptyDataPVT, dataInformationEndPVT);
            const allPropertiesExist = REQUIRED_PROPIERTIES_PL.every(prop => prop in dataCreateFuild);
            if (allPropertiesExist) {
              setTimeout(() => {
                mutationDeleteWell.mutate({ 
                  operator: userDataInfo.operator, 
                  lease: userDataInfo.lease, 
                  region: userDataInfo.region, 
                  county: dataFilterUserPL.county, 
                  field: dataFilterUserPL.field, 
                  well: dataFilterUserPL.well_id, 
                  username: User.user_name, 
                  data: dataCreateFuild ,
                  type: "parametersplungerlift"
                })
                message.success(`Fluid delete Successfull`)
              }, 100);
              setTimeout(() => {
                refetchListWellPL();
              }, 1000);
            } else {
              message.warning('Sorry, something was wrong, please try again');
            }

          }
        }
        else if (type === 'reservoir') {
          const dataDeleteReservoir = Object.assign({}, emptyDataReservoir, dataInformationEndReservoir);
          const allPropertiesExist = REQUIRED_PROPIERTIES_PL.every(prop => prop in dataDeleteReservoir);
          if (allPropertiesExist) {
            setTimeout(() => {
              mutationUpdateWellPlungerLift.mutate({ 
                operator: userDataInfo.operator, 
                lease: userDataInfo.lease, 
                region: userDataInfo.region, 
                county: dataFilterUserPL.county, 
                field: dataFilterUserPL.field, 
                well: dataFilterUserPL.well_id, 
                username: User.user_name, 
                data: dataDeleteReservoir,
                type: "parametersplungerlift"
              })
              message.success(`Reservoir delete Successfull`)
            }, 100);
            setTimeout(() => {
              refetchListWellPL();
            }, 1000);
          } else {
            message.warning('Sorry, something was wrong, please try again');
          }
        }
        else if (type === 'plunger_lift') {
          const dataDeleteReservoir = Object.assign({}, emptyDataPlungerLift, dataInformationEndPlungerLift);
          const allPropertiesExist = REQUIRED_PROPIERTIES_PL.every(prop => prop in dataDeleteReservoir);
          if (allPropertiesExist) {
            setTimeout(() => {
              mutationUpdateWellPlungerLift.mutate({ 
                operator: userDataInfo.operator, 
                lease: userDataInfo.lease, 
                region: userDataInfo.region, 
                county: dataFilterUserPL.county, 
                field: dataFilterUserPL.field, 
                well: dataFilterUserPL.well_id, 
                username: User.user_name, 
                data: dataDeleteReservoir,
                type: "parametersplungerlift"
              })
              message.success(`Reservoir delete Successfull`)
            }, 100);
            setTimeout(() => {
              refetchListWellPL();
            }, 1000);
          } else {
            message.warning('Sorry, something was wrong, please try again');
          }
        }
        else if (type === "history") {
          setTimeout(() => {
            mutationDeleteDataHistory.mutate({
              operator: userDataInfo.operator,
              lease: userDataInfo.lease,
              region: userDataInfo.region,
              county: dataFilterUserPL.county,
              field: dataFilterUserPL.field,
              well: dataFilterUserPL.well_id,
              username: User.user_name,
              type: "parametersplungerlift"
            });
          }, 1000);
          setTimeout(() => {
            //refetchListDataHistory();
          }, 2000);
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const showDeleteConfirm = (id) => {
    confirm({
      title: "Do you want to delete this well?",
      icon: <ExclamationCircleFilled />,
      content:
        "Deleted information can´t be recovered",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        const newData = data.filter((item) => item.well_id !== id);
        setData(newData);
        // const newDataCreated = dataCreated.filter(
        //   (item) =>
        //     item.id !== id && item.well_id !== dataFilterUserPL.well_id
        // );
        // getDataCreated(newDataCreated);

        const historyExists = Array.isArray(listDataHistory) && listDataHistory.some((itemHistory) => itemHistory.wellid === id);
        if(historyExists){
          setTimeout(() => {
            mutationDeleteDataHistory.mutate({
              operator: userDataInfo.operator,
              lease: userDataInfo.lease,
              region: userDataInfo.region,
              county: dataFilterUserPL.county,
              field: dataFilterUserPL.field,
              well: dataFilterUserPL.well_id,
              username: User.user_name,
              type: "parametersplungerlift"
            });
          }, 1000);
        }

        setTimeout(() => {
          mutationDeleteWell.mutate({
            operator: userDataInfo.operator,
            lease: userDataInfo.lease,
            region: userDataInfo.region,
            county: dataFilterUserPL.county,
            field: dataFilterUserPL.field,
            well: dataFilterUserPL.well_id,
            username: User.user_name,
            type: "parametersplungerlift"
          });
        }, 1000);
        getSelectedWellPL(null)
        getDataFilterPL(null)
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const info = () => {
    const selectedWellIndex = data.findIndex(
      (well) => well.well_id === selectedWellPL
    );
    Modal.info({
      title: "Warning",
      icon: <ExclamationCircleFilled />,
      okText: "OK",
      okType: "danger",
      content: (
        <div>
          {(userDataInfo.operator === undefined || userDataInfo.lease === undefined) ? (
            <>
              <p>Please select or fill-out the information of operator, lease, and region</p>
            </>
          ) : (selectedWellPL === undefined || selectedWellPL === null) &&
            (!!userDataInfo) ? (
              <p>Please select a well first</p>
            ) : !data[selectedWellIndex]?.wellConfig?.pvtData && (
            <p>Please select a well with a fluid</p>
          )}
        </div>
      ),
      onOk() {userDataInfo.operator === undefined || userDataInfo.lease === undefined ? getOpenFirstModal(true) : getOpenFirstModal(false)},
    });
  };

  const infoDelete = (typeConfig, id) => {
    Modal.info({
      title: "Warning",
      content: (
        <div>
          {(typeConfig === 'fluidConfig' && (selectedWellPL != null || selectedWellPL != 'null' || selectedWellPL == undefined)) &&
            <>
              <p>Please delete the reservor first</p>
            </>
          }
          {(typeConfig === 'reservoir' && (selectedWellPL == null || selectedWellPL == 'null' || selectedWellPL == undefined)) &&
            <>
              <p>Please select a well to continue</p>
            </>
          }
          {(selectedWellPL != id) &&
            <>
              <p>Please select the well corresponding to this {typeConfig === 'reservoir' ? 'reservoir' : typeConfig === 'tuning' ? 'tuning' : 'fluid'}</p>
            </>
          }
        </div>
      ),
      onOk() { },
    });
  };
  
  const handleChangeCheckWell = (valueCheck, idCheck) => {
    if (valueCheck) {
      setSelectedItemId(idCheck);
    } else {
      setSelectedItemId(null);
    }

    if (valueCheck === true) {
      const dataWellFind = data.find((item) => item.well_id === idCheck);
      const dataFilterNew = {
        county: dataWellFind.county,
        field: dataWellFind.field,
        well_id: dataWellFind.well_id,
        well_platforms:  dataWellFind.well_platforms,
        well_name: dataWellFind.well_name,
      };
      getDataFilterPL(dataFilterNew);
      getSelectedWellPL(idCheck);
      // setTimeout(() => {
      //   mutationUpdateFilter.mutate({
      //     user: User.user_name,
      //     data: {...dataFilter?.data,checkedWellGaslift:idCheck}
      //   });
      // }, 1000);
    } else {
      getSelectedWellPL(null);
      getDataFilterPL(null);
      // setTimeout(() => {
      //   mutationUpdateFilter.mutate({
      //     user: User.user_name,
      //     data: {...dataFilter?.data,checkedWellGaslift:""}
         
      //   });
      // }, 1000);
    }
  }


  return (
    <div className="flex flex-col w-full h-full bg-white">
      <div className="flex">
        <Navbar wellboreOption={wellboreOption}></Navbar>
        <Sidebar className="flex abosolute h-full"></Sidebar>
      </div>
      <div className="flex flex-col w-full h-full  gap-4 pr-2 pl-[90px] p-5">
        <div className="flex flex-row gap-5 pl-[76px] pr-[76pc]">
          <div className="flex w-[157px] h-[42px] justify-center text-center items-center">
            <button
              onClick={() => handleClickButtonCreate(0)}
              className="flex w-[157px] h-[42px] justify-center text-center items-center text-[15px] text-pa-purple border border-solid border-pa-purple rounded-md hover:bg-pa-purple hover:text-pa-white1"
            >
              Add Well Confing
            </button>
          </div>
          <div className="flex w-[157px] h-[42px] justify-center text-center items-center">
            <button
              onClick={() => handleClickButtonCreate(1)}
              className="flex w-[157px] h-[42px] justify-center text-center items-center text-[15px] text-pa-purple border border-solid border-pa-purple rounded-md hover:bg-pa-purple hover:text-pa-white1"
            >
              Add PVT data
              {/* Add Fluid data */}
            </button>
          </div>
          <div className="flex w-[157px] h-[42px] justify-center text-center items-center">
            <button
              onClick={() => handleClickButtonCreate(2)}
              className="flex w-[157px] h-[42px] justify-center text-center items-center text-[15px] text-pa-purple border border-solid border-pa-purple rounded-md hover:bg-pa-purple hover:text-pa-white1"
            >
              Add Reservoir
            </button>
          </div>

          <div className="flex w-[157px] h-[42px] justify-center text-center items-center">
            <button
              onClick={() => handleClickButtonCreate(3)}
              className="flex w-[157px] h-[42px] justify-center text-center items-center text-[15px] text-pa-purple border border-solid border-pa-purple rounded-md hover:bg-pa-purple hover:text-pa-white1"
            >
              Add Plunger Lift
            </button>
          </div>
          
          <div className="flex w-[157px] h-[42px] justify-center text-center items-center">
            {/* <ImportFileHistory uploadfile={handleUploadFile}></ImportFileHistory> */}
          </div>
        </div>
        {(errorListDataHistory  && listWells === null  ) ? <ErrorLoadingData /> :

        <ul className="flex flex-col gap-y-[17px]">
          {data?.map((item, index) => {
            const historyExists = Array.isArray(listDataHistory) && listDataHistory.some(
              (itemHistory) => itemHistory.wellid === item.well_id
            );
            return (
              <li
                key={index}
                className="flex flex-row w-full  border-solid  p-5 rounded-md"
              >
                <div className="flex flex-row w-full gap-5">
                  <table className="min-w-full text-left text-sm font-light">
                    <thead className="border-b font-medium text-[15px] border border-[#BCBCCC] bg-[#FAFAFA] ">
                      <tr style={{ width: '100%' }}>
                        <th scope="col" className="px-6 py-4" style={{ width: '15%' }}>
                          Select well
                        </th>
                        <th scope="col" className="px-6 py-4" style={{ width: '20%' }}>
                          Well Name
                        </th>
                        <th scope="col" className="px-6 py-4" style={{ width: '20%' }}>
                          Well ID
                        </th> 
                        <th scope="col" className="px-6 py-4" style={{ width: '20%' }}>
                          Description
                        </th>
                        <th scope="col" className="px-6 py-4 justify-center text-center items-center" style={{ width: '20%' }}>
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody className="border-b font-medium text-[15px]  border border-[#BCBCCC]">
                      <tr>
                        <td className="whitespace-nowrap px-6 py-4" rowSpan={ historyExists || item.wellConfig.pvtData || item.wellConfig.reservoir || item.wellConfig.PlungerLift ? 6  : 1}>
                          <Checkbox
                            className={"custom-checkbox"}
                            onChange={(e) => {
                              handleChangeCheckWell(e.target.checked, item.well_id);
                              getSelectedWellPL(e.target.checked ? item.well_id : null);
                            }
                            }
                            checked={selectedWellPL ? item.well_id === dataFilterUserPL?.well_id : false}
                          ></Checkbox>
                        </td>
                        <td className="whitespace-nowrap px-6 py-4 text-purple-800" >
                          {item.well_name}
                        </td>
                        <td className="whitespace-nowrap px-6 py-4" rowSpan={ historyExists || item.wellConfig.pvtData || item.wellConfig.reservoir || item.wellConfig.PlungerLift ? 6 : 1} >
                          {item.well_id}
                        </td>
                        <td className="whitespace-nowrap px-6 py-4" >
                          well's data 
                        </td>
                        <td className="whitespace-nowrap px-6 py-4 justify-center text-center items-center" >
                          <Tooltip title={"Update"} color={"#662D91"}>
                            <EditOutlined 
                              key="edit" 
                              onClick={() => handleClickButtonEdit("wellConfig", item.well_id)} 
                              style={{ color: '#707070' }}/>
                          </Tooltip>
                          <span className="mx-6"></span>
                          <Tooltip title={"Delete the well"} color={"#662D91"}>
                            <DeleteOutlined key="deleteWell" onClick={() => handleDelete(item.well_id)} style={{ color: '#707070' }}/>
                          </Tooltip>
                        </td>
                      </tr>

                      {item.wellConfig.pvtData && (
                        <tr>
                          <td className="whitespace-nowrap px-6 py-4 text-purple-800" >
                            PVT config
                          </td>
                          <td className="whitespace-nowrap px-6 py-4" >
                            well's PVT config
                          </td>
                          <td className="whitespace-nowrap px-6 py-4 justify-center text-center items-center" >
                            <Tooltip title={"Update"} color={"#662D91"}>
                            <EditOutlined key="edit" onClick={() => handleClickButtonEdit("fluidConfig", item.well_id)} style={{ color: '#707070' }}/>
                            </Tooltip>
                            <span className="mx-6"></span>
                            <Tooltip title={"Delete"} color={"#662D91"}>
                            <DeleteOutlined key="delete" onClick={() => handleClickButtonDelete("fluidConfig", item.well_id)} style={{ color: '#707070' }}/>
                            </Tooltip>
                          </td>
                        </tr>
                      )}

                      {item.wellConfig.reservoir && (
                        <tr>
                          <td className="whitespace-nowrap px-6 py-4 text-purple-800" >
                            Reservoir
                          </td>
                          <td className="whitespace-nowrap px-6 py-4" >
                            well's reservoir data  
                          </td>
                          <td className="whitespace-nowrap px-6 py-4 justify-center text-center items-center" >
                            <Tooltip title={"Update"} color={"#662D91"}>
                            <EditOutlined key="edit" onClick={() => handleClickButtonEdit("reservoir", item.well_id)} style={{ color: '#707070' }}/>
                            </Tooltip>
                            <span className="mx-6"></span>
                            <Tooltip title={"Delete"} color={"#662D91"}>
                            <DeleteOutlined key="delete"  onClick={() => handleClickButtonDelete("reservoir", item.well_id)} style={{ color: '#707070' }}/>
                            </Tooltip>
                          </td>
                        </tr>
                      )}
                      {item.wellConfig.plungerLift && (
                        <tr>
                          <td className="whitespace-nowrap px-6 py-4 text-purple-800" >
                            Plunger Lift
                          </td>
                          <td className="whitespace-nowrap px-6 py-4" >
                            well's Plunger Lift data  
                          </td>
                          <td className="whitespace-nowrap px-6 py-4 justify-center text-center items-center" >
                            <Tooltip title={"Update"} color={"#662D91"}>
                            <EditOutlined key="edit" onClick={() => handleClickButtonEdit("plunger_lift", item.well_id)} style={{ color: '#707070' }}/>
                            </Tooltip>
                            <span className="mx-6"></span>
                            <Tooltip title={"Delete"} color={"#662D91"}>
                            <DeleteOutlined key="delete"  onClick={() => handleClickButtonDelete("plunger_lift", item.well_id)} style={{ color: '#707070' }}/>
                            </Tooltip>
                          </td>
                        </tr>
                      )}
                      {historyExists && (
                        <tr>
                          <td className="whitespace-nowrap px-6 py-4 text-purple-800" >
                            Data history
                          </td>
                          <td className="whitespace-nowrap px-6 py-4" >
                            well's production history data 
                          </td>
                          <td className="whitespace-nowrap px-6 py-4 justify-center text-center items-center" >
                            <Tooltip title={"Delete"} color={"#662D91"}>
                              <DeleteOutlined key="delete" onClick={() => handleClickButtonDelete("history", item.well_id)} style={{ color: '#707070' }}/>
                            </Tooltip>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </li>
            );
          })}
        </ul>
        }
      </div>
    </div>
  );
};