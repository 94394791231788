import React, { useContext, useEffect, useState } from "react";
import { useTapMap } from "../../hooks/useTabMap";
import { DashboardContext } from "../../context/Dashboard/DashboardContentx";
import { LayersControl, MapContainer,  TileLayer } from 'react-leaflet'
import L from 'leaflet';
import "leaflet-easybutton/src/easy-button.js";
import "leaflet-easybutton/src/easy-button.css";
import 'leaflet/dist/leaflet.css'
import { Markers} from './Markers'
import { Button, Modal } from 'antd'
import { ExclamationCircleFilled } from "@ant-design/icons";
import { LoadingMap } from "../Loading";
import { useTabPlungerLift } from "../../hooks/useTabPlungerLift";

const { BaseLayer } = LayersControl;



export const MapView = () => {
  const [data, setData] = useState([]);
  const { selectedWell, getSelectedWell } = useContext(DashboardContext);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [map, setMap] = useState(null);
  const [position, setPosition] = useState(null);
  const {
    dataListDCA,
    refetchListDCA,
    listWellsGasLift,
    refetchListWellsGasLift,
    dataFilterUserDCA,
    dataFilterUserGasLift,
    setViewLoading,
    loadDataADDWellDCA,
    loadListWellsGasLift
  } = useTapMap();

  const {dataFilterUserPL, getDataFilterPL, listWellPL, loadListWellPL, refetchListWellPL, refetchDataWellPL} = useTabPlungerLift();
  
  useEffect(() => {
    refetchListDCA();
    refetchListWellsGasLift();
    refetchListWellPL();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      refetchListDCA();
      refetchListWellsGasLift();
      refetchListWellPL();
    }, 10000); // 10 segundos
  
    // Limpieza del intervalo cuando el componente se desmonta
    return () => clearInterval(intervalId);
  }, [refetchListDCA, refetchListWellsGasLift, refetchListWellPL ]);

  useEffect(() => {
    setTimeout(() => {
      if(dataListDCA && listWellsGasLift && listWellPL ){
        if(dataListDCA.statusType != "ERROR" && listWellsGasLift.statusType != "ERROR"){
          const combinedData = [...dataListDCA.data, ...listWellsGasLift.data, ...listWellPL.data];
          setData(combinedData)
        } else if (
          dataListDCA.statusType != "ERROR"
        ){
          setData(dataListDCA.data)
        } else if (
          listWellsGasLift.statusType != "ERROR"
        ){
          setData(listWellsGasLift.data)
        } else if (
          listWellPL.statusType != "ERROR"
        ){
          setData(listWellPL.data)
        }
      }
    }, 1000);
  }, [dataListDCA, listWellsGasLift, listWellPL]);


  const info = () => {
    Modal.info({
      title: "Warning",
      icon: <ExclamationCircleFilled />,
      okText: "OK",
      okType: "danger",
      content: (
        <div>
          {(dataListDCA.statusType === "ERROR" || listWellsGasLift.statusType === "ERROR" || listWellPL.statusType === "ERROR" ) ?
            (<p>Please create a well first</p>) :  ""}
        </div>
      ),
      onOk() {},
    });
    
  };

  useEffect(() => {
    if (!map) return;

    var helloPopup = L.popup().setContent('Hello World!');
 
    L.easyButton('fa-globe', function(btn, map){
        helloPopup.setLatLng(map.getCenter()).openOn(map);
    }).addTo(map);

  }, [map]);
  const coordinates = [29.1347, -90.4511]
  const coordinates2 = [32.4042, -83.1138]
  const coordinates3 = [37.4527, -122.2615]
  const coordinates4 = [47.5513, -104.3622]

  function convertirCoordenada(coordenada) {
    const partes = coordenada.split(/[°'"\s]+/);
  
    // Extraer grados, minutos y segundos como números
    const grados = parseInt(partes[0]);
    const minutos = parseInt(partes[1]);
    const segundos = parseFloat(partes[2]);
    
    // Calcular la coordenada en decimal
    let decimal = grados + (minutos / 60) + (segundos / 3600);
    
    // Ajustar la dirección (Norte, Sur, Este, Oeste)
    if (partes[3] === 'S' || partes[3] === 'W') {
      decimal *= -1; // Si es Sur o Oeste, multiplicar por -1 para obtener un valor negativo
    }
    
    return decimal;
  }

  if(loadDataADDWellDCA || loadListWellsGasLift || loadListWellPL) {
    return (<LoadingMap />)
  }
  
  return (
    <MapContainer  center={coordinates} zoom={3.4} whenCreated={setMap}>
      <TileLayer 
        url='https://tile.openstreetmap.org/{z}/{x}/{y}.png'
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      {data?.map((item) => {
        let latitude = item.latitude;
        let longitude = item.longitude;
        if (latitude && longitude) {
          latitude = convertirCoordenada(latitude);
          longitude = convertirCoordenada(longitude);
        }

        let markerColor = "grey";
        
        switch (item.type_process) {
          case 'Forecasting':
            if (dataFilterUserDCA?.well_id === item?.well_id){
              markerColor = 'blue';
            } else {
              markerColor = 'grey';
            }
            break;
          case 'Gas Lift':
            if (dataFilterUserGasLift?.well_id === item?.well_id) { 
              markerColor = 'red';
            } else {
              markerColor = 'grey';
            }
            break;
            case 'Plunger Lift':
              if (dataFilterUserPL?.well_id === item?.well_id) {
                markerColor = 'green';
              } else {
                markerColor = 'grey';
              }
              break;
          default:
            break;
        }
        return (
          <Markers
            key={`${item.well_id}-${item.type_process}`}
            coordinates={[latitude, longitude]}
            item={item}
            markerColor={[markerColor]}
          />
        );
        
      })}
    </MapContainer>
  )
}
