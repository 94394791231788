import React, { useContext, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { message } from "antd";
import { postTuningAdd, getEspecificTuning, putTuningUpdate, deleteTuning, postTuningCalculateVLP, putTuningCalculateVLP, getTuningCalculateVLP, getUnTunedCalculateVLP, getPressDrowDown } from "../services/api/tuningAPI";
import { getParametersGasLift } from "../services/api/gasLiftAPI";
import { DashboardContext } from "../context/Dashboard/DashboardContentx";
import { AuthContext } from "../auth/context/AuthContext";
import { DataGasLiftContext } from "../context/DataGasLift/DataGasLiftContext";
import { useTabGaslift } from "./useTabGaslift";
export const useTabTuning = () => {
  const [enabledDataTuning, setEnabledDataTuning] = useState(false);
  const [enabledDataWell, setEnabledDataWell] = useState(false);
  const { User } = useContext(AuthContext);
  const { userDataInfo, getSelectedWell, selectedWell, dataPage} = useContext(DashboardContext);
  const { dataFilterUserGasLift } = useContext(DataGasLiftContext);
  const { refetchDataTuning } = useTabGaslift()
  const [enableCalculatedMse, setEnableCalculatedMse] = useState(false)
  const {
    data: queryDataWell,
    isLoading: loadDataWell,
    isError: errorDataWell,
    refetch: refetchWell,
  } = useQuery({
    queryKey: ["WellData"],
    retry: 1,
    enabled: enabledDataWell,
    queryFn: () =>
      getParametersGasLift(
        userDataInfo.operator,
        userDataInfo.lease,
        userDataInfo.region,
        dataFilterUserGasLift.county,
        dataFilterUserGasLift.field,
        dataFilterUserGasLift.well_id,
        User.user_name
      ),
    onSuccess: (data) => {
      setEnabledDataWell(false);
    },
    onError: () => {
      setEnabledDataWell(false);
    },
  });

  const {
    data: queryDataTuning,
    isLoading: loadDataTuning,
    isError: errorDataTuning,
    refetch: refetchTuning,
  } = useQuery({
    queryKey: ["TuningData"],
    retry: 1,
    enabled: enabledDataTuning,
    queryFn: () =>
      getEspecificTuning(
        userDataInfo.operator,
        userDataInfo.lease,
        userDataInfo.region,
        dataFilterUserGasLift.county,
        dataFilterUserGasLift.field,
        dataFilterUserGasLift.well_id,
        User.user_name
      ),
    onSuccess: (data) => {
      setEnabledDataTuning(false);
    },
    onError: () => {
      setEnabledDataTuning(false);
    },
  });

  const mutationCreateDataTuning = useMutation({
    mutationFn: postTuningAdd,
    onSuccess: () => {
      message.success(`Data Done Successfull`);
      refetchDataTuning();
    },
    onError: (error) => {
      console.log(error);
      message.error(
        `An error has occurred at the time of create`
      );
    },
  });

  const mutationUpdateDataTuning = useMutation({
    mutationFn: putTuningUpdate,
    onSuccess: () => {
      message.success(`Data Update Successfull`);
      refetchDataTuning()
    },
    onError: (error) => {
      console.log(error);
      message.error(
        `An error has occurred at the time of update`
      );
    },
  });
  
  const mutationDeleteDataTuning = useMutation({
    mutationFn: deleteTuning,
    onSuccess: () => {
      message.success(`Data Tuning Delete Successfull`);
    },
    onError: (error) => {
      console.log(error);
      message.error(
        `An error has occurred at the time of delete`
      );
    },
  });  

  const emptyDataTuning = useMutation({
    mutationFn: putTuningUpdate,
    onSuccess: () => {
      message.success(`Data Validate Successfull`);
    },
    onError: (error) => {
      console.log(error);
      message.error(
        `An error has occurred when update the data tuning`
      );
    },
  });  

  const mutationCreateCalTuningVLP = useMutation({
    mutationFn: postTuningCalculateVLP,
    onSuccess: () => {
      message.success(`Data Done Successfull`);
    },
    onError: (error) => {
      message.error(
        `An error has occurred when done the Data for calculate VLP`
      );
    },
  });

  const mutationUpdateCalTuningVLP = useMutation({
    mutationFn: putTuningCalculateVLP,
    onSuccess: () => {
      message.success(`Data Update Successfull`);
    },
    onError: (error) => {
      message.error( `An error has occurred when update the Data for calculate VLP` );
    },
  });

  const { data: queryCalculateTuningVLP, isLoading: loadDataCalculateTuningVLP, isError: errorDataCalculateTuningVLP, refetch: refetchCalculateTuningVLP} = useQuery({
    queryKey: ["TuningCalculateVLP"],
    retry: 1,
    enabled:false,
    queryFn: () =>
      getTuningCalculateVLP(
        userDataInfo.operator,
        userDataInfo.lease,
        userDataInfo.region,
        dataFilterUserGasLift.county,
        dataFilterUserGasLift.field,
        dataFilterUserGasLift.well_id,
        User.user_name
      ),
      onSuccess: (data) => {
        //Crear la variable de estado para setear el calculate pressure
          setEnableCalculatedMse(true)
          message.success(`Data query Successfull`);
      },
      onError: (error) => {
      },
  });

  const { data: queryCalculateUntunedVLP, isLoading: loadDataCalculateUntunedVLP, isError: errorDataCalculateUntunedVLP, refetch: refetchCalculateUntunedVLP} = useQuery({
    queryKey: ["UntunedCalculateVLP"],
    retry: 1,
    enabled:false,
    queryFn: () =>
    getUnTunedCalculateVLP(
        userDataInfo.operator,
        userDataInfo.lease,
        userDataInfo.region,
        dataFilterUserGasLift.county,
        dataFilterUserGasLift.field,
        dataFilterUserGasLift.well_id,
        User.user_name
      ),
      onSuccess: (data) => {
        //Crear la variable de estado para setear el calculate pressure
        setEnableCalculatedMse(true)
        message.success(`Data Processed Successfull`);
      },
      onError: () => {
      }
  });

  const { data: queryPressDrowDown, isLoading: loadDataPressDrowDown, isError: errorDataPressDrowDown, refetch: refetchPressDrowDown} = useQuery({
    queryKey: ["DrowDown"],
    retry: 1,
    enabled:false,
    queryFn: () =>
      getPressDrowDown(
        userDataInfo.operator,
        userDataInfo.lease,
        userDataInfo.region,
        dataFilterUserGasLift.county,
        dataFilterUserGasLift.field,
        dataFilterUserGasLift.well_id,
        User.user_name
      ),
      onSuccess: (data) => {
        //Crear la variable de estado para setear el calculate pressure
        message.success(`Data Processed Successfull`);
      },
      onError: () => {
      }
  });

  const [maxDepth, setMaxDepth] = useState(0);
  const [minDepth, setMinDepth] = useState(0);
  const [maxPressure, setMaxPressure] = useState(0);
  const [minPressure, setMinPressure] = useState(0);

  const calculateRangesGraphVLP = (axisXGraph1,axisYGraph1,axisXGraph2,axisYGraph2) => {
    
    if (!!axisYGraph1 && !!axisXGraph2  && !!axisXGraph2 && !!axisYGraph2) {
      const maxD = Math.max(...axisYGraph1,...axisYGraph2);
      const roundedMaxD = Math.ceil(maxD);
      const maxP = Math.max(...axisXGraph1,...axisXGraph2);
      const roundedMaxP = Math.ceil(maxP / 100) * 100;
      
      const minD = Math.min(...axisYGraph1,...axisYGraph2);
      const roundedMinD = Math.floor(minD);
      const minP = Math.min(...axisXGraph1,...axisXGraph2);
      const roundedMinP = Math.floor(minP / 100) * 100;

      setMaxDepth(roundedMaxD,roundedMinP);
      setMaxPressure(roundedMaxP);
      setMinDepth(roundedMinD);
      setMinPressure(0);

    }else{
      setMaxDepth(0);
      setMinDepth(0);
      setMaxPressure(0);
      setMinPressure(0);
    }
  }
  
  return {
    maxDepth,
    minDepth,
    maxPressure,
    minPressure,
    calculateRangesGraphVLP,
    refetchTuning,
    setEnabledDataTuning,
    mutationUpdateDataTuning,
    mutationDeleteDataTuning,
    loadDataTuning,
    errorDataTuning,
    getSelectedWell, 
    selectedWell,
    dataFilterUserGasLift,
    queryDataTuning,
    queryCalculateTuningVLP,
    queryCalculateUntunedVLP,
    queryDataWell,
    emptyDataTuning,
    queryPressDrowDown,
    refetchWell,
    refetchCalculateTuningVLP,
    refetchCalculateUntunedVLP,
    refetchPressDrowDown,
    mutationCreateCalTuningVLP,
    mutationUpdateCalTuningVLP,
    mutationCreateDataTuning,
    enableCalculatedMse,
    setEnableCalculatedMse
  };
};
