import { serviceEndPoints } from "../../config/config";

const DB_URL_LIST= serviceEndPoints.MAP.DB_URL_LIST_MAP;

export const getListDCA = async (operator,lease,region,userName) => {
    try {
      const response = await fetch(`${DB_URL_LIST}/parameterslist/${operator}/${lease}/${region}/${userName}/parametersdca`, {
      method: "GET",
      headers: {
        "Authorization": "Token TroT6HpzeXw78LnkgPrPO772lSBAYELfpFWzpC1S3"},
      });
      return response.json();
    } catch (error) {
      throw new Error("Problem getting the well")
    }
  };

  export const getListGasLift = async (operator,lease,region,username) => {
    try {
      const response = await fetch(`${DB_URL_LIST}/parameterslist/${operator}/${lease}/${region}/${username}/parametersgaslift`, {
        method: "GET",
        headers: { 
          "Authorization": "Token TroT6HpzeXw78LnkgPrPO772lSBAYELfpFWzpC1S3"}
      });
      return response.json();
    } catch (error) {
      throw new Error("Problem getting users");
    }
  };
