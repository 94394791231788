import React, { PureComponent, useState, useEffect, useContext } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { DashboardContext } from "../../../context/Dashboard/DashboardContentx";
import { DataGasLiftContext } from "../../../context/DataGasLift/DataGasLiftContext";

const ChartWellCond = (props) => {
  const { dataFilterUserGasLift } = useContext(DataGasLiftContext);
  const { deviceRealTime } = useContext(DashboardContext);
  const { dataHistory, dataGrapIOT, dataDomainGraph } = props;
  
  const pressInjectionMax = dataDomainGraph.injpress_max_wic;
  const pressInjectionMin = dataDomainGraph.injpress_min_wic;
  const gasInjectionMax = dataDomainGraph.injrate_max_wic;
  const gasInjectioMin = dataDomainGraph.injrate_min_wic;

  const [yAxisDomain2, setYAxisDomain2] = useState([pressInjectionMin, pressInjectionMax]);
  const [yAxisDomain1, setYAxisDomain1] = useState([gasInjectioMin, gasInjectionMax]);
  const [dataFinalGraph, setDataFinalGraph] = useState([])

  useEffect(() => {
    setYAxisDomain2([pressInjectionMin, pressInjectionMax]);
    setYAxisDomain1([gasInjectioMin, gasInjectionMax]);
  }, [pressInjectionMax, gasInjectionMax, pressInjectionMin, gasInjectioMin])
  
  useEffect(() => {
    const formattedData = dataGrapIOT?.map((item, index) => ({
      date: String(item["Date (MM/DD/YYYY)"]).split(" ")[0],
      //date_hour: String(item["Date (MM/DD/YYYY)"]),
      "Static Pressure": parseFloat(item["Static Pressure"]).toFixed(2),
      "Flowing Temperature": parseFloat(item["Flowing Temperature"]).toFixed(2)
    }));
    
    if(!!dataHistory && !!formattedData){
  
      const verifyValues = formattedData.filter(item => item.injection_gas !== undefined && item.injection_pressure !== undefined)
  
      const dataConcatGraph = dataHistory?.concat(verifyValues.length === 0 ? [] : formattedData);
  
      setDataFinalGraph(dataConcatGraph.filter(item => item.injection_gas !== undefined && item.injection_pressure !== undefined));
  
    }else if(!!dataHistory && !formattedData){
      
      setDataFinalGraph(dataHistory);
  
    }else{
      setDataFinalGraph(formattedData?.filter(item => item.injection_gas !== undefined && item.injection_pressure !== undefined));
    }
  }, [dataHistory, dataGrapIOT])


  const CustomDot = ({ cx, cy, payload, index, color }) => {
    // Solo muestra el punto si el índice es un múltiplo de 5000
    if ((index + 1) % 1 === 0) {
      return (
        <circle cx={cx} cy={cy} r={2} stroke={color} fill={color} />
      );
    }
    return null; // No muestra el punto
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={500}
        height={300}
        data={dataFinalGraph}
        margin={{
          top: 0,
          right: 30,
          left: 20,
          bottom: 20,
        }}
      >
        <CartesianGrid />
        <XAxis
          dataKey="date"
          label={{
            value: `Date`,
            style: { textAnchor: "middle" },
            //key: "xAxisLabel",
            position: "bottom",
          }}
          tickCount={10}
        />
        <YAxis
          yAxisId="left"
          domain={yAxisDomain1}
          allowDataOverflow={true}
          label={{
            value: `Injection Gas Rate (MSCF/D)`,
            style: { textAnchor: "middle" },
            angle: -90,
            position: "left",
            offset: 0,
          }}
          padding={{ bottom: 10 }}
          
        />
        <YAxis
          yAxisId="right"
          orientation="right"
          allowDataOverflow={true}
          domain={yAxisDomain2}
          label={{
            value: `Injection Pressure (psig)`,
            style: { textAnchor: "middle" },
            angle: -90,
            position: "left",
            offset: -60,
          }}
          padding={{ bottom: 10 }}
        />
        <Tooltip />
        {/* <Legend align="center" verticalAlign="top" /> */}
        <Line
          yAxisId="left"
          type="monotone"
          dataKey="injection_gas"
          name="Injection Gas Rate"
          stroke="#16a34a"
          dot={<CustomDot color="#16a34a"/>}
          activeDot={{ r: 8 }}
        />
        <Line
          yAxisId="right"
          name="Injection Pressure"
          type="monotone"
          dataKey="injection_pressure"
          stroke="#dc2626"
          dot={<CustomDot color="#dc2626"/>}
          activeDot={{ r: 8 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};
export default ChartWellCond;
