import React, { PureComponent, useState, useEffect, useContext } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { DashboardContext } from "../../../context/Dashboard/DashboardContentx";
import { DataGasLiftContext } from "../../../context/DataGasLift/DataGasLiftContext";

const ChartWellCond = (props) => {
  const { dataFilterUserGasLift } = useContext(DataGasLiftContext);
  const { deviceRealTime } = useContext(DashboardContext);
  const [dataIOT, setDataIOT] = useState();
  const { dataHistory, dataGrapIOT, dataDomainGraph, statusGraph, typeDeviceWIC, dataAverage, wellId} = props;
  const averageData = !!dataAverage ? dataAverage : null
  const pressInjectionMax = dataDomainGraph.injpress_max_wic;
  const pressInjectionMin = dataDomainGraph.injpress_min_wic;
  const gasInjectionMax = dataDomainGraph.injrate_max_wic;
  const gasInjectioMin = dataDomainGraph.injrate_min_wic;
  
  const [yAxisDomain2, setYAxisDomain2] = useState([pressInjectionMin, pressInjectionMax]);
  const [yAxisDomain1, setYAxisDomain1] = useState([gasInjectioMin, gasInjectionMax]);
  const [dataFinalGraph, setDataFinalGraph] = useState([])

  useEffect(() => {
    if(!!dataGrapIOT){
      
      const dataIOT = dataGrapIOT?.filter((item) => {
        return item["LSD"] === typeDeviceWIC &&
          item["Static Pressure"] !== -999.25 &&
          item["Flowing Temperature"] !== -999.25 &&
          item["Corrected Flow Rate"] !== -999.25 &&
          item["Liquid Flow Rate"] !== -999.25;
    });
      setDataIOT(dataIOT);
    }
  }, [dataGrapIOT, typeDeviceWIC]);
  
  useEffect(() => {
    setYAxisDomain2([pressInjectionMin, pressInjectionMax]);
    setYAxisDomain1([gasInjectioMin, gasInjectionMax]);
  }, [pressInjectionMax, gasInjectionMax, pressInjectionMin, gasInjectioMin])
  
  useEffect(() => {
    const formattedData = dataIOT?.map((item, index) => ({
      date: String(item["Date (MM/DD/YYYY)"]).split(" ")[0],
      "Injection Pressure": parseFloat(item[deviceRealTime?.device_gaslift?.discretized ? dataFilterUserGasLift.well_name + ' Well Head Pressure': 'Static Pressure' ]),
      'Injection Gas Rate': parseFloat(item["Flowing Temperature"])?.toFixed(2)
    }));

    if(!!dataHistory && statusGraph === false){
      const dataHistoryMod = dataHistory?.map((item, index) => ({
        
        
        "date": String(item["date"])?.split(" ")[0],
        "Injection Pressure": item["injection_pressure"]?.toFixed(2),
        'Injection Gas Rate': item["injection_gas"]?.toFixed(2),
      }));
      
      /* let filteredData = null;
      
      // Iterar sobre las claves
      Object.keys(averageData).forEach(key => {
        if (key === wellId) {
          // Extraer la fecha y los datos de rates
          const dateKey = Object.keys(averageData[key])[0]; // En este caso la primera fecha
          const rateData = averageData[key][dateKey];

          // Crear el nuevo objeto
          filteredData = {
            date: dateKey,
            gas_rate: rateData.gas_rate.toFixed(2),
            oil_rate: rateData.oil_rate.toFixed(2),
            water_rate: rateData.water_rate.toFixed(2),
            liquid_rate: rateData.water_rate.toFixed(2)
          };
          }
      }); */
      // const combineddata = dataHistoryMod.concat(filteredData)
      setDataFinalGraph(dataHistoryMod);
    }else if (statusGraph === true) {
      setDataFinalGraph(formattedData);
    }
  }, [dataIOT, dataHistory, statusGraph, averageData])

  const CustomDot = ({ cx, cy, payload, index, color }) => {
    // Solo muestra el punto si el índice es un múltiplo de 5000
    if ((index + 1) % 1 === 0) {
      return (
        <circle cx={cx} cy={cy} r={2} stroke={color} fill={color} />
      );
    }
    return null; // No muestra el punto
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={500}
        height={300}
        data={dataFinalGraph}
        margin={{
          top: 0,
          right: 30,
          left: 20,
          bottom: 20,
        }}
      >
        <CartesianGrid />
        <XAxis
          dataKey="date"
          label={{
            value: `Date`,
            style: { textAnchor: "middle" },
            //key: "xAxisLabel",
            position: "bottom",
          }}
          tickCount={10}
        />
        <YAxis
          yAxisId="left"
          domain={yAxisDomain1}
          allowDataOverflow={true}
          label={{
            value: `Injection Gas Rate (MSCF/D)`,
            style: { textAnchor: "middle" },
            angle: -90,
            position: "left",
            offset: 0,
          }}
          padding={{ bottom: 10 }}
          
        />
        <YAxis
          yAxisId="right"
          orientation="right"
          allowDataOverflow={true}
          domain={yAxisDomain2}
          label={{
            value: `Injection Pressure (psig)`,
            style: { textAnchor: "middle" },
            angle: -90,
            position: "left",
            offset: -60,
          }}
          padding={{ bottom: 10 }}
        />
        <Tooltip />
        {/* <Legend align="center" verticalAlign="top" /> */}
        <Line
          yAxisId="left"
          type="monotone"
          dataKey="Injection Gas Rate"
          name="Injection Gas Rate"
          stroke="#16a34a"
          dot={<CustomDot color="#16a34a"/>}
          activeDot={{ r: 8 }}
        />
        <Line
          yAxisId="right"
          name="Injection Pressure"
          type="monotone"
          dataKey="Injection Pressure"
          stroke="#dc2626"
          dot={<CustomDot color="#dc2626"/>}
          activeDot={{ r: 8 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};
export default ChartWellCond;
