import { Checkbox } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTapMap } from "../../hooks/useTabMap";
import { DashboardContext } from "../../context/Dashboard/DashboardContentx";
import { Info } from "react-bootstrap-icons";
import { Loading } from "../Loading";
import { DashboardDCAContext } from "../../context/DasboardDCA/DashboardDCAContext"
import { useTabDCA } from "../../hooks/useTabDCA";
import { useTabGaslift } from "../../hooks/useTabGaslift";
import { useTabPlungerLift } from "../../hooks/useTabPlungerLift";

/* onChange={()=>CheckboxGroup(data.Latitude, data.Longitude)} 
sessionStorage.setItem("Latitude", Latitude);
sessionStorage.setItem("Longitude", Longitude);
*/

export const TableWell = () => {
  const [data, setData] = useState([]);
  const { selectedWell, getSelectedWell, selectedWellDCA,  getSelectedWellDCA, selectedWellPL, getSelectedWellPL } = useContext(DashboardContext);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedItemIdDCA, setSelectedItemIdDCA] = useState(null);
  const {
    userDataInfo,
    getUserDataInfo,
    dataListDCA,
    refetchListDCA,
    listWellsGasLift,
    refetchListWellsGasLift,
    getDataFilterUserDCA,
    dataFilterUserDCA,
    getDataFilterUserGaslift,
    dataFilterUserGasLift
  } = useTapMap();
  const {dataFilterUserPL, getDataFilterPL, listWellPL, refetchListWellPL, refetchDataWellPL} = useTabPlungerLift();
  const {refetchDataWellDCA} = useTabDCA();
  const {refetchDataWellGasLift} = useTabGaslift();

  useEffect(() => {
    setTimeout(() => {
      if (dataListDCA && listWellsGasLift && listWellPL ) {
        if (
          dataListDCA.statusType != "ERROR" &&
          listWellsGasLift.statusType != "ERROR" &&
          listWellPL.statusType != "ERROR"
        ) {
          const combinedData = [...dataListDCA.data, ...listWellsGasLift.data, ...listWellPL.data];
          setData(combinedData);
        } else if (
          dataListDCA.statusType != "ERROR"
        ){
          setData(dataListDCA.data)
        } else if (
          listWellsGasLift.statusType != "ERROR"
        ){
          setData(listWellsGasLift.data)
        } else if (
          listWellPL.statusType != "ERROR"
        ){
          setData(listWellPL.data)
        }
      }
    }, 1000);
  }, [dataListDCA, listWellsGasLift, listWellPL]);

  const handleChangeCheckWell = (valueCheck, idCheck, type_process) => {
    if (valueCheck === true) {
      // En esta seccion de debe llamar el api de listado de pozos y buscar el id que se toma de la variable de estado para extraer el resto de datos y setearlo de esta forma se valida que exista
      if (type_process === "Forecasting") {
        const dataListDCA = data.find((item) => item.well_id === idCheck);
        const dataFilterDCA = {
          county: dataListDCA.county,
          field: dataListDCA.field,
          well_id: dataListDCA.well_id,
          well_platforms:  dataListDCA.well_platforms,
          well_name: dataListDCA.well_name,
        };
        getDataFilterUserDCA(dataFilterDCA);
        getSelectedWellDCA(valueCheck ? dataListDCA.well_id : null)
        setSelectedItemIdDCA(idCheck);
        setTimeout(() => {
          refetchDataWellDCA();
        }, 1000);
      } else {
        setSelectedItemIdDCA(null);
      }
      if (type_process === "Gas Lift") {
        const dataListFind = data.find((item) => item.well_id === idCheck);
        const dataFilterNew = {
          county: dataListFind.county,
          field: dataListFind.field,
          well_id: dataListFind.well_id,
          well_platforms:  dataListFind.well_platforms,
          well_name: dataListFind.well_name,
        };
        getDataFilterUserGaslift(dataFilterNew);
        getSelectedWell(valueCheck ? dataListFind.well_id : null)
        setSelectedItemId(idCheck);
        setTimeout(() => {
          refetchDataWellGasLift();
        }, 1000);
      } 
      if (type_process === "Plunger Lift") {
        const dataListFind = data.find((item) => item.well_id === idCheck);
        const dataFilterNew = {
          county: dataListFind.county,
          field: dataListFind.field,
          well_id: dataListFind.well_id,
          well_platforms:  dataListFind.well_platforms,
          well_name: dataListFind.well_name,
        };
        getDataFilterPL(dataFilterNew);
        getSelectedWellPL(valueCheck ? dataListFind.well_id : null)
        setSelectedItemId(idCheck);
        setTimeout(() => {
          refetchDataWellPL();
        }, 1000);
      } else {
        setSelectedItemId(null);
      }
      
    } 
  };

  const getData = data.map((item, index) => (
    <tr
      key={`${item.well_id}-${item.type_process}-${index}`}
      className="border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600"
    >
      <td className="whitespace-nowrap px-6 py-4 ">
        <Checkbox
          onChange={(e) => {
            handleChangeCheckWell(e.target.checked, item.well_id, item.type_process);
          }}
          checked={(item.type_process === "Forecasting" && item.well_id === dataFilterUserDCA?.well_id ) || (item.type_process === "Gas Lift" && item.well_id === dataFilterUserGasLift?.well_id ) || (item.type_process === "Plunger Lift" && item.well_id === dataFilterUserPL?.well_id ) }
        ></Checkbox>
      </td>
      <td className="whitespace-nowrap px-6 py-4">{item.type_process}</td>
      <td className="whitespace-nowrap px-6 py-4">{item.well_name}</td>
      <td className="whitespace-nowrap px-6 py-4">{item.fluid_type}</td>
      <td className="whitespace-nowrap px-6 py-4">{item.region}</td>
      <td className="whitespace-nowrap px-6 py-4">{item.county}</td>
      <td className="whitespace-nowrap px-6 py-4">{item.field}</td>
      <td className="whitespace-nowrap px-6 py-4">{item.latitude}</td>
      <td className="whitespace-nowrap px-6 py-4">{item.longitude}</td>
    </tr>
  ));
  
  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
          <div className="overflow-hidden">
            <table className="min-w-full text-left text-sm font-light">
              <thead className="border-b font-medium border-pc-purple2 bg-pc-grey2 text-white">
                <tr>
                  <th scope="col" className="px-6 py-4">
                    Select well
                  </th>
                  <th scope="col" className="px-6 py-4">
                    Type Process
                  </th>
                  <th scope="col" className="px-6 py-4">
                    Well name
                  </th>
                  <th scope="col" className="px-6 py-4">
                    Fluid type
                  </th>
                  <th scope="col" className="px-6 py-4">
                    Region
                  </th>
                  <th scope="col" className="px-6 py-4">
                    County
                  </th>
                  <th scope="col" className="px-6 py-4">
                    Filed
                  </th>
                  <th scope="col" className="px-6 py-4">
                    Latitude
                  </th>
                  <th scope="col" className="px-6 py-4">
                    Longitude
                  </th>
                </tr>
              </thead>
              <tbody>{getData}</tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
