import React from 'react'
import { DashboardContext } from './DashboardContentx'
import { useDashboard}  from '../../hooks/useDashboard'

export const DashboardProvider = ({children}) => {
const {
  dataPage,
  optionsNavbar,
  userDataInfo,
  selectedWell,
  selectedWellDCA,
  selectedReservoirSCT,
  dataDomainGraph,
  dataAlertGasLift,
  alertDCAData,
  someAlert,
  checkTypeGraph,
  deviceRealTime,
  selectVLP,
  getDataPage,
  getOptionsNavbar,
  getUserDataInfo,
  getSelectedWell,
  getSelectedWellDCA,
  getSelectedReservoirSCT,
  getDataDomainGraph,
  getDataAlertGasLift,
  getDataAlertDCA,
  getSomeAlert,
  dataGas,
  dataOil,
  openFirstModal,
  getOpenFirstModal,
  getCheckTypeGraph,
  getDeviceRealTime,
  getSelectIntervalVLP
} = useDashboard()
  return (
    <DashboardContext.Provider value={{    
      dataPage,
      optionsNavbar,
      userDataInfo,
      selectedWell,
      selectedWellDCA,
      selectedReservoirSCT,
      dataDomainGraph,
      dataAlertGasLift,
      alertDCAData,
      someAlert,
      checkTypeGraph,
      deviceRealTime,
      selectVLP,
      getDataPage,
      getOptionsNavbar,
      getUserDataInfo,
      getSelectedWell,
      getSelectedWellDCA,
      getSelectedReservoirSCT,
      getDataDomainGraph,
      getDataAlertGasLift,
      getDataAlertDCA,
      getSomeAlert,
      dataGas,
      dataOil,
      openFirstModal,
      getOpenFirstModal,
      getCheckTypeGraph,
      getDeviceRealTime,
      getSelectIntervalVLP
    }}>
        {children}
    </DashboardContext.Provider>
  )
}
