import React, { useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { DashboardContext } from '../context/Dashboard/DashboardContentx';
import { getJsonPlottingIOT, getListDevicesPlotting, getListPlatformsPlotting } from '../services/api/plotting';
import { INITIAL_DATA_PLOTTING } from '../components/elements/types/type_plotting';
import { DashboardPlottingContext } from '../context/DashboardPlotting/DashboardPlottingContext';

export const useTabPlotting = () => {
    const { userDataInfo } = useContext(DashboardContext);
    const { dataPlotting, getOptionsParmsPlotting, getDataPlotting} = useContext(DashboardPlottingContext);
    const [concatenatedData, setConcatenatedData] = useState([]);
    const [inputDataPlot, setInputDataPlot] = useState(INITIAL_DATA_PLOTTING);
    const [optionsAllIOT, setOptionsAllIOT] = useState([]);

    const { data: dataListPlatforms, isLoading: loadDataListPlatforms, isError: ErrorDataListPlatforms, refetch: refetchDataListPlatforms,} = useQuery({
        queryKey: ['dataListPlatforms'],
        queryFn: () => getListPlatformsPlotting(userDataInfo.operator, userDataInfo.lease, userDataInfo.region),
        retry: 1,
        enabled: false,
        onSuccess: (data) => {
        },
        onError: (error) => {
        },
    })
    const { data: dataListDevices, isLoading: loadDataListDevices, isError: ErrorDataListDevices, refetch: refetchDataListDevices, isSuccess } = useQuery({
        queryKey: ['dataListDevices',inputDataPlot.selected_platform],
        queryFn: () => getListDevicesPlotting(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, inputDataPlot.selected_platform),
        retry: 2,
        enabled: false,
        onSuccess: (data) => {
        },
        onError: (error) => {
        },
    })
    const { data: dataPlottingIOT, isLoading: loadDataPlottingIOT, isError: ErrorDataPlottingIOT, refetch: refetchDataPlottingIOT} = useQuery({
      queryKey: ['dataIOTDCA'],
      queryFn: () => getJsonPlottingIOT(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, 'all', 'all'),
      retry: 1,
      enabled: false,
      onSuccess: (data) => {
        if(data?.data){
          const responseData = data?.data;
          const optionsSelect = Object.keys(responseData[0]).filter(key => !key.includes("Date")).map((item, index)=> ({
            label: item,
            value: item,
          }));
          setOptionsAllIOT(optionsSelect.sort((a, b) => a.label.localeCompare(b.label)));
        }
      },
      onError: (error) => {
      },
    })

  return {
    dataListPlatforms,
    refetchDataListPlatforms,
    dataListDevices,
    refetchDataListDevices,
    dataPlottingIOT,
    refetchDataPlottingIOT,
    concatenatedData,
    setConcatenatedData,
    inputDataPlot,
    setInputDataPlot,
    optionsAllIOT
  }
}