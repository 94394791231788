import React, { useState } from 'react'
import { UserContext } from './UserContext'

export const UserProvider = ({children}) => {
  const [selectUser, setSelectUser] = useState("");
  const [selectType, setSelectType] = useState("");
  const [selectTable, setSelectTable] = useState("");

  const selectedUserCrud = (user) => {
    setSelectUser(user);
  }
  const selectedTypeCrud = (type) => {
    setSelectType(type);
  }
  const selectedTableCrud = (type) => {
    setSelectTable(type);
  }
  return (
    <UserContext.Provider value={{
      selectedUserCrud,
      selectedTypeCrud,
      selectedTableCrud,
      selectUser,
      selectType,
      selectTable
    }}>
      {children}
    </UserContext.Provider>
  )
}
