import React, { useEffect, useState } from 'react'

import dataGas from '../data/dataGas.json';
import dataOil from '../data/dataOil.json';
import { DATA_ALERTS, INITIAL_TYPE_GRAPH, WELL_SENSOR_DEVICE } from '../components/elements/types/type_dashboard'
import { INITIAL_DATA_ALERTS_DCA } from '../components/elements/types/type_dca';
import { INITIAL_DATA_ALERTS_GASLIFT } from '../components/elements/types/type_gas_lift';

export const useDashboard = () => {
    const initialOptionsNavbar = {
        menuWellBore:false,
        addWellfalse:false
    }
    const initialDeviceRealTime = {
        list_device_gaslift: WELL_SENSOR_DEVICE.data,
        list_device_dca: WELL_SENSOR_DEVICE.data,
        device_dca: {platform:"",name:"",discretized:false},
        device_gaslift:{platform:"",name:"",discretized:false}
    }
    const initialUserDataInfo = {
        operator:"",
        lease:"",
        region:""
    }

    const initialHistoryData = {
        id: 0,
        date: "",
        oil_rate: null,
        water_rate: null,
        gas_rate: null,
        wellhead_pressure: null,
        wellhead_tem: null,
        injection_gas: null,
        injection_pressure: null,
    };

    const initialDomainGraph = {
    press_max_wpc: null,
    press_min_wpc: null,
    temp_max_wpc: null,
    temp_min_wpc: null,
    injrate_max_wic: null,
    injrate_min_wic: null,
    injpress_max_wic: null,
    injpress_min_wic: null,
    rate_max_ph: null,
    rate_min_ph: null,
    cum_max_ph: null,
    cum_min_ph: null,
    }

    const [optionsNavbar, setOptionsNavbar] = useState(initialOptionsNavbar);
    const [userDataInfo, setUserDataInfo] = useState(initialUserDataInfo);
    const [selectedWell, setSelectedWell] = useState(null);
    const [selectedWellDCA, setSelectedWellDCA] = useState(null);
    const [selectedReservoirSCT, setSelectedReservoirSCT] = useState(null);
    const [selectedWellPL, setSelectedWellPL] = useState(null);
    const [dataPage, setDataPage] = useState("");
    const [openFirstModal, getOpenFirstModal] = useState(false);
    const [dataDomainGraph, setDataDomainGraph] = useState(initialDomainGraph)
    const [dataAlertGasLift, setDataAlertGasLift] = useState(INITIAL_DATA_ALERTS_GASLIFT)
    const [alertDCAData, setDataAlertDCAData] = useState(INITIAL_DATA_ALERTS_DCA)
    const [someAlert, setSomeAlert] = useState(DATA_ALERTS)
    const [checkTypeGraph, setCheckTypeGraph] = useState(INITIAL_TYPE_GRAPH);
    const [deviceRealTime, setDeviceRealTime] = useState(initialDeviceRealTime);
    const [selectVLP, setSelectVLP] = useState();

    useEffect(()=>{
        const getDataPage = sessionStorage.getItem('dataPage');
        const getOptionNavbar = JSON.parse(sessionStorage.getItem('optionNavbar'));
        const getUserDataInfo = JSON.parse(sessionStorage.getItem('userDataInfo'));
        const getSelectedWell = sessionStorage.getItem('selectedWell');
        const getSelectedWellDCA = sessionStorage.getItem('selectedWellDCA');
        const getSelectedReservoirSCT = sessionStorage.getItem('selectedReservoirSCT');
        const getSelectedWellPL = sessionStorage.getItem('selectedWellPL');
        const getDataDomainGraph = JSON.parse(sessionStorage.getItem('dataDomainGraph'));
        const getDataAlertGasLift = JSON.parse(sessionStorage.getItem('dataAlertGasLift'));
        const getDataAlertDCA = JSON.parse(sessionStorage.getItem('dataAlertDCA'));
        const getSomeAlert = JSON.parse(sessionStorage.getItem('someAlert'));
        const getCheckTypeGraph = JSON.parse(sessionStorage.getItem('checkTypeGraph'));
        const getDeviceRealTime = JSON.parse(sessionStorage.getItem('deviceRealTime'));
        const getSelectIntervalVLP = JSON.parse(sessionStorage.getItem('selectIntervalVLP'));

        if (getDataPage){
            setDataPage(getDataPage);
        }
        if (getOptionNavbar) {
            setOptionsNavbar(getOptionNavbar);
        } 
        if (getUserDataInfo) {
            setUserDataInfo(getUserDataInfo);
        } 
        if (getSelectedWell) {
            setSelectedWell(getSelectedWell);
        } 
        if (getSelectedWellDCA) {
            setSelectedWellDCA(getSelectedWellDCA);
        }
        if (getSelectedReservoirSCT) {
            setSelectedReservoirSCT(getSelectedReservoirSCT);
        }
        if (getSelectedWellPL) {
            setSelectedWellPL(getSelectedWellPL);
        }
        if(getDataDomainGraph){
            setDataDomainGraph(getDataDomainGraph);
        }
        if(getDataAlertGasLift){
            setDataAlertGasLift(getDataAlertGasLift);
        }
        if(getDataAlertDCA){
            setDataAlertDCAData(getDataAlertDCA);
        }
        if(getSomeAlert){
            setSomeAlert(getSomeAlert);
        }
        if (getCheckTypeGraph) {
            setCheckTypeGraph(getCheckTypeGraph);
        }
        if (getDeviceRealTime) {
        setDeviceRealTime(getDeviceRealTime);
        }
        if (getSelectIntervalVLP) {
            setSelectVLP(getSelectIntervalVLP);
        }
    },[])

    const getDataPage = (options) => {
        setDataPage(options);
        sessionStorage.setItem('dataPage', options);
    }

    const getOptionsNavbar = (options) => {
        setOptionsNavbar(options);
        sessionStorage.setItem('optionNavbar', JSON.stringify(options));
    }

    const getUserDataInfo = (options) => {
        setUserDataInfo(options);
        sessionStorage.setItem('userDataInfo', JSON.stringify(options));
    }

    const getSelectedWell = (options) => {
        setSelectedWell(options);
        sessionStorage.setItem('selectedWell', options);
    }

    const getSelectedWellDCA = (options) => {
        setSelectedWellDCA(options);
        sessionStorage.setItem('selectedWellDCA', options);
    }

    const getSelectedReservoirSCT = (options) => {
        setSelectedReservoirSCT(options);
        sessionStorage.setItem('selectedReservoirSCT', options);
    }
    const getSelectedWellPL = (options) => {
        setSelectedWellPL(options);
        sessionStorage.setItem('selectedWellPL', options);
    }

    const getDataDomainGraph = (options) => {
        setDataDomainGraph(options);
        sessionStorage.setItem('dataDomainGraph', JSON.stringify(options))
    }

    const getDataAlertGasLift = (options) => {
        setDataAlertGasLift(options);
        sessionStorage.setItem('dataAlertGasLift', JSON.stringify(options))
    }

    const getDataAlertDCA = (options) => {
        setDataAlertDCAData(options);
        sessionStorage.setItem('dataAlertDCA', JSON.stringify(options))
    }
    const getSomeAlert = (options) => {
        setSomeAlert(options);
        sessionStorage.setItem('someAlert', JSON.stringify(options))
    }
    const getCheckTypeGraph = (options) => {
        setCheckTypeGraph(options);
        sessionStorage.setItem('checkTypeGraph', JSON.stringify(options));
    }
    const getDeviceRealTime = (dataDevice) => {
        setDeviceRealTime(dataDevice);
        sessionStorage.setItem('deviceRealTime', JSON.stringify(dataDevice));
    }

    const getSelectIntervalVLP = (options) => {
        setSelectVLP(options);
        sessionStorage.setItem('selectIntervalVLP', JSON.stringify(options));
    }

    return {
        dataPage,
        optionsNavbar,
        userDataInfo,
        selectedWell,
        selectedWellDCA,
        selectedReservoirSCT,
        selectedWellPL,
        dataDomainGraph,
        dataAlertGasLift,
        alertDCAData,
        someAlert,
        checkTypeGraph,
        deviceRealTime,
        selectVLP,
        getDataPage,
        getOptionsNavbar,
        getUserDataInfo,
        getSelectedWell,
        getSelectedWellDCA,
        getSelectedReservoirSCT,
        getSelectedWellPL,
        getDataDomainGraph,
        getDataAlertGasLift,
        getDataAlertDCA,
        getSomeAlert,
        dataGas,
        dataOil,
        openFirstModal,
        getOpenFirstModal,
        getCheckTypeGraph,
        getDeviceRealTime,
        getSelectIntervalVLP
    }
}