import React, { useEffect, useState, useContext } from 'react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import {COLOR_GRAPH} from "../../elements/types/type_flow"
import { DashboardContext } from "../../../context/Dashboard/DashboardContentx";

export const ChartInflowOutflow = ({dataTableGraphFlow, limits, uniqueQJ, dashboardMode}) => {
  
  const [countGraph, setCountGraph] =  useState(0);
  const [valueQJ, setvalueQJ] = useState([]);
  const { dataPage } = useContext(DashboardContext);

  useEffect(()=>{
    if(dataTableGraphFlow?.length > 0){

      const count = (Object.keys(dataTableGraphFlow[0]).length) - 2;
      setCountGraph(count);

      if(uniqueQJ.length > 0){
        const sortValue = ([...uniqueQJ].sort((a, b) => a - b));
        setvalueQJ(sortValue);
      }else{
        setvalueQJ([]);
      }

      // if(!uniqueQJ || uniqueQJ.length === 0){
      //   values = [];
      //   setvalueQJ(values);
      // }
      // else{
      //   values = uniqueQJ;
      //   const sortValue = ([...values].sort((a, b) => a - b));
      //   setvalueQJ(sortValue);
      // }
    }
  }, [dataTableGraphFlow, uniqueQJ]);

  function renderTooltip({ active, payload, label }) {
    if (active && payload.length) {
      const blackVal = payload[0].payload;
      const qoStep = blackVal['qo_step'];
      const countSensibility = (payload.length) - 1;

      return (
        <div 
          style={{ 
            backgroundColor: '#fff', 
            border: '1px solid #ccc', 
            padding: '10px',
            fontSize: dataPage === "DashboardGasLift" ? '12px' : '14px' 
          }}>
          <p>{`q: ${label} STB/D`}</p>
          {payload.map((entry, index) => {
            const invertValue = payload[countSensibility - (index+1)] ? payload[countSensibility - (index+1)].value : "";
            return(
            <p 
              style={{ color: entry.name === "IPR" ? "#000000" : `#${COLOR_GRAPH[index+1]}` }}
              key={`item-${index}`}>
              {`${entry.name === "IPR" ? "IPR" : "Sensibility"}  ${entry.name === "IPR" ? "" : index+1}: ${entry.name === "IPR" ? entry.value: invertValue} (psia)`}
            </p>
          )
        })}
        </div>
      );
    }
    return null;
  };

  function renderLegend({ payload }) {
    if (payload && payload.length) {

      return (
        <div>
            {Array.from({ length: limits?.number_cases_inj_flow }, (item, index) => (
                <p key={index} style={{ display: 'inline-block', marginRight: '10px', color: `#${COLOR_GRAPH[index+1]}` }}>Sensibility {index+1} - Inj. Gas Rate = {valueQJ[index]} MSCF/D</p>
            ))
            }
            <p style={{ display: 'inline-block', marginRight: '10px', color: "#000000" }}>IPR</p>
        </div>
      );
    }
    return null;
}

  const calculateDecimal = (value, decimal) => {
    if(decimal === 1){
      const fixedValue = Number((value).toFixed(decimal));
      return fixedValue;
    }
    return null;
  };

  const dataTableGraphFixed = dataTableGraphFlow.map((element, index) => {
    const fixedElement = {};
    for (let key in element) {
      if (typeof element[key] === 'number') {
        fixedElement[key] = calculateDecimal(element[key], 1);
      } else {
        fixedElement[key] = element[key];
      }
    }
    return fixedElement;
  });

  return (
    <div className='flex w-full h-full'>
      <ResponsiveContainer width="100%" height="98%">
        <LineChart
        width={500}
        height={300}
        data={dataTableGraphFixed}
        margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 45,
        }}
        >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis 
          dataKey="qo_step" 
          interval={0}
          label={{
            value: `Production Rate (STB/day)`,
            style: { textAnchor: "middle" },
            angle: 0,
            position: "bottom",
            offset: 10,
            fontSize: 14,
          }}
          allowDataOverflow={true}
          //show only values integers
          tickFormatter={(tick) => tick.toFixed(1)}
          tick={{ fontSize: 13}}
        />
        <YAxis 
          domain={[limits.minChartFlow,limits.maxChartFlow]}
          allowDataOverflow={true}
          tickCount={10}
          label={{
            value: `Pressure (psia) at Mid-perf Depth, TVD`,
            style: { textAnchor: "middle" },
            angle: -90,
            position: "left",
            offset: 0,
          }}
          tickFormatter={(tick) => Math.floor(tick)}
          tick={{ fontSize: 13}}
        />

        <Tooltip content={renderTooltip} />
        {dataPage != "DashboardGasLift" && (
          <Legend  verticalAlign="top" content={renderLegend}/>
        )}
        {dataTableGraphFlow?.map((key, index) => (
          <Line key={index} type="monotone" dataKey={"press_sensibilty_"+(index+1)} name={"Sensibility "+(index+1)+" - Inj. Gas Rate = 100 MSCF/D"} stroke={`#${COLOR_GRAPH[(countGraph+1) - (index+1)]}`} />
        ))}
        <Line type="monotone" dataKey="ipr_interpolate" name="IPR" stroke={"#000000"} activeDot={{ r: 8 }}/>
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}
