import React from 'react'
import {CircleMarker, Marker, Popup, Tooltip} from 'react-leaflet'
import { IconLocation } from './IconLocation'

export const Markers = ({coordinates, item, markerColor}) => {
  return (
    <>
    <Marker 
        position={coordinates}
        icon={IconLocation}
    >
      <Popup>Popup for Marker</Popup>
      <Tooltip>
        <div className='bg-slate-200 rounded-md'>
        <h1>Well Information</h1>
            <p>Name: {item.well_name}</p>
            <p>Type Process: {item.type_process}</p>
            <p>Region: {item.region}</p>
            <p>County: {item.county}</p>
            <p>Field: {item.field}</p>
        </div>
      </Tooltip>
    </Marker>
    <CircleMarker
      center={coordinates} 
      pathOptions={{color : markerColor , coordinates}}
      radius={20}>
      <Tooltip>Tooltip for CircleMarker</Tooltip>
    </CircleMarker>
    </>
  )
}

