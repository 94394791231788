import React, { useEffect, useState } from 'react'

const initialDataCreated = {
    id:0,
    wellId:"",
    wellName:"",
    wellRegion:"",
    wellFiled:"",
    wellCounty:"",
}
const initialDataFilterUserDCA = {
    county:"",
    field:"",
    well_id:""
}

const initialUserDataInfo = {
    operator:"",
    lease:"",
    region:""
}

export const useDashboardDCA = () => {
  const [dataCreated, setDataCreated] = useState(initialDataCreated);
  const [dataCreatedDCA, setDataCreatedDCA] = useState([]);
  const [dataFilterUserDCA, setDataFilterUserDCA] = useState(null);
  const [dataWell, setDataWell] = useState([]);
  const [dataFluid, setDataFluid] = useState([]);
  const [datReservoir, setReservoir ] = useState([]);
  const [wellDataInfo, setWellDatainfo] = useState(initialUserDataInfo);
  const [dataHistoryDCA, setDataHistoryDCA] = useState([]);
  const [checkedValuesFirstCol, setCheckedValuesFirstCol] = useState([]);
  const [checkedValuesLastCol, setCheckedValuesLastCol] = useState([]);

  useEffect(()=>{
      const getDataCreated = JSON.parse(sessionStorage.getItem('dataCreated'));
      const getDataCreatedDCA = JSON.parse(sessionStorage.getItem('dataCreatedDCA'));
      const getDataWell =JSON.parse(sessionStorage.getItem('dataWell'));
      const getDataFluid =JSON.parse(sessionStorage.getItem('dataFluid'));
      const getDataReservoir =JSON.parse(sessionStorage.getItem('dataReservoir'));
      const getWellDataInfo =JSON.parse(sessionStorage.getItem('wellDataInfo'));
      const getDataFilterUserDCA = JSON.parse(sessionStorage.getItem('dataFilterUserDCA'));
      const getDataHistoryDCA = JSON.parse(sessionStorage.getItem('historyDataDCA'));
      const getCheckedValuesFirstCol = JSON.parse(sessionStorage.getItem('checkedValuesFirstCol'));
      const getCheckedValuesLastCol = JSON.parse(sessionStorage.getItem('checkedValuesLastCol'));

      if (getDataCreated) {
          setDataCreated(getDataCreated);
      } 
      if (getDataFilterUserDCA) {
        setDataFilterUserDCA(getDataFilterUserDCA);
      }
      if (getDataCreatedDCA) {
        setDataCreatedDCA(getDataCreatedDCA);
     } 
      if (getDataWell) {
          setDataWell(getDataWell);
      } 
      if (getDataFluid) {
          setDataFluid(getDataFluid);
      } 
      if (getDataReservoir) {
          setReservoir(getDataReservoir);
      } 
      if (getWellDataInfo) {
        setWellDatainfo(getWellDataInfo);
      } 
      if (getDataHistoryDCA) {
        setDataHistoryDCA(getDataHistoryDCA);
      }
      if (getCheckedValuesFirstCol) {
        setDataHistoryDCA(getCheckedValuesFirstCol);
      }
      if (getCheckedValuesLastCol) {
        setDataHistoryDCA(getCheckedValuesLastCol);
      }
  },[])

  const getDataCreated = (options) => {
      setDataCreated(options);
      sessionStorage.setItem('dataCreated', JSON.stringify(options));
  }

  const getDataFilterUserDCA = (options) => {
    setDataFilterUserDCA(options);
    sessionStorage.setItem('dataFilterUserDCA', JSON.stringify(options));
  }
  
  const getDataCreatedDCA = (options) => {
    setDataCreatedDCA(options);
    sessionStorage.setItem('dataCreatedDCA', JSON.stringify(options));
  }
  //DEPURAR ESTE CONTEXTO
  const getDataWell = (options) => {
      setDataWell(options);
      sessionStorage.setItem('dataWell', JSON.stringify(options));
  }
  const getDataFluid = (options) => {
      setDataFluid(options);
      sessionStorage.setItem('dataFluid', JSON.stringify(options));
  }
  const getDataReservoir = (options) => {
      setReservoir(options);
      sessionStorage.setItem('dataReservoir', JSON.stringify(options));
  }

  const getWellDataInfo = (options) => {
    setWellDatainfo(options);
    sessionStorage.setItem('wellDataInfo', JSON.stringify(options));
}
  const handleSelectWellDCA = (wellId) => {
    setDataFilterUserDCA(wellId);
  sessionStorage.setItem('dataFilterUserDCA', wellId);
}

const getDataHistoryDCA = (options) => {
  setDataHistoryDCA(options);
  sessionStorage.setItem('historyDataDCA', JSON.stringify(options));
}

const getCheckedValuesFirstCol = (options) => {
  setCheckedValuesFirstCol(options);
  sessionStorage.setItem('checkedValuesFirstCol', JSON.stringify(options));
}

const getCheckedValuesLastCol = (options) => {
  setCheckedValuesLastCol(options);
  sessionStorage.setItem('checkedValuesLastCol', JSON.stringify(options));
}

return {
  dataCreated,
  dataCreatedDCA,
  dataWell,
  dataFluid,
  datReservoir,
  wellDataInfo,
  dataFilterUserDCA,
  dataHistoryDCA,
  checkedValuesFirstCol,
  checkedValuesLastCol,
  getDataCreated,
  getDataCreatedDCA,
  getDataWell,
  getDataFluid,
  getDataReservoir,
  getWellDataInfo,
  getDataFilterUserDCA,
  getDataHistoryDCA,
  handleSelectWellDCA,
  getCheckedValuesFirstCol,
  getCheckedValuesLastCol
}
}
