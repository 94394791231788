import React, { useEffect, useState } from 'react'
import { PieChart, Pie, Sector, Cell, ResponsiveContainer, Tooltip } from 'recharts';

const COLORS = ['#47b452', '#f32d19', '#1937f3', '#fff300'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export const ChartPieStage = ({dataRank, rank, typeRank}) => {
  
  const [data, setData] = useState([]);
  
  useEffect(() => {
    if(Object.keys(dataRank).length){

      const intervals = dataRank?.[rank] ? dataRank[rank][typeRank]?.intervals : [];
      
      const valuesRank = !!intervals === undefined ? 0 : Object.entries(intervals)
        .filter(([key]) => key?.startsWith('wells_Interval_'))
        .map(([key, value]) => ({
          name: key,
          value: value
        }
      ));

      setData(valuesRank);
    }
  }, [rank, dataRank, typeRank]);
  
  const renderCustomizedLabel = ({
    cx, cy, midAngle, innerRadius, outerRadius, percent, index, value
  }) => {
    const RADIAN = Math.PI / 180;
    const radius = outerRadius + 30; // Ajusta la posición del label
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    
    const intervals = dataRank?.[rank] ? dataRank[rank][typeRank]?.intervals : [];    
    const intervalKey = `Interval_${index + 1}`;
    const interval = intervals[intervalKey];
    
    return (
      <text
        x={x}
        y={y}
        fill="black"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
        fontSize="12px"
      >
        <tspan x={x} dy="0">{`Interval: ${interval}`}</tspan>
        <tspan x={x} dy="15">{`Wells: ${value}`}</tspan>
        <tspan x={x} dy="15">{`${(percent * 100).toFixed(0)}%`}</tspan>
      </text>
    );
  };

  return (
    <div className='flex w-full h-full'>
        <ResponsiveContainer width="100%" height="100%">
            <PieChart width={100} height={50}>
            <Pie
                data={data}
                cx="50%"
                cy="50%"
                labelLine={true}
                label={renderCustomizedLabel}
                outerRadius={90}
                fill="#8884d8"
                dataKey="value"
            >
              {data?.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            </PieChart>
        </ResponsiveContainer>
    </div>
  )
}
