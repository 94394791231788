import { Select, message } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import {
  Offcanvas,
  Ripple,
  initTE,
} from "tw-elements";
import { ReactComponent as ExpandIcon } from '../../assets/icon/expand1.svg';
import { ReactComponent as SaveAlert } from "../../assets/img/icons/save.svg";
import { FrameGraph1 } from '../../components/Dashboard/FrameGraph1';
import { FrameGraph2 } from '../../components/Dashboard/FrameGraph2';
import { FrameGraph3 } from '../../components/Dashboard/FrameGraph3';
import { CardGraph } from '../../components/DCA/complements/CardGraph';
import { ImportFile } from '../../components/DCA/complements/ImportFileAlerts';
import { DATA_ALERTS, INITIAL_TYPE_GRAPH } from '../../components/elements/types/type_dashboard';
import { INITIAL_DATA_ALERTS_DCA, STATE_ALERTS } from "../../components/elements/types/type_dca";
import { STATUS_DATA_DCA } from "../../components/elements/types/type_response_API";
import { ErrorLoadingDashboard } from '../../components/ErrorPage';
import { Navbar } from '../../components/layout/Navbar';
import { Sidebar } from '../../components/layout/Sidebar';
import { LoadingDashboard } from '../../components/Loading';
import { DashboardDCAContext } from "../../context/DasboardDCA/DashboardDCAContext";
import { DashboardContext } from '../../context/Dashboard/DashboardContentx';
import { useFilters } from '../../hooks/useFilters';
import { useTabDCA } from '../../hooks/useTabDCA';
import { useTabIOT } from '../../hooks/useTabIOT';

const dataWellDCA = {
  well_name: '',
  fluid_type: '',
  field: '',
  region: "",
  country: ''
}

export const DashboardDCA = () => {
  const { Option } = Select;
  const { dataFilter, User} = useFilters();
  const { getDataPage, selectedWellDCA, getDataAlertDCA, alertDCAData, getSomeAlert, someAlert, checkTypeGraph, getCheckTypeGraph, deviceRealTime} = useContext(DashboardContext);
  const { checkedValuesLastCol } = useContext(DashboardDCAContext);
  const keyCheck = Object.keys(checkedValuesLastCol).find(key => checkedValuesLastCol[key] === true) || "";
  const{
    userDataInfo,
    dataHistoryDCA,
    dataFilterUserDCA,
    dataGetADDWellDCA,
    refetchDataADDWellDCA,
    dataGetWellDCA,
    refetchDataWellDCA,
    refetchDataHistoryDCA,
    dataForecast,
    refetchDataForecast,
    mutationUpdateJsonWellDCA,
    loadDataWellDCA
  } = useTabDCA(`df_cp_forecast_${keyCheck}`);

  const{
    dataIOT,
    refetchDataIOT,
    loadDataIOT,
    viewLoading,
    datGetIOT
  } = useTabIOT();
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [selectedOption, setSelectedOption] = useState('Line');
  const [information, setInformation] = useState(dataWellDCA);
  const [wellboreOptionState, setWellboreOptionState] = useState(false);
  const seletedFilter = dataFilter ? dataFilter?.data : null;
  const DCAData = dataGetWellDCA ? dataGetWellDCA?.data : null;
  const [IOTData, setIOTData] = useState([]);
  const [dataDashboardDCA, setDataDashboardDCA] = useState([])
  const [dataAlertDCA, setDataAlertDCA] = useState([])
  const [dataAlert, setDataAlert] = useState([]);
  const [typeGraph, setTypeGraph] = useState();
  
  useEffect(() => {
    initTE({ Offcanvas, Ripple });
    getDataPage("DashboardDCA");
    if (!!selectedWellDCA) {
      refetchDataHistoryDCA();
      refetchDataIOT();
      refetchDataWellDCA();
      refetchDataADDWellDCA();
      getCheckTypeGraph(INITIAL_TYPE_GRAPH)
      if (keyCheck != ""){
      refetchDataForecast(`df_cp_forecast_${keyCheck}`);
      }
    }
  }, [])

  useEffect(() => {
    setTimeout(() => {
      if (dataFilterUserDCA != null){
        if(dataGetWellDCA?.statusCode === STATUS_DATA_DCA.SUCCESS){
          setInformation({
          ...information,
          well_name: dataGetWellDCA?.data?.well_name,
          fluid_type: dataGetWellDCA?.data?.fluid_type,
          field: dataGetWellDCA?.data?.field,
          region: dataGetWellDCA?.data?.region,
          country: dataGetWellDCA?.data?.county
        })
        
        if(!!dataGetWellDCA?.data?.data_alert && !!Object.keys(dataGetWellDCA?.data?.data_alert).length){
          getDataAlertDCA(dataGetWellDCA?.data.data_alert);
          setDataAlert(dataGetWellDCA?.data.data_alert);
        }else{
          getDataAlertDCA(INITIAL_DATA_ALERTS_DCA);
          setDataAlert(INITIAL_DATA_ALERTS_DCA);
        }

        if (!!dataGetWellDCA?.data.type_graph_dashboard && !!Object.keys(dataGetWellDCA?.data?.type_graph_dashboard).length) {
          getCheckTypeGraph({
            ...checkTypeGraph,
            forecasting: dataGetWellDCA?.data.type_graph_dashboard
          });
        }else{
          getCheckTypeGraph({
            ...checkTypeGraph,
            forecasting: INITIAL_TYPE_GRAPH.forecasting
          });
        }

        if(!!dataGetWellDCA?.data?.check_Alert && !!Object.keys(dataGetWellDCA?.data?.check_Alert).length){
          getSomeAlert(  {
            ...someAlert,
            messageChange: false,
            forecasting : dataGetWellDCA?.data?.check_Alert
          })
        } else{
          getSomeAlert({
            ...someAlert,
            messageChange: false,
            forecasting: DATA_ALERTS.forecasting
          });
        }
        setDataAlertDCA(dataGetWellDCA?.data)
      }}
    }, 1000)
  },[dataGetWellDCA, dataFilterUserDCA]);

  useEffect(() => {
    // const IOTDataFilter = dataIOT.filter((item) => item["LSD"] ===  WELL_SENSOR_TYPE.data[0].sensor || item["LSD"] ===  WELL_SENSOR_TYPE.data[5].sensor )
    const IOTDataFilter = dataIOT.filter((item) => item["LSD"] ===  deviceRealTime?.device_dca?.name )
    setIOTData(IOTDataFilter);
  },[dataIOT,deviceRealTime]);

  useEffect(() => {
    
    const lastDataIOT = IOTData != "" || IOTData[0] != undefined ? [IOTData[IOTData?.length - 1]] : [];

    if (lastDataIOT && lastDataIOT.length > 0 && lastDataIOT[0] != undefined) {
      setDataDashboardDCA({
        ...dataDashboardDCA,
      staticPressure: lastDataIOT[0][deviceRealTime?.device_dca?.discretized ?  information.well_name+' Well Head Pressure' : 'Static Pressure' ],
      tempValue: lastDataIOT[0]['Flowing Temperature'],
      oilValues: lastDataIOT[0]['Liquid Flow Rate'],
      gasValues: lastDataIOT[0]['Corrected Flow Rate'],
      waterValues: lastDataIOT[0]['Liquid Flow Rate'],
      })
      
    } else {
      setDataDashboardDCA({
        ...dataDashboardDCA,
        staticPressure: null,
        tempValue: null,
        oilValues: null,
        gasValues: null,
        waterValues: null
      })
    }
  }, [dataIOT, IOTData, checkTypeGraph?.forecasting,deviceRealTime])
  
  useEffect(() => {
    const validateAlert = {
      ...someAlert.forecasting.valuesAlert,
      [STATE_ALERTS.alert_wc_pressure]: validateTrueCount('wc_pressure', dataDashboardDCA?.staticPressure, alertDCAData.wc_pressure_max, alertDCAData.wc_pressure_min),
      [STATE_ALERTS.alert_wc_temp]: validateTrueCount('wc_temp', dataDashboardDCA?.tempValue, alertDCAData.wc_temp_max, alertDCAData.wc_temp_min),
      [STATE_ALERTS.alert_pp_oil_rate]: validateTrueCount('pp_oil_rate', dataDashboardDCA?.oilValues, alertDCAData.pp_oil_rate_max, alertDCAData.pp_oil_rate_min),
      [STATE_ALERTS.alert_pp_gas_rate]: validateTrueCount('pp_gas_rate', dataDashboardDCA?.gasValues, alertDCAData.pp_gas_rate_max, alertDCAData.pp_gas_rate_min),
      [STATE_ALERTS.alert_pp_water_rate]: validateTrueCount('pp_water_rate', dataDashboardDCA?.waterValues, alertDCAData.pp_water_rate_max, alertDCAData.pp_water_rate_min)
    }
    
    const trueCount = Object.values(validateAlert).filter(value => value).length

    if(trueCount >= 0 && someAlert.forecasting.countCheck !== undefined){

      const realValueTrueCount = trueCount <= 0 ? 0 : trueCount - someAlert.forecasting.countCheck;

      getSomeAlert({
        ...someAlert, 
        forecasting : {
          ...someAlert.forecasting,
          countAlert: realValueTrueCount, 
          valuesAlert: validateAlert, 
        }})
    }
  }, [alertDCAData, dataDashboardDCA, someAlert.forecasting.valuesCheck]);

  const validateTrueCount = (name, valueCard, valueMax, valueMin) => {
    if(valueCard !== null){
     if (someAlert?.forecasting?.valuesCheck[name] === false) {
        const validateFalse = someAlert?.forecasting?.valuesAlert[name];
        return validateFalse;
      }
      else if(someAlert?.forecasting?.valuesCheck[name]) {
        const validateTrue = valueCard > valueMax || valueCard < valueMin ? true : false
        return validateTrue;
      }
    } 

  }

  const wellboreOption = () => {
    setWellboreOptionState(!wellboreOptionState);
  };

  const handleOpenModalZoom = (id) => {
    switch (id) {
      case 1:
        setOpen1(true)
        break;
      case 2:
        setOpen2(true)
        break;
      case 3:
        setOpen3(true)
        break;
      default:
        break;
    }
  }

  const handleCloseModalZoom = (id) => {
    switch (id) {
      case 1:
        setOpen1(false)
        break;
      case 2:
        setOpen2(false)
        break;
      case 3:
        setOpen3(false)
        break;
      default:
        break;
    }
  }

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  const handleUploadFile = (dataFile) => {
    if (!!selectedWellDCA){
      const dataFileAlert = dataFile.map((obj, index) => {
        return {id: index + 1, ...obj };
      });

      const finalDataAlert = {};

      dataFileAlert.map(item => {
        const nameMax = `${item.item}_max`;
        const nameMin = `${item.item}_min`;

        finalDataAlert[nameMin] = item.min >= item.max ? 0 : item.min;
        finalDataAlert[nameMax] = item.max;
      });

      const dataConcatAlertDCA = {...dataAlertDCA, data_alert: finalDataAlert}
      setTimeout(() => {
        mutationUpdateJsonWellDCA.mutate({
          operator: userDataInfo.operator, 
          lease: userDataInfo.lease, 
          region: userDataInfo.region, 
          county: dataAlertDCA?.county,
          field: dataAlertDCA?.field,
          wellid: dataAlertDCA?.well_id,
          userName: User?.user_name, 
          data: dataConcatAlertDCA
        })
      }, 500);
      getDataAlertDCA(dataConcatAlertDCA?.data_alert);
      setDataAlert(dataConcatAlertDCA?.data_alert);
      getSomeAlert({...someAlert, messageChange: false})
    }else{
      message.info("Please seleted well for save domain alert")
    }
  }

  const handleSaveAlert = () => {
    if(alertDCAData && !!selectedWellDCA){
      const data_alert = alertDCAData;
      const checkAlert = someAlert.forecasting;
      
      const dataConcatAlertDCA = {...dataAlertDCA, data_alert: data_alert, check_Alert: checkAlert }

      setTimeout(() => {
        mutationUpdateJsonWellDCA.mutate({
          operator: userDataInfo.operator, 
          lease: userDataInfo.lease, 
          region: userDataInfo.region, 
          county: dataAlertDCA?.county,
          field: dataAlertDCA?.field,
          wellid: dataAlertDCA?.well_id,
          userName: User?.user_name, 
          data: dataConcatAlertDCA
        })
      }, 500);
      getDataAlertDCA(dataConcatAlertDCA?.data_alert);
      setDataAlert(dataConcatAlertDCA?.data_alert);
      getSomeAlert({...someAlert, messageChange: false})
    }else{
      message.info("Please seleted well for save domain alert")
    }
  }
  
  const handleTypeGraph = (event, name) => {
    
    if( checkTypeGraph.forecasting[name] === true){
      getCheckTypeGraph({...checkTypeGraph, forecasting: {...checkTypeGraph.forecasting, [name]: false}})
      
    } else{
      getCheckTypeGraph({...checkTypeGraph, forecasting: {...checkTypeGraph.forecasting, [name]: true}})
    }
  };

  return (
    <>
    <style>
      {`
      /* Estilo para el input no seleccionado */
      .unselectedInput {
          border: 2px solid #ccc; /* Estilo de borde cuando no está seleccionado */
      }

      /* Estilo para el input seleccionado */
      .selectedGraph {
          border: 2px solid #662D91; /* Estilo de borde según el selectedOptions */
          background-color: #662D91; /* Color de fondo según el selectedOptions */
          padding: 3px; 
          background-clip: content-box; /* Colorea solo el área interna del contenido, excluyendo el padding */
      }
      `}
    </style>
    <div className='flex flex-col w-full h-screen bg-white'>
      <div className='flex'>
        <Navbar
          wellboreOption={wellboreOption}
        ></Navbar>
        <Sidebar className="flex abosolute h-full"></Sidebar>
      </div>
      {(viewLoading || loadDataWellDCA) && (!!selectedWellDCA) && (!!deviceRealTime.device_dca.name) 
        ?
        <div className='flex w-full h-full'>
          <LoadingDashboard />
        </div> 
        : ((!selectedWellDCA || selectedWellDCA === "null") || (seletedFilter?.checkedWellDCA === null || seletedFilter?.checkedWellDCA === "") || (!deviceRealTime.device_dca.name))  ? 
        <ErrorLoadingDashboard/>
        :
        <div className="grid grid-cols-3 grid-rows-10 w-full h-full gap-x-6 pr-2 pl-[90px] bg-pc-grey4">
          <div className="w-full h-full grid grid-cols-4 grid-rows-10 gap-x-[1em] gap-y-[1em] row-span-6 overflow-y-auto ml-[1em] mt-[1em] pb-[2em]">
            {/* Tarjeta 1: Form */}
            <div className="card w-full col-span-2 bg-white p-[0.5em] grid-rows-1 grid">
              <label className="w-full  text-pc-grey2 font-bold text-[14px] p-1">
                Well information
              </label>
              <div className="grid grid-cols-2 grid-rows-4 w-full h-full gap-y-[1em] ">
                <div className="flex  flex-col col-span-2 w-full h-1/6 max-h-[45px]">
                  <label className="w-full font-bold  text-pc-grey2 text-[14px] p-1">
                    Selected well
                  </label>
                  <input className="w-full  pl-1" value={information.well_name} readOnly/>
                </div>
                <div className="flex  flex-col w-full col-span-2  h-1/6 max-h-[45px]">
                  <label className="w-full  text-pc-grey2 font-bold text-[14px] p-1">
                    Fluidtype
                  </label>
                  <input className="w-full pl-1" value={information.fluid_type} readOnly/>
                </div>
                <div className="flex flex-col w-full col-span-2  h-1/6 max-h-[45px]">
                  <label className="w-full  text-pc-grey2 font-bold text-[14px] p-1">
                    Field
                  </label>
                  <input className="w-full pl-1" value={information.field} readOnly/>
                </div>
                <div className="flex  flex-col w-full col-span-2  h-1/6 max-h-[45px]">
                  <label className="w-full  text-pc-grey2 font-bold text-[14px] p-1">
                    Region
                  </label>
                  <input className="w-full pl-1"  value={information.region} readOnly/>
                </div>
                <div className="flex  flex-col w-full  col-span-2 h-1/6 max-h-[45px]">
                  <label className="w-full  text-pc-grey2 font-bold text-[14px] p-1">
                  Country
                  </label>
                  <input className="w-full pl-1"  value={information.country} readOnly/>
                </div>
                <div className="flex flex-col w-[157px] h-[42px] ml-[8px] justify-start text-start items-start">
                    <label className="w-full text-pc-grey2 font-bold text-[14px]">
                      Import Domain Alert
                    </label>
                    <div className="flex flex-row w-full mt-[5px]">
                      <ImportFile uploadfile={handleUploadFile}></ImportFile>
                      <SaveAlert 
                      onClick={() => handleSaveAlert()} 
                      className="w-4 h-5 ml-[10px] fill-neutral-500 bg-opacity-50 hover:fill-pa-purple"/> 
                    </div>
                  </div>
              </div>
            </div>
            {/* Tarjeta 5: ARTIFICIAL LIFT METRICS */}
            <div className="card w-full col-span-2  bg-white p-[0.5em] row-span-3">
              <div className="grid grid-cols-2 grid-rows-5 w-full h-full">
                <div className="flex  flex-col col-span-2 w-full">
                  <label className="w-full  text-pc-grey2 font-bold text-[14px] p-1">
                    Artificial Lift Metrics
                  </label>
                  <CardGraph
                    title={"Injection Pressure "}
                    value={"--"}
                    unit={"(psig)"}
                    name={"alm_injection"}
                  ></CardGraph>
                </div>
                <div className="flex  flex-col col-span-2 w-full justify-center">
                <CardGraph
                    title={"Injection Gas Rate "}
                    value={'--'}
                    unit={"(MSCFD)"}
                    name={"alm_inj_gas"}
                  ></CardGraph>
                </div>
                <div className="flex  flex-col col-span-2 w-full justify-center">
                <CardGraph
                    title={"GLR Injection "}
                    value={"--"}
                    unit={"(SCF/STB)"}
                    name={"alm_inj_GLR"}
                  ></CardGraph>
                </div>
                <div className="flex  flex-col col-span-2 w-full justify-center">
                <CardGraph
                    title={"GLR Formation "}
                    value={"--"}
                    unit={"(SCF/STB)"}
                    name={"alm_formation"}
                  ></CardGraph>
                </div>
                <div className="flex  flex-col col-span-2 w-full justify-center">
                <CardGraph
                    title={"Water Cut "}
                    value={"--"}
                    unit={"(%)"}
                    name={"alm_water_cut"}
                  ></CardGraph>
                </div>
              </div>
            </div>
            {/* Tarjeta 2: WELLHEAD CONDITIONS */}
            <div className="card w-full col-span-2 bg-white p-[0.5em]">
              <div className="grid grid-cols-2 grid-rows-2 w-full h-full">
                <div className="col-span-2 w-full">
                  <label className="w-full  text-pc-grey2 font-bold text-[14px] p-1">
                    Wellhead Conditions
                  </label>
                  <CardGraph
                    title={"PRESS "}
                    unit={"(psia)"}
                    name={"wc_pressure"}
                    value={dataDashboardDCA.staticPressure != 0 ? dataDashboardDCA.staticPressure : 0}
                  ></CardGraph>
                </div>
                <div className="flex  flex-col col-span-2 w-full justify-center">
                  <CardGraph
                    title={"TEMP "}
                    unit={"(deg f)"}
                    name={"wc_temp"}
                    value={dataDashboardDCA.tempValue != 0 ? dataDashboardDCA.tempValue : 0}
                  ></CardGraph>
                </div>
              </div>
            </div>
            {/* Tarjeta 6: BH Condition*/}
            <div className="card w-full  col-span-2 bg-white p-[0.5em]">
              <div className="grid grid-cols-2 grid-rows-2 w-full h-full">
                <div className="col-span-2 w-full">
                  <label className="w-full  text-pc-grey2 font-bold text-[14px] p-1">
                    BH Condition
                  </label>
                  <CardGraph
                    title={"PRESS "}
                    value={"--"}
                    unit={"(psia)"}
                    name={"bhc_pressure"}
                  ></CardGraph>
                </div>
                <div className="flex  flex-col col-span-2 w-full justify-center">
                  <CardGraph
                    title={"TEMP "}
                    value={"--"}
                    unit={"(deg f)"}
                    name={"bhc_temp"}
                  ></CardGraph>
                </div>
              </div>
            </div>
            {/* Tarjeta 4: PRODUCTION PERFORMANCE */}
            <div className="card w-full bg-white grid-rows-1 p-[0.5em] col-span-4 ">
              <div className="grid grid-cols-2  w-full h-full">
                <div className="w-full">
                  <label className="w-full  text-pc-grey2 font-bold text-[14px] p-1">
                    Production Performance
                  </label>
                  <CardGraph
                    title={"Oil "}
                    unit={"(STB/D)"}
                    name={"pp_oil_rate"}
                    value={dataDashboardDCA.oilValues != 0 ? dataDashboardDCA.oilValues : 0}
                  ></CardGraph>
                </div>
                <div className="flex  flex-col w-full justify-center">
                  <CardGraph
                    title={"Water "}
                    unit={"(BBL/D)"}
                    name={"pp_water_rate"}
                    value={dataDashboardDCA.waterValues != 0 ? dataDashboardDCA.waterValues : 0}
                  ></CardGraph>
                </div>
                <div className="flex  flex-col w-full justify-center">
                  <CardGraph
                    title={"Gas "}
                    unit={"(MSCF/D)"}
                    name={"pp_gas_rate"}
                    value={dataDashboardDCA.gasValues != 0 ? dataDashboardDCA.gasValues : 0}
                  ></CardGraph>
                </div>
                <div className="flex  flex-col w-full justify-center">
                  <CardGraph
                    title={"WOR "}
                    value={"-"}
                    unit={"(Fraction)"}
                    name={"pp_wor"}
                  ></CardGraph>
                </div>
                <div className="flex  flex-col w-full justify-center">
                  <CardGraph
                    title={"Water Cut "}
                    value={"--"}
                    unit={"(%)"}
                    name={"pp_water_cute"}
                  ></CardGraph>
                </div>
                <div className="flex  flex-col w-full justify-center ">
                  <CardGraph
                    title={"GOR "}
                    value={"--"}
                    unit={"(SCF/STB)"}
                    name={"pp_gor"}
                  ></CardGraph>
                </div>
              </div>
            </div>
          </div>
          {!wellboreOptionState && (
            <div className="w-full h-full grid grid-cols-2 gap-x-[1em] gap-y-[1em] grid-rows-6  col-span-2 row-span-6 overflow-y-auto mt-[1em] pb-[2em]">
              <div className="card w-full row-span-3  bg-white p-[0.5em] ">
                <ExpandIcon onClick={() => handleOpenModalZoom(1)} className="w-5 h-5 hover:bg-pc-grey3 cursor-pointer" />
                <div className='flex flex-row w-full h-[5%] pl-[2em] max-h-[10%]'>
                  <label className="text-pc-grey2 font-bold text-[14px] p-1 mr-6">
                    DCA
                  </label> 
                  <div className='flex flex-row w-[40%] h-full items-center text-right justify-end gap-2'>
                      <input
                          className={`ml-1 w-5 h-5 appearance-none rounded-full ${!checkTypeGraph.forecasting.dcaGraph ? 'selectedGraph' : 'unselectedInput'} cursor-pointer`}
                          type="checkbox"
                          checked={checkTypeGraph.forecasting.dcaGraph}
                          onChange={(event) => handleTypeGraph(event, "dcaGraph")}
                          disabled={!selectedWellDCA || selectedWellDCA === "null" ? true : false}
                      />
                      <label htmlFor="" className='text-bc-gray text-[14px]'>Historical Data + RT avg</label>
                  </div>
                  <div className='flex flex-row w-[30%] h-full items-center text-right justify-end gap-2'>
                      <input
                          className={`ml-1 w-5 h-5 appearance-none rounded-full ${checkTypeGraph.forecasting.dcaGraph ? 'selectedGraph' : 'unselectedInput'} cursor-pointer`}
                          type="checkbox"
                          checked={checkTypeGraph.forecasting.dcaGraph}
                          onChange={(event) => handleTypeGraph(event, "dcaGraph")}
                          disabled={!selectedWellDCA || selectedWellDCA === "null" ? true : false}
                      />
                      <label htmlFor="" className='text-bc-gray text-[14px]'>Real Time Data</label>
                  </div>
                </div>
                <div className="flex flex-col h-[90%]">
                  <FrameGraph1 open={open1} onCloseModal={() => handleCloseModalZoom(1)} dataHistory={dataHistoryDCA} dataIOT={dataIOT} dataForecastDCA={dataForecast} statusGraph={checkTypeGraph?.forecasting?.dcaGraph}></FrameGraph1>
                </div>
              </div>
              <div className="card w-full row-span-3  bg-white p-[0.5em] ">
                <ExpandIcon onClick={() => handleOpenModalZoom(2)} className="w-5 h-5 hover:bg-pc-grey3 cursor-pointer" />
                <div className="flex  flex-col col-span-2 w-full h-[5%] pl-[75px] max-h-[10%] ">
                  <label className="w-full  text-pc-grey2 font-bold text-[14px] p-1">
                    Performance
                  </label>
                </div>
                <div className="flex flex-col h-[90%]">
                  <FrameGraph2 open={open2} onCloseModal={() => handleCloseModalZoom(2)} dataHistory={dataHistoryDCA} dataIOT={dataIOT}></FrameGraph2>
                </div>
              </div>
              <div className="card w-full row-span-3  bg-white p-[0.5em] col-span-2">
                <div className='flex flex-row gap-4 w-full'>
                <ExpandIcon onClick={() => handleOpenModalZoom(3)} className="w-5 h-5 hover:bg-pc-grey3 cursor-pointer" />
                    <label htmlFor="chartType" className="mr-2">Chart Type:</label>
                    <Select
                    id="chartType"
                    value={selectedOption}
                    onChange={(value) => handleOptionChange(value)}
                    className="w-[70px] h-[20px]"
                  >
                    <Option value="Line">Line</Option>
                    <Option value="Bar">Bar</Option>
                  </Select>
                </div> 
                <div className='flex flex-row w-full h-[10%] pl-[2em] max-h-[15%]'>
                  <label className="text-pc-grey2  w-[18%] font-bold text-[14px] p-1 mr-6">
                    Production History
                  </label> 
                  <div className='flex flex-row w-2/4 h-full items-center text-right justify-end gap-2'>
                      <input
                          className={`ml-1 w-5 h-5 appearance-none rounded-full ${!checkTypeGraph.forecasting.production_history ? 'selectedGraph' : 'unselectedInput'} cursor-pointer`}
                          type="checkbox"
                          checked={checkTypeGraph.forecasting.production_history}
                          onChange={(event) => handleTypeGraph(event, "production_history")}
                          disabled={!selectedWellDCA || selectedWellDCA === "null" ? true : false}
                      />
                      <label htmlFor="" className='text-bc-gray text-[14px]'>Historical Data + RT avg</label>
                  </div>
                  <div className='flex flex-row w-[20%] h-full items-center text-right justify-end gap-2'>
                      <input
                          className={`ml-1 w-5 h-5 appearance-none rounded-full ${checkTypeGraph.forecasting.production_history ? 'selectedGraph' : 'unselectedInput'} cursor-pointer`}
                          type="checkbox"
                          checked={checkTypeGraph?.forecasting.production_history}
                          onChange={(event) => handleTypeGraph(event, "production_history")}
                          disabled={ !selectedWellDCA || selectedWellDCA === "null" ? true : false}
                      />
                      <label htmlFor="" className='text-bc-gray text-[14px]'>Real Time Data</label>
                  </div>
                </div>
                <div className="flex flex-col h-[90%]">
                  <FrameGraph3 open={open3} onCloseModal={() => handleCloseModalZoom(3)} typeGraph3={selectedOption} dataHistory={dataHistoryDCA} dataIOT={dataIOT} statusGraph={checkTypeGraph?.forecasting?.production_history}></FrameGraph3>
                </div>
              </div>
            </div>
          )}
        </div>
      }
    </div>
    </>
  )
}