import moment from 'moment';
import React, { useContext } from 'react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Brush } from 'recharts';
import { DashboardPlottingContext } from '../../../context/DashboardPlotting/DashboardPlottingContext';
import { COLORS_LINES } from '../../elements/types/type_plotting';

export const ChartPlotting = ({ data, linesData, colorMapping }) => {
  const { dataPlotting } = useContext(DashboardPlottingContext);

  const filteredDataPlotting = dataPlotting.dataPlotting?.filter(item =>
    linesData.includes(item.device)
  );

  const formattedData = data?.map((item, index) => ({
    ...item,
    Date: String(item["Date (MM/DD/YYYY)"]).split(" ")[0], // Formato deseado de fecha
  }))

  const filterData = formattedData?.map(item => {
    const updatedItem = { ...item };
    Object.keys(updatedItem).forEach(key => {
      if (updatedItem[key] === -999.25) {
        updatedItem[key] = null;
      }
    });

    return updatedItem;
  });

  return (
    <div className='flex w-full'>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={350}
          data={formattedData}
          margin={{
            top: 35,
            right: 100,
            left: 30,
            bottom: 60,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="Date (MM/DD/YYYY)"
            type="category"
            allowDecimals={false}
            label={{
              value: "Date",
              position: "bottom",
              fill: "black",
              fontSize: 14,
              dy: 60, // Ajusta el valor para mover el label hacia abajo
            }}
            tickFormatter={(value) => moment(value, "MM/DD/YYYY HH:mm:ss").format("MM/DD/YYYY HH:mm:ss")}
            interval={200}
            tickCount={10}
            tick={{ fill: "black", fontSize: 12, angle: -45, textAnchor: 'end' }} // Inclinar los ticks
          />
          {dataPlotting.optionsParmsPlotting?.length && filteredDataPlotting?.map((lineKey, index) => {
            const color = colorMapping[lineKey.device] || lineKey.color;
            return (
              <YAxis
                key={`yAxis-${lineKey.device}`}
                label={{
                  value: lineKey.device, // Etiqueta del eje Y
                  angle: -90,
                  position: index % 2 === 0 ? 'outsideLeft' : 'outsideRight', // Coloca las etiquetas fuera
                  fill: color,
                  style: { textAnchor: "middle" }, // Mover el label hacia arriba
                  fontSize: 14,
                  dx: index % 2 === 0 ? -10 : 10,
                }}
                tick={{ fill: color, fontSize: 12 }} // Color de las marcas
                yAxisId={index} // Cada eje tiene un ID único
                orientation={index % 2 === 0 ? 'left' : 'right'} // Alterna la orientación
                stroke={color} // Color del eje, igual al de la línea
                interval={0} // Muestra todos los ticks
                width={60}
                tickCount={10}
                domain={dataPlotting.dataPlotting[dataPlotting.dataPlotting?.findIndex(item => item.device === lineKey.device)].domain}
                allowDataOverflow={true}
              />
            )
          })}
          <Tooltip />
          {/* <Legend /> */}
          {filteredDataPlotting?.map((lineKey, index) => {
            let colorIndex = dataPlotting.optionsParmsPlotting?.findIndex(item => item.value === lineKey.device);
            // Asignar color cíclico
            if (colorIndex >= 0) {
              colorIndex = colorIndex % COLORS_LINES?.length;
            } else {
              colorIndex = 0; // Color por defecto si no se encuentra
            }
            const color = colorMapping[lineKey.device] || lineKey.color;
            return (
              <Line
                key={lineKey.device}
                type="monotone"
                dataKey={lineKey.device}
                stroke={color} // Puedes variar los colores
                yAxisId={index}
                activeDot={{ r: 8 }}
              />
            )
          })}
          <Brush dataKey="Date (MM/DD/YYYY)" height={20} stroke="#8884d8" y={0} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}