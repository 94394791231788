import React from 'react'
import { DashboardSCTContext } from './DasboardSCTContext'
import { useDashboardSCT } from '../../hooks/useDashboardSCT'

export const DashboardSCTProvider = ({children}) => {
  const {
    dataFilterUserSCT,
    getDataFilterUserSCT
  } = useDashboardSCT();
  return (
    <DashboardSCTContext.Provider value={{   
      dataFilterUserSCT,
      getDataFilterUserSCT
    }}>
        {children}
    </DashboardSCTContext.Provider>
  )
}
