import React, { useEffect, useState } from 'react'

export const ChartTableAnalytics = ({dataTable, rank}) => {

    const [dataTableRank, setDataTableRank] = useState([]);
    
    useEffect(() =>{
        if (!!dataTable && !!rank) {
            const data = dataTable
            setDataTableRank(data[rank]);
        }else{
            setDataTableRank([]);
        }
    }, [dataTable, rank]);
    
    
    const decimalRound = (number, round) => {
        let valRound = !!number || number >= 0 ? Number(number.toFixed(round)) : null;
        return valRound;
    };
    
  return (
    <div className='flex w-full h-full'>
        <table className="min-w-full text-left text-sm font-light ">
        <thead className="font-medium">
            <tr>
                <th scope="col" className="px-6 py-2 border border-gray-400 text-center">Parameter</th>
                <th scope="col" className="px-6 py-2 border border-gray-400 text-center"> Mean</th>
                <th scope="col" className="px-6 py-2 border border-gray-400 text-center">Perc. 25</th>
                <th scope="col" className="px-6 py-2 border border-gray-400 text-center">Perc. 50</th>
                <th scope="col" className="px-6 py-2 border border-gray-400 text-center ">Perc. 75</th>
                <th scope="col" className="px-6 py-2 border border-gray-400 text-center ">min value</th>
                <th scope="col" className="px-6 py-2 border border-gray-400 text-center">max value</th>
            </tr>
        </thead>
        <tbody>
            <tr key={1}>
                <td className="border border-gray-400 text-center">Oil Rate (STB/D)</td>
                <td className="border border-gray-400 text-center">{decimalRound(dataTableRank?.["Oil Rate (STB/D)"]?.mean_value, 1)}</td>
                <td className="border border-gray-400 text-center">{decimalRound(dataTableRank?.["Oil Rate (STB/D)"]?.percentiles.percentile_25, 1)}</td>
                <td className="border border-gray-400 text-center">{decimalRound(dataTableRank?.["Oil Rate (STB/D)"]?.percentiles.percentile_50, 1)}</td>
                <td className="border border-gray-400 text-center">{decimalRound(dataTableRank?.["Oil Rate (STB/D)"]?.percentiles.percentile_75, 1)}</td>
                <td className="border border-gray-400 text-center">{decimalRound(dataTableRank?.["Oil Rate (STB/D)"]?.min_value, 1)}</td>
                <td className="border border-gray-400 text-center">{decimalRound(dataTableRank?.["Oil Rate (STB/D)"]?.max_value, 1)}</td>
            </tr>
            <tr key={2}>
                <td className="border border-gray-400 text-center">GOR (SCF/STB)</td>
                <td className="border border-gray-400 text-center">{decimalRound(dataTableRank?.["GOR (SCF/STB)"]?.mean_value, 1)}</td>
                <td className="border border-gray-400 text-center">{decimalRound(dataTableRank?.["GOR (SCF/STB)"]?.percentiles.percentile_25, 1)}</td>
                <td className="border border-gray-400 text-center">{decimalRound(dataTableRank?.["GOR (SCF/STB)"]?.percentiles.percentile_50, 1)}</td>
                <td className="border border-gray-400 text-center">{decimalRound(dataTableRank?.["GOR (SCF/STB)"]?.percentiles.percentile_75, 1)}</td>
                <td className="border border-gray-400 text-center">{decimalRound(dataTableRank?.["GOR (SCF/STB)"]?.min_value, 1)}</td>
                <td className="border border-gray-400 text-center">{decimalRound(dataTableRank?.["GOR (SCF/STB)"]?.max_value, 1)}</td>
            </tr>
            <tr key={3}>
                <td className="border border-gray-400 text-center">Water Cut (%)  </td>
                <td className="border border-gray-400 text-center">{decimalRound(dataTableRank?.["Water Cut (%)"]?.mean_value, 1)}</td>
                <td className="border border-gray-400 text-center">{decimalRound(dataTableRank?.["Water Cut (%)"]?.percentiles.percentile_25, 1)}</td>
                <td className="border border-gray-400 text-center">{decimalRound(dataTableRank?.["Water Cut (%)"]?.percentiles.percentile_50, 1)}</td>
                <td className="border border-gray-400 text-center">{decimalRound(dataTableRank?.["Water Cut (%)"]?.percentiles.percentile_75, 1)}</td>
                <td className="border border-gray-400 text-center">{decimalRound(dataTableRank?.["Water Cut (%)"]?.min_value, 1)}</td>
                <td className="border border-gray-400 text-center">{decimalRound(dataTableRank?.["Water Cut (%)"]?.max_value, 1)}</td>
            </tr>
        </tbody>
        </table>
    </div>
  )
}
