import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Route, Routes } from 'react-router-dom';
import { Login } from '../auth/pages/Login'
import { AuthProvider } from '../auth/context/AuthProvider';
import { MapWells } from '../pages/TabMap/MapWells';
import { DashboardDCA } from '../pages/TabDCA/DashboardDCA';
import { DashboardProvider } from '../context/Dashboard/DashboardProvider'
import { DashboardGasLift } from '../pages/TabGaslift/DashboardGasLift';
import { AddWellDCA } from '../pages/TabDCA/AddWellDCA';
import { StepMenuEditWellDCA } from '../pages/TabDCA/StepMenuEditWellDCA';
import { AddWellGasLift } from '../pages/TabGaslift/AddWellGasLift';
import { StepMenuFluidConfig } from '../pages/TabGaslift/StepMenuFluidConfig';
import { StepMenuAddWellDCA } from '../pages/TabDCA/StepMenuAddWellDCA';
import { StepMenuReservoir } from '../pages/TabGaslift/StepMenuReservoir';
import { StepMenuWellConfig } from '../pages/TabGaslift/StepMenuWellConfig';
import { ProtectedRoutes } from '../components/ProtectedRoutes';
import { Register } from '../pages/Users_Control/Register';
import { TuningGasLift } from '../pages/TabGaslift/TuningGasLift';
import { UsersExternal } from '../pages/Users_Control/UsersExternal';
import { UsersInternal } from '../pages/Users_Control/UsersInternal';
import { DataGasLiftProvider } from '../context/DataGasLift/DataGasLiftProvider';
import { DashboardDCAProvider } from '../context/DasboardDCA/DashboardDCAProvider';
import { UserProvider } from '../context/User/UserProvider';
import { StepMenuEditWellConfig } from '../pages/TabGaslift/StepMenuEditWellConfig';
import { StepMenuEditFluidConfig } from '../pages/TabGaslift/StepMenuEditFluidConfig';
import { StepMenuEditReservoir } from '../pages/TabGaslift/StepMenuEditReservoir';
import { StepMenuInOutFlow } from '../pages/TabGaslift/StepMenuInOutFlow';
import { StepMenuEditInOutFlow } from '../pages/TabGaslift/StepMenuEditInOutFlow';
import { DashboardScreeningTool } from '../pages/TabScreeningTool/DashboardScreeningTool';
import { AddScreeningTool } from '../pages/TabScreeningTool/AddScreeningTool';
import { StepMenuScreeningTool } from '../pages/TabScreeningTool/StepMenuScreeningTool';
import { StepMenuEditScreeningTool } from '../pages/TabScreeningTool/StepMenuEditScreeningTool';
import { DashboardSCTProvider } from '../context/DashboardSCT/DashboardSCTProvider';
import { Plotting } from '../pages/TabPlotting/Plotting';
import { DashboardPlottingProvider } from '../context/DashboardPlotting/DashboardPlottingProvider';
import { DashboardPlungerLift } from '../pages/TabPlungerLift/DashboardPlungerLift';
import { AddPlungerLift } from '../pages/TabPlungerLift/AddPlungerLift';
import { StepMenuWellPlungerLift } from '../pages/TabPlungerLift/StepMenuWellPlungerLift';
import { StepMenuEditWellPlungerLift } from '../pages/TabPlungerLift/StepMenuEditWellPlungerLift';
import { StepMenuFluidPlungerLift } from '../pages/TabPlungerLift/StepMenuFluidPL';
import { StepMenuReservoirPlungerLift } from '../pages/TabPlungerLift/StepMenuReservoirPL';
import { StepMenuEditReservoirPlungerLift } from '../pages/TabPlungerLift/StepMenuEditReservoirPL';
import { StepMenuPlungerLift } from '../pages/TabPlungerLift/StepMenuPlungerLift';
import { DashboardPLProvider } from '../context/DashboardPlungerLift/DashboardPLProvider';
import { StepMenuEditFluidPlungerLift } from '../pages/TabPlungerLift/StepMenuEditFluidPL';
import { StepMenuEditPlungerLift } from '../pages/TabPlungerLift/StepMenuEditPlungerLift';

const queryClient = new QueryClient();
export const AppRoutes = () => {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <UserProvider>
            <DashboardProvider>
              <DashboardDCAProvider>
                <DataGasLiftProvider>
                  <DashboardPlottingProvider>
                    <DashboardSCTProvider>
                      <DashboardPLProvider>
                        <Routes>
                          <Route element={<ProtectedRoutes isAllowed="External" />}>
                            <Route index element={<DashboardDCA />} />
                          </Route>
                          <Route element={<ProtectedRoutes isAllowed="Internal" />}>
                            <Route path="/Users-Register" element={<Register />} />
                            <Route path="/Users-External" element={<UsersExternal />} />
                            <Route path="/Users-Internal" element={<UsersInternal />} />
                          </Route>
                          <Route element={<ProtectedRoutes isAllowed="Bound" />}>
                            <Route path="/Map" element={<MapWells />} />
                            <Route path="/Plotting" element={<Plotting />} />
                            <Route path="/DashboardScreeningTool" element={<DashboardScreeningTool />} />
                            <Route path="/DashboardGasLift" element={<DashboardGasLift />} />
                            <Route path="/DashboardDCA" element={<DashboardDCA />} />
                            <Route path="/AddWellDCA" element={<AddWellDCA />} />
                            <Route path="/AddWellDCA/StepMenu-WellDCA" element={<StepMenuAddWellDCA />} />
                            <Route path="/AddWellDCA/StepMenu-EditWellDCA" element={<StepMenuEditWellDCA />} />
                            <Route path="/AddWellGasLift" element={<AddWellGasLift />} />
                            <Route path="/AddWellGasLift/StepMenu-EditWellConfig" element={<StepMenuEditWellConfig />} />
                            <Route path="/AddWellGasLift/StepMenu-EditFluidConfig" element={<StepMenuEditFluidConfig />} />
                            <Route path="/AddWellGasLift/StepMenu-EditReservoir" element={<StepMenuEditReservoir />} />
                            <Route path="/AddWellGasLift/StepMenu-FluidConfig" element={<StepMenuFluidConfig />} />
                            <Route path="/AddWellGasLift/StepMenu-Reservoir" element={<StepMenuReservoir />} />
                            <Route path="/AddWellGasLift/StepMenu-WellConfig" element={<StepMenuWellConfig />} />
                            <Route path="/AddWellGasLift/TuningGasLift" element={<TuningGasLift />} />
                            <Route path="/AddWellGasLift/StepMenu-InOutFlow" element={<StepMenuInOutFlow />} />
                            <Route path="/AddWellGasLift/StepMenu-EditInOutFlow" element={<StepMenuEditInOutFlow />} />
                            <Route path="/AddScreeningTool" element={<AddScreeningTool />} />
                            <Route path="/AddScreeningTool/StepMenu-ScreeningTool" element={<StepMenuScreeningTool />} />
                            <Route path="/AddScreeningTool/StepMenu-EditScreeningTool" element={<StepMenuEditScreeningTool />} />
                            <Route path="/DashboardPlungerLift" element={<DashboardPlungerLift />} />
                            <Route path="/AddPlungerLift" element={<AddPlungerLift />} />
                            <Route path="/AddPlungerLift/StepMenu-WellPlungerLift" element={<StepMenuWellPlungerLift />} />
                            <Route path="/AddPlungerLift/StepMenu-EditWellPlungerLift" element={<StepMenuEditWellPlungerLift />} />
                            <Route path="/AddPlungerLift/StepMenu-FluidPlungerLift" element={<StepMenuFluidPlungerLift />} />
                            <Route path="/AddPlungerLift/StepMenu-EditFluidPlungerLift" element={<StepMenuEditFluidPlungerLift />} />
                            <Route path="/AddPlungerLift/StepMenu-ReservoirPlungerLift" element={<StepMenuReservoirPlungerLift />} />
                            <Route path="/AddPlungerLift/StepMenu-EditReservoirPlungerLift" element={<StepMenuEditReservoirPlungerLift />} />
                            <Route path="/AddPlungerLift/StepMenu-EditPlungerLift" element={<StepMenuEditPlungerLift />} />
                            <Route path="/AddPlungerLift/StepMenu-PlungerLift" element={<StepMenuPlungerLift />} />
                          </Route>
                          <Route path="/login" element={<Login />} />
                        </Routes>
                      </DashboardPLProvider>
                    </DashboardSCTProvider>
                  </DashboardPlottingProvider>
                </DataGasLiftProvider>
              </DashboardDCAProvider>
            </DashboardProvider>
          </UserProvider>
        </AuthProvider>
      </QueryClientProvider>
    </>
  )
}
