import React, { useContext, useState } from 'react'
import { AuthContext } from '../auth/context/AuthContext'
import { useMutation, useQuery } from 'react-query'
import { message } from 'antd'
import { DashboardContext } from '../context/Dashboard/DashboardContentx'
import { DATA_RESERVOIR } from '../components/elements/types/type_gas_lift'
import { DashboardPLContext } from '../context/DashboardPlungerLift/DashboardPLContext'
import { 
  calculateQIPRPL, 
  uploadJsonIPRPL, 
  uploadJsonCasing, 
  calculateCasing, 
  dataWellPlungerLift, 
  uploadJsonWellPL, 
  deleteWellPL,
  updateJsonWellPL,
  calculatePressure,
  uploadJsonPressure,
  listWellPlungerLift,
  calculateInterpolation,
  calculatePVT,
  uploadJsonPVT
} from '../services/api/PlungerLiftAPI'

const initialDataDeviationApi = {
  md: [],
  tvd: [],
  inc: [],
  t: null,
  p: null,
}

const initiailInputPropiertiesOut = {
  Pb: "",
  Bo: "",
  Uo: "",
  Deno: "",
  SigmaO: "",
  Rs: "",
  Bg: "",
  Ug: "",
  Deng: "",
  Bw: "",
  Uw: "",
  Denw: "",
  SigmaW: "",
};

const initiailInputCalculatedLab = {
  BoUntuned: null,
  PbUntuned: null,
  RsUntuned: null,
  VisoUntuned: null,
  CoUntuned: null,
  BoTuned: null,
  PbTuned: null,
  RsTuned: null,
  VisoTuned: null,
  CoTuned: null
};

const initialDataTableLab = [{
  pressureLab: null,
  BoExp: null,
  RsExp: null,
  VisoExp: null,
  CoExp: null,
  BoUntuned: null,
  RsUntuned: null,
  VisoUntuned: null,
  CoUntuned: null,
  BoTuned: null,
  RsTuned: null,
  VisoTuned: null,
  CoTuned: null
}]

export const useTabPlungerLift= () => {
  const { User } = useContext(AuthContext);
  const { getUserDataInfo, userDataInfo, selectedWellPL, getSelectedWellPL } = useContext(DashboardContext);
  const { getDataFilterPL, dataFilterUserPL } = useContext(DashboardPLContext);
  const [ dataDeviationApi, setDataDeviationApi] = useState(initialDataDeviationApi);
  const [ dataEquipmentApi, setDataEquipmentApi] = useState([]);
  const [ bodyAddWell, setBodyAddWell] = useState([]);
  const [ inputPropertiesOut, setInputPropertiesOut] = useState(initiailInputPropiertiesOut);
  const [ viewLoading, setViewLoading] = useState(true)
  const [ viewFluidLab, setViewFluidLab] = useState(false);
  const [ inputCalculatedLab, setInputCalculatedLab] = useState(initiailInputCalculatedLab);
  const [ dataTableLab, setDataTableLab] = useState(initialDataTableLab);
  const [ executeTuned, setExecuteTuned] = useState(false);
  const [ executeTunedCorrelation, setExecuteTunedCorrelation] = useState(false);
  const [ indexTableLab, setIndexTableLab] = useState(0);
  const [ dataTableIPRPL, setDataTableIPRPL] = useState();
  const [ inputReservoir, setInputReservoir] = useState(DATA_RESERVOIR.initialInputReservoir);
  
  //FUNCIONES
  const transformData = (dataTableIPRPL) => {
    if (!inputReservoir || !inputReservoir.ipr_model || !dataTableIPRPL) return [];

    const { ipr_model } = inputReservoir;
    const { qo, steps } = dataTableIPRPL;

    const transformModelData = (qoModel) => {
      if (!qoModel || !Array.isArray(qoModel) || qoModel.length === 0) {
        return [];
      }
  
      return qoModel.map((qoValue, index) => ({
        qo: qoValue,
        steps: steps[index].toFixed(2),
      }));
    };

    const transformCompositeData = () => {
      if (!qo.qliq_pet || !Array.isArray(qo.qliq_pet) || qo.qliq_pet.length === 0 | !Array.isArray(qo.qo_pet) || qo.qo_pet.length === 0 | !Array.isArray(qo.qw_pet) || qo.qw_pet.length === 0) {
        return [];
      }
      
      return qo.qliq_pet.map((_, index) => ({
        qliq_pet: qo.qliq_pet[index],
        qo_pet: qo.qo_pet[index],
        qw_pet: qo.qw_pet[index],
        steps: steps[index].toFixed(2),
      }));
    };
  
    switch (ipr_model) {
      case 'vogel':
        return transformModelData(qo.vogel);
      case 'fetko':
        return transformModelData(qo.fetko);
      case 'straight':
        return transformModelData(qo.straight);
      case 'composite':
        return transformCompositeData();
      default:
        return qo ? Object.keys(qo).flatMap(model => 
          transformModelData(qo[model]).map(data => ({ ...data, model }))
        ) : [];
    }
  };

  //LLAMADOS API

  const mutationSaveDataIPR = useMutation({
    mutationFn: uploadJsonIPRPL,
    onSuccess: () => {
      message.success(` IPR DataSaved Successfull`)
      setTimeout(() => {
        refetchListqIPR();
      }, 500);
    },
    onError: (error) => {
      message.error(`An error has occurred when loading the IPR Data`)
    }
  })

  const { data: listqIPR, isLoading: loadListqIPR, isError: ErrorListqIPR, refetch: refetchListqIPR } = useQuery({
    queryKey: ['get_QIPR'],
    queryFn: () => calculateQIPRPL(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, dataFilterUserPL.county, dataFilterUserPL.field, dataFilterUserPL.well_id, User.user_name),
    retry: 2,
    enabled: false,
    onSuccess: (data) => {
      if (data?.statusType === "ERROR") {
        message.info("The calculation carried out was not processed correctly, validates the data")
      }
      else if (data?.statusType === "SUCCESS") {
        const dataReservoior = data.data
        setDataTableIPRPL(transformData(dataReservoior));
      }

      setViewLoading(false)
    },
    onError: (error) => {
      setViewLoading(false)
    }
  })

  const generateRadomId = () => {
    const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lowercaseLettersAndNumbers = 'abcdefghijklmnopqrstuvwxyz0123456789';
    let id = '';

    // Generar los primeros tres caracteres con letras mayúsculas
    for (let i = 0; i < 3; i++) {
      const randomUppercaseLetter = uppercaseLetters.charAt(Math.floor(Math.random() * uppercaseLetters.length));
      id += randomUppercaseLetter;
    }


    // Generar el resto de caracteres con letras minúsculas y números
    const remainingLength = 10 - id.length; // Asegurarse de que la longitud total sea al menos 10
    for (let i = 0; i < remainingLength; i++) {
      const randomChar = lowercaseLettersAndNumbers.charAt(Math.floor(Math.random() * lowercaseLettersAndNumbers.length));
      id += randomChar;
    }

    return id;

  };

  const mutationCalcCasing = useMutation({
    mutationFn: uploadJsonCasing,
    onSuccess: () => {
      message.success(`Data Done Successfull`);
    },
    onError: (error) => {
      message.error(`An error has occurred upload data`)
    }
  });

  const { data: dataCalcCasing, isLoading: loadCalcCasing, isError: ErrorCalcCasing, refetch: refetchCalcDataCasing } = useQuery({
    queryKey: ['get_data_casing_pressure'],
    queryFn: () => calculateCasing(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, dataFilterUserPL.county, dataFilterUserPL.field, dataFilterUserPL.well_id, User.user_name),
    retry: 2,
    enabled: false,
    onSuccess: (data) => {
      if (data?.statusType === "ERROR") {
        message.info("The calculation carried out was not processed correctly, validates the data")
      }
      setViewLoading(false)
    },
    onError: (error) => {
      setViewLoading(false)
    }
  });

  const mutationCalcPressure = useMutation({
    mutationFn: uploadJsonPressure,
    onSuccess: () => {
      message.success(`Data Done Successfull`);
    },
    onError: (error) => {
      message.error(`An error has occurred upload data`)
    }
  });

  const { data: dataCalcPressure, isLoading: loadDataCalcPressure, isError: ErrorDataCalcPressure, refetch: refetchCalcDataPressure } = useQuery({
    queryKey: ['get_data_pressure'],
    queryFn: () => calculatePressure(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, dataFilterUserPL.county, dataFilterUserPL.field, dataFilterUserPL.well_id, User.user_name),
    retry: 2,
    enabled: false,
    onSuccess: (data) => {
      if (data?.statusType === "ERROR") {
        message.info("The calculation carried out was not processed correctly, validates the data")
      }
      setViewLoading(false)
    },
    onError: (error) => {
      setViewLoading(false)
    }
  });

  const { data: dataCalcInterpolation, isLoading: loadDataCalcInterpolation, isError: ErrorDataCalcInterpolation, refetch: refetchCalcDataInterpolation } = useQuery({
    queryKey: ['get_data_Interpolation'],
    queryFn: () => calculateInterpolation(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, dataFilterUserPL.county, dataFilterUserPL.field, dataFilterUserPL.well_id, User.user_name),
    retry: 2,
    enabled: false,
    onSuccess: (data) => {
      if (data?.statusType === "ERROR") {
        message.info("The calculation carried out was not processed correctly, validates the data")
      }
      setViewLoading(false)
    },
    onError: (error) => {
      setViewLoading(false)
    }
  });

  const { data: dataWellPL, isLoading: loadDataWellPL, isError: ErrorDataWellPL, refetch: refetchDataWellPL } = useQuery({
    queryKey: ['data_well_plunger_lift'],
    queryFn: () => dataWellPlungerLift(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, dataFilterUserPL.county, dataFilterUserPL.field, dataFilterUserPL.well_id, User.user_name,"parametersplungerlift"),
    retry: 2,
    enabled: false,
    onSuccess: (data) => {
      if (data?.statusType === "ERROR") {
        message.info("Problem for data")
      }
      setViewLoading(false)
    },
    onError: (error) => {
      setViewLoading(false)
    }
  });

  const { data: listWellPL, isLoading: loadListWellPL, isError: ErrorListWellPL, refetch: refetchListWellPL } = useQuery({
    queryKey: ['list_well_plunger_lift'],
    queryFn: () => listWellPlungerLift(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, User.user_name, "parametersplungerlift"),
    retry: 2,
    enabled: false,
    onSuccess: (data) => {
      if (data?.statusType === "ERROR") {
        message.info("Problem list data")
      }
    },
    onError: (error) => {
    }
  });

  const mutationWellPlungerLift = useMutation({
    mutationFn: uploadJsonWellPL,
    onSuccess: () => {
      message.success(`Data Done Successfull`);
    },
    onError: (error) => {
      message.error(`An error has occurred upload data`)
    }
  });

  const mutationUpdateWellPlungerLift = useMutation({
    mutationFn: updateJsonWellPL,
    onSuccess: () => {
      message.success(`Data Done Successfull`);
    },
    onError: (error) => {
      message.error(`An error has occurred upload data`)
    }
  });

  const mutationDeleteWell = useMutation({
    mutationFn: deleteWellPL,
    onSuccess: () => {
      message.success(`Data Well Delete Successfull`);
    },
    onError: (error) => {
      message.error(
        `An error has occurred when delete`
      );
    },
  });

  // PVT

  const mutationDataPVT = useMutation({
    mutationFn: uploadJsonPVT,
    onSuccess: () => {
      message.success(`Data PVT upload Successfull`);
    },
    onError: (error) => {
      message.error(
        `An error has occurred when upload data`
      );
    },
  });

  const { data: calcDataPVT, isLoading: loadCalcDataPVT, isError: ErrorCalcDataPVT, refetch: refetchCalcDataPVT } = useQuery({
    queryKey: ['calculate_pvt'],
    queryFn: () => calculatePVT(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, dataFilterUserPL.county, dataFilterUserPL.field, dataFilterUserPL.well_id, User.user_name),
    retry: 1,
    enabled: false,
    onSuccess: (data) => {
      if (data?.statusType === "ERROR") {
        message.info("Problem calculate data")
      }
    },
    onError: (error) => {
    }
  });

  return {
    User,
    bodyAddWell,
    userDataInfo,
    selectedWellPL,
    getSelectedWellPL,
    dataDeviationApi,
    dataEquipmentApi,
    getUserDataInfo,
    initiailInputPropiertiesOut,
    setDataDeviationApi,
    setDataEquipmentApi,
    setBodyAddWell,
    inputPropertiesOut,
    setInputPropertiesOut,
    viewLoading,
    generateRadomId,
    getDataFilterPL,
    dataFilterUserPL,
    setViewLoading,
    viewFluidLab,
    setViewFluidLab,
    inputCalculatedLab,
    dataTableLab,
    setDataTableLab,
    setExecuteTuned,
    executeTuned,
    setExecuteTunedCorrelation,
    executeTunedCorrelation,
    setIndexTableLab,
    indexTableLab,
    dataTableIPRPL,
    setDataTableIPRPL,
    inputReservoir,
    setInputReservoir,
    mutationSaveDataIPR,
    listqIPR,
    mutationCalcCasing,
    dataCalcCasing,
    refetchCalcDataCasing,
    dataWellPL,
    refetchDataWellPL,
    mutationWellPlungerLift,
    mutationDeleteWell,
    mutationUpdateWellPlungerLift,
    refetchCalcDataPressure,
    refetchCalcDataInterpolation,
    dataCalcInterpolation,
    dataCalcPressure,
    mutationCalcPressure,
    refetchListWellPL,
    listWellPL,
    loadListWellPL,
    refetchListqIPR,
    mutationDataPVT,
    refetchCalcDataPVT,
    calcDataPVT
  }
}