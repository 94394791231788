import React, { useEffect, useContext, useState } from 'react'
import { useNavigate } from "react-router-dom";
import RadioButton from "../elements/RadioButton";
import MultiSelects from "../elements/MultiSelects";
import { Modal, Checkbox, Select, message, Tag } from "antd";
import { Loading } from "../Loading";
import { useUsers } from "../../hooks/useUsers";
import { UserContext } from "../../context/User/UserContext";
import AddRegister from './AddRegister';


export default function ModalActu({ visible, onClose, user, table, typeUser }) {
    const { Option } = Select;
    const [wellboreOptionState, setWellboreOptionState] = useState(false);
    const wellboreOption = () => {
        setWellboreOptionState(!wellboreOptionState);
    };

    const options = [
        {
            value: "option 1",
        },
        {
            value: "option 2",
        },
        {
            value: "option 3",
        },
        {
            value: "option 4",
        },
    ];
    //Hooks
    // const {
    //     usuarioSeleccionado,
    //     refetchDataClientsPlatforms,
    //     refetchDataClientsProjects,
    //     refetchClientsPlatforms,
    //     refetchClientsProjects,
    //     refetchPlatforms,
    //     refetchProjects,
    //     handleCheckDuplicatesUpdate,
    //     UpdateSuperUser,
    //     setUserSelect,
    //     TableClients,
    //     TableRoles,
    //     optionProyects,
    //     optionPlatforms,
    //     Token,
    //     setOptionsClients,
    //     setProyects,
    //     setPlatforms,
    //     idDataClient,
    //     setIdDataClient,
    //     loadOptionClient,
    //     loadOptionRole,
    //     ErrorOptionClient,
    //     ErrorOptionRoles,
    //     ErrorDataClient,
    //     ErrorClientsPlatforms,
    //     ErrorClientsProjects,
    //     ErrorPlatforms,
    //     ErrorProjects,
    //     queryEnabled
    // } = useUsers()
    // const { selectedTypeCrud, selectedTableCrud } = useContext(UserContext);
    // const navigate = useNavigate();
    // //Declaration state
    // const [inputClient, setInputValueClient] = useState("");
    // const [inputTypeUser, setTypeUser] = useState(typeUser);
    // const [error, setError] = useState();
    // const [isModalOpen, setIsModalOpen] = useState(false);
    // const [messageModal, setMessageModal] = useState("");
    // const [titleModal, setTitleModal] = useState("");
    // const [messagePattern, setPattern] = useState("");
    // const [loadPage, setLoadPage] = useState(false);


    // const [isCheckedMaster, setIsCheckedMaster] = useState(false);
    // const [isCheckedSimple, setIsCheckedSimple] = useState(false);

    // const handleCheckboxChangeMaster = () => {
    //     setIsCheckedMaster(!isCheckedMaster);
    //     setIsCheckedSimple(isCheckedMaster ? isCheckedSimple : false);
    //     //console.log(isCheckedMaster)
    // };

    // const handleCheckboxChangeSimple = () => {
    //     setIsCheckedSimple(!isCheckedSimple);
    //     setIsCheckedMaster(isCheckedSimple ? isCheckedMaster : false);
    // };
    // useEffect(() => {
    //     // selectedTableCrud(table);
    //     // handleOptionsByClient();
    // }, []);

    // function handleTypeUser(event) {
    //     // selectedTypeCrud(event.target.value);
    //     setTypeUser(event.target.value);
    // }

    // const handleChange = ({ target: { name, value } }) => {
    //     const searchClient = TableClients.filter((option) => option.idclient == value);
    //     const nameClient = searchClient[0]?.client;
    //     if (name === 'user_clientId') {
    //         setUserSelect({ ...usuarioSeleccionado, [name]: Number(value), ['clients']: nameClient });
    //     }
    //     else {
    //         setUserSelect({ ...usuarioSeleccionado, [name]: Number(value) });
    //     }
    //     // handleOptionsByClient();
    // };

    // function handleLoadClient(e) {
    //     const options = e.target.value;
    //     const index = e.target.selectedIndex;
    //     if (index > 0) {
    //         setIdDataClient(options);
    //         queryEnabled[2] = true;
    //     }
    // }

    // useEffect(() => {
    //     if (idDataClient > 0) {
    //         try {
    //             refetchDataClientsPlatforms();
    //             refetchDataClientsProjects();
    //         } catch (error) {
    //             refetchProjects();
    //             refetchPlatforms();
    //             return console.error({ messageModal: error, status: "El cliente seleccioando no posee informacion relacionada" });
    //         }
    //     }
    // }, [idDataClient])

    // const handleLoadClients = async (data) => {
    //     const dataName = data.map((item) => item.clients);
    //     setUserSelect({ ...usuarioSeleccionado, clients: dataName });
    //     try {
    //         refetchProjects();
    //         refetchPlatforms();
    //     } catch (error) {
    //         return console.error({ messageModal: error, status: "El cliente seleccioando no posee informacion relacionada" });
    //     }
    // };

    // const handleAddOption = (event) => {
    //     event.preventDefault();
    //     if (inputClient.trim()) {
    //         const isOptionExists = TableClients.some((option) => option.client === inputClient);
    //         if (isOptionExists) {
    //             showModal();
    //             setTitleModal("Warning");
    //             setMessageModal("The option already exists. Please choose another name");
    //         }
    //         else {
    //             message.info("A new item has been created, please select it in the respective box");
    //             //este proceso se realiza para indicar el id del cliente de la base de datos
    //             const arrayIdclients = TableClients.map((item) => item.idclient)
    //             const endValueIdClient = Math.max(...arrayIdclients) + 1;

    //             setOptionsClients([...TableClients, { idclient: endValueIdClient, client: inputClient }]);
    //             setInputValueClient("");
    //         }
    //     }
    // };

    // const showModal = () => {
    //     setIsModalOpen(true);
    // };

    // const handleOk = () => {
    //     if (messageModal === "Update Completed") {
    //         UpdateSuperUser();
    //         setLoadPage(true);
    //         message.info("Wait a few seconds while the Super User is updated");
    //     }

    //     if (messagePattern) {
    //         setPattern(false);
    //         setIsModalOpen(false);
    //     } else {
    //         if (typeUser === "Simple User") {
    //             navigate("/Crud/external");
    //             setIsModalOpen(false);
    //         } else {
    //             navigate("/Crud/internal");
    //             setIsModalOpen(false);
    //         }
    //     }
    // };

    // const handleCancel = () => {
    //     setIsModalOpen(false);
    // };

    // if (!visible) return null;

    // if (loadPage) return (<div className="absolute w-full w-hull"><Loading /></div>);

    // if (loadOptionClient || loadOptionRole) {
    //     return <div className="absolute w-full w-hull"><Loading /></div>
    // }

    // if (ErrorOptionClient || ErrorOptionRoles || ErrorDataClient || ErrorClientsPlatforms || ErrorClientsProjects || ErrorPlatforms || ErrorProjects) {
    //     return (
    //         <div className="absolute w-[300px] h-[300px] bg-white">
    //             <div className="flex text-center justify-center items-center h-full bg-slate-400 font-sans font-bold ">
    //                 <h1>Ha ocurrido un error</h1>
    //             </div>
    //         </div>
    //     );
    // }

    const tagRender = (props) => {
        const { label, value, closable, onClose } = props;
        const onPreventMouseDown = (event) => {
            event.preventDefault();
            event.stopPropagation();
        };
        return (
            <Tag
                className="bg-pa-purple text-white rounded-md"
                onMouseDown={onPreventMouseDown}
                closable={closable}
                onClose={onClose}
                style={{
                    marginRight: 3,
                }}
            >
                {label}
            </Tag>
        );
    };

    return (
        <div className=" absolute top-0 bg-nc-gray bg-opacity-30 backdrop-blur-sm flex justify-center items-center w-full  h-[1000px] z-10 ">
            <div className="w-6/12 flex items-center justify-center lg:items-center lg:justify-center lg:w-1/2 lg:max-h-full">
                {/* <Modal
                    title={titleModal}
                    open={isModalOpen}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    className="bg-blue-500 rounded-md"
                    okButtonProps={{ className: "bg-purple-700" }}
                >
                    <p className="text-center text-lg">{messageModal}</p>
                </Modal> */}
                <form className="flex flex-col pl-[88px] w-full h-full">
                    <div className="flex flex-row max-w-[1500px] h-full">
                        <div className="flex flex-col w-1/4 h-full justify-center text-start items-start">
                            <div className="mb-5 mt-5 mr-auto ml-auto">
                                <h3 className="text-3xl text-right bg-white">PRODUCTION</h3>
                                <h3 className="text-2xl text-right bg-white mt-[-10px]">
                                    ANALYTICS
                                </h3>
                            </div>
                            <div className="flex flex-col w-full mt-3 px-[30px]">
                                <div className="flex flex-row w-full">
                                    <h1 className="flex font-row font-bold">TYPE USER</h1>
                                </div>
                                <div className="flex flex-row w-full mt-3 gap-4 justify-center items-center">
                                    <Checkbox
                                        name="CheckboxMaster"
                                    // checked={isCheckedMaster}
                                    // onChange={handleCheckboxChangeMaster}
                                    >
                                        <div className="flex flex-row text-center">
                                            <label>Master</label>
                                        </div>
                                    </Checkbox>
                                    <Checkbox
                                        name="CheckboxSimple"
                                    // checked={isCheckedSimple}
                                    // onChange={handleCheckboxChangeSimple}
                                    >
                                        <div className="flex flex-row text-center">
                                            <label>Simple User</label>
                                        </div>
                                    </Checkbox>
                                </div>
                                <div className="flex flex-row w-full mt-3 justify-start items-center">
                                    <div className="flex flex-col w-full text-start">
                                        <label
                                            htmlFor=""
                                            className="flex w-full items-end text-start text-[15px] font-bold"
                                        >
                                            USER NAME
                                        </label>
                                        <input
                                            type="text"
                                            className="w-full h-[47px] rounded-[4px] border border-solid border-[#BCBCCC] mb-[17px]"
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-row w-full justify-start items-center">
                                    <div className="flex flex-col w-full text-start">
                                        <label
                                            htmlFor=""
                                            className="flex w-full items-end text-start text-[15px] font-bold"
                                        >
                                            PASSWORD
                                        </label>
                                        <input
                                            type="password"
                                            className="w-full h-[47px] rounded-[4px] border border-solid border-[#BCBCCC] mb-[17px]"
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-row w-full justify-start items-center">
                                    <div className="flex flex-col w-full text-start">
                                        <label
                                            htmlFor=""
                                            className="flex w-full items-end text-start text-[15px] font-bold"
                                        >
                                            OPERATOR
                                        </label>
                                        {/* Espacio para el tipo de select segun TYPE USER */}
                                        {/* {!isCheckedMaster == false && (
                                            <>
                                                <Select
                                                    className="w-full max-w-[592px] h-[47px] rounded-[4px] border-solid border-[#BCBCCC] mb-[17px]"
                                                    mode="multiple"
                                                    tagRender={tagRender}
                                                    options={options}
                                                />
                                            </>
                                        )} */}
                                        {/* {!isCheckedSimple == false && ( */}
                                        <>
                                            <Select
                                                className="w-full h-[47px] rounded-[4px] border-solid border-[#BCBCCC] mb-[17px]"
                                                placeholder="Select Client"
                                            >
                                                <Option value="0">Operator 1</Option>
                                                <Option value="1">Operator 2</Option>
                                                <Option value="2">Operator 3</Option>
                                            </Select>
                                        </>
                                        {/* )} */}
                                    </div>
                                </div>
                                {/* {(isCheckedMaster || isCheckedSimple) && ( */}
                                <>
                                    <AddRegister></AddRegister>
                                </>
                                {/* // )} */}
                                <div className="flex flex-row w-full mt-3 justify-start items-center">
                                    <div className="flex flex-col w-full text-start">
                                        <label
                                            htmlFor=""
                                            className="flex w-full items-end text-start text-[15px] font-bold"
                                        >
                                            LIST
                                        </label>
                                        <Select
                                            className="w-full max-w-[592px] h-[47px] rounded-[4px] border-solid border-[#BCBCCC] mb-[17px]"
                                            mode="multiple"
                                            tagRender={tagRender}
                                            // defaultValue={["gold", "cyan"]}
                                            options={options}
                                        />
                                    </div>
                                </div>
                                <AddRegister></AddRegister>
                                <div className="flex flex-row w-full mt-3 justify-start items-center">
                                    <div className="flex flex-col w-full text-start">
                                        <label
                                            htmlFor=""
                                            className="flex w-full items-end text-start text-[15px] font-bold"
                                        >
                                            REGION
                                        </label>
                                        <Select
                                            className="w-full max-w-[592px] h-[47px] rounded-[4px] border-solid border-[#BCBCCC] mb-[17px]"
                                            mode="multiple"
                                            tagRender={tagRender}
                                            // defaultValue={["gold", "cyan"]}
                                            options={options}
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-row w-full justify-start items-center">
                                    <AddRegister></AddRegister>
                                </div>
                                <div className="flex flex-row w-full mt-3 justify-start items-center">
                                    <div className="flex flex-col w-full text-start">
                                        <>
                                            {/* Espacio para el tipo de select segun TYPE USER */}
                                            <label
                                                htmlFor=""
                                                className="flex w-full items-end text-start text-[15px] font-bold"
                                            >
                                                ROLE
                                            </label>
                                        </>
                                        {/* {isCheckedMaster && ( */}
                                        <>
                                            {/* Espacio para el tipo de select segun TYPE USER */}
                                            <Select
                                                className="w-full max-w-[592px] h-[47px] rounded-[4px] border-solid border-[#BCBCCC] mb-[17px]"
                                                mode="multiple"
                                                tagRender={tagRender}
                                                // defaultValue={["gold", "cyan"]}
                                                options={options}
                                            />
                                        </>
                                        {/* )} */}
                                    </div>
                                    <div className="mb-4 text-white">
                                        <button type="submit" className="btn-1">
                                            Update
                                        </button>
                                        <button className="btn-1" onClick={onClose}>
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                                {/* {isCheckedMaster && ( */}
                                <>
                                    <AddRegister></AddRegister>
                                </>
                                {/* )} */}

                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div >
    );
}
