import React, { useContext, useState } from 'react'
import { DashboardPlottingContext } from '../../../context/DashboardPlotting/DashboardPlottingContext';
import { COLORS_LINES } from '../../elements/types/type_plotting';

export const TablePlotting = ({platform, selectedData, colorMapping, setColorMapping }) => {

    const { dataPlotting, getDataPlotting} = useContext(DashboardPlottingContext);
    const filteredInputDomain = dataPlotting?.dataConfigGraph.filter(item =>
        selectedData.includes(item.device)
    );

    const handleInputDomain = (e, selectedValue, index, position) => {
        const updatedDomain = [...dataPlotting.dataConfigGraph];
        const newDomainValue = Number(e.target.value) || 0;
        // Encuentra el elemento correspondiente en el array de opciones y actualiza el dominio
        const indexData = updatedDomain.findIndex(item => item.device === selectedValue);
        updatedDomain[indexData].domain[position] = newDomainValue;
        getDataPlotting({...dataPlotting,dataConfigGraph:updatedDomain});
    };

    const handleColorChange = (e, label) => {
      const newColor = e.target.value;
      const updatedColor= [...dataPlotting.dataConfigGraph];
      const newDomainValue = e.target.value;
      // Encuentra el elemento correspondiente en el array de opciones y actualiza el dominio
      const indexData = updatedColor.findIndex(item => item.device === label);
      updatedColor[indexData].color = newDomainValue;
      getDataPlotting({...dataPlotting,dataConfigGraph:updatedColor});
      setColorMapping(prevMapping => ({
          ...prevMapping,
          [label]: newColor
      }));
  };
    
  return (
    <table className="min-w-full text-left text-sm font-light text-surface">
      <thead className='border-b font-medium text-black'>
        <tr>
          <th scope="col" className="px-6 py-4 border border-gray-400 text-center">Color</th>
          <th scope="col" className="px-6 py-4 border border-gray-400 text-center">Platform</th>
          <th scope="col" className="px-6 py-4 border border-gray-400 text-center">Parameter</th>
          <th scope="col" className="px-6 py-4 border border-gray-400 text-center">Scale Min</th>
          <th scope="col" className="px-6 py-4 border border-gray-400 text-center">Scale Max</th>
        </tr>
      </thead>
      <tbody>
        {filteredInputDomain.map((item, index) => {
          // Encontrar el índice del color para el item actual
          let colorIndex = dataPlotting.optionsParameters.findIndex(dat => dat.value === item.device);

          // Asegurar que el color se asigne cíclicamente
          if (colorIndex >= 0) {
            colorIndex = colorIndex % COLORS_LINES.length;
          } else {
            colorIndex = index % COLORS_LINES.length; // Usar el índice del mapeo para un color cíclico si no se encuentra
          }

          const color = colorMapping[item.device] || item.color; 

          return (
          <tr key={index} className='border-b border-neutral-200' style={{ height: '30px' }}>
            <td className="h-[45px] border border-gray-400 text-center">
                <input type="color" className='w-full h-full text-center justify-center items-center' value={color} onChange={(e) => handleColorChange(e, item.device)}/>
            </td>
            <td className="border border-gray-400 px-6 py-4 text-center">{platform}</td>
            <td className="border border-gray-400 px-6 py-4 text-center">{item.device}</td>
            <td className="h-[45px] border border-gray-400 text-center">
              <input
                type="text"
                className="w-full h-full box-border text-center justify-center items-center"
                value={item.domain[0]}
                onChange={(e) => handleInputDomain(e, item.device, index, 0)}
              />
            </td>
            <td className="h-[45px] border border-gray-400 text-center">
              <input
                type="text"
                className="w-full h-full box-border text-center justify-center items-center"
                value={item.domain[1]}
                onChange={(e) => handleInputDomain(e, item.device, index, 1)}
              />
            </td>
          </tr>
        )})}
      </tbody>
    </table>
  )
}
