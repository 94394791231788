import React, { useEffect, useState } from "react";
import {
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Scatter,
  XAxis,
  YAxis,
  Tooltip
} from "recharts";

const ChartVLP = ({ activeChecks, dataPoints, dataTableLine, dataTableLine2,  domainMaxDepth, domainMinDepth, domainMaxPressure, domainMinPressure, tuningnNav, dataPoint2, dataPoint3}) => {
  
  const [depthArray, setDepthArray] = useState([]);
  const [depthArray2, setDepthArray2] = useState([]);
  const [pressureArray, setPressureArray] = useState([]);
  const [pressureArray2, setPressureArray2] = useState([]);
  const [coordinatesPoints, setCoordinatesPoints] = useState([]);
  const [coordinatesPoints2, setCoordinatesPoints2] = useState([]);
  const [coordinatesPoints3, setCoordinatesPoints3] = useState([]);
  const [combinedData, setCombinedData] = useState([]);
  const [combinedData2, setCombinedData2] = useState([]);
  
  useEffect(() =>{
    const valueDepthArray = dataTableLine != undefined ? dataTableLine?.depth_md : [];
    const valuePressureArray = dataTableLine != undefined ? dataTableLine?.pressure : [];
  
    const valueDepthArray2 = dataTableLine2 != undefined ? dataTableLine2?.depth_md : [];
    const valuePressureArray2 = dataTableLine2 != undefined ? dataTableLine2?.pressure : [];
    
    setDepthArray(valueDepthArray)
    setDepthArray2(valueDepthArray2)
    setPressureArray(valuePressureArray)
    setPressureArray2(valuePressureArray2)

    const valueCoordinatesPoints = dataPoints?.map((item)=>({
      depth: item?.gauge_tvd,
      pressure: item?.gauge_pressure
    }));

    const valueCoordinatesPoints2 = [{
      depth: dataPoint2?.mid_perf_tvd_depth,
      pressure: dataPoint2?.reservoir_pressure
    }];

    const valueCoordinatesPoints3 = [{
      depth: dataPoint2?.mid_perf_tvd_depth,
      pressure: dataPoint3?.pwf_user
    }];
    

    setCoordinatesPoints(valueCoordinatesPoints);
    setCoordinatesPoints2(valueCoordinatesPoints2)
    setCoordinatesPoints3(valueCoordinatesPoints3)
  },[dataTableLine, dataTableLine2, dataPoints, dataPoint2, dataPoint3])
  
  const coordinatesPoints1 = [{
    depth: 9000,
    pressure: 3940
  }];
  
  useEffect(() => {
      const valueCombinedData = depthArray?.map((depth, index) => {
        return {
          depth: depth,
          pressure: Number(pressureArray[index]?.toFixed(2))
        };
      });
    
      const valueCombinedData2 = depthArray2?.map((depth, index) => {
        return {
          depth: depth,
          pressure: Number(pressureArray2[index]?.toFixed(2))
        };
      });

      setCombinedData(valueCombinedData);
      setCombinedData2(valueCombinedData2)
  }, [depthArray, depthArray2, pressureArray, pressureArray2])

  const colorsByType = {
    "0": "#5D7EC6",
    "1": "#9D6949",
    "2": "#FFC737",
    "3": "#70AC53",
    "4": "#FE3F40",
  };
 
  const getColorByType = (type) => colorsByType[type] || "#4c0519";

  function customTooltip({ active, payload, option }) {

    if (active && payload && payload.length) {
      const valuesData1 = payload[0]?.payload;
      const valuesData2 = payload[1]?.payload;

      return (
        <div
          className="custom-tooltip"
          style={{ backgroundColor: "white", padding: "5px", textAlign: "left" }}
        >
          <p>
            {`Depth: `}
            <span>{valuesData1?.depth} (ft)</span>
          </p>
          <p style={{ color: "#253fce"}}>
            {`Pressure: `}
            <span>{valuesData2?.pressure} (psi)</span>
          </p>
          {tuningnNav &&
            <>
              <p style={{ color: "#FE3F40"}}>
                {`Pressure: `}
                <span>{valuesData1?.pressure} (psi)</span>
              </p>
            </>
          }
        </div>
      );
    }
  
    return null;
  }
  
  const generateTicks = (min, max, divisions) => {
    const range = max - min;
    const step = range / (divisions - 1);
    const ticks = [];
    for (let i = 0; i < divisions; i++) {
      ticks.push(Number((min + step * i).toFixed(0))); // Redondea a 2 decimales
    }
    return ticks;
  };
  
  return (
    <div className="flex w-full h-full">
      <ResponsiveContainer width="100%" height="98%">
        <ComposedChart
          width={400}
          height={300}
          layout="vertical"
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 20,
          }}
        >
          <CartesianGrid strokeDasharray="4 4" />
          <YAxis
            dataKey="depth"
            domain={[domainMinDepth, domainMaxDepth]}
            interval={0}
            label={{
              value: `Depth (feet)`,
              style: { textAnchor: "middle" },
              angle: -90,
              position: "left",
              offset: 0,
            }}
            allowDataOverflow={true}
            name="Depth"
            tick={5}
            ticks={generateTicks(domainMinDepth, domainMaxDepth, 11)}
          />
          <XAxis
            dataKey="pressure"
            domain={[domainMinPressure, domainMaxPressure]}
            interval={0}
            orientation="top"
            type="number"
            label={{
              value: `Pressure (psi)`,
              style: { textAnchor: "middle" },
              key: "xAxisLabel",
              position: "top",
              offset: 0,
            }}
            allowDataOverflow={true}
            tick={5}
            ticks={generateTicks(domainMinPressure, domainMaxPressure, 9)}
          />
          
          {/* {activeChecks.map((type, index) => (
            
            <Line
              key={index}
              type="monotone"
              strokeWidth={2}
              data={dataGraphic[type]}
              dot={false}
              dataKey="x"
              tooltipType="none"
              stroke={getColorByType(type)}
              strokeDasharray= {type === 4 ? "5 5" : ""}
            />
          ))} */}
          <Tooltip content={customTooltip}/>
          <Line
            type="monotone"
            strokeWidth={2}
            data={combinedData}
            dot={false}
            dataKey="pressure"
            //tooltipType="none"
            stroke="#FE3F40"
            name="Pressure"
          />
          <Line
            type="monotone"
            strokeWidth={2}
            data={combinedData2}
            dot={false}
            dataKey="pressure"
            //tooltipType="none"
            stroke="#253fce"
            name="Pressure"
          />
          {dataPoint2?.enable_gauge_parameters && (
            <Scatter
              type="monotone"
              strokeWidth={5}
              data={coordinatesPoints}
              stroke="#1e1b4b"
              tooltipType="none"
            />
          )}
          <Scatter
            type="monotone"
            strokeWidth={5}
            data={coordinatesPoints2}
            stroke="#684200"
            tooltipType="none"
          />
          <Scatter
            type="monotone"
            strokeWidth={5}
            data={coordinatesPoints3}
            stroke="#216800"
            tooltipType="none"
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ChartVLP;
