export const VALUE_SELECT_PARAMETERS = { 
    lift_gas_injection_rate: "lift_gas_injection_rate", 
    water_cut: "water_cut",
    formation_gor:"formation_gor"
}

export const COLOR_GRAPH = {
    1: "cf1322",
    2: "fadb14",
    3: "73d13d",
    4: "36cfc9",
    5: "ff85c0",
    6: "871400",
    7: "002c8c",
    8: "820014"
}