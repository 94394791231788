import React, { useContext, useEffect, useRef } from 'react';
import { ResponsiveBullet } from '@nivo/bullet';
import { DashboardContext } from "../../../context/Dashboard/DashboardContentx"
import { message, Switch } from 'antd';

export const KpiGraph = ({dataKpi, nameValue, val_max, val_min, stateCheck, stateColor}) => {

    const {dataAlertGasLift, getDataAlertGasLift, getSomeAlert, someAlert, selectedWell} = useContext(DashboardContext);
   
    const data = dataKpi

    const valueAlertMin = !val_min ? 0 : val_min;
    const valueAlertMax = !val_max ? 0 : val_max;

    const handleChangeInput = (e) => {
      const {name, value} = e.target;
      
      if(name === `${nameValue}_min` && value >= `${nameValue}_max`){
        message.error("The value min cannot be less than value max")
      }else{
        const dataNewDomain = {
        ...dataAlertGasLift,
        [name]: Number(value),
        }
        getDataAlertGasLift(dataNewDomain);
        getSomeAlert({...someAlert, messageChange: true})
      }
    }
    
    const handleCheckAlert = (event, name) => {
      
      if (name === nameValue && someAlert.gas_lift.valuesCheck[nameValue] === true){
          getSomeAlert({
            ...someAlert,
            messageChange: true,
            gas_lift: {
              ...someAlert.gas_lift,
              valuesAlert: {
                ...someAlert.gas_lift.valuesAlert, 
                [name]: stateColor ? true : false},
              valuesCheck: {
                ...someAlert.gas_lift.valuesCheck,
                [name]: false},
              countAlert: someAlert.gas_lift.valuesAlert[nameValue] ? someAlert.gas_lift.countAlert - 1 : someAlert.gas_lift.countAlert,
              countCheck: someAlert.gas_lift.valuesAlert[nameValue] ? someAlert.gas_lift.countCheck + 1 : someAlert.gas_lift.countCheck
          }
        })
      } else if (name === nameValue && someAlert.gas_lift.valuesCheck[nameValue] === false) {
        getSomeAlert({
          ...someAlert,
          messageChange: true,
          gas_lift: {
            ...someAlert.gas_lift,
            valuesCheck: {...someAlert.gas_lift.valuesCheck,[name]: true},
            countAlert: someAlert.gas_lift.valuesAlert[nameValue] ? someAlert.gas_lift.countAlert + 1 : someAlert.gas_lift.countAlert,
            countCheck: someAlert.gas_lift.valuesAlert[nameValue] ? someAlert.gas_lift.countCheck - 1 : someAlert.gas_lift.countCheck
          }
        })
      }
    };

    return (
      <>
        { data &&
          <div className='w-full h-full flex flex-row'>
            <input 
              type="number" 
              name={`${nameValue}_min`}
              onChange={handleChangeInput}
              value={valueAlertMin} 
              className={`mr-1 flex w-full h-[20px] text-right items-start justify-start text-[11px]`}
              disabled={stateCheck === null || !selectedWell || selectedWell === "null"? true : false}
            />
            <p className='flex h-[20px] items-start justify-start text-[14px]'>-</p>
            <input 
              type="number" 
              name={`${nameValue}_max`} 
              onChange={handleChangeInput} 
              value={valueAlertMax} 
              className={`ml-1 flex w-full h-[20px] text-left items-start justify-start text-[11px]`}
              disabled={stateCheck === null || !selectedWell || selectedWell === "null"? true : false}
            />
            <Switch
              style={{
              backgroundColor: someAlert?.gas_lift?.valuesCheck[nameValue] ? "#662D91" : "#707070",
              borderColor: someAlert.gas_lift?.valuesCheck[nameValue] ? "#662D91" : "#707070",
              }}
              checked={someAlert?.gas_lift?.valuesCheck[nameValue]}
              onChange={(event) => handleCheckAlert(event, nameValue)}
              size="small"
              disabled={stateCheck === null || !selectedWell || selectedWell === "null"? true : false}
            ></Switch>
          </div>
        }
    </>
    )
}
