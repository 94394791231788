import React, { useContext, useEffect, useState } from 'react'
import { Navbar } from '../../components/layout/Navbar'
import { Sidebar } from '../../components/layout/Sidebar'
import { DashboardContext } from '../../context/Dashboard/DashboardContentx';
import { message, Select, Tag } from 'antd';
import { useTabDCA } from '../../hooks/useTabDCA';
import { useTabGaslift } from '../../hooks/useTabGaslift';
import { ChartPlotting } from '../../components/Plotting/GraphicsPlotting/ChartPlotting';
import { TablePlotting } from '../../components/Plotting/Complements/TablePlotting';
import { COLORS_LINES } from '../../components/elements/types/type_plotting';

import { useTabPlotting } from '../../hooks/useTabPlotting';
import { DashboardPlottingContext } from '../../context/DashboardPlotting/DashboardPlottingContext';

export const Plotting = () => {
  const [wellboreOptionState, setWellboreOptionState] = useState(false);
  const { getDataPage } = useContext(DashboardContext);
  const {
    dataListPlatforms,
    refetchDataListPlatforms,
    dataListDevices,
    refetchDataListDevices,
    dataPlottingIOT,
    refetchDataPlottingIOT,
    inputDataPlot,
    setInputDataPlot,
    optionsAllIOT,
    refetchParamsPlotting
  } = useTabPlotting();

  const { dataPlotting, getDataPlotting } = useContext(DashboardPlottingContext);
  const { refetchDataADDWellDCA, dataGetADDWellDCA } = useTabDCA();
  const { refetchListWellsGasLift, listWellsGasLift } = useTabGaslift();
  const [colorMapping, setColorMapping] = useState({});
  const { Option } = Select;
  // const sortedDomainPlotting = optionsParmsPlotting?.sort((a, b) => a.label.localeCompare(b.label));

  const safeArray1 = dataGetADDWellDCA?.data || []; // Si array1 es null, se usa un arreglo vacío
  const safeArray2 = listWellsGasLift?.data || []; // Si array2 es null, se usa un arreglo vacío

  const concatListWells = safeArray1.concat(safeArray2);
  const seenNames = new Set();
  const listWells = concatListWells?.filter(item => {
    // Check if the well_name is already in the Set
    if (seenNames.has(item.well_name)) {
      return false; // Skip this item if it's a duplicate
    } else {
      seenNames.add(item.well_name); // Add to Set if it's unique
      return true; // Include this item
    }
  });

  useEffect(() => {
    getDataPage("Plotting");
    refetchDataListPlatforms();
    refetchDataPlottingIOT();
    refetchParamsPlotting()
  }, [])

  useEffect(() => {
    if (!!dataPlotting) {
      const dataSave = {
        selected_platform: dataPlotting.selected_platform,
        selected_device: dataPlotting.selected_device,
        selected_parameter: dataPlotting.selected_parameter,
        optionsParmsPlotting: dataPlotting.optionsParmsPlotting,
        dataPlotting: dataPlotting.dataPlotting
      }
      setInputDataPlot(dataSave)
      if (!!dataPlotting.selected_platform && !!dataPlotting.selected_device && !!dataPlotting.selected_device && !!dataPlotting.optionsParmsPlotting && dataPlotting.dataPlotting) {
        setTimeout(() => {
          refetchDataListDevices();
        }, 2000);
      }
    }
  }, [])


  const wellboreOption = () => {
    setWellboreOptionState(!wellboreOptionState);
  };

  const handleChangeSelect = (nameValue, selectedValue) => {
    if (nameValue === "selected_platform") {
      setInputDataPlot((prevState) => ({
        ...prevState,
        ["selected_device"]: [],
        ["selected_parameter"]: []
      }));
      getDataPlotting({
        ...dataPlotting,
        selected_platform: selectedValue,
      })
      setTimeout(() => {
        refetchDataListDevices();
      }, 700);

    }
    else if (nameValue === "selected_device") {
      setInputDataPlot((prevState) => ({
        ...prevState,
        ["selected_parameter"]: [],
      }));

      const filteredData = optionsAllIOT?.filter(item =>
        selectedValue.some(label => item.label.startsWith(label))
      ).sort((a, b) => a.label.localeCompare(b.label));
      const infoPlotting = filteredData.map((item, index) => ({
        id: index,
        device: item.label,
        color: COLORS_LINES[index % COLORS_LINES?.length],
        domain: [0, 0]
      }));

      getDataPlotting({
        ...dataPlotting,
        dataPlotting: infoPlotting,
        optionsParmsPlotting: filteredData,
        selected_device: selectedValue
      })
    }
    if (nameValue === "selected_parameter") {
      if (selectedValue?.length > 10) {
        message.warning('You can only select up to 10 options.');
        return; // No actualizar el estado si se supera el límite
      }

      // Crear un nuevo mapeo de colores con solo los seleccionados actuales
      const newColorMapping = { ...colorMapping };
      Object.keys(newColorMapping).forEach((key) => {
        if (!selectedValue.includes(key)) {
          // Elimina los parámetros que ya no están seleccionados
          delete newColorMapping[key];
        }
      });

      // Asignar colores a los parámetros seleccionados, manteniendo los colores existentes
      selectedValue.forEach((value, index) => {
        if (!newColorMapping[value]) {
          // Solo asignar un nuevo color si no hay uno existente
          newColorMapping[value] = COLORS_LINES[index % COLORS_LINES.length];
        }
      });

      // Actualizar el estado de `colorMapping`
      setColorMapping(newColorMapping);

      // Actualizar los colores en `dataPlotting`
      const updatedPlottingData = dataPlotting?.dataPlotting?.map((item) => {
        if (newColorMapping[item.device]) {
          return { ...item, color: newColorMapping[item.device] };
        }
        return item;
      });

      getDataPlotting({
        ...dataPlotting,
        selected_parameter: selectedValue,
        dataPlotting: updatedPlottingData,
      });
    }

    setInputDataPlot((prevState) => ({
      ...prevState,
      [nameValue]: selectedValue,
    }));
  };

  const tagRender = (props) => {
    const { label, value, closable, onClose, colorIndex } = props;
    const deviceConfig = dataPlotting?.dataPlotting?.find((item) => item.device === value);
    const color = deviceConfig ? deviceConfig.color : COLORS_LINES[colorIndex % COLORS_LINES?.length];
    // const color = colorMapping[value] || dataPlotting ? coloDefault: COLORS_LINES[colorIndex % COLORS_LINES?.length];
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        color={color}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginInlineEnd: 4,
        }}
      >
        {label}
      </Tag>
    );
  };


  const getColorIndex = (value) => {
    const index = inputDataPlot.selected_parameter?.indexOf(value);
    return index < COLORS_LINES?.length ? index : index % COLORS_LINES?.length;
  };

  return (
    <div className='flex flex-col w-full h-screen bg-[#F8F8F8]'>
      <div className='flex'>
        <Navbar
          wellboreOption={wellboreOption}
        ></Navbar>
        <Sidebar className="flex abosolute h-full"></Sidebar>
      </div>
      <div className='flex flex-col w-full h-full pl-[101px] p-[21px] '>
        <div className='flex flex-col w-full h-full bg-white'>
          <div className="flex w-full h-[30px] justify-start text-start items-start mt-[20px] pl-[55px] mb-[20px] ">
            <div className="flex flex-row w-full gap-5 justify-start text-center items-center ">
              <h1 className="flex flex-row font-bold text-[20px]" >Plotting</h1>
            </div>
          </div>
          <div className='flex flex-row w-full gap-[14px] ml-[55px] mb-[43px]'>
            <div className='flex flex-col w-full max-w-[203px]'>
              <label htmlFor="" className='text-[12px]'>Platforms</label>
              <Select
                disabled={dataListPlatforms?.data ? false : true}
                name="selected_platform"
                value={inputDataPlot.selected_platform || ""}
                placeholder="Selected option"
                className="w-full max-w-[203px] h-[42px] text-left"
                onChange={(value) => handleChangeSelect("selected_platform", value)}
              >
                {/* <Option value="">Selected option</Option> */}
                {
                  dataListPlatforms?.data
                    // Ordenar los elementos alfabéticamente
                    .sort((a, b) => a.localeCompare(b))
                    .map((item, index) => (
                      <Option key={index} value={item}>
                        {item.toLowerCase() === "all" ? "All" : item}
                      </Option>
                    ))
                }
              </Select>
            </div>
            <div className='flex flex-col w-full max-w-[203px] max-h-[100px]'>
              <label htmlFor="" className='text-[12px]'>Devices</label>
              <Select
                disabled={inputDataPlot?.selected_platform === "" ? true : false}
                name="selected_device"
                mode='multiple'
                value={inputDataPlot.selected_device}
                placeholder="Selected option"
                className="w-full  min-h-[42px] h-full text-left overflow-y-auto"
                onChange={(value) => handleChangeSelect("selected_device", value)}
              >
                {
                  dataListDevices?.data
                    ?.sort((a, b) => a.localeCompare(b))
                    ?.filter(item => item !== "all")
                    .map((item, index) => (
                      <Option key={index} value={item}>
                        {item.toLowerCase() === "all" ? "All" : item}
                      </Option>
                    ))
                }
              </Select>
            </div>
            <div className='flex flex-col w-full max-w-[592px]'>
              <label htmlFor="" className='text-[12px]'>Parameters</label>
              <Select
                disabled={inputDataPlot?.selected_platform === "" || !inputDataPlot?.selected_device?.length ? true : false}
                name="selected_parameter"
                mode='multiple'
                tagRender={(props) => tagRender({
                  ...props,
                  colorIndex: getColorIndex(props.value),
                })}
                style={{
                  width: '100%',
                }}
                placeholder="Selected option"
                defaultValue={['Static Pressure']}
                className="w-full  min-h-[42px] text-left"
                value={inputDataPlot.selected_parameter}
                onChange={(value) => handleChangeSelect("selected_parameter", value)}
                options={dataPlotting.optionsParmsPlotting}
                showSearch // Activar el buscador
                optionFilterProp="label" // Filtrar opciones según el texto de la etiqueta
                filterOption={(input, option) =>
                  option.label.toLowerCase().includes(input.toLowerCase())
                }
              >
              </Select>
            </div>
          </div>
          <div className='flex flex-col w-full h-[700px]'>
            <div className='flex w-full h-2/3'>
              <ChartPlotting data={dataPlottingIOT ? dataPlottingIOT?.data : []} linesData={inputDataPlot.selected_parameter ? inputDataPlot.selected_parameter : []} colorMapping={colorMapping} ></ChartPlotting>
            </div>
            <div className='w-full h-1/3 overflow-y-auto mt-10'>
              <TablePlotting platform={inputDataPlot ? inputDataPlot.selected_platform : ""} selectedData={inputDataPlot.selected_parameter ? inputDataPlot.selected_parameter : []} colorMapping={colorMapping} setColorMapping={setColorMapping}></TablePlotting>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}