import React from 'react'
import { DashboardPlottingContext } from './DashboardPlottingContext'
import { useDashboardPlotting } from '../../hooks/useDashboardPlotting'

export const DashboardPlottingProvider = ({children}) => {
const {
  dataPlotting,
  getDataPlotting,
  optionsParmsPlotting,
  getOptionsParmsPlotting
} = useDashboardPlotting()
  return (
    <DashboardPlottingContext.Provider value={{    
      dataPlotting,
      getDataPlotting,
      optionsParmsPlotting,
      getOptionsParmsPlotting
    }}>
        {children}
    </DashboardPlottingContext.Provider>
  )
}
