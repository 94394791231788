export const STATUS_CODE_VLP = { SUCCESS: 200044, ERROR: 101010}
export const STATUS_CODE_UNTUNED_VLP = { SUCCESS: 200044, ERROR: 101010}
export const STATUS_DATA_VLP = { SUCCESS: 200075, ERROR: 101010}

export const STATUS_CODE_FLOW = { SUCCESS: 200044, ERROR: 101010}
export const STATUS_CODE_FLOW_GRAPH = { SUCCESS: 200044, ERROR: 101010}

export const STATUS_CODE_DCA_TABLES = { SUCCESS: 200090, ERROR: 101010}
export const STATUS_DATA_DCA = { SUCCESS: 200075, ERROR: 101010}

export const STATUS_DATA_GAS_LIFT = { SUCCESS: 200075, ERROR: 101010}
export const STATUS_CODE_WELL_GASLIFT = { SUCCESS: 200075, ERROR: 101010}
export const STATUS_TYPE_WELL_GASLIFT= { SUCCESS: "SUCCESS", ERROR: "ERROR"}

export const STATUS_CODE_CALCULATE_IPR= { SUCCESS: 200044, ERROR: 101010}
export const STATUS_TYPE_CALCULATE_IPR= { SUCCESS: "SUCCESS", ERROR: "ERROR"}

export const STATUS_TYPE_CALCULATE_PVT= { SUCCESS: "SUCCESS", ERROR: "ERROR"}

export const STATUS_TYPE_DATA_IOT = {SUCCESS: 200077, ERROR: 101010, STATUSCODE: 401001 }
export const STATUS_DATA_HISTORY= { STATUSCODE: 500201, SUCCESS: "SUCCESS", ERROR: "ERROR"}

export const STATUS_CODE_SCREENING_TOOL = {SUCCESS: 200044, ERROR: 500201}
