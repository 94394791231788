import React, { useEffect, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

function CustomTooltip({ active, payload,  }) {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    return (
      <div className="custom-tooltip" style={{ backgroundColor: 'white', padding: '5px' }}>
        <p style={{ color: '#000000' }}>{`ql_max: ` + data.ql_max + ` (psia)`}</p>
        <p style={{ color: '#873d01' }}>{`Plunger Lift performance: ` + data.press_csg_avg + ` (STB/D)`}</p>
        <p style={{ color: '#de1919' }}>{`Surface Casing Pressure @ BHP: ` + data.final_press + ` (psia)`}</p>
        <p style={{ color: '#8884d8' }}>{`Inflow Performance Relationship: ` + data.data_inter + ` (psia)`}</p>
      </div>
    )
  }
}

export const ChartPlungerLift = ({dataCasingChart, dataPressChart}) => {
  const [ dataGraph, setDataGraph ] = useState([]);

  useEffect(() => {
    if (!!dataPressChart && !!dataCasingChart) {
      if(Object.keys(dataPressChart).length && Object.keys(dataCasingChart).length ){
        const combinedData = {
          data_inter: dataPressChart.data_interpolation,
          final_press: dataPressChart.final_pressures,
          press_csg_avg: dataCasingChart.table_casing.map(item => item.press_csg_avg),
          ql_max: dataCasingChart.table_casing.map(item => item.ql_max)
        };

        const data = combinedData.data_inter.map((value, index) => ({
          id: index + 1,
          data_inter: combinedData.data_inter[index],
          final_press: combinedData.final_press[index],
          press_csg_avg: combinedData.press_csg_avg[index],
          ql_max: combinedData.ql_max[index]
      }));
        setDataGraph(data);
      }
    }else{
      setDataGraph([]);
    }
  }, [dataPressChart]);
  // console.log(dataGraph);
  
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={500}
        height={300}
        data={dataGraph}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 15,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          type='number'
          dataKey="ql_max"
          label={{
            value: `Liquid Rate (STB/D)`,
            position: 'insideBottom ',
            style: { textAnchor: "middle" },
            dy: 20,
          }}
          tickCount={15}
        />
        <YAxis
          type='number'
          label={{
            value: `Pressure (psia) at Mid-perf Depth, TVD`,
            angle: -90,
            position: 'insideLeft',
            offset: -15,
            dy: 140,
          }}
          tickCount={15}
        />
        <Tooltip content={<CustomTooltip/>}/>
        <Line type="monotone" dataKey="press_csg_avg" stroke="#873d01" />
        <Line type="monotone" dataKey="final_press" stroke="#de1919" />
        <Line type="monotone" dataKey="data_inter" stroke="#8884d8" />
      </LineChart>
    </ResponsiveContainer>
  );
};
