import React, { useEffect, useState } from "react";
import { Select, initTE } from "tw-elements";
import { Modal, message } from "antd";

function MultiSelects(props) {
  useEffect(() => {
    initTE({ Select });
  }, []);

  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  // const [hiddenElement, setHiddenElement] = useState(false);
  
  // useEffect(() => {
  //   setHiddenElement(props.hiddenSelect);
  // }, [props.hiddenSelect]);


  useEffect(() => {
    setOptions(props.Options);
  }, [props.Options]);

  const handleInputChange = (event) => {
    const data = event.target.value.toLowerCase();
    const pattern = /^[a-zA-Z0-9\s-_]*$/;

    if (pattern.test(data)) {
      const transformedData = data.replaceAll(' ', '_');
      setInputValue(transformedData);
    } else {
      showModal();
    }
  };

  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleSelectChange = (event) => {
    const options = event.target.options;
    const selected = [];
    //el type process se creo para separar las ejecuciones del registro y de la actualizacion
    if(props.onTypeProcess === "Register"){
      for (let i = 0; i < options.length; i++) {
        if (options[i].selected) {
          selected.push({idclient:options[i].value,nameId:options[i].text});
        }
      }
      const nameIdOption = props.id === undefined ? props.idclient : props.id;
      const selectedOption = selected.map(objeto => {
        const { nameId, ...resto } = objeto;
        return { [nameIdOption]: nameId, ...resto };
      });
      setSelectedOptions(selectedOption);
      props.onSelectValue(selectedOption);
    }
    else{
      for (let i = 0; i < options.length; i++) {
        if (options[i].selected) {
          selected.push({idclient:options[i].value,nameId:options[i].text});
        }
      }
      const nameIdOption = props.id === undefined ? props.idclient : props.id;
      const selectedOption = selected.map(objeto => {
        const { nameId, ...resto } = objeto;
        return { [nameIdOption]: nameId, ...resto };
      });
      setSelectedOptions(selectedOption);
      props.onSelectValue(selectedOption);
    }
  };
  const [newOption, setNewOption] = useState("");
  const [modalVisible, setModalVisible] = useState(false);

  const handleAddOption = (event) => {
    event.preventDefault();
    const nameIdOption = props.id === undefined ? props.idclient : props.id;

    if(nameIdOption === "clients"){
      if (inputValue.trim() !== "") {
        const isOptionExists = options.some((option) => option.client === inputValue);
        if (isOptionExists) {
          setModalVisible(true);
        } else {
          message.info("A new item has been created, please select it in the respective box");
          setOptions([...options, {idclient: options.length+1, client: inputValue}]);
          setInputValue("");
        }
      }
    }else{
      if (inputValue.trim() !== "") {
        const isOptionExists = options.some((option) => option === inputValue);
        if (isOptionExists) {
          setModalVisible(true);
        } else {
          message.info("A new item has been created, please select it in the respective box");
          setOptions([...options, inputValue]);
          setInputValue("");
        }
      }
    }

  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleModalOk = () => {
    setModalVisible(false);
  };
  return (
    <div >
      <div className="mb-4 border-2 border-pc-purple rounded">
        <select
          id={props.id === undefined ? props.idclient : props.id}
          data-te-select-init
          data-te-class
          data-te-class-form-outline="relative bg-white"
          multiple
          onChange={handleSelectChange}
          value={props.OptionsId}>
          {options.map((option, index) => (
            <option
              key={index}
              value={option.idclient === undefined ? option : option.idclient}
            >
              {option.client === undefined ? option : option.client}
            </option>
          ))}
        </select>
      </div>
      <div className="mb-4">
        <input
          type="text"
          onKeyUp={handleInputChange}
          onChange={handleInputChange}
          value={inputValue}
          className="shadow appearance-none border-2 border-pc-purple rounded w-64 py-2 px-3 text-nc-gray leading-tight focus:outline-none"
          placeholder={props.placeholder}
        />
        <button
          onClick={handleAddOption}
          className="inline-block rounded bg-pc-purple px-3 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] ml-3"
        >
          Add
        </button>
      </div>
      <Modal
        title="Warning!"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        className="bg-blue-500 rounded-md"
        okButtonProps={{
          className: "bg-pc-purple",
        }}
      >
        <p className="text-center text-lg">
          The entered value contains illegal characters
        </p>
      </Modal>
      <Modal
        title="Warning!"
        className="bg-blue-500 rounded-md"
        okButtonProps={{
          className: "bg-pc-purple",
        }}
        open={modalVisible}
        onOk={handleModalOk}
        onCancel={handleModalOk}
      >
        <p>The option already exists. Please choose another name</p>
      </Modal>
    </div>
  );
}
export default MultiSelects;
