import React, { useContext, useEffect, useState } from 'react'
import { Navbar } from "../../components/layout/Navbar";
import { Sidebar } from "../../components/layout/Sidebar";
import { DashboardContext } from '../../context/Dashboard/DashboardContentx';



export const DashboardPlungerLift = () => { 
  const [wellboreOptionState, setWellboreOptionState] = useState(false);
  const {getDataPage} = useContext(DashboardContext)
  getDataPage("DashboardPlungerLift");
  useEffect(() => {

  }, [])

  const wellboreOption = () => {
    setWellboreOptionState(!wellboreOptionState);
  };

  return (
    <div className='flex flex-col w-full h-screen bg-white'>
      <div className="flex">
        <Navbar ></Navbar>
        <Sidebar className="flex abosolute h-full" />
      </div>
    </div>
  )
}
