import { serviceEndPoints } from "../../config/config"

const BASE_URL_WELL = serviceEndPoints.GASLIFT.BASE_URL_WELL;
const BASE_ULR_DATA_HISTORY = serviceEndPoints.GASLIFT.BASE_URL_DATA_HISTORY;
const DB_URL_VOLUMETRACKER = serviceEndPoints.GASLIFT.BASE_URL_VOLUMETRACKER;

export const createParametersGasLift = async (dataJson) => {
    try {
        const operator = dataJson.operator;
        const lease = dataJson.lease;
        const region = dataJson.region;
        const county = dataJson.county;
        const field = dataJson.field;
        const well = dataJson.well;
        const username = dataJson.username;
        const data = dataJson.data;

        const response = await fetch(`${BASE_URL_WELL}/parameters/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}/parametersgaslift`, {
            method: "POST",
            headers: { 
            "Content-Type": "application/json",
            "Authorization": "Token TroT6HpzeXw78LnkgPrPO772lSBAYELfpFWzpC1S3" },
            body: JSON.stringify(data)
        });
    return response.json();
    } catch (error) {
    throw new Error("Problem getting data");
    }
};

export const updateParametersGasLift = async (dataJson) => {
    try {
        const operator = dataJson.operator;
        const lease = dataJson.lease;
        const region = dataJson.region;
        const county = dataJson.county;
        const field = dataJson.field;
        const well = dataJson.well;
        const username = dataJson.username; 
        const response = await fetch(`${BASE_URL_WELL}/parameters/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}/parametersgaslift`, {
            method: "PUT",
            headers: { 
            "Content-Type": "application/json",
            "Authorization": "Token TroT6HpzeXw78LnkgPrPO772lSBAYELfpFWzpC1S3" },
            body: JSON.stringify(dataJson.data)
        });
    return response.json();
    } catch (error) {
    throw new Error("Problem getting data");
    }
};

export const deleteParametersGasLift = async (dataJson) => {
    try {
        const operator = dataJson.operator;
        const lease = dataJson.lease;
        const region = dataJson.region;
        const county = dataJson.county;
        const field = dataJson.field;
        const well = dataJson.well;
        const username = dataJson.username; 
        const response = await fetch(`${BASE_URL_WELL}/parameters/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}/parametersgaslift`, {
            method: "DELETE",
            headers: { 
            "Content-Type": "application/json",
            "Authorization": "Token TroT6HpzeXw78LnkgPrPO772lSBAYELfpFWzpC1S3" }
        });
    return response.json();
    } catch (error) {
    throw new Error("Problem getting data");
    }
};

export const getParametersGasLift = async (operator,lease,region,county,field,well,username) => {
  try {
    const response = await fetch(`${BASE_URL_WELL}/parameters/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}/parametersgaslift`, {
      method: "GET",
      headers: { 
        "Authorization": "Token TroT6HpzeXw78LnkgPrPO772lSBAYELfpFWzpC1S3" }
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem getting data");
  }
};

export const getListWellGasLift = async (operator,lease,region,username) => {
  try {
    const response = await fetch(`${BASE_URL_WELL}/parameterslist/${operator}/${lease}/${region}/${username}/parametersgaslift`, {
      method: "GET",
      headers: { 
        "Authorization": "Token TroT6HpzeXw78LnkgPrPO772lSBAYELfpFWzpC1S3"}
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem getting data");
  }
};

export const getWellsUserGasLift = async (operator,lease,region,username) => {
  try {
    const response = await fetch(`${BASE_URL_WELL}/parameterslist/${operator}/${lease}/${region}/${username}/parametersgaslift`, {
      method: "GET",
      headers: { 
        "Authorization": "Token TroT6HpzeXw78LnkgPrPO772lSBAYELfpFWzpC1S3"}
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem getting data");
  }
};

export const getDataHistory = async (operator,lease,region,county,field,wellid,username) => {
  try{
      const response = await fetch(`${BASE_ULR_DATA_HISTORY}/get-data/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${username}`, {
          method: "Get",
          headers: {
              "Authorization": "Token 3Mex3aNFqENWFpjQ2mhgNhYhOxX9TJdeuez7yvEvh"
          }
      });
      return response.json();
  } catch (error) {
      throw new Error("Problem getting the information")
  }
};

export const createDataHistory = async (dataJson) => {
  try{
    const operator = dataJson.operator;
    const lease = dataJson.lease;
    const region = dataJson.region;
    const county = dataJson.county;
    const field = dataJson.field;
    const well = dataJson.wellid;
    const username = dataJson.username;
    const data = dataJson.data;
    const body = {data: data};
    
    const response = await fetch(`${BASE_ULR_DATA_HISTORY}/upload-data/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`, {
    //const response = await fetch(`https://rnjmrk51-4000.use2.devtunnels.ms/upload-data/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        "Authorization": "Token 3Mex3aNFqENWFpjQ2mhgNhYhOxX9TJdeuez7yvEvh"},
        body: JSON.stringify(body)
    });
  return response.json();
  }catch (error) {
    throw new Error("Problem creating data history")
  }
};

export const updateDataHistory = async (dataJson) => {
  try{

    const operator = dataJson.operator;
    const lease = dataJson.lease;
    const region = dataJson.region;
    const county = dataJson.county;
    const field = dataJson.field;
    const well = dataJson.wellid;
    const username = dataJson.username;
    const data = dataJson.data;
    const body = {data: data};

    const response = await fetch(`${BASE_ULR_DATA_HISTORY}/update-data/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`, {
    //const response = await fetch(`https://rnjmrk51-4000.use2.devtunnels.ms/update-data/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`, {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
        "Authorization": "Token 3Mex3aNFqENWFpjQ2mhgNhYhOxX9TJdeuez7yvEvh"},
        body: JSON.stringify(body)
    });
  return response.json();
  }catch (error) {
    throw new Error("Problem update data history")
  }
};

export const deleteDataHistory = async (dataJson) => {
  try{
    const operator = dataJson.operator;
    const lease = dataJson.lease;
    const region = dataJson.region;
    const county = dataJson.county;
    const field = dataJson.field;
    const well = dataJson.well;
    const username = dataJson.username;

    const response = await fetch(`${BASE_ULR_DATA_HISTORY}/delete-data/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`, {
    //const response = await fetch(`https://rnjmrk51-4000.use2.devtunnels.ms/delete-data/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`, {
      method: "DELETE",
      headers: {
        "Authorization": "Token 3Mex3aNFqENWFpjQ2mhgNhYhOxX9TJdeuez7yvEvh"},
    });
  return response.json();
  }catch (error) {
    throw new Error("Problem delete data history")
  }
};

export const getListDataHistory = async (operator,lease,region,username) => {
  try{
      
      const response = await fetch(`${BASE_ULR_DATA_HISTORY}/list-wells/${operator}/${lease}/${region}/${username}`, {
      //const response = await fetch(`https://rnjmrk51-4000.use2.devtunnels.ms/list-wells/operator/lease/region/username`, {
          method: "GET",
          headers: {
              "Authorization": "Token 3Mex3aNFqENWFpjQ2mhgNhYhOxX9TJdeuez7yvEvh"
          }
      });
      return response.json();
  } catch (error) {
      throw new Error("Problem getting the information")
  }
};

export const uploadJsonVolumeTracker = async (dataJson) => {
  try {
    const operator = dataJson.operator;
    const lease = dataJson.lease;
    const region = dataJson.region;
    const county = dataJson.county;
    const field = dataJson.field;
    const userName = dataJson.userName;
    const data = dataJson.data;
    const response = await fetch(`${DB_URL_VOLUMETRACKER}/calculate_averages_volume/${operator}/${lease}/${region}/${county}/${field}/${userName}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Token 23df4b99a9d4833bdcb0dbc5192b55b5250da7fee8a6a51fc82694721b0d04c9"},
      body: JSON.stringify(data)
    }); 
    return response.json();
  } catch (error) {
    throw new Error("Problem getting the well")
  } 
};

export const getJsonVolumeTracker = async (operator,lease,region,county,field,wellid,userName) => {
  try {
    const response = await fetch(`${DB_URL_VOLUMETRACKER}/results_averages_volume/${operator}/${lease}/${region}/${county}/${field}/${userName}`, {
    method: "GET",
    headers: {
      "Authorization": "Token 23df4b99a9d4833bdcb0dbc5192b55b5250da7fee8a6a51fc82694721b0d04c9"},
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem getting the well")
  }
};