import React, { useEffect, useState } from 'react'

export const useDashboardSCT = () => {
    const [dataFilterUserSCT, setDataFilterUserSCT] = useState(null);
    useEffect(()=>{
        const getDataFilterUserSCT = JSON.parse(sessionStorage.getItem('dataFilterUserSCT'));
        if (getDataFilterUserSCT) {
            setDataFilterUserSCT(getDataFilterUserSCT);
        }
    },[])

    const getDataFilterUserSCT = (options) => {
        setDataFilterUserSCT(options);
        sessionStorage.setItem('dataFilterUserSCT', JSON.stringify(options));
      }
  return {
    dataFilterUserSCT,
    getDataFilterUserSCT
  }
}
