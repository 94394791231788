import { ExclamationCircleFilled } from "@ant-design/icons";
import { Checkbox, Menu, Modal, message, Switch, Tooltip } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ExpandIcon1 } from "../../assets/icon/expand1.svg";
import { ReactComponent as IconAdd } from "../../assets/img/icons/add.svg";
import { ReactComponent as IconDelete } from "../../assets/img/icons/delete.svg";
import { AlertModal } from "../../components/AlertModal";
import { ImportFile } from "../../components/GasLift/Complements/ImportFile";
import ChartVLP from "../../components/GasLift/GraphicsTuning/ChartVLP";
import { Navbar } from "../../components/layout/Navbar";
import { Sidebar } from "../../components/layout/Sidebar";
import { Loading } from "../../components/Loading";
import { DashboardContext } from "../../context/Dashboard/DashboardContentx";
import { useTabGaslift } from "../../hooks/useTabGaslift";
import { useTabTuning } from "../../hooks/useTabTuning";
import { useUsers } from "../../hooks/useUsers";
import { useTabIOT } from '../../hooks/useTabIOT';
import { STATUS_TYPE_DATA_IOT } from "../../components/elements/types/type_response_API";
import { InfoCircleOutlined } from '@ant-design/icons';
import { WELL_SENSOR_TYPE_DEFAULT } from "../../components/elements/types/type_gas_lift";
import { logDOM } from "@testing-library/react";

const initialTuningData = [
  {
    id: 0,
    test_date: "",
    wthp_psia: "",
    wtht_deg_f: "",
    water_cut: "",
    liquid_rate: "",
    gauge_depth: "",
    gauge_pressure: "",
    reservor_pressure: "",
    gor: "",
  },
];

const initialDataTubing = [
  {
    id: 0,
    nameTubing: "",
    measured_depth: "",
    internal_diameter: "",
    absolute_roughness: "",
  }
];

const initialDataGauge = [
  {
    id: 0,
    test_date:"",
    gauge_tvd:"",
    gauge_pressure:"",
    gauge_temperature:"",
  }
];
const initialDataTestVLP= [
  {
    id: 0,
    depth_tvd:"",
    measured_pressure_tvd:"",
    oil_rate_tvd:"",
  }
];

const initialData = {
  mid_perf_tvd_depth: "",
  reservoir_temperature:"",
  reservoir_pressure:"",
  test_date_pp:"",
  oil_rate:"",
  gas_rate:"",
  gor_pp:"",
  water_cut:"",
  test_date_pp:"",
  test_date_glp:"",
  injection_gas_rate:"",
  injection_pressure:"",
  test_date_whp:"",
  wellhead_temperature:"",
  wellhead_pressure:"",
  gas_lift_valve_depth_1:"",
  gas_lift_valve_depth_2:"",
  gas_lift_valve_depth_3:"",
  gas_injection_distribution_valve_1:"",
  gas_injection_distribution_valve_2:"",
  gas_injection_distribution_valve_3:"",
  tubing_data: [],
  gauge_data: [],
  enable_gauge_parameters: [],
  active_checks: [],
  paramter1_vlp: "",
  paramter2_vlp: "",
  number_of_intervals: "",
  test_data: [],
  calculated_pressure: "",
  mse_vlp: "",
  whpCheck: false,
  timeIOTWhp: 900000,
  drawdownPres: "",
  calculatedPressRD: "",
  calculatedDrawdown: ""
};

const typeLineGraph = {
  0: "Beggs and Brill",
  1: "Duns and Ros",
  2: "Fancher Brown",
  3: "Hagedorn Brown",
  4: "Wolgha"
};

const typeLineColorGraph = [
  "#5D7EC6",
  "#9D6949",
  "#FFC737",
  "#70AC53",
  "#FE3F40",
];

const { confirm } = Modal;
export const TuningGasLift = () => {
  const navigate = useNavigate();
  const { User } = useUsers();
  const {
    queryCalculateTuningVLP,
    queryCalculateUntunedVLP,
    dataFilterUserGasLift,
    mutationCreateDataTuning,
    mutationUpdateDataTuning,
    queryDataTuning,
    loadDataTuning,
    queryDataWell,
    queryPressDrowDown,
    refetchTuning,
    refetchWell,
    refetchCalculateTuningVLP,
    refetchCalculateUntunedVLP,
    refetchPressDrowDown,
    mutationCreateCalTuningVLP,
    mutationUpdateCalTuningVLP,
    maxDepth,
    minDepth,
    maxPressure,
    minPressure,
    calculateRangesGraphVLP,
    enableCalculatedMse,
    setEnableCalculatedMse
  } = useTabTuning();
  const { refetchDataIOTGasLift, dataIOTGasLift, viewLoading, dataGetIOTGasLift } = useTabIOT();
  const { userDataInfo, selectedWell } = useTabGaslift();
  const inputGetDataTuning = queryDataTuning && selectedWell ? queryDataTuning.data : null;
  const [wellboreOptionState, setWellboreOptionState] = useState(false);
  const {getDataPage, deviceRealTime, getDeviceRealTime } = useContext(DashboardContext);
  const [messageAlert, setMessageAlert] = useState(false);
  const [typeAlert, setTypeAlert] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [selectedValue, setSelectedValue] = useState("0");
  const [inputDataTuning, setInputDataTuning] = useState(initialData);
  const [dataTableTubing, setDataTableTubing] = useState(initialDataTubing);
  const [dataTableGauge, setDataTableGauge] = useState(initialDataGauge);
  const [dataTableTestVLP, setDataTableTestVLP] = useState(initialDataTestVLP);
  const [inputEmpty, setInputEmpty] = useState(false);
  const [open, setOpen] = useState(false);
  const [activeChecks, setActiveChecks] = useState(inputGetDataTuning != null ? inputGetDataTuning.active_checks : []);
  const [hasChanges, setHasChanges] = useState(false);
  const [dataTuning, setDataTuning] = useState(inputGetDataTuning != null ? inputGetDataTuning.tuning_data : initialTuningData);
  const [enableInputGOR , setEnableInputGOR] = useState(false)
  const [enableInputGasRate , setEnableInputGasRate] = useState(false)
  const [dataTableGraphVLP, setDataTableGraphVLP] = useState()
  const [dataTableGraphUntVLP, setDataTableGraphUntVLP] = useState()
  const dataQueryWell = queryDataWell && selectedWell ? queryDataWell.data : null;
  const emptyTuningData = inputGetDataTuning?.active_checks.length == 0 && inputGetDataTuning?.mid_perf_tvd_depth == "" && inputGetDataTuning?.reservoir_temperature == "" && inputGetDataTuning?.reservoir_pressure == "" && inputGetDataTuning?.paramter1_vlp == "" && inputGetDataTuning?.paramter2_vlp == "" && inputGetDataTuning?.number_of_intervals == "" && inputGetDataTuning?.depth_tvd == "" && inputGetDataTuning?.measured_depth == "" ? 0 : null;


  useEffect(() => {
    getDataPage("AddWellGasLift");
    refetchWell();
    refetchTuning();
    refetchCalculateTuningVLP();
    refetchCalculateUntunedVLP();
    refetchDataIOTGasLift();
  }, [])
  

  useEffect(() => {
    
    if(queryDataWell){
      if(queryDataWell.statusCode === 200075 ){ 
        const filterDataWellEquipment = queryDataWell.data.dataEquipment;
        const arrayModify = filterDataWellEquipment.map((item) => ({ 
          id:item.id, 
          nameTubing:item.name,
          measured_depth:Number(item.measured_depth),
          internal_diameter:Number(item.internal_diameter),
          absolute_roughness:Number(item.absolute_roughness)
      }));

        setDataTableTubing(arrayModify)
      }
    }
  }, [queryDataWell]);
  
  useEffect(() => {
    if(queryCalculateUntunedVLP){
      if(queryCalculateUntunedVLP?.statusType === "SUCCESS"){
        setTimeout(() => {
          if(enableCalculatedMse){
            const searchValuePressure = queryCalculateTuningVLP?.data ? queryCalculateTuningVLP?.data[1]?.calc_press_tuned[0] : null;
            const ultimateValuePressure = !!searchValuePressure ? searchValuePressure?.toFixed(2) : "";
            const mseCalculated = queryCalculateUntunedVLP?.statusCode !== 500201 ? validateDataRMS() : null;
            setInputDataTuning((prevState) => ({
              ...prevState,
              calculated_pressure: ultimateValuePressure,
              mse_vlp: mseCalculated,
            }));
            setEnableCalculatedMse(false)
          }
        }, 1000);
      }
    }
  }, [enableCalculatedMse]);
  
  useEffect(() => {
    if(queryPressDrowDown){
      if(queryPressDrowDown?.statusType === "SUCCESS"){
        setInputDataTuning((prevState) => ({
          ...prevState,
          calculatedPressRD: queryPressDrowDown?.data?.pwf_tuned,
          calculatedDrawdown: queryPressDrowDown?.data?.p_drowd
        }))
      }
    }
  }, [queryPressDrowDown])

  useEffect(() => {
    if(dataGetIOTGasLift?.statusCode === STATUS_TYPE_DATA_IOT.SUCCESS && inputDataTuning?.whpCheck){
      let valWellIOT = '';

      if (deviceRealTime?.device_gaslift.name === '' && queryDataWell?.data?.well_platforms === WELL_SENSOR_TYPE_DEFAULT.data[0].name){
        valWellIOT = WELL_SENSOR_TYPE_DEFAULT.data[0].sensor;
      } else if (deviceRealTime?.device_gaslift?.name === '' && queryDataWell?.data?.well_platforms === WELL_SENSOR_TYPE_DEFAULT.data[1].name) {
        valWellIOT = WELL_SENSOR_TYPE_DEFAULT.data[1].sensor;
      } else {
        valWellIOT = deviceRealTime?.device_gaslift?.name;
      }

      const dataIOT = dataIOTGasLift?.filter((item) => {
          return item["LSD"] === valWellIOT; 
      });
      const lastDataIOT = !!dataIOT ? [dataIOT[dataIOT?.length - 1]] : null;
      
      if (lastDataIOT && lastDataIOT.length > 0 && lastDataIOT[0] != undefined) {

        const filterDataCreate = queryDataWell?.data;
        const [dateIOT, timeIOT] = lastDataIOT[0]['Date (MM/DD/YYYY)'].split(' ');

        const formatDate = (dateStr) => {
          const [month, day, year] = dateStr.split('/');
          return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
        };

        setInputDataTuning((prevState) => ({
          ...prevState,
          test_date_whp: formatDate(dateIOT),
          wellhead_temperature: lastDataIOT[0]['Flowing Temperature'],
          wellhead_pressure: lastDataIOT[0][deviceRealTime?.device_gaslift?.discretized  ?  filterDataCreate.well_name + ' Well Head Pressure' : 'Static Pressure'],
        })); 
      } 
    } 

    
  }, [dataIOTGasLift, inputDataTuning?.whpCheck]);
  
  
  useEffect(() => {
    if (emptyTuningData == 0) {
      // setInputDataTuning(initialData);
      setDataTuning(initialTuningData);
      setActiveChecks([]);
    } 
    else if (inputGetDataTuning !== null && emptyTuningData != 0) {
      const filterDataCreate = queryDataWell?.data;

      setInputDataTuning({
        ...inputDataTuning,
        mid_perf_tvd_depth: inputGetDataTuning?.mid_perf_tvd_depth,
        reservoir_temperature: inputGetDataTuning.reservoir_temperature,
        reservoir_pressure:  inputGetDataTuning.reservoir_pressure,
        // mid_perf_tvd_depth: filterDataCreate?.changeData?.md_perf_depth ? Number(filterDataCreate?.md_perf_depth) :  inputGetDataTuning?.mid_perf_tvd_depth,
        // reservoir_temperature: filterDataCreate?.changeData?.layer_temperature ? Number(filterDataCreate?.layer_temperature) : inputGetDataTuning.reservoir_temperature,
        // reservoir_pressure: filterDataCreate?.changeData?.layer_pressure ? Number(filterDataCreate?.layer_pressure) : inputGetDataTuning.reservoir_pressure,
        test_date_pp:inputGetDataTuning.test_date_pp,
        oil_rate:inputGetDataTuning.oil_rate,
        gas_rate:inputGetDataTuning.gas_rate,
        gor_pp:inputGetDataTuning.gor_pp,
        water_cut: inputGetDataTuning.water_cut,
        // water_cut: filterDataCreate?.changeData?.water_cut_fraction ? Number(filterDataCreate?.water_cut_fraction) : inputGetDataTuning.water_cut,
        test_date_pp:inputGetDataTuning.test_date_pp,
        test_date_glp:inputGetDataTuning.test_date_glp,
        injection_gas_rate:inputGetDataTuning.injection_gas_rate,
        injection_pressure:inputGetDataTuning.injection_pressure,
        test_date_whp:inputGetDataTuning.test_date_whp,
        wellhead_temperature:inputGetDataTuning.wellhead_temperature,
        wellhead_pressure:inputGetDataTuning.wellhead_pressure,
        gas_lift_valve_depth_1:inputGetDataTuning.gas_lift_valve_depth_1,
        gas_lift_valve_depth_2:inputGetDataTuning.gas_lift_valve_depth_2,
        gas_lift_valve_depth_3:inputGetDataTuning.gas_lift_valve_depth_3,
        gas_injection_distribution_valve_1:inputGetDataTuning.gas_injection_distribution_valve_1,
        gas_injection_distribution_valve_2:inputGetDataTuning.gas_injection_distribution_valve_2,
        gas_injection_distribution_valve_3:inputGetDataTuning.gas_injection_distribution_valve_3,
        active_checks: inputGetDataTuning.active_checks,
        paramter1_vlp: inputGetDataTuning.paramter1_vlp,
        paramter2_vlp: inputGetDataTuning.paramter2_vlp,
        number_of_intervals: inputGetDataTuning.number_of_intervals,
        calculated_pressure: inputGetDataTuning.calculated_pressure,
        mse_vlp: inputGetDataTuning.mse_vlp,
        whpCheck: inputGetDataTuning.whpCheck,
        drawdownPres: inputGetDataTuning?.drawdownPres,
        enable_gauge_parameters: inputGetDataTuning?.enable_gauge_parameters
      });

      setEnableInputGOR(!!inputGetDataTuning.gas_rate ? true : false);
      setDataTableTubing(inputGetDataTuning.tubing_data);
      setDataTableGauge(inputGetDataTuning.gauge_data);
      setDataTableTestVLP(inputGetDataTuning.test_data);
      setActiveChecks(inputGetDataTuning.active_checks);
      setTimeout(() => {
        refetchPressDrowDown();
      }, 1000);
    }
    else if(inputGetDataTuning === null){
      setInputDataTuning(initialData);
      if(queryDataWell){
        if(queryDataWell.statusCode === 200075 ){
          const filterDataCreate = queryDataWell?.data;
          setInputDataTuning((prevState) => ({
          ...prevState,
          mid_perf_tvd_depth: Number(filterDataCreate?.md_perf_depth),
          reservoir_pressure: Number(filterDataCreate?.layer_pressure),
          reservoir_temperature: Number(filterDataCreate?.layer_temperature),
          water_cut: Number(filterDataCreate?.water_cut_fraction),
          number_of_intervals: 20
          }));
        }
      }
      setDataTuning(initialTuningData);
      setActiveChecks([]);
    }
  }, [inputGetDataTuning, queryDataWell]);

  useEffect(() => {
    if(queryCalculateTuningVLP && queryCalculateTuningVLP.statusCode === 200044 || queryCalculateUntunedVLP && queryCalculateUntunedVLP.statusCode === 200044){
        const getDataCalculatedVLP = queryCalculateTuningVLP?.data != null ? queryCalculateTuningVLP?.data[0] : [];
        const getDataCalculatedUntVLP = queryCalculateUntunedVLP?.data !== null ? queryCalculateUntunedVLP?.data[0] : [];
        setDataTableGraphVLP(getDataCalculatedVLP)
        setDataTableGraphUntVLP(getDataCalculatedUntVLP)

        const pressureTuned = getDataCalculatedVLP?.pressure || [];
        const depthTuned = getDataCalculatedVLP?.depth_md || [];
        const pressureUntuned = getDataCalculatedUntVLP?.pressure || [];
        const depthUntuned = getDataCalculatedUntVLP?.depth_md || [];
        
        const pressureArrayTuned = [...pressureTuned, inputDataTuning?.reservoir_pressure + 200];
        const depthArrayTuned = [...depthTuned, inputDataTuning?.mid_perf_tvd_depth + 200];
        const pressureArrayUntuned = [...pressureUntuned, inputDataTuning?.reservoir_pressure + 200] ;
        const depthArrayUntuned = [...depthUntuned, inputDataTuning?.mid_perf_tvd_depth + 200];
        calculateRangesGraphVLP(pressureArrayTuned,depthArrayTuned,pressureArrayUntuned,depthArrayUntuned)
      }
  },[queryCalculateTuningVLP,queryCalculateUntunedVLP, inputDataTuning?.mid_perf_tvd_depth, inputDataTuning?.reservoir_pressure]);
  
  useEffect(() => {
    if (hasChanges) {
      const customEventChecks = {
        target: {
          name: "active_checks",
          value: [...activeChecks],
        },
      };
      handleChangeInputData(customEventChecks);
      setHasChanges(false);
    }
  }, [hasChanges, activeChecks]);

  useEffect(() => {
    if (loadDataTuning) {
    }
  }, [loadDataTuning]);
  
  const handleCLickExpand = () => {
    setOpen(true);
  };

  function VerifyingNulls(arreglo) {
    let validateValuesNull = false;
    arreglo.forEach((objeto) => {
      Object.values(objeto).forEach((valor) => {
        if (valor === null || valor === "") {
          validateValuesNull = true;
        }
      });
    });
    return validateValuesNull;
  }

  function validateDataRMS() {
    if (inputDataTuning?.enable_gauge_parameters){// Verificar si los arreglos tienen la misma longitud
      const dataUntunedVLP = queryCalculateTuningVLP?.data !== null ? queryCalculateTuningVLP?.data[1].calc_press_tuned : [];
      const pressureReal = dataTableGauge.map(item => item.gauge_pressure);
      const PressureCalculated = dataUntunedVLP;
      const realEmpty = pressureReal?.every(elemento => elemento !== "");
      const realCalculated = PressureCalculated?.every(elemento => elemento !== "");
      
      if(pressureReal.length !== PressureCalculated.length || !realEmpty || !realCalculated){
        message.error("The error cannot be calculated because data is missing")
        return "";
      }

      // Calcular la suma de los cuadrados de las diferencias
      /* const sumSquaredDifferences = pressureReal?.reduce((acc, realValue, index) => {
        const predictedValue = PressureCalculated[index];
        const difference = realValue - predictedValue;
        return acc + Math.pow(difference, 2); 
        }, 0)*/
        const sumSquaredDifferences = pressureReal?.reduce((acc, realValue, index) => {
          const predictedValue = PressureCalculated[index];
          const difference = realValue - predictedValue;
          return acc + Math.pow(Math.pow(difference, 2), 0.5);
      }, 0);

      // Calcular el promedio de los cuadrados de las diferencias
      // const mse = (sumSquaredDifferences / pressureReal.length)?.toFixed(2);
      const STD_DEV = (sumSquaredDifferences)?.toFixed(2);

      return STD_DEV;
    } else{
      return ""
    }
  }
  
  const wellboreOption = () => {
    setWellboreOptionState(!wellboreOptionState);
  };

  const handleNav = (value) => {
    setSelectedValue(value.key);
  };

  const handleAddTubing = () => {
    const uniqueIds = [...new Set(dataTableTubing.map((obj) => obj.id))];
    if (uniqueIds.length < 3) {
      const maxId = Math.max(...uniqueIds);
    const newTubingData = {
      id: maxId + 1,
      nameTubing: "",
      measured_depth: null,
      internal_diameter: null,
      absolute_roughness: null,
    }
    setDataTableTubing([...dataTableTubing, newTubingData]);
    setHasChanges(true);
    }else {
      message.info("Maximum 3 part of tubing are allowed")
    }
  };
  
  const handleDeleteTubing = (id) => {
    const newData = dataTableTubing.filter((item) => item.id !== id);
    setDataTableTubing(newData);
    setHasChanges(true);
  };

  const handleAddGauge = () => {
    const uniqueIds = [...new Set(dataTableGauge.map((obj) => obj.id))];
    const maxId = Math.max(...uniqueIds);
    const newGaugeData = {
      id: maxId + 1,
      test_date:null,
      gauge_tvd:null,
      gauge_pressure:null,
      gauge_temperature:null,
    };
    const newGaugeData2 = {
      id: maxId + 1,
      depth_tvd:null,
      measured_pressure_tvd:null,
      oil_rate_tvd:inputDataTuning.oil_rate,
    };
    setDataTableGauge([...dataTableGauge, newGaugeData]);
    setDataTableTestVLP([...dataTableTestVLP, newGaugeData2]);
    setHasChanges(true);
  };

  const handleDeleteGauge = (id) => {
    const newData = dataTableGauge.filter((item) => item.id !== id);
    setDataTableGauge(newData);
    const newData2 = dataTableTestVLP.filter((item) => item.id !== id);
    setDataTableTestVLP(newData2);
    setHasChanges(true);
  };

  const handleUploadFileTubing = (dataFile) => {
    const uniqueIds = [...new Set(dataTableTubing.map((obj) => obj.id))];
    const maxId = (Math.max(...uniqueIds)) + 1;
    const dataFileEnd = dataFile.map((obj, index) => {
      return { id: index + maxId, ...obj };
    });
    setDataTableTubing([...dataTuning, ...dataFileEnd]);
    setHasChanges(true);
  };

  const handleSwitch = (nameSwitch,checked) => {
    const customEvent = {
      target: {
        name: nameSwitch,
        value: checked,
      },
    };
    handleChangeInputData(customEvent);
  }

  const handleUploadFileGauge = (dataFile) => {
    const uniqueIds = [...new Set(dataTableGauge.map((obj) => obj.id))];
    const maxId = (Math.max(...uniqueIds)) + 1;
    const dataFilePre = dataFile.map((obj, index) => {
      return { id: index + maxId, ...obj };
    });
    const dataFileEnd = dataFilePre.map((item)=>({
      ...item, 
      ['test_date']: convertDate(item.test_date) 
    }))

    const dataFileEndTest = dataFileEnd.map((item) => {
      return { 
        id:item.id,
        depth_tvd:item.gauge_tvd,
        measured_pressure_tvd:item.gauge_pressure,
        oil_rate_tvd:inputDataTuning.oil_rate,
      };
    });

    setDataTableGauge([...dataTableGauge, ...dataFileEnd]);
    setDataTableTestVLP([...dataTableTestVLP, ...dataFileEndTest]);
    setHasChanges(true);
  };

  const handleChangeTubing = (e, id) => {
    const { name, value } = e.target;
    let dataValue = value;
    if (name !== "nameTubing") {
      dataValue = Number(value);
    } 
    if (value === "") {
      dataValue = "";
      setDataTableTubing((prevState) =>
      prevState.map((item) =>
        item.id === id ? { ...item, [name]: dataValue } : item
      ));
    }
    else{
      if((name === 'measured_depth') && (dataValue < 0 || dataValue > 60000)){
        message.error("The value cannot be less than 0 or more than 60000");
      }
      else if((name === 'internal_diameter') && (dataValue < 0 || dataValue > 24)){
        message.error("The value cannot be less than 0 or more than 24");
      }
      else if((name === 'absolute_roughness') && (dataValue < 0 || dataValue > 0.01)){
        message.error("The value cannot be less than 0 or more than 0.01");
      }
      else {
        setDataTableTubing((prevState) =>
          prevState.map((item) =>
            item.id === id ? { ...item, [name]: dataValue } : item
        ));

      }
    }
    setHasChanges(true);
  };

  const handleChangeBlur = (e, id) => {
    const { name, value } = e.target;
    
    let dataValue = value !== "" ? Number(value) : value;
    let valMeasuredDepth = false;

    if (name === "measured_depth"){
      valMeasuredDepth = sumMeasureDepth("array", dataTableTubing);
      if (valMeasuredDepth) {
        message.error("the sum of MD Length in the table cannot exceed the maximum value of Measured Depth in Well Orientation");
        setDataTableTubing(prevState =>
          prevState.map(item =>
            item.id === id ? { ...item, [name]: null } : item
          )
        );
      }      
    }else if(name === "gauge_tvd" && inputDataTuning.enable_gauge_parameters){
      valMeasuredDepth = sumMeasureDepth("array", dataTableGauge);
      if (valMeasuredDepth) {
        message.error("the sum of MD Length in the table cannot exceed the maximum value of Measured Depth in Well Orientation");
        setDataTableGauge(prevState =>
          prevState.map(item =>
            item.id === id ? { ...item, [name]: null } : item
          )
        );
      }   
    }   
  };  

  const handleChangeGauge = (e, id) => {
    const { name, value } = e.target;
    let dataValue = value;
    if (name !== "test_date") {
      dataValue = Number(value);
    } 
    if (value === "") {
      dataValue = "";
      setDataTableGauge((prevState) =>
      prevState.map((item) =>
        item.id === id ? { ...item, [name]: dataValue } : item
      ));
    }
    else{
      if((name === 'gauge_tvd') && (dataValue < 0 || dataValue > 60000)){
        message.error("The value cannot be less than 0 or more than 60000");
      }
      else if((name === 'gauge_pressure') && (dataValue < 0 || dataValue > 25000)){
        message.error("The value cannot be less than 0 or more than 25000");
      }
      else if((name === 'gauge_temperature') && (dataValue < 0 || dataValue > 450)){
        message.error("The value cannot be less than 0 or more than 450");
      }
      else{
        if(name === "gauge_tvd"  && value !== null){
          setDataTableTestVLP((prevState) =>
          prevState.map((item) =>
            item.id === id ? { ...item, ["depth_tvd"]: dataValue } : item
          ));
        }
        if(name === "gauge_pressure"  && value !== null){
          setDataTableTestVLP((prevState) =>
          prevState.map((item) =>
            item.id === id ? { ...item, ["measured_pressure_tvd"]: dataValue } : item
          ));
        }
        if(name === "gauge_temperature" && value !== null){
          setDataTableTestVLP((prevState) =>
          prevState.map((item) =>
            item.id === id ? { ...item, ["gauge_temperature"]: dataValue } : item
          ));
        }
        setDataTableGauge((prevState) =>
        prevState.map((item) =>
          item.id === id ? { ...item, [name]: dataValue } : item
        ));
      }
    }

    setHasChanges(true);
  };

  const handleChangeInputData = (event) => {
    const { name, value } = event.target;
    let dataValue = value;
    setHasChanges(true);
    
    const validateMD = name === "mid_perf_tvd_depth" ? sumMeasureDepth("only", dataValue) : false;

    if(name === "gas_rate" && value === "" || value === null){
      setEnableInputGOR(false)
    }
    if(name === "gas_rate" && value !== "" || value === null){
      setEnableInputGOR(true)
    }
    if(name === "gor_pp" && value === "" || value === null){
      setEnableInputGasRate(false)
    }
    if(name === "gor_pp" && value !== "" || value === null){
      setEnableInputGasRate(true)
    }
    if(name === "oil_rate" && value !== "" || value === null){
      const dataModifyTest = dataTableTestVLP.map((item)=>({
        ...item,
        "oil_rate_tvd":value
      }))
      setDataTableTestVLP(dataModifyTest);
    }

    
    if(value === "" || value === null || value === 0){
      setInputDataTuning((prevState) => ({
        ...prevState,
        [name]: "",
      }));
    }
    else{
      if(name === "calculated_pressure" ){
        const mseCalculated = validateDataRMS();
        setInputDataTuning((prevState) => ({
          ...prevState,
          mse_vlp: mseCalculated,
        }));
      }
      else if((name === 'mid_perf_tvd_depth' 
      || name === 'gas_lift_valve_depth_1'
      || name === 'gas_lift_valve_depth_2'
      || name === 'gas_lift_valve_depth_3') 
      && (dataValue < 0 || dataValue > 60000)){
        message.error("The value cannot be less than 0 or more than 60000");
      }
      else if(validateMD){
        message.error("Mid-perf Measured Depth cannot exceed the maximum value of Measured Depth in Well Orientation");
        setInputDataTuning((prevState) => ({
          ...prevState,
          [name]: null,
        }));
      }
      else if((name === 'reservoir_pressure' || name === 'injection_pressure') && (dataValue < 0 || dataValue > 25000)){
        message.error("The value cannot be less than 0 or more than 25000");
      }
      else if((name === 'reservoir_temperature') && (dataValue < 0 || dataValue > 450)){
        message.error("The value cannot be less than 0 or more than 450");
      }
      else if((name === 'oil_rate') && (dataValue < 0 || dataValue > 50000)){
        message.error("The value cannot be less than 0 or more than 50000");
      }
      else if((name === 'gas_rate' || name === 'injection_gas_rate') && (dataValue < 0 || dataValue > 100000)){
        message.error("The value cannot be less than 0 or more than 100000");
      }
      else if((name === 'gor_pp') && (dataValue < 0 || dataValue > 500000)){
        message.error("The value cannot be less than 0 or more than 500000");
      }
      else if((name === 'water_cut'||
      name === 'gas_injection_distribution_valve_1'  ||
      name === 'gas_injection_distribution_valve_2'  ||
      name === 'gas_injection_distribution_valve_3')
       && (dataValue < 0 || dataValue > 100)){
        message.error("The value cannot be less than 0 or more than 100");
      }
      else if((name === 'wellhead_temperature') && (dataValue < 0 || dataValue > 250)){
        message.error("The value cannot be less than 0 or more than 250");
      }
      else if((name === 'wellhead_pressure') && (dataValue < 0 || dataValue > 5000)){
        message.error("The value cannot be less than 0 or more than 5000");
      }
      else if(name === "test_date_pp"  || name === "test_date_glp" || name === "test_date_whp" || name == "enable_gauge_parameters"){
        setInputDataTuning((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
      else{
        setInputDataTuning((prevState) => ({
          ...prevState,
          [name]: Number(value),
        }));
      }
      
    }
    
  };

  const handleCheckChange = (id, name) => {
    if (activeChecks.includes(id)) {
      setActiveChecks(activeChecks.filter((checkId) => checkId !== id));
      setHasChanges(true);
    } else {
      setActiveChecks([id]);
      setHasChanges(true);
    }
  };
  // const activeDataChecks = activeChecks.map((id) =>
  //   typeLineGraphData[id] ? typeLineGraphData[id] : ""
  // );

  // const handleChangeSelectRates = (nameData, valueData) => {
  //   setSelectRatesType(valueData);
  //   const customEvent = {
  //     target: {
  //       name: nameData,
  //       value: valueData,
  //     },
  //   };
  //   handleChangeInputData(customEvent);
  // };

  // const handleChangeSelectCorrelation = (nameData, valueData) => {
  //   setSelectRatesType(valueData);
  //   const customEvent = {
  //     target: {
  //       name: nameData,
  //       value: valueData,
  //     },
  //   };
  //   handleChangeInputData(customEvent);
  // };

  // useEffect(() => {
  //   if (hasChanges) {
  //     const customEvent = {
  //       target: {
  //         name: "tuning_data",
  //         value: [...dataTuning],
  //       },
  //     };
  //     handleChangeInputData(customEvent);
  //     setHasChanges(false);
  //   }
  // }, [hasChanges, dataTuning]);

  const handleClickDone = () => {
    const validateDataTubingNull = VerifyingNulls(dataTableTubing);
    const validateDataGaugeNull = inputDataTuning.enable_gauge_parameters ? VerifyingNulls(dataTableGauge) : false;
    const validateDataTestNull = VerifyingNulls(dataTableTestVLP);
    if (
      validateDataTubingNull === true ||
      validateDataGaugeNull === true ||
      validateDataTestNull === true ||
      inputDataTuning.mid_perf_tvd_depth === "" ||
      inputDataTuning.reservoir_temperature === "" ||
      inputDataTuning.reservoir_pressure === "" ||
      inputDataTuning.test_date_pp === "" ||
      inputDataTuning.oil_rate === "" ||
      inputDataTuning.water_cut === "" ||
      inputDataTuning.test_date_glp === "" ||
      inputDataTuning.injection_gas_rate === "" ||
      inputDataTuning.injection_pressure === "" ||
      inputDataTuning.test_date_whp === "" ||
      inputDataTuning.wellhead_temperature === "" ||
      inputDataTuning.wellhead_pressure === "" ||
      inputDataTuning.gas_lift_valve_depth_1 === "" ||
      inputDataTuning.gas_lift_valve_depth_2 === "" ||
      inputDataTuning.gas_lift_valve_depth_3 === "" ||
      inputDataTuning.paramter1_vlp === "" ||
      inputDataTuning.paramter2_vlp === "" ||
      inputDataTuning.number_of_intervals === "" ||
      activeChecks.length < 1 
    ) {
      setInputEmpty(true);
      handleOpenModal();
      setTypeAlert("Warning");
      setMessageAlert("The information is empty please enter rows");
    } else {
      setInputEmpty(false);
      const dataWell = {
        well_id: dataQueryWell.well_id,
        well_platforms: dataQueryWell.well_platforms,
        well_name: dataQueryWell.well_name,
        region: dataQueryWell.region,
        country: dataQueryWell.country,
        field: dataQueryWell.field,
        county: dataQueryWell.county,
        latitude: dataQueryWell.latitude,
        longitude: dataQueryWell.longitude,
        company: dataQueryWell.company,
        objective: dataQueryWell.objective,
        well_type: dataQueryWell.well_type,
        well_oritentation: dataQueryWell.well_orientation,
        artificial_method: dataQueryWell.artificial_method,
        well_reference: dataQueryWell.well_reference,
        flow_type: dataQueryWell.flow_type,
        fluid_type: dataQueryWell.fluid_type,
      }
      const arrayGaugePressure = inputDataTuning.enable_gauge_parameters ? dataTableGauge.map(item=> item.gauge_tvd) : [];
      const dataCalculatelVLP = {
        res_pressg: Number(inputDataTuning.reservoir_pressure),
        res_templ: Number(inputDataTuning.reservoir_temperature),
        mid_perf_tvd: Number(inputDataTuning.mid_perf_tvd_depth),
        qo_sc: Number(inputDataTuning.oil_rate),
        qg_sc: Number(inputDataTuning.gas_rate) === 0 ? null : Number(inputDataTuning.gas_rate),
        gor: Number(inputDataTuning.gor_pp) === 0 ? null : Number(inputDataTuning.gor_pp),
        w_cut: Number(inputDataTuning.water_cut),
        qg_inj_sc: Number(inputDataTuning.injection_gas_rate),
        inj_pressg: Number(inputDataTuning.injection_pressure),
        wh_pressg: Number(inputDataTuning.wellhead_pressure),
        wh_temp: Number(inputDataTuning.wellhead_temperature),
        inj_depth1: Number(inputDataTuning.gas_lift_valve_depth_1),
        inj_depth2: Number(inputDataTuning.gas_lift_valve_depth_2),
        inj_depth3: Number(inputDataTuning.gas_lift_valve_depth_3),
        gas_inj_distribution1: Number(inputDataTuning.gas_injection_distribution_valve_1),
        gas_inj_distribution2: Number(inputDataTuning.gas_injection_distribution_valve_2),
        gas_inj_distribution3: Number(inputDataTuning.gas_injection_distribution_valve_3),
        num_interval: Number(inputDataTuning.number_of_intervals),
        parameter1: Number(inputDataTuning.paramter1_vlp),
        parameter2: Number(inputDataTuning.paramter2_vlp),
        API: Number(dataQueryWell.res_oil_gravity),
        pb: Number(dataQueryWell.bubble_point_pressure),
        rsb: Number(dataQueryWell.res_initial_gor),
        sgg: Number(dataQueryWell.res_gas_gravity),
        yco2: Number(dataQueryWell.res_CO2),
        yh2s: Number(dataQueryWell.res_H2S),
        yn2: Number(dataQueryWell.res_N2),
        ppm: Number(dataQueryWell.res_water_salinity),
        depth_press_test_values: arrayGaugePressure
      }

      const dataUnstructuredTubing = dataTableTubing.reduce((resultado, objeto, index) => {
        resultado[`MD${index + 1}`] = objeto.measured_depth;
        resultado[`ID${index + 1}`] = objeto.internal_diameter;
        resultado[`roughness${index + 1}`] = objeto.absolute_roughness;
        return resultado;
      }, {});
      const dataCreateWellTables = { tubing_data: [...dataTableTubing], gauge_data: [...dataTableGauge], test_data: [...dataTableTestVLP], active_checks: [...activeChecks]};
      const finalData = Object.assign({}, dataWell, inputDataTuning, dataCreateWellTables );
      const finalDataCalculateVLP = Object.assign({}, dataCalculatelVLP,dataUnstructuredTubing );

      setTimeout(() => {
        mutationCreateDataTuning.mutate({
          operator: userDataInfo.operator,
          lease: userDataInfo.lease,
          region: userDataInfo.region,
          county: dataFilterUserGasLift.county,
          field: dataFilterUserGasLift.field,
          wellid: dataFilterUserGasLift.well_id,
          username: User.user_name,
          data: finalData,
        });
      }, 1000);

      setTimeout(() => {
        mutationUpdateCalTuningVLP.mutate({
          operator: userDataInfo.operator,
          lease: userDataInfo.lease,
          region: userDataInfo.region,
          county: dataFilterUserGasLift.county,
          field: dataFilterUserGasLift.field,
          wellid: dataFilterUserGasLift.well_id,
          username: User.user_name,
          data: finalDataCalculateVLP,
        });
      }, 1000);

      message.success("Processing complete!");
      navigate("/AddWellGasLift");
    }
  };
  
    const handleClickEdit = () => {
    const validateDataTubingNull = VerifyingNulls(dataTableTubing);
    const validateDataGaugeNull = inputDataTuning.enable_gauge_parameters ? VerifyingNulls(dataTableGauge) : false;
    const validateDataTestNull = VerifyingNulls(dataTableTestVLP);
    if (
      validateDataTubingNull === true ||
      validateDataGaugeNull === true ||
      validateDataTestNull === true ||
      inputDataTuning.mid_perf_tvd_depth === "" ||
      inputDataTuning.reservoir_temperature === "" ||
      inputDataTuning.reservoir_pressure === "" ||
      inputDataTuning.test_date_pp === "" ||
      inputDataTuning.oil_rate === "" ||
      inputDataTuning.water_cut === "" ||
      inputDataTuning.test_date_glp === "" ||
      inputDataTuning.injection_gas_rate === "" ||
      inputDataTuning.injection_pressure === "" ||
      inputDataTuning.test_date_whp === "" ||
      inputDataTuning.wellhead_temperature === "" ||
      inputDataTuning.wellhead_pressure === "" ||
      inputDataTuning.gas_lift_valve_depth_1 === "" ||
      inputDataTuning.gas_lift_valve_depth_2 === "" ||
      inputDataTuning.gas_lift_valve_depth_3 === "" ||
      inputDataTuning.paramter1_vlp === "" ||
      inputDataTuning.paramter2_vlp === "" ||
      inputDataTuning.number_of_intervals === "" ||
      activeChecks.length < 1
    ) {
      setInputEmpty(true);
      handleOpenModal();
      setTypeAlert("Warning");
      setMessageAlert("The information is empty please enter rows");
    } else {
      setInputEmpty(false);
      const dataWell = {
        well_id: dataQueryWell.well_id,
        well_platforms: dataQueryWell.well_platforms,
        well_name: dataQueryWell.well_name,
        region: dataQueryWell.region,
        country: dataQueryWell.country,
        field: dataQueryWell.field,
        county: dataQueryWell.county,
        latitude: dataQueryWell.latitude,
        longitude: dataQueryWell.longitude,
        company: dataQueryWell.company,
        objective: dataQueryWell.objective,
        well_type: dataQueryWell.well_type,
        well_oritentation: dataQueryWell.well_orientation,
        artificial_method: dataQueryWell.artificial_method,
        well_reference: dataQueryWell.well_reference,
        flow_type: dataQueryWell.flow_type,
        fluid_type: dataQueryWell.fluid_type,
      }
      const arrayGaugePressure = inputDataTuning.enable_gauge_parameters ? dataTableGauge.map(item=> item.gauge_pressure) : [];
      const dataCalculatelVLP = {
        res_pressg: Number(inputDataTuning.reservoir_pressure),
        res_templ: Number(inputDataTuning.reservoir_temperature),
        mid_perf_tvd: Number(inputDataTuning.mid_perf_tvd_depth),
        qo_sc: Number(inputDataTuning.oil_rate),
        qg_sc: Number(inputDataTuning.gas_rate) === 0 ? null : Number(inputDataTuning.gas_rate),
        gor: Number(inputDataTuning.gor_pp) === 0 ? null : Number(inputDataTuning.gor_pp),
        w_cut: Number(inputDataTuning.water_cut),
        qg_inj_sc: Number(inputDataTuning.injection_gas_rate),
        inj_pressg: Number(inputDataTuning.injection_pressure),
        wh_pressg: Number(inputDataTuning.wellhead_pressure),
        wh_temp: Number(inputDataTuning.wellhead_temperature),
        inj_depth1: Number(inputDataTuning.gas_lift_valve_depth_1),
        inj_depth2: Number(inputDataTuning.gas_lift_valve_depth_2),
        inj_depth3: Number(inputDataTuning.gas_lift_valve_depth_3),
        gas_inj_distribution1: Number(inputDataTuning.gas_injection_distribution_valve_1),
        gas_inj_distribution2: Number(inputDataTuning.gas_injection_distribution_valve_2),
        gas_inj_distribution3: Number(inputDataTuning.gas_injection_distribution_valve_3),
        num_interval: Number(inputDataTuning.number_of_intervals),
        parameter1: Number(inputDataTuning.paramter1_vlp),
        parameter2: Number(inputDataTuning.paramter2_vlp),
        depth_press_test_values: arrayGaugePressure
      }

      const dataUnstructuredTubing = dataTableTubing.reduce((resultado, objeto, index) => {
        resultado[`MD${index + 1}`] = objeto.measured_depth;
        resultado[`ID${index + 1}`] = objeto.internal_diameter;
        resultado[`roughness${index + 1}`] = objeto.absolute_roughness;
        return resultado;
      }, {});

      const dataCreateWellTables = { tubing_data: [...dataTableTubing], gauge_data: [...dataTableGauge], test_data: [...dataTableTestVLP], active_checks: [...activeChecks]};
      const finalData = Object.assign({}, dataWell, inputDataTuning, dataCreateWellTables );
      const finalDataCalculateVLP = Object.assign({}, dataCalculatelVLP,dataUnstructuredTubing );

      setTimeout(() => {
        mutationUpdateDataTuning.mutate({
          operator: userDataInfo.operator,
          lease: userDataInfo.lease,
          region: userDataInfo.region,
          county: dataFilterUserGasLift.county,
          field: dataFilterUserGasLift.field,
          wellid: dataFilterUserGasLift.well_id,
          username: User.user_name,
          data: finalData,
        });
      }, 1000);

      setTimeout(() => {
        mutationUpdateCalTuningVLP.mutate({
          operator: userDataInfo.operator,
          lease: userDataInfo.lease,
          region: userDataInfo.region,
          county: dataFilterUserGasLift.county,
          field: dataFilterUserGasLift.field,
          wellid: dataFilterUserGasLift.well_id,
          username: User.user_name,
          data: finalDataCalculateVLP,
        });
      }, 1000);
      message.success("Processing Edit complete!");
      navigate("/AddWellGasLift");
    }
  };

  const handleClickClean = () => {
    showCleanConfirm();
  };

  const handleOpenModal = () => {
    setShowAlert(true);
  };

  const handleCloseModal = () => {
    setShowAlert(false);
  };

  const handleCalculateVLP = () => {
    const validateMaxMD = sumMeasureDepth("array", dataTableTubing) || sumMeasureDepth("array", dataTableGauge) || sumMeasureDepth("only", inputDataTuning.mid_perf_tvd_depth) ? true : false;
    const validateDataTubingNull = VerifyingNulls(dataTableTubing);
    const validateDataGaugeNull = inputDataTuning.enable_gauge_parameters ? VerifyingNulls(dataTableGauge) : false;
    const validateDataTestNull = VerifyingNulls(dataTableTestVLP);
    
    if(
      validateDataTubingNull === true ||
      validateDataGaugeNull === true ||
      validateDataTestNull === true ||
      inputDataTuning.mid_perf_tvd_depth === "" ||
      inputDataTuning.reservoir_temperature === "" ||
      inputDataTuning.reservoir_pressure === "" ||
      inputDataTuning.test_date_pp === "" ||
      inputDataTuning.oil_rate === "" ||
      inputDataTuning.water_cut === "" ||
      inputDataTuning.test_date_glp === "" ||
      inputDataTuning.injection_gas_rate === "" ||
      inputDataTuning.injection_pressure === "" ||
      inputDataTuning.test_date_whp === "" ||
      inputDataTuning.wellhead_temperature === "" ||
      inputDataTuning.wellhead_pressure === "" ||
      inputDataTuning.gas_lift_valve_depth_1 === "" ||
      inputDataTuning.gas_lift_valve_depth_2 === "" ||
      inputDataTuning.gas_lift_valve_depth_3 === "" ||
      inputDataTuning.paramter1_vlp === "" ||
      inputDataTuning.paramter2_vlp === "" ||
      inputDataTuning.number_of_intervals === "" ||
      activeChecks.length < 1 || validateMaxMD
    ){
      setInputEmpty(true);
      handleOpenModal();
      setTypeAlert("Message");
      setMessageAlert("The are empty fields or invalid information");
    }
    else{
      

      setInputEmpty(false);
      const arrayGaugePressure =  inputDataTuning.enable_gauge_parameters ? dataTableGauge.map(item=> item.gauge_tvd) : [];
      const arrayDataDeviation = dataQueryWell.dataDeviation.map(item=> item.measured_depth);
      const arrayTVD = dataQueryWell.dataDeviation.map(item=> item.tvd);
      const stateCorrelation = activeChecks[0] === 0 ? "BB" : activeChecks[0] === 1 ? "DR" : activeChecks[0] === 2 ? "FB" : activeChecks[0] === 3 ? "HB" : "W";
      const stateWellType = dataQueryWell.well_type === "producer" ? true : false

      const dataCalculatelVLP = {
        res_pressg: Number(inputDataTuning.reservoir_pressure),
        res_templ: Number(inputDataTuning.reservoir_temperature),
        mid_perf_tvd: Number(inputDataTuning.mid_perf_tvd_depth),
        qo_sc: Number(inputDataTuning.oil_rate),
        qg_sc: Number(inputDataTuning.gas_rate) === 0 ? null : Number(inputDataTuning.gas_rate),
        gor: Number(inputDataTuning.gor_pp) === 0 ? null : Number(inputDataTuning.gor_pp),
        w_cut: Number(inputDataTuning.water_cut),
        qg_inj_sc: Number(inputDataTuning.injection_gas_rate),
        inj_pressg: Number(inputDataTuning.injection_pressure),
        wh_pressg: Number(inputDataTuning.wellhead_pressure),
        wh_temp: Number(inputDataTuning.wellhead_temperature),
        inj_depth1: Number(inputDataTuning.gas_lift_valve_depth_1),
        inj_depth2: Number(inputDataTuning.gas_lift_valve_depth_2),
        inj_depth3: Number(inputDataTuning.gas_lift_valve_depth_3),
        API: Number(dataQueryWell.res_oil_gravity),
        pb: Number(dataQueryWell.bubble_point_pressure),
        rsb: Number(dataQueryWell.res_initial_gor),
        sgg: Number(dataQueryWell.res_gas_gravity),
        yco2: Number(dataQueryWell.res_CO2),
        yh2s: Number(dataQueryWell.res_H2S),
        yn2: Number(dataQueryWell.res_N2),
        ppm: Number(dataQueryWell.res_water_salinity),
        num_interval: Number(inputDataTuning.number_of_intervals),
        parameter1: Number(inputDataTuning.paramter1_vlp),
        parameter2: Number(inputDataTuning.paramter2_vlp),
        depth_press_test_values: arrayGaugePressure,
        type_correlation: stateCorrelation,
        is_producer: stateWellType,
        measured_depth_table: arrayDataDeviation,
        true_vertical_depth_table: arrayTVD,
        switch_lab: dataQueryWell.fluidLab,
        correlation: dataQueryWell.calculate_correlation,
        correlation_2: dataQueryWell.calculate_correlation_oil,
        gas_inj_distribution1: Number(inputDataTuning.gas_injection_distribution_valve_1),
        gas_inj_distribution2: Number(inputDataTuning.gas_injection_distribution_valve_2),
        gas_inj_distribution3: Number(inputDataTuning.gas_injection_distribution_valve_3),
        pdd: !!inputDataTuning.drawdownPres ? Number(inputDataTuning.drawdownPres) : 0,
      };

      const dataUnstructuredTubing = dataTableTubing.reduce((resultado, objeto, index) => {
        resultado[`MD${index + 1}`] = objeto.measured_depth;
        resultado[`ID${index + 1}`] = objeto.internal_diameter;
        resultado[`roughness${index + 1}`] = objeto.absolute_roughness;
        return resultado;
      }, {});
  
      const dataCalculateVLP = Object.assign({}, dataCalculatelVLP,dataUnstructuredTubing );
      
      if (inputGetDataTuning === null || emptyTuningData === 0) {

        async function executeFunction() {
          await sequentialMutationVLPCreate(dataCalculateVLP);
        }
        executeFunction()
      }else if(inputGetDataTuning != null && emptyTuningData != 0){
        async function executeFunction() {
          await sequentialMutationVLPUpdate(dataCalculateVLP);
        }
        executeFunction()
      }

    }
  }

  const sequentialMutationVLPCreate = async (dataCalculateVLP) => {

    await mutationCreateCalTuningVLP.mutateAsync({
      operator: userDataInfo.operator,
      lease: userDataInfo.lease,
      region: userDataInfo.region,
      county: dataFilterUserGasLift.county,
      field: dataFilterUserGasLift.field,
      wellid: dataFilterUserGasLift.well_id,
      username: User.user_name,
      data: dataCalculateVLP,
    });

    await new Promise(resolve => setTimeout(resolve, 1000));

    await Promise.all([
      refetchCalculateUntunedVLP(),
      refetchCalculateTuningVLP(),
      refetchPressDrowDown()
    ]);
  };

  const sequentialMutationVLPUpdate = async (dataCalculateVLP) => {

    await mutationUpdateCalTuningVLP.mutateAsync({
      operator: userDataInfo.operator,
      lease: userDataInfo.lease,
      region: userDataInfo.region,
      county: dataFilterUserGasLift.county,
      field: dataFilterUserGasLift.field,
      wellid: dataFilterUserGasLift.well_id,
      username: User.user_name,
      data: dataCalculateVLP,
    });
    await new Promise(resolve => setTimeout(resolve, 1000));

    await Promise.all([
      refetchCalculateUntunedVLP(),
      refetchCalculateTuningVLP(),
      refetchPressDrowDown()
    ]);
  };

  const convertDate = (fechaNumerica) => {
    const fecha = new Date((fechaNumerica - (25567 + 1)) * 86400 * 1000); // Convertir la fecha numérica en milisegundos
    const mes = fecha.getMonth() + 1;
    const dia = fecha.getDate();
    const anio = fecha.getFullYear();
    return `${mes}/${dia}/${anio}`;
  };

  const showCleanConfirm = () => {
    confirm({
      title: "Do want to clean this tuning?",
      icon: <ExclamationCircleFilled />,
      content:
        "Remember that by executing this processes all fields will be emptied",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        setInputDataTuning(initialData);
        setDataTableTubing(initialDataTubing);
        setDataTableGauge(initialDataGauge);
        setDataTableTestVLP(initialDataTestVLP);
        setActiveChecks([]);
        message.success("The fields have been cleaned");
      },
      onCancel() {
        
      },
    });
  };

  const handleFluidLab = (checked) => {
    setInputDataTuning({
      ...inputDataTuning,
      whpCheck: checked
    })
  };

  const decimalRound = (number, round) => {
    let valRound = number != undefined && number != null && number != "" ? Number(number.toFixed(round)) : null;
    return valRound;
  };

  function sumMeasureDepth(option, value) {    
    const valuesDeviation = dataQueryWell.dataDeviation.length ? dataQueryWell.dataDeviation.length : null;
    const lastDeviation = dataQueryWell.dataDeviation[valuesDeviation - 1].measured_depth;
    
    let validation = false;
    switch (option) {
      case "only":
        validation = value > lastDeviation;
        break;
        case "array":          
          const dataValue = value.reduce((total, item) => {
            return total + parseFloat(item.measured_depth ? item.measured_depth : item.gauge_tvd);
          }, 0);

          validation = dataValue > lastDeviation;
        break;
      default:
        break;
    }
    return validation;
    
  }

  return (
    <div className="flex flex-col w-full h-full bg-white ">
      <div className="flex">
        <Navbar wellboreOption={wellboreOption}></Navbar>
        <Sidebar className="flex absolute h-full "></Sidebar>
      </div>
      <div>
        {loadDataTuning && (
          <div>
            <Loading></Loading>
          </div>
        )}
      </div>
      <div className="flex w-full h-full">
        <AlertModal
          showAlert={showAlert}
          handleCloseModal={handleCloseModal}
          messageAlert={messageAlert}
          typeModal={typeAlert}
        ></AlertModal>
      </div>
      <div className="flex flex-col w-full h-full pl-[88px] text-center justify-center">
        <div className="flex w-full h-[50px] justify-start text-center items-center mb-[27px] mt-[60px] pl-[110px]">
          <div className="flex flex-row w-[1230px] h-[24px] gap-5 justify-start text-center items-center">
            <h1 className="flex flex-row font-bold text-[20px]">VLP Tuning</h1>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full h-full pl-[160px] pr-[100px] text-center justify-center  mb-[10px]">
        <div className="flex w-full h-full justify-center text-center items-center mb-[7px]">
          <div className="flex flex-row w-full h-full gap-5 justify-start text-center items-center ">
            <h1 className="flex flex-row font-bold text-[16px]" >Reservoir Parameters</h1>
          </div>
        </div>
        <div className="flex flex-row w-full  text-center justify-center gap-5">
          <div className="flex flex-col w-1/3">
            <label htmlFor="" className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">Mid-perf Measured Depth (feet)</label>
            <input 
              type="number" 
              onWheel={(e) => e.target.blur()} 
              name="mid_perf_tvd_depth" 
              value={inputDataTuning.mid_perf_tvd_depth} 
              onChange={handleChangeInputData} 
              className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px] mb-[17px] border 
                ${(inputEmpty && inputDataTuning.mid_perf_tvd_depth === "") || inputDataTuning.mid_perf_tvd_depth === null
                ? ' border-red-700 bg-yellow-100' 
                : 'border-solid border-[#BCBCCC]'}`} 
              min="0"
              max="60000"
            />
          </div>
          <div className="flex flex-col w-1/3">
            <label htmlFor="" className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">Reservoir Pressure (psia)</label>
            <input type="number" onWheel={(e) => e.target.blur()}  name="reservoir_pressure" value={inputDataTuning.reservoir_pressure} onChange={handleChangeInputData} className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border ${inputEmpty && inputDataTuning.reservoir_pressure === "" ? ' border-red-700 bg-yellow-100' : 'border-solid border-[#BCBCCC]'}`} min="0" max="25000"/>
          </div>
          <div className="flex flex-col w-1/3">
            <label htmlFor="" className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">Reservoir Temperature (deg F)</label>
            <input type="number" onWheel={(e) => e.target.blur()}  name="reservoir_temperature" value={inputDataTuning.reservoir_temperature} onChange={handleChangeInputData} className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border ${inputEmpty && inputDataTuning.reservoir_temperature === "" ? ' border-red-700 bg-yellow-100' : 'border-solid border-[#BCBCCC]'}`} min="0" max="450"/>
          </div>
        </div>
      </div>

      <div className="flex flex-col w-full h-full pl-[160px] pr-[100px] text-center justify-center  mb-[10px]">
        <div className="flex w-full h-full justify-center text-center items-center mb-[7px]">
          <div className="flex flex-row w-full h-full gap-5 justify-start text-center items-center ">
            <h1 className="flex flex-row font-bold text-[16px]" >Production Parameters</h1>
          </div>
        </div>
        <div className="flex flex-row w-full  text-center justify-center gap-5">
          <div className="flex flex-col w-1/3">
            <label htmlFor="" className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">Test Date</label>
            <input type="date" name="test_date_pp" value={inputDataTuning.test_date_pp} onChange={handleChangeInputData} className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border ${inputEmpty && inputDataTuning.test_date_pp === "" ? ' border-red-700 bg-yellow-100' : 'border-solid border-[#BCBCCC]'}`} />
          </div>
          <div className="flex flex-col w-1/3">
            <label htmlFor="" className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">Oil Rate (STB/D)</label>
            <input type="number" onWheel={(e) => e.target.blur()}  name="oil_rate" value={inputDataTuning.oil_rate} onChange={handleChangeInputData} className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border ${inputEmpty && inputDataTuning.oil_rate === "" ? ' border-red-700 bg-yellow-100' : 'border-solid border-[#BCBCCC]'}`} min="0" max="50000"/>
          </div>
          <div className="flex flex-col w-1/3">
            <label htmlFor="" className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">Gas Rate (MSCF/D)</label>
            <input type="number" onWheel={(e) => e.target.blur()}  disabled={enableInputGasRate} name="gas_rate" value={inputDataTuning.gas_rate} onChange={handleChangeInputData} className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border `} min="0" max="100000"/>
          </div>
        </div>
        <div className="flex flex-row w-full  text-start justify-start gap-5">
          <div className="flex flex-col w-1/3">
            <label htmlFor="" className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">GOR (SCF/STB)</label>
            <input type="number" onWheel={(e) => e.target.blur()}  disabled={enableInputGOR} name="gor_pp" value={inputDataTuning.gor_pp} onChange={handleChangeInputData} className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border`} min="0" max="500000"/>
          </div>
          <div className="flex flex-col w-1/3">
            <label htmlFor="" className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">Water Cut (%)</label>
            <input type="number" onWheel={(e) => e.target.blur()}  name="water_cut" value={inputDataTuning.water_cut} onChange={handleChangeInputData} className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border ${inputEmpty && inputDataTuning.water_cut === "" ? ' border-red-700 bg-yellow-100' : 'border-solid border-[#BCBCCC]'}`} min="0" max="100"/>
          </div>
          <div className="flex flex-col w-1/3 max-w-[592px]" hidden={true}>
          </div>
        </div>
      </div>

      <div className="flex flex-col w-full h-full pl-[160px] pr-[100px] text-center justify-center  mb-[10px]">
        <div className="flex w-full h-full justify-center text-center items-center mb-[7px]">
          <div className="flex flex-row w-full h-full gap-5 justify-start text-center items-center ">
            <h1 className="flex flex-row font-bold text-[16px]" >Gas Lift Parameters</h1>
          </div>
        </div>
        <div className="flex flex-row w-full  text-center justify-center gap-5">
          <div className="flex flex-col w-1/3">
            <label htmlFor="" className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">Test Date</label>
            <input type="date" name="test_date_glp" value={inputDataTuning.test_date_glp} onChange={handleChangeInputData} className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border ${inputEmpty && inputDataTuning.test_date_glp === "" ? ' border-red-700 bg-yellow-100' : 'border-solid border-[#BCBCCC]'} `} />
          </div>
          <div className="flex flex-col w-1/3">
            <label htmlFor="" className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">Injection Gas Rate  (MSCF/D)</label>
            <input type="number" onWheel={(e) => e.target.blur()}  name="injection_gas_rate" value={inputDataTuning.injection_gas_rate} onChange={handleChangeInputData} className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border ${inputEmpty && inputDataTuning.injection_gas_rate === "" ? ' border-red-700 bg-yellow-100' : 'border-solid border-[#BCBCCC]'}  `} min="0" max="100000"/>
          </div>
          <div className="flex flex-col w-1/3">
            <label htmlFor="" className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">Injection Pressure (psig)</label>
            <input type="number" onWheel={(e) => e.target.blur()}  name="injection_pressure" value={inputDataTuning.injection_pressure} onChange={handleChangeInputData} className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border ${inputEmpty && inputDataTuning.injection_pressure === "" ? ' border-red-700 bg-yellow-100' : 'border-solid border-[#BCBCCC]'}  `} min="0" max="25000"/>
          </div>
        </div>

        <div className="flex w-full h-full justify-center text-center items-center mb-[7px]">
          <div className="flex flex-row w-full  text-center justify-center gap-5">
            <div className="flex flex-col w-1/3">
              <label htmlFor="" className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">Gas Lift Valve Depth 1 (feet)</label>
              <input type="number" onWheel={(e) => e.target.blur()}  name="gas_lift_valve_depth_1" value={inputDataTuning.gas_lift_valve_depth_1} onChange={handleChangeInputData} className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border ${inputEmpty && inputDataTuning.gas_lift_valve_depth_1 === "" ? ' border-red-700 bg-yellow-100' : 'border-solid border-[#BCBCCC]'}  `} min="0" max="60000"/>
            </div>
            <div className="flex flex-col w-1/3">
              <label htmlFor="" className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">Gas Lift Valve Depth 2 (feet)</label>
              <input type="number" onWheel={(e) => e.target.blur()}  name="gas_lift_valve_depth_2" value={inputDataTuning.gas_lift_valve_depth_2} onChange={handleChangeInputData} className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border ${inputEmpty && inputDataTuning.gas_lift_valve_depth_2 === "" ? ' border-red-700 bg-yellow-100' : 'border-solid border-[#BCBCCC]'}  `} min="0" max="60000"/>
            </div>
            <div className="flex flex-col w-1/3">
              <label htmlFor="" className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">Gas Lift Valve Depth 3 (feet)</label>
              <input type="number" onWheel={(e) => e.target.blur()}  name="gas_lift_valve_depth_3" value={inputDataTuning.gas_lift_valve_depth_3} onChange={handleChangeInputData} className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border ${inputEmpty && inputDataTuning.gas_lift_valve_depth_3 === "" ? ' border-red-700 bg-yellow-100' : 'border-solid border-[#BCBCCC]'}  `} min="0" max="60000"/>
            </div>
          </div>
        </div>

        <div className="flex w-full h-full justify-center text-center items-center mb-[7px]">
          <div className="flex flex-row w-full  text-center justify-center gap-5">
            <div className="flex flex-col w-1/3">
              <label htmlFor="" className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">Gas Injection Distribution Valve 1 (%)</label>
              <input type="number" onWheel={(e) => e.target.blur()}  name="gas_injection_distribution_valve_1" value={inputDataTuning.gas_injection_distribution_valve_1} onChange={handleChangeInputData} className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border ${inputEmpty && inputDataTuning.gas_injection_distribution_valve_1 === "" ? ' border-red-700 bg-yellow-100' : 'border-solid border-[#BCBCCC]'}  `} min="0" max="100"/>
            </div>
            <div className="flex flex-col w-1/3">
              <label htmlFor="" className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">Gas Injection Distribution Valve 2 (%)</label>
              <input type="number" onWheel={(e) => e.target.blur()}  name="gas_injection_distribution_valve_2" value={inputDataTuning.gas_injection_distribution_valve_2} onChange={handleChangeInputData} className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border ${inputEmpty && inputDataTuning.gas_injection_distribution_valve_2 === "" ? ' border-red-700 bg-yellow-100' : 'border-solid border-[#BCBCCC]'}  `} min="0" max="100"/>
            </div>
            <div className="flex flex-col w-1/3">
              <label htmlFor="" className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">Gas Injection Distribution Valve 3 (%)</label>
              <input type="number" onWheel={(e) => e.target.blur()}  name="gas_injection_distribution_valve_3" value={inputDataTuning.gas_injection_distribution_valve_3} onChange={handleChangeInputData} className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border ${inputEmpty && inputDataTuning.gas_injection_distribution_valve_3 === "" ? ' border-red-700 bg-yellow-100' : 'border-solid border-[#BCBCCC]'}  `} min="0" max="100"/>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col w-full h-full pl-[160px] pr-[100px] text-center justify-center  mb-[10px]">
        <div className="flex w-full h-full justify-center text-center items-center mb-[7px]">
          <div className="flex flex-row w-full h-full gap-5 justify-start text-center items-center ">
            <h1 className="flex flex-row font-bold text-[16px]" >Well Head Parameters</h1>
            <div className="flex w-full max-w-[592px] text-start font bold text-[15px] gap-2">
              <label htmlFor="" className='text-bc-gray text-[14px] ml-[15px]'>User</label>
              <Switch
                size="small"
                style={{
                  backgroundColor: inputDataTuning?.whpCheck ? "#662D91" : "#707070",
                  borderColor: inputDataTuning?.whpCheck ? "#662D91" : "#707070",
                }}
                checked={inputDataTuning?.whpCheck}
                onChange={handleFluidLab}
              ></Switch> 
              <label htmlFor="" className='text-bc-gray text-[14px] ml-[3px]'>Real Time</label>
              <Tooltip title="This switch enables to use Real Time data">
                <InfoCircleOutlined className="" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
              </Tooltip>
            </div>
          </div>
        </div>
        <div className="flex w-full h-full justify-center text-center items-center mb-[7px]">
          <div className="flex flex-row w-full  text-center justify-center gap-5">
            <div className="flex flex-col w-1/3">
              <label htmlFor="" className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">Test Date</label>
              <input type="date" name="test_date_whp" value={inputDataTuning.test_date_whp} onChange={handleChangeInputData} disabled={inputDataTuning?.whpCheck} className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border ${inputEmpty && inputDataTuning.test_date_whp === "" ? ' border-red-700 bg-yellow-100' : 'border-solid border-[#BCBCCC]'}  `} />
            </div>
            <div className="flex flex-col w-1/3">
              <label htmlFor="" className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">Wellhead Pressure (psig)</label>
              <input type="number" onWheel={(e) => e.target.blur()}  name="wellhead_pressure" value={inputDataTuning.wellhead_pressure} onChange={handleChangeInputData} disabled={inputDataTuning?.whpCheck} className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border ${inputEmpty && inputDataTuning.wellhead_pressure === "" ? ' border-red-700 bg-yellow-100' : 'border-solid border-[#BCBCCC]'}  `} min="0" max="5000"/>
            </div>
            <div className="flex flex-col w-1/3">
              <label htmlFor="" className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">Wellhead Temperature (degF)</label>
              <input type="number" onWheel={(e) => e.target.blur()}  name="wellhead_temperature" value={inputDataTuning.wellhead_temperature} onChange={handleChangeInputData} disabled={inputDataTuning?.whpCheck} className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border ${inputEmpty && inputDataTuning.wellhead_temperature === "" ? ' border-red-700 bg-yellow-100' : 'border-solid border-[#BCBCCC]'}  `} min="0" max="250"/>
            </div>
          </div>
        </div>

        
      </div>
      
      <div className="flex flex-col w-full h-full pl-[160px] pr-[100px] text-center justify-center mb-[10px]">
        <div className="flex flex-col w-full h-full justify-center text-center items-center mb-[27px]">
          <div className="flex w-full h-full justify-center text-center items-center mb-[7px]">
            <div className="flex flex-row w-full h-full gap-5 justify-start text-center items-center ">
              <h1 className="flex flex-row font-bold text-[16px]" >Tubing</h1>
              <ImportFile uploadfile={handleUploadFileTubing}></ImportFile>
            </div>
          </div>
          <ul className="flex flex-col w-full h-full">
            {/* seccion de titulos */}
            <div className="flex flex-col w-full text-start justify-end items-end mb-[7px]">
              <div className="flex flex-row w-full h-[20px] pl-[40px] pr-[40px] gap-2">
                <label className="flex max-w-[592px] w-1/4 text-left items-start justify-start font-bold text-[15px] mb-[7px]">
                  Name
                </label>
                <label className="flex max-w-[592px] w-1/4 text-left items-start justify-start font-bold text-[15px] mb-[7px]">
                  MD Length (feet)
                </label>
                <label className="flex max-w-[592px] w-1/4 text-left items-start justify-start font-bold text-[15px] mb-[7px]">
                  Internal Diameter (inches)
                </label>
                <label className="flex max-w-[592px] w-1/4 text-left items-start justify-start font-bold text-[15px] mb-[7px]">
                  Absolute Roughness (inches)
                </label>
              </div>
            </div>
            {dataTableTubing.map((item, index) => (
              <li key={item.id+"tubing"+index} className="flex flex-row w-full overflow-y-auto">
                {/* <IconArrowsVertical className="flex w-6 h-[47px] fill-neutral-500" /> */}
                <div className="flex w-6 h-[47px] fill-neutral-500"></div>
                <label
                  htmlFor=""
                  className="flex w-[20px] h-[47px] pr-[10px] font-bold text-lg text-center items-center justify-center "
                >
                  {index + 1}
                </label>
                <div className="flex flex-col w-full pr-[10px] gap-x-[18px] mb-[10px]">
                  <div className="flex flex-row w-full gap-5">
                    <div className="flex flex-col w-1/4 text-right items-end">
                      <input
                        type="text"
                        defaultValue={item.nameTubing}
                        name={`nameTubing`}
                        value={dataTableTubing[index].nameTubing}
                        onChange={(e) => handleChangeTubing(e, item.id)}
                        className={`w-full max-w-[592px] h-[47px] px-2 rounded-[4px] border border-solid border-[#BCBCCC]`}
                      />
                    </div>
                    <div className="flex flex-col w-1/4 text-right items-end">
                      <input
                        type="number"
                        defaultValue={item.measured_depth}
                        name={`measured_depth`}
                        value={dataTableTubing[index].measured_depth}
                        onChange={(e) => handleChangeTubing(e, item.id)}
                        onWheel={(e) => e.target.blur()}
                        onBlur={(e) => handleChangeBlur(e, item.id)}
                        className={`w-full max-w-[592px] h-[47px] px-2 rounded-[4px] border border-solid 
                          ${item.measured_depth === null
                            ? "border-red-700 bg-yellow-100"
                            : "border-[#BCBCCC]"
                          }`}
                      />
                    </div>
                    <div className="flex flex-col w-1/4 text-right items-end">
                      <input
                        type="number"
                        defaultValue={item.internal_diameter}
                        name={`internal_diameter`}
                        value={dataTableTubing[index].internal_diameter}
                        onChange={(e) => handleChangeTubing(e, item.id)}
                        onWheel={(e) => e.target.blur()}
                        className="w-full max-w-[592px] h-[47px] px-2 rounded-[4px] border border-solid border-[#BCBCCC]"
                      />
                    </div>
                    <div className="flex flex-col w-1/4 text-right items-end">
                      <input
                        type="number"
                        defaultValue={item.absolute_roughness}
                        name={`absolute_roughness`}
                        value={dataTableTubing[index].absolute_roughness}
                        onChange={(e) => handleChangeTubing(e, item.id)}
                        onWheel={(e) => e.target.blur()}
                        className="w-full  max-w-[592px]  h-[47px] px-2 rounded-[4px] border border-solid border-[#BCBCCC]"
                      />
                    </div>
                    
                    <div className={`flex flex-row  text-right items-start gap-2 ${dataTableTubing.length - 1 === index ? "": "pr-8"}`}>
                      {dataTableTubing.length - 1 === index &&(
                        <IconAdd
                          className="w-6 h-[47px] fill-neutral-500"
                          onClick={() => handleAddTubing()}
                        />
                      )}
                      {dataTableTubing.length >= 2 && (
                        <IconDelete
                          className="w-6 h-[47px] fill-neutral-500 "
                          onClick={() => handleDeleteTubing(item.id)}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="flex flex-col w-full h-full justify-center text-center items-center mb-[27px]">
          <div className="flex w-full h-full justify-center text-center items-center mb-[7px]">
            <div className="flex flex-row w-full h-full gap-2 justify-start text-center items-center ">
              <h1 className="flex flex-row font-bold text-[16px] mr-[12px]" >Gauge Parameters</h1>
              <label htmlFor="" className='text-bc-gray text-[14px] ml-[15px]'>Off</label>
              <Switch 
                style={{
                  backgroundColor: inputDataTuning?.enable_gauge_parameters ? "#662D91" : "#707070",
                  borderColor: inputDataTuning?.enable_gauge_parameters ? "#662D91" : "#707070",
                }}
                checked={inputDataTuning.enable_gauge_parameters} 
                onChange={(value) => handleSwitch("enable_gauge_parameters",value)} size="small"
              />
              <label htmlFor="" className='text-bc-gray text-[14px]'>On</label>
              <Tooltip title="Enable this switch when production test information is available">
                <InfoCircleOutlined className="" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
              </Tooltip> 
              <ImportFile uploadfile={handleUploadFileGauge}></ImportFile>
            </div>
          </div>
          <ul className="flex flex-col w-full h-full">
            {/* seccion de titulos */}
            <div className="flex flex-col w-full text-start justify-end items-end mb-[7px]">
              <div className="flex flex-row w-full h-[20px] pl-[40px] pr-[40px] gap-2">
                <label className="flex w-1/4 text-left items-start justify-start font-bold text-[15px] mb-[7px]">
                  Test Date
                </label>
                <label className="flex w-1/4 text-left items-start justify-start font-bold text-[15px] mb-[7px]">
                  Gauge MD (feet)
                </label>
                <label className="flex w-1/4 text-left items-start justify-start font-bold text-[15px] mb-[7px]">
                  Gauge Pressure (psia)
                </label>
                <label className="flex w-1/4 text-left items-start justify-start font-bold text-[15px] mb-[7px]">
                  Gauge Temperature (deg F)
                </label>
              </div>
            </div>
            {inputDataTuning.enable_gauge_parameters && dataTableGauge.map((item, index) => (
              <li key={item.id+"gauge"+index} className="flex flex-row w-full overflow-y-auto">
                {/* <IconArrowsVertical className="flex w-6 h-[47px] fill-neutral-500" /> */}
                <div className="flex w-6 h-[47px] fill-neutral-500"></div>
                <label
                  htmlFor=""
                  className="flex w-[20px] h-[47px] pr-[10px] font-bold text-lg text-center items-center justify-center "
                >
                  {index + 1}
                </label>
                <div className="flex flex-col w-full pr-[10px] gap-x-[18px] mb-[10px]">
                  <div className="flex flex-row w-full gap-5">
                    <div className="flex flex-col w-1/4  text-right items-end">
                      <input
                        type="date"
                        defaultValue={item.test_date}
                        name={`test_date`}
                        value={dataTableGauge.find(data => data.id === item.id)?.test_date}
                        onChange={(e) => handleChangeGauge(e, item.id)}
                        className="w-full max-w-[592px] h-[47px] px-2 rounded-[4px] border border-solid border-[#BCBCCC]"
                      />
                    </div>
                    <div className="flex flex-col w-1/4 text-right items-end">
                      <input
                        type="number"
                        //defaultValue={item.gauge_tvd}
                        name={`gauge_tvd`}
                        value={dataTableGauge.find(data => data.id === item.id)?.gauge_tvd}
                        onChange={(e) => handleChangeGauge(e, item.id)}
                        onWheel={(e) => e.target.blur()}
                        onBlur={(e) => handleChangeBlur(e, item.id)}
                        className={`w-full max-w-[592px] h-[47px] px-2 rounded-[4px] border border-solid border-[#BCBCCC]
                          ${item.gauge_tvd === null ? "border-red-700 bg-yellow-100" : "border-[#BCBCCC]"}
                        `}
                      />
                    </div>
                    <div className="flex flex-col w-1/4 text-right items-end">
                      <input
                        type="number"
                        defaultValue={item.gauge_pressure}
                        name={`gauge_pressure`}
                        value={dataTableGauge.find(data => data.id === item.id)?.gauge_pressure}
                        onChange={(e) => handleChangeGauge(e, item.id)}
                        onWheel={(e) => e.target.blur()}
                        className="w-full max-w-[592px] h-[47px] px-2 rounded-[4px] border border-solid border-[#BCBCCC]"
                      />
                    </div>
                    <div className="flex flex-col w-1/4 text-right items-end">
                      <input
                        type="number"
                        defaultValue={item.gauge_temperature}
                        name={`gauge_temperature`}
                        value={dataTableGauge[index].gauge_temperature}
                        onChange={(e) => handleChangeGauge(e, item.id)}
                        onWheel={(e) => e.target.blur()}
                        className="w-full  max-w-[592px]  h-[47px] px-2 rounded-[4px] border border-solid border-[#BCBCCC]"
                      />
                    </div>
                    
                    <div className={`flex flex-row  text-right items-start gap-2 ${dataTableGauge.length - 1 === index ? "": "pr-8"}`}>
                      {dataTableGauge.length - 1 === index && (
                        <IconAdd
                          className="w-6 h-[47px] fill-neutral-500 "
                          onClick={() => handleAddGauge()}
                        />
                      )}
                      {dataTableGauge.length >= 2 && (
                        <IconDelete
                          className="w-6 h-[47px] fill-neutral-500 "
                          onClick={() => handleDeleteGauge(item.id)}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>

        <div className="flex flex-row w-full h-full mb-[20px]">
          <div className="flex flex-col w-2/5 text-right items-start pl-[45px] ">
            <Menu
              className="flex w-[446px]"
              mode="horizontal"
              defaultSelectedKeys={["0"]}
              onClick={handleNav}
            >
              <Menu.Item value="0" key="0">
                Correlation
              </Menu.Item>
              <Menu.Item value="1" key="1">
                Tuning VLP
              </Menu.Item>
            </Menu>

            {selectedValue === "0" && (
              <>
                <div className="flex flex-col w-full h-full text-right items-start mt-[30px]">
                  <div className="flex flex-col w-full h-full text-right items-start mb-[10px]">
                    {Object.keys(typeLineGraph).map((id,index) => (
                      <div key={"corrrelation"+index}>
                        <Checkbox
                          name={typeLineGraph[id]}
                          className={`my-checkbox ${parseInt(id) === 0 ? "mt-[]" : "mt-[25px]"
                            }`}
                          checked={activeChecks.length > 0 ? activeChecks.includes(parseInt(id)): false}
                          onChange={() =>
                            handleCheckChange(parseInt(id), typeLineGraph[id])
                          }
                          disabled={
                            parseInt(id) === 1 ||
                            parseInt(id) === 2 ||
                            parseInt(id) === 4
                          }
                        >
                          <div className="flex flex-row text-center items-center justify-center">
                            <div
                              style={{ background: typeLineColorGraph[id] }}
                              className={`flex w-[10px] h-[10px]  rounded-full ml-[5px] mr-[5px] text-center items-center justify-center`}
                            ></div>
                            <label htmlFor="">{typeLineGraph[id]}</label>
                          </div>
                        </Checkbox>
                      </div>
                    ))}
                  </div>
                  <div className="flex flex-col w-full text-right items-start mb-[18px]">
                    <label
                      htmlFor=""
                      className="flex w-full max-w-[150px] text-start text-[15px] mb-[7px]"
                    >
                      Number of Intervals
                    </label>
                    <input
                      name="number_of_intervals"
                      value={inputDataTuning.number_of_intervals}
                      onChange={handleChangeInputData}
                      onWheel={(e) => e.target.blur()}
                      type="number"
                      className={`w-full h-[47px] rounded-[4px] px-2 border ${inputEmpty && inputDataTuning.number_of_intervals === "" ? ' border-red-700 bg-yellow-100' : 'border-solid border-[#BCBCCC]'}`}
                    />
                  </div>
                  <div className="flex flex-col w-full text-right items-end mb-[10px] ">
                    <button
                      className="h-[2em] w-[5em] bg-pa-purple text-white rounded-md hover:bg-pc-purple"
                      onClick={handleCalculateVLP}
                    >
                      Run
                    </button>
                  </div>
                  <div className="flex flex-col w-full text-right items-start mb-[10px]">
                    <h1 className="flex flex-row font-bold text-[20px]">
                      Test Data
                    </h1>
                  </div>
                  <div className="flex flex-col w-full h-full mb-[10px]">
                    <div className="flex flex-col w-full h-full text-start justify-end items-end mb-[7px]">
                      <div className="flex flex-row w-full gap-2">
                        <label className="flex w-1/3 text-left items-start justify-start font-bold text-[15px] ">
                          TVD Depth (ft)
                        </label>
                        <label className="flex w-1/3 text-left items-start justify-start font-bold text-[15px]">
                          Measured Pressure (psig)
                        </label>
                        <label className="flex w-1/3 text-left items-start justify-start font-bold text-[15px]">
                          Oil Rate (STB/D)
                        </label>
                      </div>
                    </div>
                    {dataTableTestVLP.map((item, index) => (
                      <li key={item.id+"test"+index} className="flex flex-row w-full h-full min-h-[30px] max-h-[500px]">
                        <div className="flex flex-col w-full pr-[10px] gap-x-[18px] mb-[10px]">
                          <div className="flex flex-row w-full gap-5">
                            <div className="flex flex-col w-1/3  text-right items-end">
                              <input
                                type="text"
                                //defaultValue={item.depth_tvd}
                                name={`depth_tvd`}
                                defaultValue={dataTableTestVLP.find(data => data.id === item.id)?.depth_tvd || ''}
                                className="w-full max-w-[592px] h-[47px] px-2 rounded-[4px] border border-solid border-[#BCBCCC]"
                              />
                            </div>
                            <div className="flex flex-col w-1/3 text-right items-end">
                              <input
                                type="number"
                                // defaultValue={item.measured_pressure_tvd}
                                name={`measured_pressure_tvd`}
                                defaultValue={dataTableTestVLP.find(data => data.id === item.id)?.measured_pressure_tvd || ''}
                                className="w-full max-w-[592px] h-[47px] px-2 rounded-[4px] border border-solid border-[#BCBCCC]"
                              />
                            </div>
                            <div className="flex flex-col w-1/3 text-right items-end">
                              <input
                                type="number"
                                // defaultValue={item.oil_rate_tvd}
                                name={`oil_rate_tvd`}
                                defaultValue={dataTableTestVLP.find(data => data.id === item.id)?.oil_rate_tvd || ''}
                                className="w-full max-w-[592px] h-[47px] px-2 rounded-[4px] border border-solid border-[#BCBCCC]"
                              />
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </div>
                </div>
              </>
            )}
            {selectedValue === "1" && (
              <>
                <div className="flex flex-col w-full h-full text-right items-start mt-[30px]">
                  <div className="flex flex-col w-full h-full col-span-2 text-right items-start mb-[12px]">
                    <label
                      htmlFor=""
                      className="flex w-full max-w-[150px] text-start text-[15px] mb-[7px]"
                    >
                      Parameter 1
                    </label>
                    <input
                      name="paramter1_vlp"
                      value={inputDataTuning.paramter1_vlp}
                      onChange={handleChangeInputData}
                      onWheel={(e) => e.target.blur()}
                      type="number"
                      className={`w-full h-[47px] rounded-[4px] px-2  mb-[12px] border ${inputEmpty && inputDataTuning.paramter1_vlp === "" ? ' border-red-700 bg-yellow-100' : 'border-solid border-[#BCBCCC]'}`}
                    />
                  </div>
                  <div className="flex flex-col w-full col-span-2 text-right items-start mb-[12px]">
                    <label
                      htmlFor=""
                      className="flex w-full max-w-[150px] text-start text-[15px] mb-[7px]"
                    >
                      Parameter 2
                    </label>
                    <input
                      name="paramter2_vlp"
                      value={inputDataTuning.paramter2_vlp}
                      onChange={handleChangeInputData}
                      onWheel={(e) => e.target.blur()}
                      type="number"
                      className={`w-full h-[47px] rounded-[4px] px-2  mb-[12px] border ${inputEmpty && inputDataTuning.paramter2_vlp === "" ? ' border-red-700 bg-yellow-100' : 'border-solid border-[#BCBCCC]'}`}
                    />
                  </div>
                  <div className="flex flex-col w-full col-span-2 text-right items-start md-[10px]">
                    <label
                      htmlFor=""
                      className="flex w-full max-w-[150px] text-start text-[15px] mb-[7px]"
                    >
                      Number of Intervals
                    </label>
                    <input
                      name="number_of_intervals"
                      value={inputDataTuning.number_of_intervals}
                      onChange={handleChangeInputData}
                      onWheel={(e) => e.target.blur()}
                      type="text"
                      className={`w-full h-[47px] rounded-[4px] px-2  mb-[14px] border ${inputEmpty && inputDataTuning.number_of_intervals === "" ? ' border-red-700 bg-yellow-100' : 'border-solid border-[#BCBCCC]'}`}
                    />
                  </div>
                  <div className="flex flex-col w-full col-span-2 text-right items-start ">
                    <label
                      htmlFor=""
                      className="flex w-full max-w-[350px] text-start text-[15px] mb-[7px]"
                    >
                      Drawdown Pressure (psi) from Test at Reservoir Depth
                    </label>
                    <input
                      name="drawdownPres"
                      value={inputDataTuning?.drawdownPres}
                      onChange={handleChangeInputData}
                      onWheel={(e) => e.target.blur()}
                      type="text"
                      className={`w-full h-[47px] rounded-[4px] px-2  mb-[17px] border ${inputEmpty && inputDataTuning.number_of_intervals === "" ? ' border-red-700 bg-yellow-100' : 'border-solid border-[#BCBCCC]'}`}
                    />
                  </div>  
                  <div className="flex flex-col w-full text-right items-end mb-[10px]">
                    <button
                      className="h-[2em] w-[5em] bg-pa-purple text-white rounded-md hover:bg-pc-purple"
                      onClick={handleCalculateVLP}
                    >
                      Run
                    </button>
                  </div>
                  <div className="flex flex-col w-full col-span-2 text-right items-start">
                    <h1 className="flex flex-row font-bold text-[16px] mb-[10px]">
                      Tuned data
                    </h1>
                  </div>
                  <div className="flex flex-row w-full gap-5 ">
                    <div className="flex flex-col w-1/2 items-end text-start ">
                      <label
                        htmlFor=""
                        className="flex w-full items-end text-[15px] mb-[7px]"
                      >
                        Flowing Pressure (psia) at Sensor Depth
                      </label>
                      <input
                        type="number"
                        name="calculated_pressure"
                        value={inputDataTuning.calculated_pressure}
                        onChange={handleChangeInputData}
                        className="w-full h-[47px] rounded-[4px] px-2 border border-solid border-[#BCBCCC] mb-[17px]"
                      />
                    </div>
                    <div className="flex flex-col w-1/2 items-end text-start ">
                      <label
                        htmlFor=""
                        className="flex w-full items-end text-[15px] mb-[7px]"
                      >
                        Standard Deviation
                      </label>
                      <input
                        name="mse_vlp"
                        value={inputDataTuning.mse_vlp}
                        onChange={handleChangeInputData}
                        type="text"
                        className="w-full h-[47px] rounded-[4px] px-2 border border-solid border-[#BCBCCC] mb-[17px]"
                      />
                    </div>
                  </div>
                  <div className="flex flex-row w-full gap-5 ">
                    <div className="flex flex-col w-1/2 items-end text-start ">
                      <label
                        htmlFor=""
                        className="flex w-full items-end text-[15px] mb-[7px]"
                      >
                        Flowing Pressure (psia) at Reservoir Depth
                      </label>
                      <input
                        type="calculatedPressRD"
                        name="calculatedPressRD"
                        value={decimalRound(inputDataTuning?.calculatedPressRD, 2)}
                        onChange={handleChangeInputData}
                        className="w-full h-[47px] rounded-[4px] px-2 border border-solid border-[#BCBCCC] mb-[17px]"
                      />
                    </div>
                    <div className="flex flex-col w-1/2 items-end text-start ">
                      <label
                        htmlFor=""
                        className="flex w-full items-end text-[15px] mb-[7px]"
                      >
                        Calculated Drawdown
                        Pressure (psi)
                      </label>
                      <input
                        name="calculatedDrawdown"
                        value={decimalRound(inputDataTuning?.calculatedDrawdown, 2)}
                        onChange={handleChangeInputData}
                        type="text"
                        className="w-full h-[47px] rounded-[4px] px-2 border border-solid border-[#BCBCCC] mb-[17px]"
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="flex flex-col w-full text-right items-start " >
            {selectedValue === "0" && (
              <div className="flex flex-col h-full w-full text-right items-start ml-2">
                <div className="flex w-full items-start justify-end text-center ">
                  <ExpandIcon1
                    className="w-5 h-5 hover:bg-pc-grey3"
                    onClick={handleCLickExpand}
                  ></ExpandIcon1>
                </div>
                <div className="flex w-full h-full">
                  <ChartVLP 
                  activeChecks={activeChecks} 
                  dataPoints={dataTableGauge}  
                  dataPoint2={inputDataTuning}
                  dataPoint3={queryPressDrowDown?.data}
                  dataTableLine={[]}
                  dataTableLine2={dataTableGraphUntVLP}
                  domainMaxDepth= {maxDepth}
                  domainMinDepth= {minDepth}
                  domainMaxPressure= {maxPressure}
                  domainMinPressure= {minPressure}
                  ></ChartVLP>
                </div>
                <div className='flex flex-row w-full gap-2 justify-center items-center'>
                    <div className='rounded-full w-3 h-3 bg-[#253fce]'></div>
                    <p className='text-bc-grey text-[13px]'>Untuned</p>
                </div>
                <Modal
                  title="Tuned VLP"
                  centered
                  open={open}
                  onOk={() => setOpen(false)}
                  onCancel={() => setOpen(false)}
                  width={1000}
                  className="bg-[#662D91] rounded-md"
                  okButtonProps={{
                    className: "bg-[#662D91]",
                  }}
                >
                  <div className="mb-[25px] justify-center mt-[25px] ml-[150px]"></div>
                  <div className="flex h-[500px] w-full">
                    <ChartVLP 
                      activeChecks={activeChecks} 
                      dataPoints={dataTableGauge} 
                      dataPoint2={inputDataTuning}
                      dataPoint3={queryPressDrowDown?.data}
                      dataTableLine={[]}
                      dataTableLine2={dataTableGraphUntVLP}
                      domainMaxDepth= {maxDepth}
                      domainMinDepth= {minDepth}
                      domainMaxPressure= {maxPressure}
                      domainMinPressure= {minPressure}
                    ></ChartVLP>
                  </div>
                  <div className='flex flex-row w-full gap-2 justify-center items-center'>
                    <div className='rounded-full w-3 h-3 bg-[#253fce]'></div>
                    <p className='text-bc-grey text-[13px]'>Untuned</p>
                  </div>
                </Modal>
              </div>
            )}
            {selectedValue === "1" && (
              <div className="flex flex-col h-full w-full text-right items-start ml-2">
                <div div className="flex flex-col h-full w-full text-right">
                  <div className="flex w-full items-start justify-end text-center ">
                    <ExpandIcon1
                      className="w-5 h-5 hover:bg-pc-grey3"
                      onClick={handleCLickExpand}
                    ></ExpandIcon1>
                  </div>

                  <ChartVLP 
                  activeChecks={activeChecks} 
                  dataPoints={dataTableGauge} 
                  dataPoint2={inputDataTuning}
                  dataPoint3={queryPressDrowDown?.data}
                  dataTableLine={dataTableGraphVLP}
                  dataTableLine2={dataTableGraphUntVLP}
                  domainMaxDepth= {maxDepth}
                  domainMinDepth= {minDepth}
                  domainMaxPressure= {maxPressure}
                  domainMinPressure= {minPressure}
                  tuningnNav = {true}
                  ></ChartVLP>
                  <div className='flex flex-row w-full gap-2 justify-center items-center'>
                      <div className='rounded-full w-3 h-3 bg-[#FE3F40]'></div>
                      <p className='text-bc-grey text-[13px]'>Tuned</p>
                      <div className='rounded-full w-3 h-3 bg-[#253fce]'></div>
                      <p className='text-bc-grey text-[13px]'>Untuned</p>
                  </div>
                </div>
                <Modal
                  title="VLP Correlations"
                  centered
                  open={open}
                  onOk={() => setOpen(false)}
                  onCancel={() => setOpen(false)}
                  width={1000}
                  className="bg-[#662D91] rounded-md"
                  okButtonProps={{
                    className: "bg-[#662D91]",
                  }}
                >
                  <div className="w-full h-[500px]">
                    <ChartVLP                   
                      activeChecks={activeChecks} 
                      dataPoints={dataTableGauge} 
                      dataPoint2={inputDataTuning}
                      dataPoint3={queryPressDrowDown?.data}
                      dataTableLine={dataTableGraphVLP}
                      dataTableLine2={dataTableGraphUntVLP}
                      domainMaxDepth= {maxDepth}
                      domainMinDepth= {minDepth}
                      domainMaxPressure= {maxPressure}
                      domainMinPressure= {minPressure}
                      tuningnNav = {true}
                      >
                    </ChartVLP>
                  </div>
                  <div className='flex flex-row w-full gap-2 justify-center items-center'>
                      <div className='rounded-full w-3 h-3 bg-[#FE3F40]'></div>
                      <p className='text-bc-grey text-[13px]'>Tuned</p>
                      <div className='rounded-full w-3 h-3 bg-[#253fce]'></div>
                      <p className='text-bc-grey text-[13px]'>Untuned</p>
                  </div>
                </Modal>
              </div>
            )}
            
          </div>
          
        </div>
        <div className="flex flex-col w-full h-[40px] text-right items-end">
              {selectedWell === null && (
                <>
                  <h1>Select Well</h1>
                </>
              )}
              {selectedWell != null && (
                <>
                  {(inputGetDataTuning === null) && (
                    <>
                      <button
                        className="w-[180px] h-[40px] bg-pa-purple text-white rounded-md"
                        onClick={() => handleClickDone()}
                      >
                        Done
                      </button>
                    </>
                  )}
                  {(emptyTuningData === 0) && (
                    <>
                      <button
                        className="w-[180px] h-[40px] bg-pa-purple text-white rounded-md"
                        onClick={() => handleClickEdit()}
                      >
                        Save
                      </button>
                    </>
                  )}

                  {(inputGetDataTuning != null && emptyTuningData != 0) && (
                    <div className="flex flex-col gap-5">
                      <button
                        hidden={true}
                        className="w-[180px] h-[40px] mr-[10px] bg-pa-purple text-white rounded-md"
                        onClick={() => handleClickClean()}
                      >
                        Clean
                      </button>
                      <button
                        className="w-[180px] h-[40px] bg-pa-purple text-white rounded-md"
                        onClick={() => handleClickEdit()}
                      >
                        Save
                      </button>
                    </div>
                  )}
                </>
              )}
            </div>
      </div>
    </div>
  );
};
