import React, { useContext, useEffect, useState } from "react";
import { Navbar } from "../../components/layout/Navbar";
import { Sidebar } from "../../components/layout/Sidebar";
import { Checkbox, Select, message, Tooltip, Tag, Modal } from "antd";
import { ConsoleSqlOutlined } from "@ant-design/icons";
import { Newspaper } from "react-bootstrap-icons";
import AddRegister from "../../components/User/AddRegister";
import { useUsers } from "../../hooks/useUsers";
import { postUsersAdd } from "../../services/api/usersAPI";
import { DashboardContext } from "../../context/Dashboard/DashboardContentx";
import { InfoCircleOutlined } from "@ant-design/icons";
import { is } from "date-fns/locale/is";

const options = [
  {
    value: "",
  },
  {
    value: "option 1",
  },
  {
    value: "option 2",
  },
  {
    value: "option 3",
  },
  {
    value: "option 4",
  },
  {
    value: "option 5",
  },
  {
    value: "option 6",
  },
  {
    value: "option 7",
  },
];
export const Register = () => {
  const { Option } = Select;
  const { getDataPage } = useContext(DashboardContext);
  const {
    User,
    body,
    setBody,
    loadOptionClient,
    ErrorOptionClient,
    loadOptionRole,
    ErrorOptionRoles,
    TableClients,
    setOptionsClients,
    TableRoles,
    idDataClient,
    optionProyects,
    setProyects,
    optionPlatforms,
    setPlatforms,
    setIdDataClient,
    refetchProjects,
    refetchPlatforms,
    validatePassword,
    refetchClientsProjects,
    refetchClientsPlatforms,
    handleCheckDuplicates,
    UpdateSuperUser,
    setLoadPage
  } = useUsers();
  const [wellboreOptionState, setWellboreOptionState] = useState(false);
  const wellboreOption = () => {
    setWellboreOptionState(!wellboreOptionState);
  };
  const Token = User.token;
  const [inputClient, setInputValueClient] = useState("");
  const [inputTypeUser, setTypeUser] = useState("");
  const [validationPassword, setValidationPassword] = useState('');
  const [error, setError] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [messageModal, setMessageModal] = useState("");
  const [titleModal, setTitleModal] = useState("");
  const [messagePattern, setPattern] = useState("");
  const [validateTootlip, setTooltip] = useState(false)
  const [inputValue, setInputValue] = useState(""); 

  const [isCheckedTable, setIsCheckedTable] = useState({
    users_enovates:false,
    users_externals:false
  });
  const [passwordValidate, SetPasswordValidate] = useState(false);
  const [inputEmpty, setInputEmpty] = useState(false);
  
  useEffect(() => {
    getDataPage("Register");
  }, []);

  useEffect(() => {
    if (idDataClient) {
      refetchClientsProjects();
      refetchClientsPlatforms();
    }
  }, [idDataClient])

  const showModal = () => {
    setIsModalOpen(true);
  };

  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        className="bg-pa-purple text-white rounded-md"
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginRight: 3,
        }}
      >
        {label}
      </Tag>
    );
  };
  

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
  
    setIsCheckedTable((prevState) => {
      // Resetear todos los checkboxes a false
      const newState = Object.keys(prevState).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {});
  
      // Activar solo el checkbox que fue clickeado
      return {
        ...newState,
        [name]: checked
      };
    });
  };

  const handleChange = ({ target: { name, value } }) => {
    const validateTableUser = Object.values(isCheckedTable).some(value => value === true);
    if (validateTableUser){
      if (name === "user_name" ) {
        const pattern = /^[a-zA-Z0-9\s-@._]*$/;
        if (pattern.test(value)) {
          let transformedValue = '';
          transformedValue = value.replaceAll(' ', '_');
  
          setBody({ ...body, [name]: transformedValue });
        } else {
          showModal();
          setPattern(true);
          setTitleModal("Error");
          setMessageModal("The entered value contains illegal characters");
        }
  
      }
      else if (name === "user_password") {
        SetPasswordValidate(validatePassword(value));
        if (passwordValidate) {
          setValidationPassword('✔');
          setTooltip(true);
        } else {
          setValidationPassword('❌');
          setTooltip(false);
        }
        setBody({ ...body, [name]: value });
      }
      else {
        setBody({ ...body, [name]: value });
      }
    } else {
      message.info("Select first the type user")
    }
  };

  const handleChangeSelect = (nameData,valueData, isMultiple = false) => {
    const validateTableUser = Object.values(isCheckedTable).some(value => value === true);
    if(validateTableUser){
      let filteredValues = valueData;

      if (isMultiple) {
        filteredValues = valueData.filter((option) => option && option !== "");
      }
      const customEvent = {
        target: {
          name: nameData,
          value: filteredValues,
        },
      };
      handleChange(customEvent);
    } else {
      message.info("Select first the type user")
    }
  };
  
  //Send Form Accion
  const handleSubmit = async (e) => {
    
    e.preventDefault();
    SetPasswordValidate(validatePassword(body.user_password));
    if (
      body.user_name !== "" &&
      body.user_password !== "" &&
      body.clients !== "" &&
      body.platforms !== "" &&
      body.proyects !== "" &&
      passwordValidate
    ) {
      try {
        // let table = isCheckedTable;
        const table = Object.entries(isCheckedTable).find(([key, value]) => value === true)?.[0];
        //Se deben crear primero los clientes proyectos y plataformas o de lo contrario estos no se peuden asignar al usuario a crear
        handleCheckDuplicates(TableClients, optionProyects, optionPlatforms ); 
        setLoadPage(true)
        setTimeout(() => {
          setLoadPage(false)
        }, 5000);
        setTimeout(async () => {
          const data = await postUsersAdd(body, table, Token);
          if (data.token) {
            showModal();
            setTitleModal("Successful");
            setMessageModal("Register Completed");
          } else {
            showModal();
            setTitleModal("Error");
            setMessageModal(
              "The process wasn't successful, please validate the information entered"
            );
          }
        }, 5000); 
      } catch (error) {
        setError("No registration data has been entered");
      }
      setInputEmpty(false);
    } else {
      message.info(
        "Incomplete fields, please validate the information entered"
      );
    } 
  };

  const handleAddOption = async (dataTable, name) => {
    if(!!dataTable){
        message.info("A new item has been created, please select it in the respective box");
        handleEntityRegistration(dataTable, name);
    } else (
      message.info("The creation not succefull")
    ) 
  };

   const handleEntityRegistration = async (dataTable, name) => {
    switch (name) { // entityType es una variable o estado que indica si es un cliente, proyecto, etc.
      case "clients":
        return await setOptionsClients(dataTable);
      case "projects":
        return await setProyects(dataTable);
      case "platforms":
        return await setPlatforms(dataTable);
      // case "role":
      //   return await postRoles(dataTable);
      default:
        throw new Error("Unknown entity type");
    }
  } 

  const handleOk = () => {
    /* if (messageModal === "Register Completed") {
      UpdateSuperUser();
      setLoadPage(true);
      message.info("Wait a few seconds while the Super User is updated");
    }
    if (messagePattern) {
      setPattern(false);
      setIsModalOpen(false);
    } else {
      navigate("/Register");
      setIsModalOpen(false);
    } */
      if (messageModal === "Register Completed") {
        UpdateSuperUser();
        setLoadPage(true);
        message.info("Wait a few seconds while the Super User is updated");
        setBody({
        user_name: "",
        user_password: "",
        clients: "",
        proyects: "",
        platforms: "",
        role: ""
      })
    }
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  
  const messageValidPassword = validationPassword === '✔' ? 'valid' : 'invalid';
  return (
    <div className="flex flex-col w-full h-full bg-white ">
      <div className="flex">
        <Navbar wellboreOption={wellboreOption}></Navbar>
        <Sidebar className="flex absolute h-full "></Sidebar>
      </div>
      <Modal
        title={titleModal}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={500}
        centered
        className="rounded-md"
        okButtonProps={{
          className: "bg-[#662D91]",
        }}
      >
          <p className="text-center text-lg">{messageModal}</p>
      </Modal>
      <form className="flex flex-col pl-[88px] w-full h-full" onSubmit={handleSubmit}>
        <div className="flex flex-row max-w-[1500px] h-full">
          <div className="flex flex-col w-1/4 h-full justify-center text-start items-start">
            <div className="mb-5 mt-5 mr-auto ml-auto">
              <h3 className="text-3xl text-right bg-white">PRODUCTION</h3>
              <h3 className="text-2xl text-right bg-white mt-[-10px]">
                ANALYTICS
              </h3>
            </div>
            <div className="flex flex-col w-full mt-3 px-[30px]">
              <div className="flex flex-row w-full">
                <h1 className="flex font-row font-bold">TYPE USER</h1>
              </div>
              <div className="flex flex-row w-full mt-3 gap-4 justify-center items-center">
                <Checkbox
                  name="users_enovates"
                  checked={isCheckedTable.users_enovates}
                  onChange={handleCheckboxChange}
                >
                  <div className="flex flex-row text-center">
                    <label>Master</label>
                  </div>
                </Checkbox>
                <Checkbox
                  name="users_externals"
                  checked={isCheckedTable.users_externals}
                  onChange={handleCheckboxChange}
                >
                  <div className="flex flex-row text-center">
                    <label>Simple User</label>
                  </div>
                </Checkbox>
              </div>
              <div className="flex flex-row w-full mt-3 justify-start items-center">
                <div className="flex flex-col w-full text-start">
                  <label
                    htmlFor=""
                    className="flex w-full items-end text-start text-[15px] font-bold"
                  >
                    USER NAME
                  </label>
                  <input
                    type="email"
                    name="user_name"
                    value={body.user_name}
                    placeholder="youremail@company.ltd"
                    onChange={handleChange}
                    className={`w-full h-[47px] rounded-[4px] border border-solid mb-[17px] mt-[10px] ${inputEmpty && body.user_name === "" ? ' border-red-700 bg-yellow-100' : 'border border-solid border-[#BCBCCC]' }`}
                  />
                </div>
              </div>
              <div className="flex flex-row w-full justify-start items-center">
                <div className="flex flex-col w-full text-start">
                    <label
                      htmlFor=""
                      className="text-[15px] font-bold"
                    >
                      PASSWORD
                    </label>
                    <div className="relative w-full">
                      <input
                        id="password"
                        type="password"
                        name="user_password"
                        value={body.user_password}
                        onChange={handleChange}
                        className={`w-full h-[47px] rounded-[4px] border border-solid mb-[17px] mt-[10px] pr-10 ${inputEmpty  && (body.user_password === "" || !passwordValidate)  ? ' border-red-700 bg-yellow-100' : 'border border-solid border-[#BCBCCC]' }`} 
                      />
                      <Tooltip
                        title={
                          <ul className="list-disc pl-4">
                            <li>The password must contain a minimum of 8 characters.</li>
                            <li>The password must contain at least one uppercase letter.</li>
                            <li>The password must contain at least one special character.</li>
                            <li>The password must contain at least one number.</li>
                          </ul>
                        }
                        placement="right"
                      >
                        <InfoCircleOutlined
                          style={{
                            color: 'rgba(0, 0, 0, 0.45)',
                            fontSize: '18px',
                            position: 'absolute',
                            right: '10px',
                            top: '45%',
                            transform: 'translateY(-50%)',
                            cursor: 'pointer',
                          }}
                        />
                      </Tooltip>
                    </div>
                </div>
              </div>
              <div className="flex flex-row w-full justify-start items-center">
                <div className="flex flex-col w-full text-start">
                  {(isCheckedTable.users_enovates || isCheckedTable.users_externals) && (
                    <>
                    <label
                      htmlFor=""
                      className="flex w-full items-end text-start text-[15px] font-bold"
                    >
                      OPERATOR
                    </label>
                    </>
                  )}
                  {/* Espacio para el tipo de select segun TYPE USER */}
                  {!isCheckedTable.users_enovates == false && (
                    <>
                      <Select
                        style={{
                          backgroundColor: inputEmpty && body.clients === "" ? "#B91C1C" : "",
                          border: inputEmpty && body.clients === "" ? "1px solid #B91C1C" : "",
                          borderRadius: "8px",
                          textAlignalign: "left"
                        }}
                        name="clients"
                        value={body.clients}
                        onChange={(value) => handleChangeSelect("clients",value, true)}
                        className="w-full max-w-[592px] h-[47px] rounded-[4px] border-solid mb-[17px] mt-[10px]"
                        mode="multiple"
                        tagRender={tagRender}
                      >
                        {TableClients.map(item => (
                          <Option key={item.idclient} value={item.client}>
                            {item.client}
                          </Option>
                        ))}
                      </Select>
                    </>
                  )}
                  {!isCheckedTable.users_externals == false && (
                    <>
                      <Select
                        style={{
                          backgroundColor: inputEmpty && body.clients === "" ? "#B91C1C" : "",
                          border: inputEmpty && body.clients === "" ? "1px solid #B91C1C" : "",
                          borderRadius: "8px",
                          textAlignalign: "left"
                        }}
                        name="clients"
                        value={body.clients}
                        onChange={(value) => handleChangeSelect("clients",value, false)}
                        className="w-full h-[47px] rounded-[4px] border-solid border-[#BCBCCC] mb-[17px] mt-[10px]"
                        placeholder="Select Client"
                      >
                        {TableClients.map((item) => (
                          <Option key={item.idclient} value={item.client}>
                            {item.client}
                          </Option>
                        ))}
                      </Select>
                    </>
                  )}
                </div>
              </div>
              {(isCheckedTable.users_enovates || isCheckedTable.users_externals) && (
                <>
                  <AddRegister Data={TableClients} Token={Token} name={"clients"} dataTable={(dataTable) => handleAddOption(dataTable, "clients")}></AddRegister>
                </>
              )}
              <div className="flex flex-row w-full mt-3 justify-start items-center">
                <div className="flex flex-col w-full text-start">
                  <label
                    htmlFor=""
                    className="flex w-full items-end text-start text-[15px] font-bold"
                  >
                    LEASE
                  </label>
                  <Select
                    style={{
                      backgroundColor: inputEmpty && body.proyects === "" ? "#B91C1C" : "",
                      border: inputEmpty && body.proyects === "" ? "1px solid #B91C1C" : "",
                      borderRadius: "8px",
                      textAlignalign: "left"
                    }}
                    name="proyects"
                    value={body.proyects}
                    onChange={(value) => handleChangeSelect("proyects",value, true)}
                    className="w-full max-w-[592px] h-[47px] rounded-[4px] border-solid border-[#BCBCCC] mb-[17px] mt-[10px]"
                    mode="multiple"
                    tagRender={tagRender}
                  >
                    {optionProyects.map((item,index) => (
                      <Option key={index} value={item}>
                        {item}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
              <AddRegister Data={optionProyects} Token={Token} name={"projects"} dataTable={(dataTable) => handleAddOption(dataTable, "projects")}></AddRegister>
              <div className="flex flex-row w-full mt-3 justify-start items-center">
                <div className="flex flex-col w-full text-start">
                  <label
                    htmlFor=""
                    className="flex w-full items-end text-start text-[15px] font-bold"
                  >
                    REGION
                  </label>
                  <Select
                    style={{
                      backgroundColor: inputEmpty && body.platforms === "" ? "#B91C1C" : "",
                      border: inputEmpty && body.platforms === "" ? "1px solid #B91C1C" : "",
                      borderRadius: "8px",
                      textAlignalign: "left"
                    }}
                    name="platforms"
                    value={body.platforms}
                    onChange={(value) => handleChangeSelect("platforms",value, true)}
                    className="w-full max-w-[592px] h-[47px] rounded-[4px] border-solid border-[#BCBCCC] mb-[17px] mt-[10px]"
                    mode="multiple"
                    tagRender={tagRender}
                  >
                    {optionPlatforms.map((item,index) => (
                          <Option key={index} value={item}>
                            {item}
                          </Option>
                        ))}
                  </Select>
                </div>
              </div>
              <AddRegister Data={optionPlatforms} Token={Token} name={"platforms"} dataTable={(dataTable) => handleAddOption(dataTable, "platforms")}></AddRegister>
              <div className="flex flex-row w-full mt-3 justify-start items-center">
                <div className="flex flex-col w-full text-start">
                  {isCheckedTable.users_enovates  && (
                    <>
                      {/* Espacio para el tipo de select segun TYPE USER */}
                      <label
                        htmlFor=""
                        className="flex w-full items-end text-start text-[15px] font-bold"
                      >
                        ROLE
                      </label>
                    </>
                  )}
                  {isCheckedTable.users_enovates && (
                    <>
                      {/* Espacio para el tipo de select segun TYPE USER */}
                      <Select
                        style={{
                          backgroundColor: inputEmpty && body.role === "" ? "#B91C1C" : "",
                          border: inputEmpty && body.role === "" ? "1px solid #B91C1C" : "",
                          borderRadius: "8px",
                          textAlignalign: "left"
                        }}
                        name="role"
                        value={body.role}
                        onChange={(value) => handleChangeSelect("role",value, true)}
                        className="w-full max-w-[592px] h-[47px] rounded-[4px] border-solid border-[#BCBCCC] mb-[17px] mt-[10px]"
                        mode="multiple"
                        tagRender={tagRender}
                        // defaultValue={["gold", "cyan"]}
                      >
                        {TableRoles.map((item,index) => (
                          <Option key={index} value={item}>
                            {item}
                          </Option>
                        ))}
                      </Select>
                    </>
                  )}
                </div>
              </div>
              {isCheckedTable.users_enovates && (
                <>
                  <AddRegister Data={TableClients} Token={Token} name={"role"}></AddRegister>
                </>
              )}
              <div className="flex flex-row w-full mt-3">
                <div className="flex flex-col w-full justify-center items-center">
                  <button
                    className="w-[100px] h-[46px] bg-pa-purple text-white rounded-full"
                    type="submit"
                  >
                    REGISTER
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
