import React, { PureComponent, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useTabDCA } from '../../../hooks/useTabDCA';

export const ChartProduction = ({forecastSelected}) => {
  const lastTableData = forecastSelected;
  const {dataForecast, refetchDataForecast, extractTitle} = useTabDCA(lastTableData);
  const { sensibility, period } = extractTitle(lastTableData);
  const dataToGraph = !!dataForecast ? dataForecast.data : [];

  useEffect(() => {
    refetchDataForecast();
  }, [refetchDataForecast]);

  function renderTooltip ({ active, payload, label}) {

    if (active && payload && payload.length) {
      const oilVal = payload[0].payload;
      const oilP = oilVal['y_plot_calc'];

      return (
        <div style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '10px' }}>
          <p >{`Month: ${label}`}</p>
            <div style={{ flex: 1 }}>
              {oilVal && (
                <p style={{ color: '#97FF8F' }}>
                  {`Oil Rate: ${(oilP).toFixed(2)}`}
                </p>
              )}
            </div>
        </div>
      );
    }
  
    return null;
  };

  return (
    <div className='flex flex-col w-full h-full'>
      <h1 className="text-center font-bold text-lg p-2">{`Sensibility: ${sensibility} - Period: ${period}`}</h1>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={200}
          data={dataToGraph}
          margin={{
            top: 20,
            right: 50,
            left: 20,
            bottom: 18,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis 
            dataKey="x_plot_true"             
            label={{
              value: `Time (Month)`,
              style: { textAnchor: "middle" },
              position: "bottom",
            }} 
          />
          <YAxis 
            label={{
                value: `Oil Rate (STB/D)`,
                style: { textAnchor: "middle" },
                angle: -90,
                position: "left",
                offset: 0,
            }}
          />
          <Tooltip content={renderTooltip}/>
          <Line connectNulls type="monotone" dataKey="y_plot_calc" name="Oil Rate" stroke="#97FF8F" fill="#97FF8F" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};
export default ChartProduction;